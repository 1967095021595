import React from 'react';
import { Col } from 'reactstrap';
import { Cpu } from 'react-feather';
import { WidgetName, WidgetTextTooltip } from '../../../../pages/Room/AIClustering/SelectedClusterView/Summary/WidgetTextTooltip';
import { useModal } from '../../../../context/modal/ModalComponent';
import { CoordinatedActivityList } from './CoordinatedActivityList';
import { setParam, unsetParam } from '../../../../utils/urlParams';
import { Content } from '../../../../services/Content/contentInterface';
import { CoordinationDataType } from '../../../../pages/Room/AIClustering/CoordinatedModals/coordinationDataTypes';

type ContentTypes = 'text' | 'datetime' | 'title' | 'threatObject' | 'contentType' | 'from' | 'url' | 'pubdatetime';

type CoordinatedActivityProps = {
    content?: Pick<Content, ContentTypes>
    coordinationData?: CoordinationDataType[]
}

export const CoordinatedActivity = ({ content, coordinationData }: CoordinatedActivityProps) => {
    const { setModal, closeModal } = useModal();

    const handleClose = () => {
        unsetParam('subTab');
        unsetParam('actor_id');
        unsetParam('coordination_id');
        closeModal();
    };

    const openCoordinationModal = () => {
        setParam('tab', 'coordination');
        setModal({
            header:
    <div className="d-flex align-items-center">
        <WidgetTextTooltip widgetName={WidgetName.CoordinationDetected} /><Cpu className="ml-1 mb-1" />
    </div>,
            component: <CoordinatedActivityList coordinationData={coordinationData as CoordinationDataType[]} content={content} />,
            size: 'xl',
            onClose: handleClose
        });
    };
    return (
        <Col className="border rounder-4 new-content-view mr-3 px-2 py-1 set-fit-content col-lg-4 col-xs-6 mb-1">
            <WidgetTextTooltip widgetName={WidgetName.CoordinatedActivity} classes="card-text title" />
            <div className="d-flex">
                <Cpu size={24} className="mr-1" />
                <span className="text-primary cursor-pointer"
                    onClick={openCoordinationModal}
                >{coordinationData?.length}
                </span>
            </div>
        </Col>
    ); };
