import React, { useState } from 'react';
import { AlertCircle } from 'react-feather';
import { Alert, Button } from 'reactstrap';
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { DataErrorIcon } from '../../../../assets/svg/DataErrorIcon';
import { Preview } from '../../../../assets/svg/Preview';
import { Loading } from '../../../../components/Loading';
import { TotalMatchesAndThreatsOverTime } from '../../../Room/Overview/Visualisation/TotalMatchesAndThreatsOverTime';
import { CurrentSentiment } from '../../../Room/Overview/Visualisation/Pie/CurrentSentiment';
import { TermAnalysis } from '../../../Room/Overview/Visualisation/TermAnalysis';
import { Wordcloud } from '../../../Room/Overview/Visualisation/Wordcloud';
import { useRoomEditor } from '../RoomEditorContext';
import { Matches } from './Matches';
import { validateQuery } from './validateQuery';
import { getPreviewData } from './usePreviewData';
import { saveFilters, saveRoomPreviewKeywords } from '../../../Room/store';
import { TopLocationMentions } from '../../../Room/Overview/Visualisation/Table/TopLocationMentions';
import { TopLanguages } from '../../../Room/Overview/Visualisation/Table/TopLanguages';
import { TopPublishers } from '../../../Room/Overview/Visualisation/Table/TopPublishers';
import { TopSharedURLs } from '../../../Room/Overview/Visualisation/Table/TopSharedURLs';
import { getFilterQuery } from '../../../../utils/variables';
import { getToast } from '../../../../utils/getToast';

export const RoomPreview = () => {
    const client = useApolloClient();
    const toast = getToast();
    const { isQueryEmpty, room, validationErrors, errors, setErrors } = useRoomEditor();
    const dispatch = useDispatch();
    const [hasGeneratedPreview, setHasGeneratedPreview] = useState(false);
    const [validations, setValidations] = useState(undefined);
    const [previewDataCount, setPreviewDataCount] = useState(undefined);
    const [keywordsToHighlight, setKeywordsToHighlight] = useState([]);
    const [loading, setLoading] = useState(false);
    const validatorErrors = Object.values(validationErrors).find(error => error.isError);
    const isProcessingKeywords = Boolean(room.query.included.find(a => a.type === 'unknown')
        || room.query.excluded.find(a => a.type === 'unknown'));
    const onGeneratePreviewClick = async () => {
        if (isQueryEmpty || validatorErrors) {
            setErrors({
                ...errors,
                emptyBoolean: room.query_type === 'boolean' && !room.query.boolean,
                emptyKeywords: !room.query.included.length && !room.query.excluded.length,
                validationErrors: validatorErrors
            });
            toast.error('Resolve all errors before continuing');
        } else {
            setLoading(true);
            setHasGeneratedPreview(true);
            if (!validatorErrors) {
                const validateQueryResponse = await validateQuery({
                    client,
                    room,
                    validateIngestionQuery: false
                });
                const newValidations = validateQueryResponse?.validateQueryResponse;
                getFilterQuery(newValidations?.query || {});
                setValidations(newValidations);
                setKeywordsToHighlight(validateQueryResponse.keywords);
                dispatch(saveRoomPreviewKeywords({ highlightKeywords: validateQueryResponse.keywords }));

                let endDate = moment().unix();
                if (room.contentVisibilityRestriction) {
                    endDate = moment().subtract(room.contentVisibilityRestriction, 'days').unix();
                }
                dispatch(saveFilters({
                    convertedBooleanQuery: newValidations?.query || {},
                    dateRange: {
                        startDate: moment(room.start_date).unix(),
                        endDate
                    }
                }));

                const { count } = await getPreviewData({
                    client,
                    query: newValidations?.query || {},
                    limit: 0,
                    sort: 'dateNew',
                    fetchPolicy: 'no-cache',
                    filters: {
                        date: {
                            endDate
                        }
                    }
                });
                setPreviewDataCount(count);
            }
            setLoading(false);
        }
    };

    if (isQueryEmpty || !hasGeneratedPreview) {
        return (
            <div className="py-10 d-flex justify-content-center align-items-center flex-column">
                <Preview />
                <h2 className="mt-2 mb-0">Generate room preview</h2>
                <p className="mb-2 mt-2 text-center w-75">
                    See what your Situation Room will look like by generating a room preview based on a small sample of data.
                </p>
                <Button color="secondary" onClick={() => onGeneratePreviewClick()}>
                    Generate room preview
                </Button>
            </div>
        );
    }

    if (loading) {
        return <Loading relative height={300} message="Generating your room preview" />;
    }
    if (loading || validations?.isValid || previewDataCount === 0 || validatorErrors) {
        return (
            <div className="py-10 d-flex justify-content-center align-items-center flex-column text-center">
                <DataErrorIcon />
                <p className="mb-0 mt-2 maxw-500">
                    Room preview works on the content we have processed in the last 30 days.
                    Reasons why preview content can be empty:
                </p>
                <ul className="pl-2 centered-list">
                    <li>The query is too strict or there is an error within the query;</li>
                    <li>We don&apos;t have coverage for the query, within the last 30 days;</li>
                    <li>The preview data source is slow/down</li>
                </ul>
                <Button color="secondary" onClick={() => onGeneratePreviewClick()} disabled={isQueryEmpty || isProcessingKeywords}>
                    Refresh results
                </Button>
            </div>
        );
    }
    return (
        <div>
            <Button className="mb-4"
                color="secondary"
                onClick={() => onGeneratePreviewClick()}
                disabled={isQueryEmpty || isProcessingKeywords}
            >
                Refresh results
            </Button>
            <Alert color="primary" className="py-1 px-2 mb-4">
                <div className="d-flex align-items-center">
                    <div>
                        <AlertCircle size={18} className="mr-1 text-primary" />
                    </div>
                    <span>
                        This is a sample of your situation room Overview. We only show a selection of matches while you&apos;re
                        creating/editing your situation room. When you save your situation room, we will display all matches.&nbsp;
                        <a href="https://logically-intelligence.readme.io/docs/room-preview" target="_blank" rel="noreferrer">
                            Learn more about room preview.
                        </a>
                    </span>
                </div>
            </Alert>
            <div className="d-flex flex-wrap align-items-start flex-wrap mx-n2">
                <div className="flex-1 px-2 mb-4 minw-400">
                    <Matches keywordsToHighlight={keywordsToHighlight} />
                    <div className="bg-white px-3 pt-3 pb-2 my-3">
                        <TopSharedURLs preview />
                    </div>
                    <div className="bg-white px-3 pt-3 pb-2 mb-3">
                        <TopLanguages preview />
                    </div>
                </div>
                <div className="flex-1 px-2 mb-4 minw-400">
                    <div className="bg-white px-3 pt-3 pb-2 mb-3 position-relative">
                        <TotalMatchesAndThreatsOverTime preview />
                    </div>
                    <div className="bg-white px-3 pt-3 pb-2 mb-3">
                        <Wordcloud preview />
                    </div>
                    <div className="bg-white px-3 pt-3 pb-2 mb-3">
                        <TopPublishers preview />
                    </div>
                    <div className="bg-white px-3 pt-3 pb-2 mb-3">
                        <TermAnalysis preview
                            room={room}
                        />
                    </div>
                    <div className="bg-white px-3 pt-3 pb-2 mb-3">
                        <CurrentSentiment preview />
                    </div>
                    <div className="bg-white px-3 pt-3 pb-2 mb-3">
                        <TopLocationMentions preview />
                    </div>
                </div>
            </div>
        </div>
    );
};
