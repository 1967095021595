import { useQuery } from '@apollo/client';
import { gql } from '../../generated';
import { getRoom } from '../../utils/variables';

export const useCoordinationTypes = ({ ids }: { ids?: string[] } = {}) => {
    const room = getRoom();
    const { data, loading } = useQuery(GET_COORDINATION_TYPES, {
        variables: {
            projectId: room.project_id,
            ids
        }
    });
    return {
        coordinationTypes: data?.getCoordinationTypes || [],
        loading
    };
};

export const GET_COORDINATION_TYPES = gql(`
    query GetCoordinationTypes($projectId: String!, $ids: [String]) {
        getCoordinationTypes(projectId: $projectId, ids: $ids) {
            name
            count
            ids
            narrativesCount
        }
    }
`);
