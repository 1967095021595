import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GET_CLUSTERS_FOR_LIST_VIEW } from '../../../../../../../graphql';
import { Loading } from '../../../../../../../components/Loading';
import { Cluster } from '../../../../../AIClustering/types';
import { Pagination } from '../../../../../../../components/Molecules';
import { getRoom } from '../../../../../../../utils/variables';
import { NarrativesRow } from './NarrativesRow';
import { NoSearchResults } from '../../../../../../../assets/svg/noSearchResults';
import { EmptyState } from '../../../../../../../components/EmptyState';
import { RootState } from '../../../../../../../store';
import { getUpdatedReportPages } from '../getUpdatedReportPages';
import { setReportAction } from '../../../../../../../redux/reports';

export const NarrativesTableContainer = () => {
    const room = getRoom();
    const dispatch = useDispatch();
    const [offset, setOffset] = useState(0);
    const { data: clustersData, loading: clusterLoading } = useQuery(GET_CLUSTERS_FOR_LIST_VIEW, {
        variables: {
            projectId: room?.project_id,
            limit: 10,
            skip: offset,
        },
        fetchPolicy: 'cache-and-network'
    });
    const getClustersData = clustersData?.getClusters;
    const total = getClustersData?.total || 0;
    const clusters: Cluster[] = getClustersData?.clusters || [];

    const { report } = useSelector((state: RootState) => state.createReport);
    const selectedNarratives = report.pages.filter(a => a.type === 'aiNarratives').map(a => a.blocks).flat();

    const toggleThreat = (narrative: any, selected: any) => {
        const pages = getUpdatedReportPages(
            report,
            selected ? selectedNarratives.filter((a: any) => a.id !== narrative.id) : [...selectedNarratives, narrative],
            'aiNarratives'
        );
        dispatch(setReportAction({
            ...report,
            pages
        }));
    };

    if (clusterLoading) {
        return <Loading relative height={300} />;
    }

    if (!clusters || clusters?.length === 0) {
        return (
            <EmptyState title="No data found."
                summary="Please change the search string and try again."
                svgIcon={<NoSearchResults />}
            />
        );
    }

    return (
        <>
            <span className="tag mb-2" color="dark">{total || 0} narratives </span>
            <Table hover className="border-left border-right mb-0">
                <thead>
                    <tr>
                        <th>AI narratives</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {clusters.map((cluster: Cluster) => {
                        if (!cluster) return null;
                        const selected = selectedNarratives.find((a: any) => a.id === cluster.id);
                        return (
                            <NarrativesRow key={cluster.id}
                                cluster={cluster}
                                selected={selected}
                                toggleThreat={toggleThreat}
                            />
                        ); })}
                </tbody>
            </Table>
            {total > 10 && (
                <Pagination className="p-2 border mb-0"
                    total={total}
                    itemsPerPage={10}
                    offset={offset}
                    setOffset={setOffset}
                />
            )}
        </>
    );
};
