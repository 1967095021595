import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { merge, omit } from 'lodash';
import { TOP_PUBLISHERS } from '../../../services/Overview/topPublishers';
import { getRoom } from '../../../utils/variables';
import { RootState } from '../../../store';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { DropdownWithSearchAndCheckboxes } from '../components/DropdownWithSearchAndCheckboxes';
import { useContentFilters } from '../../../pages/Room/useFilters';

type Actor = {
    publisher: string,
    count: number
    contentCount?: number
}

type ActorsFilterProps = {
    clusters: boolean,
    appliedFilters: any
}

export const ActorsFilter = ({ clusters = false, appliedFilters }: ActorsFilterProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const filters = useContentFilters({ useGlobalFilters: true });
    const { from: selectedActors }: { from: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const room = getRoom();
    const { projectId } = location.state || {};
    const sanitizedAppliedFilters = appliedFilters?.filters
        ? omit(appliedFilters.filters, ['from'])
        : {};
    const { data, loading } = useQuery(!clusters ? TOP_PUBLISHERS : GET_TOP_ACTORS_IN_CLUSTERS, {
        variables: {
            ...(!clusters ? filters : {}),
            filters: omit(merge(filters.filters, sanitizedAppliedFilters), ['labels']),
            size: 300,
            projectId: (!clusters ? filters.projectId : room?.project_id) || projectId
        }
    });
    let topActors = [];
    if (clusters) {
        topActors = data?.getTopActorsInClusters?.data || [];
    } else {
        topActors = data?.topPublishers?.data || [];
    }
    const filteredActorsData = topActors.filter((a: Actor) => a?.publisher);

    const actorsData = filteredActorsData.map((actor: Actor) => ({
        id: actor.publisher,
        name: actor.publisher,
        selected: selectedActors.includes(actor.publisher),
        count: (clusters ? actor.contentCount : actor.count)
    })).sort((a: {count: number}, b: {count: number}) => b.count - a.count);

    const handleSelectItem = (id: string) => {
        dispatch(saveSelectedFilters({
            from: selectedActors.includes(id)
                ? selectedActors.filter(actor => actor !== id)
                : [...selectedActors, id]
        }));
    };
    const countFilters = selectedActors.length;

    return (
        <div>
            <DropdownWithSearchAndCheckboxes handleSelect={handleSelectItem}
                items={actorsData}
                placeholder="Search"
                description="Top 300 actors"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                loading={loading}
                itemsPerPage={30}
            />
        </div>
    );
};

export const GET_TOP_ACTORS_IN_CLUSTERS = gql`
    query getTopActorsInClusters(
        $projectId: String
        $size: Int
    ) {
        getTopActorsInClusters(
            projectId: $projectId
            size: $size 
        ) {
            data {
                publisher
                count
                contentCount
            }
            total    
        }
    }
`;
