import { useSelector } from 'react-redux';
import moment from 'moment';
import { allCtypesMongo, getRoom, languagesList } from '../../utils/variables';
import { sentiments } from '../../utils/lib/search';

export const useGlobalFiltersInClusters = ({ defaultFilters = {}, useGlobalFilters = true } = {}) => {
    const room = getRoom();
    const filtersCopy = useSelector((state) => (state.clusterFilters.filters));
    const filters = JSON.parse(JSON.stringify(filtersCopy));
    delete filters?.labels;
    delete filters?.ids;
    const sourcesFilter = filters?.sourcesWithDomains?.map(source => source.key) || [];
    const searchFilters = JSON.parse(JSON.stringify(defaultFilters));

    if (useGlobalFilters) {
        if (filters.query.length && !searchFilters?.search) searchFilters.search = filters.query;
        if (filters.dateRange?.startDate && filters.dateRange?.endDate && !searchFilters?.date) {
            searchFilters.date = { ...filters.dateRange };
        }
        if (filters.origin?.length && !searchFilters?.locations) searchFilters.locations = filters.origin;

        searchFilters.contentType = getSupportedCTypesFilters({
            sourcesFilter,
            providedFilters: searchFilters?.contentType
        });
        searchFilters.sentiment = getSupportedSentimentFilters({
            filtersSentiment: filters?.sentiment,
            providedSentiment: searchFilters?.sentiment,
        });
        searchFilters.languages = getSupportedLanguagesFilters({
            filtersLanguages: filters?.languages,
            providedLanguages: searchFilters?.languages
        });
        if (filters.from?.length) {
            searchFilters.from = filters.from;
        }
        if (filters.keywords?.length) {
            searchFilters.keywords = filters.keywords;
        }
        if (filters.coordinationTypes?.length) {
            searchFilters.coordinationTypes = filters.coordinationTypes.map(a => a.name);
        }
    }

    const ret = {
        ...(room?.project_id && { projectId: room.project_id }),
        filters: searchFilters
    };

    if (useGlobalFilters && filters.dateRange?.startDate && filters.dateRange?.endDate && !searchFilters?.date) {
        searchFilters.date = { ...filters.dateRange };
    }

    if (!searchFilters?.date?.endDate && room.contentVisibilityRestriction) {
        if (!searchFilters?.date) searchFilters.date = {};
        searchFilters.date.endDate = moment(moment().subtract(room.contentVisibilityRestriction, 'days').startOf('day').toDate()).unix();
    }

    return ret;
};

const getSupportedCTypesFilters = ({ sourcesFilter = [], providedFilters = [] }) => {
    const allTypes = allCtypesMongo().map((type) => type.key);

    if (providedFilters.length) {
        return allTypes.filter((type) => providedFilters.includes(type));
    }
    if (sourcesFilter.length) {
        return allTypes.filter((type) => sourcesFilter.includes(type));
    }

    return undefined;
};

const getSupportedSentimentFilters = ({ filtersSentiment = [], providedSentiment = [] }) => {
    const allSentiment = sentiments.map((sentiment) => sentiment.key);

    if (providedSentiment.length) {
        return allSentiment.filter((sentiment) => providedSentiment.includes(sentiment));
    }
    if (filtersSentiment.length) {
        return allSentiment.filter((sentiment) => filtersSentiment.includes(sentiment));
    }

    return undefined;
};

const getSupportedLanguagesFilters = ({ filtersLanguages = [], providedLanguages = [] }) => {
    const allLanguages = languagesList();

    if (providedLanguages.length) {
        return allLanguages.filter(lang => providedLanguages.includes(lang));
    }
    if (filtersLanguages.length) {
        return allLanguages.filter(lang => filtersLanguages.includes(lang));
    }

    return undefined;
};
