import React, { useRef, useState, useEffect } from 'react';
import { EditorState } from 'draft-js';

export const ImageComponent = ({ block, contentState, blockProps, setState }) => {
    const entityKeyFromBlock = block?.getEntityAt?.(0);
    const { src, width = '200' } = contentState.getEntity(entityKeyFromBlock).getData();
    const imageRef = useRef(null);
    const [imageWidth, setImageWidth] = useState(width); // State for width

    const maxHeight = '350px';
    useEffect(() => {
        if (imageRef.current) {
            setImageWidth(imageRef.current.clientWidth); // Set initial width
        }
    }, []);

    const handleResizeEnd = () => {
        imageRef.current.focus();
        if (!imageRef.current) return;
        const newWidth = imageRef.current.offsetWidth; // Use offsetWidth instead of clientWidth
        setImageWidth(newWidth);
        const newContentState = contentState.mergeEntityData(entityKeyFromBlock, { width: newWidth });
        const newEditorState = EditorState.set(blockProps.editorState, {
            currentContent: newContentState,
        });

        setState?.(newEditorState);
    };
    if (blockProps.disabled) {
        return (
            <div style={{
                display: 'inline-block',
                maxWidth: '100%',
                float: 'left',
                position: 'relative',
                width: imageWidth,
                height: 'auto',
                zIndex: 10,
                padding: 6,
                paddingLeft: 0

            }}
            >
                <img src={src}
                    alt="Editor_Image"
                    style={{
                        width: '100%',
                        height: 'auto'
                    }}
                />
            </div>
        );
    }
    return (
        <div
            ref={imageRef}
            style={{
                display: 'inline-block',
                maxWidth: '100%',
                overflow: 'hidden',
                cursor: 'pointer',
                resize: 'horizontal',
                float: 'left',
                position: 'relative',
                border: '2px solid blue',
                width: imageWidth,
                height: 'auto',
                zIndex: 10,
                padding: 6,
                paddingLeft: 0,
                maxHeight,
            }}
            draggable
            onDragEnd={(e) => blockProps.onDragOver?.(e)}
            onMouseUp={handleResizeEnd}
        >
            <img
                src={src}
                alt="Editor_Image"
                style={{
                    width: '100%',
                    height: 'auto',
                    cursor: 'pointer',
                    userSelect: 'none',
                    pointerEvents: 'auto',
                }}
            />

        </div>
    );
};
