import { gql, useQuery } from '@apollo/client';
import { merge, omit } from 'lodash';
import { formatLabel } from '../../pages/Room/Overview/Visualisation/utils';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useSources = ({ dateFilter = undefined, size = 10, includeThreats = false,
    useGlobalFilters = true, clusters = false, projectId = '', appliedFilters = undefined, isThreatsPage = false } = {}) => {
    const filters = useContentFilters({
        useGlobalFilters,
        defaultFilters: {
            date: dateFilter
        }
    });
    const finalFilters = clusters ? { projectId: filters.projectId || projectId } : filters;
    const sanitizedAppliedFilters = appliedFilters?.filters
        ? omit(appliedFilters.filters, ['contentType'])
        : {};
    const ret = useQuery(getTopSourcesQuery({ includeThreats, clusters }), {
        variables: {
            ...finalFilters,
            filters: omit(merge(filters.filters, sanitizedAppliedFilters), ['labels']),
            size,
            ...(!clusters ? { threats: isThreatsPage } : {})
        }
    });
    if (clusters) {
        return {
            ...ret,
            data: ret?.data ? ret.data?.getTopSourcesInClusters?.data?.map(item => ({
                ...item,
                key: item.source,
                source: formatLabel(item.source, 'ctype__keyword')
            })) || [] : [],
            total: ret.data?.topSources?.total || 0
        };
    }
    return {
        ...ret,
        data: ret.data ? ret.data?.topSources?.data?.map(item => ({
            ...item,
            key: item.source,
            source: formatLabel(item.source, 'ctype__keyword')
        })) || [] : [],
        total: ret.data?.topSources?.total || 0
    };
};

export const getTopSourcesQuery = ({ includeThreats = false, clusters = false } = {}) => { if (!clusters) {
    return gql`
    query topSources(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $size: Int
        $threats: Boolean
    ) {
        topSources(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            size: $size
            threats: $threats
        ) {
            data {
                source
                count
                ${includeThreats ? 'threats' : ''}
            }
            total
        }
    }
`; }
return gql`
        query getTopSourcesInClusters(
            $projectId: String
            $size: Int
        ) {
            getTopSourcesInClusters(
                projectId: $projectId
                size: $size
            ){
                data {
                    source
                    count
                    contentCount
                }
                total     
            }
          }
    `;
};
