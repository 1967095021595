import React from 'react';
import { ArrowLeft } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { saveFilters } from '../../../pages/Room/store';
import { getRoom } from '../../../utils/variables';

type BackButtonProps = {
    pathName: string;
    buttonName?: string;
    gotToPreviousPath?: boolean;
    className?: any;
    betaFilters?: boolean;
}

export const BackButton = ({ pathName, buttonName = 'Back', gotToPreviousPath, className,
    betaFilters = false }: BackButtonProps) => {
    const room = getRoom();
    const history = useHistory();
    const dispatch = useDispatch();
    const content = (
        <div className="d-flex align-items-center">
            <ArrowLeft size={20} className="mr-11" />
            <span className="line-height-1">{buttonName}</span>
        </div>
    );
    if (history && gotToPreviousPath && history.location.key) {
        return (
            <Button color="link"
                className={className || 'mt-2 mb-4 d-inline-block p-0'}
                onClick={() => history.goBack()}
            >
                {content}
            </Button>
        );
    }
    const handleGoBack = () => {
        const parseFilters = sessionStorage.getItem('initialFilters');
        const initialFilters = parseFilters ? JSON.parse(parseFilters) : null;
        // Exclude Date filter based on request
        delete initialFilters.dateRange;
        delete initialFilters.dateRangeOption;
        if (initialFilters) {
            dispatch(saveFilters({ ...initialFilters, roomId: room?.id }));
            sessionStorage.removeItem('initialFilters');
        }
    };
    if (betaFilters) {
        return (
            <Button onClick={() => { handleGoBack(); }} color="link" className="m-0 p-0">
                <Link to={pathName} className={className || 'mt-2 mb-4 d-inline-block ml-n4'}>
                    {content}
                </Link>
            </Button>
        );
    }
    return (
        <Link to={pathName} className={className || 'mt-2 mb-4 d-inline-block'}>
            {content}
        </Link>
    );
};
