import React from 'react';
import { SignalName } from './threatSignalTooltip';

export const threatSignalTooltipText = (widgetName: string) => {
    let tooltipText;
    switch (widgetName) {
        case SignalName.ToxicInformation:
            tooltipText = `Content Logically class as toxic, including language that is inflammatory,
                 insulting, obscene, flirtatious, profane, threatening, or sexually explicit.
                  It also includes attacks on commenters and authors.`;
            break;
        case SignalName.AttackOnCommenter:
            tooltipText = `A sub threat of toxic information that extracts/understands the commenter
                 entity/name and analyses language directed at them.`;
            break;
        case SignalName.AttackOnAuthor:
            tooltipText = `A sub threat of toxic information that extracts/understands
                 the author entity/name and analyses language directed at them.`;
            break;
        case SignalName.AttackOnIdentity:
            tooltipText = (
                <>
                    <p>Negative or hateful comments targeting someone because of their identity.
                        Identity being a users inherent or chosen characteristics,
                        some examples include race, ethnicity, religion, gender, disability, age, political beliefs, socio-economic status.
                    </p>
                    <p>Sub threat of toxic information.</p>
                </>
            );
            break;
        case SignalName.InsultingLanguage:
            tooltipText = (
                <>
                    <p>Insulting, inflammatory, or negative comments towards a person or a group of people.</p>
                    <p>Sub threat of toxic information.</p>
                </>
            );
            break;
        case SignalName.Inflammatory:
            tooltipText = (
                <>
                    <p>Content intending to provoke or inflame.</p>
                    <p>Sub threat of toxic information.</p>
                </>
            );
            break;
        case SignalName.ToxicLanguage:
            tooltipText = (
                <>
                    <p>A rude, disrespectful, or unreasonable comment that is likely to make people leave a discussion.</p>
                    <p>Sub threat of toxic information.</p>
                </>
            );
            break;
        case SignalName.severlyToxic:
            tooltipText = (
                <>
                    <p> A very hateful, aggressive, disrespectful comment or otherwise very likely to make a user
                        leave a discussion or give up on sharing their perspective. This attribute is much less sensitive to more
                        mild forms of toxicity, such as comments that include positive uses of curse words.
                    </p>
                    <p>Sub threat of toxic information</p>
                </>
            );
            break;
        case SignalName.ThreateningLanguage:
            tooltipText = (
                <>
                    <p>Describes an intention to inflict pain, injury, or violence against an individual or group.</p>
                    <p>Sub threat of toxic information.</p>
                </>
            );
            break;
        case SignalName.Automated:
            tooltipText = `Content that originates from a bot-operated account(s) & promotes sources which generate
                 information to purposefully foster a predetermined agenda; or inauthentic actors (‘sock puppets’) that
                  could impersonate election officials or other federal, state, and local authorities.`;
            break;
        case SignalName.Domestic:
            tooltipText = `This classifier looks for content with themes of racism, anti-LGBTQ+,
                 and anti-authority & government.`;
            break;
        case SignalName.SourceCredibility:
            tooltipText = `This refers to the source's likelihood to be unreliable. Determined by the sources
                 use of ads, website security, technologies used & language on the page.`;
            break;
        case SignalName.CustomThreat:
            tooltipText = "Content that matches a narrative set within 'Custom Narratives'.";
            break;
        case SignalName.EmergingBoycott:
            tooltipText = `Posts that are indicative of ongoing boycotts or calls to take action against a product, 
            company, organisation, country or regime.`;
    }
    return tooltipText;
};
