import React, { useState } from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { SelectAllCheckBox } from './SelectAllCheckbox';
import { TooltipWrapper } from '../../../ToolTip/TooltipWrapper';
import { largeNumber } from '../../../../utils/NumberFormat';
import { truncateString } from '../../../../utils/text';

type ItemProps = {
    id: string,
    name: string,
    selected: boolean,
    count?: number
}

type MultiCheckboxFilterProps = {
    title: string,
    filters: ItemProps[],
    values: string[],
    setValues: (e: string[], selectAll?: boolean) => void,
    value: string,
    disabledOptions?: string[],
    disabled?: boolean,
    threat?: boolean,
    cluster?: boolean
    dontTruncate?: boolean
}

export const MultiCheckboxFilter = ({ title, filters, values, setValues, value, disabledOptions = [],
    disabled = false, threat = false, cluster = false, dontTruncate = false }: MultiCheckboxFilterProps) => {
    const [newid] = useState(uuid());
    return (
        <SelectAllCheckBox id={`checkbox-${title}-${newid}`}
            options={filters?.map(({ id }) => id)}
            values={values}
            selectAllReversed
            allCheck={(ids) => setValues(ids, true)}
            disabledOptions={disabledOptions}
            disabled={disabled}
        >
            {filters?.map((item, index) => (
                <FormGroup key={item.id} className={index === filters.length - 1 ? 'mb-0' : ''}>
                    <CustomInput type="checkbox"
                        checked={values.indexOf(item.id) === -1}
                        className={`custom-control-input-big${item?.id?.length > 19 ? ' word-short' : ''} grid-item`}
                        label={item.name?.length >= 14
                            ? (
                                <>
                                    <TooltipWrapper tooltipText={item.name} id={`tooltip-${newid}-${item.id}`}>
                                        <span>{dontTruncate ? item?.name : truncateString(item?.name, 20)}</span>
                                    </TooltipWrapper>
                                    <span className="ml-1">({largeNumber(item?.count)})</span>
                                </>
                            )
                            : (
                                <>
                                    <span className={(threat || dontTruncate) ? '' : 'truncate-this'}>
                                        {dontTruncate ? item?.name : truncateString(item?.name, 20)}
                                    </span>
                                    {!cluster && <span className="ml-1">({largeNumber(item?.count)})</span>}
                                </>
                            // eslint-disable-next-line react/jsx-indent
                            )}
                        id={`filter-checkbox-${item.id}-${newid}`}
                        data-testid={`checkbox-${item.id}`}
                        value={value}
                        onChange={({ target: { checked } }) => {
                            if (!checked) {
                                setValues([...values, item.id]);
                            } else {
                                setValues(values.filter(a => a !== item.id));
                            }
                        }}
                        disabled={disabled || item?.count === 0}
                    />
                </FormGroup>
            ))}
        </SelectAllCheckBox>
    );
};
