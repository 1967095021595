import React, { useMemo, useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { EditorState, convertToRaw } from 'draft-js';
import { v4 as uuid } from 'uuid';
import { Maximize2, Minimize2, Upload } from 'react-feather';
import { useModal } from '../../../../../../../context/modal/ModalComponent';
import { AddOverviewWidgetsModal } from './AddOverviewWidgetsModal';
import { DeleteOverviewBlockButton } from './DeleteOverviewBlockButton';
import { LocationMentions } from './LocationMentions';
import { Sentiment } from './Sentiment';
import { Sources } from './Sources';
import { TopLanguages } from './TopLanguages';
import { TopPublishers } from './TopPublishers';
import { TopSharedUrls } from './TopSharedUrls';
import { TotalThreatsAndMatches } from './TotalThreatsAndMatches';
import { WordcloudGenerator } from './WordcloudGenerator';
import { TextEditor } from '../TextEditor';
import { updatePageAction } from '../../../../../../../redux/reports';
import { createEditorState } from '../../createEditorState';
import { TextTooltip } from '../../../../../../../components/ToolTip/TextTooltip';

export const OverviewPage = ({ data, pdf, sendToParent }) => {
    const dispatch = useDispatch();
    const { setModal } = useModal();
    const expandedTextEditor = data.blocks.filter(item => item.type === 'textEditor' && item.isExpanded === true);
    const [isExpanded, setIsExpanded] = useState(expandedTextEditor.length ? data.id : '');
    const childRef = useRef(null);
    const isImageAlreadyAdded = useMemo(() => {
        if (!data.bodyText) return false; // Ensure data.bodyText exists
        const contentState = data.bodyText.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        return Object.values(rawContent.entityMap).some(entity => entity.type === 'IMAGE');
    }, [data.bodyText]);

    const addTextEditor = () => {
        if (!data.blocks.some((widget) => widget.type === 'textEditor')) {
            dispatch(updatePageAction({
                id: data.id,
                updates: {
                    blocks: [
                        ...data.blocks, // Keep existing widgets
                        {
                            id: uuid(),
                            type: 'textEditor',
                            title: 'Text Editor',
                            isExpanded: false
                        }
                    ],
                    bodyText: createEditorState({ blocks: [], entityMap: {} }),
                }
            }));
        }
    };
    const toggleExpand = (blockId) => {
        dispatch(updatePageAction({
            id: data.id,
            updates: {
                blocks: data.blocks.map(block =>
                    (data.id === blockId // Identify block uniquely
                        ? { ...block, isExpanded: block.isExpanded ? undefined : true }
                        : block))
            }
        }));
        setIsExpanded(prev => (prev === blockId ? '' : data.id));
    };
    const handleImageUpload = () => {
        if (childRef.current) {
            childRef.current.handleImageUpload(); // Call the child's function
        }
    };
    if (data.blocks.length === 0) {
        return (
            <div className="w-100 flex-1 d-flex align-items-center justify-content-center">
                <Button color="link"
                    className="text-decoration-none"
                    onClick={() => setModal({
                        header: false,
                        size: 'full',
                        component: <AddOverviewWidgetsModal />
                    })}
                >
                    + Add overview widgets
                </Button>
            </div>
        );
    }
    return (
        <Row className="align-items-stretch">
            {data.blocks.map((widget, index) => {
                const textEditor = widget.type === 'textEditor';
                const shouldExpand = data.blocks.length <= (isExpanded ? 2 : 3) && textEditor && data.id === isExpanded;
                const columnSize = shouldExpand ? 8 : 4;

                let borderClass = 'border';
                if (textEditor) {
                    if (!pdf) {
                        borderClass = 'border-dashed';
                    }
                }
                return (
                    <Col key={widget.id || widget.type}
                        xs={12}
                        md={columnSize}
                    >
                        <div className="pb-3 h-100 position-relative">
                            <div className="position-absolute report-overview-edit-position">
                                <DeleteOverviewBlockButton block={widget.type} id={widget.id} pageId={data.id} />
                            </div>
                            <div className={`${borderClass} h-100 font-size-10`}>
                                {widget.type !== 'textEditor' ? (
                                    <>
                                        <div className="bg-light p-1 border-bottom font-weight-bold">
                                            {widget.title === 'Top publishers' ? 'Top actors' : widget.title}
                                        </div>
                                        {widget.type === 'locationMentions' && <LocationMentions small className="mw-50" />}
                                        {widget.type === 'topPublishers' && <TopPublishers small />}
                                        {widget.type === 'sources' && <Sources small />}
                                        {widget.type === 'topLanguages' && <TopLanguages small />}
                                        {widget.type === 'topSharedUrls' && <TopSharedUrls small className="topSharedUrls" />}
                                        {widget.type === 'totalThreatsAndMatches' && <TotalThreatsAndMatches small />}
                                        {widget.type === 'sentiment' && <Sentiment small />}
                                        {widget.type === 'wordcloudGenerator' && <WordcloudGenerator small />}
                                    </>
                                ) : (
                                    <div>
                                        {!isImageAlreadyAdded && (
                                            <div className="position-absolute report-overview-upload-position cursor-pointer">
                                                <div role="button"
                                                    className="rounded-circle border border-white bg-primary d-flex align-items-center
                                                        justify-content-center icon-delete-button text-white"
                                                    onClick={handleImageUpload}
                                                >
                                                    <TextTooltip title="Upload" placement="top" withIcon id={`${data.id}-upload`}>
                                                        <Upload size="16" className="text-white" />
                                                    </TextTooltip>
                                                </div>
                                            </div>
                                        )}
                                        {data.blocks.length < 3 && (
                                            <div className="position-absolute report-overview-expand-position cursor-pointer">
                                                <div role="button"
                                                    className="rounded-circle border border-white bg-success d-flex align-items-center
                                                        justify-content-center icon-delete-button text-white"
                                                    onClick={() => toggleExpand(data.id)}
                                                >
                                                    <TextTooltip title="Expand | Collapse" placement="top" withIcon id={data.id}>
                                                        {!isExpanded
                                                            ? <Maximize2 size={15} className="text-white" />
                                                            : <Minimize2 size={15} className="text-white" />}
                                                    </TextTooltip>
                                                </div>
                                            </div>
                                        )}
                                        <br />
                                        <TextEditor
                                            value={data.bodyText || EditorState.createEmpty()}
                                            onChange={sendToParent}
                                            placeholder={pdf ? '' : 'Add body text here'}
                                            multiline
                                            disabled={pdf}
                                            parentRef={childRef}
                                        />
                                    </div>

                                )}
                            </div>
                        </div>
                    </Col>
                ); })}
            {data.blocks.length < 3 && !isExpanded && (!data.blocks.some((widget) => widget.type === 'textEditor') ? (
                <Col>
                    <div className="d-flex flex-column align-items-center gap-2 w-50">
                        <Button color="primary" onClick={addTextEditor} className="mb-2">
                            Add Text
                        </Button>
                        <Button color="link"
                            className="text-decoration-none"
                            onClick={() => setModal({
                                header: false,
                                size: 'full',
                                component: <AddOverviewWidgetsModal />
                            })}
                        >
                            + Add overview widgets
                        </Button>
                    </div>
                </Col>

            )
                : (
                    <Col>

                        {pdf ? null : (
                            <Button color="link"
                                className="text-decoration-none"
                                onClick={() => setModal({
                                    header: false,
                                    size: 'full',
                                    component: <AddOverviewWidgetsModal />
                                })}
                            >
                                + Add overview widgets
                            </Button>
                        )}
                    </Col>
                ))}
        </Row>
    );
};
