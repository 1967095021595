import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { X } from 'react-feather';
import { Button, Spinner } from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { ObjectID } from 'bson';
import { useUser } from '../../../../../context/user/UserContext';
import { EmptyStatePanel } from './EmptyStatePanel';
import { CREATE_CUSTOM_NARRATIVE, GET_CUSTOM_NARRATIVES, UPDATE_CUSTOM_NARRATIVE } from '../../../../../graphql';
import { Loading } from '../../../../../components/Loading';
import { TopicLeftPanel } from './TopicLeftPanel';
import { EditorRightPanel } from './EditorRightPanel';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { resetCustomNarratives, saveCustomNarratives } from '../../../store';
import { NarrativeVersions } from './NarrativeVersions';
import { SaveNewNarrativeVersionModal } from './SaveNewNarrativeVersionModal';
import { SplitViewNarratives } from './SplitViewNarratives';
import { useGetActiveQueries } from '../../../../../services/getActiveQueries';

export const CreateCustomNarrative = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { user } = useUser();
    const roomID = location?.state?.roomId;
    const narrativeID = location?.state?.narrativeId || '';
    const [narrativeName, setNarrativeName] = useState('');
    const [booleanSwitch, setBooleanSwitch] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [rightPanel, setRightPanel] = useState(false);
    const [isNarrativeVersion, setIsNarrativeVersion] = useState(false);
    const { setModal, closeModal } = useModal();
    const { topics, queryErrorList, selectedTopic } = useSelector(state => state.customNarratives);
    const [selectedNarrative, setSelectedNarrative] = useState('');
    const { data, loading: getNarrativesLoading } = useQuery(GET_CUSTOM_NARRATIVES, {
        variables: {
            situationRoom: roomID,
        },
        fetchPolicy: 'no-cache'
    });
    const refetchQueries = useGetActiveQueries(['getCustomNarratives']);
    const [createCustomNarrative, { loading }] = useMutation(CREATE_CUSTOM_NARRATIVE, {
        refetchQueries,
        onCompleted: () => {
            dispatch(resetCustomNarratives());
            history.goBack();
        }
    });
    const [updateCustomNarrative, { loading: updateNarrativeLoading }] = useMutation(UPDATE_CUSTOM_NARRATIVE, {
        onCompleted: () => {
            goToNarratives();
        }
    });
    const goToNarratives = () => {
        dispatch(resetCustomNarratives());
        history.replace(`/situation-rooms/${roomID}/custom-narratives?true=custom-narratives`);
    };

    const handleNameChange = (e) => {
        const val = e.target.value || '';
        if (val.length <= 70) {
            const formattedName = val[0] !== ' ' ? val : val?.trim();
            const duplicateName = data?.getCustomNarratives?.customNarratives?.filter((a) => {
                if (!narrativeID) {
                    return a.name === formattedName;
                }
                return (a.name === formattedName && (narrativeID !== a.id));
            });
            setNameError(duplicateName?.length > 0);
            setNarrativeName(formattedName);
        }
    };
    const setSaveNewNarrativeVersionModal = ({ onConfirm, onCancel }) => {
        setModal({
            header: false,
            component: <SaveNewNarrativeVersionModal onConfirm={onConfirm}
                narrativeData={data?.getCustomNarratives?.customNarratives?.filter(narrative => narrative.id === narrativeID)}
                onCancel={onCancel}
                modalTitle="Save New Version"
                roomID={roomID}
            />
        });
    };

    const handleSaveCustomNarrative = () => {
        const filteredtopics = topics.map((topic) => {
            const newTopic = { ...topic, isSaved: true };
            if (topic.id === selectedTopic.id) {
                if (booleanSwitch) {
                    newTopic.keywords = [];
                } else {
                    newTopic.boolean = '';
                }
            }
            delete newTopic.__typename;
            delete newTopic.isDslQueryValid;
            return newTopic;
        });
        if (narrativeID) {
            setSaveNewNarrativeVersionModal({ // open narrative version modal
                onConfirm: ({ title, notes }) => {
                    const finalData = { id: narrativeID,
                        name: narrativeName,
                        data: filteredtopics,
                        situationRoom: roomID,
                        versionId: new ObjectID().toString(),
                        user: user.id,
                        narrativeTitle: title,
                        notes };
                    updateCustomNarrative({
                        variables: {
                            data: finalData
                        }
                    });
                    closeModal();
                },
                onCancel: () => {
                    closeModal(); }
            });
        }
        else {
            const finalData = { name: narrativeName,
                data: filteredtopics,
                situationRoom: roomID,
                versionId: new ObjectID().toString(),
                user: user.id };
            createCustomNarrative({
                variables: {
                    data: finalData
                }
            });
        }
    };

    const checkNarrativeDetails = () => {
        let isDisable = false;
        if (topics.length === 0 || !narrativeName || loading) {
            return true;
        }
        let isErrorValue = false;
        const errorList = queryErrorList.map((a) => Object.values(a.error));
        errorList.forEach((a) => a.forEach((b) => { if (b.isError === true) {
            isErrorValue = true;
        } }));
        if (!narrativeID) {
            topics.forEach((topic) => {
                if (!topic.threat || (!topic.boolean && topic.keywords.length === 0) || nameError
                || (topic.query_type === 'query' && isErrorValue) || (topic.query_type === 'query' && !topic.dslQuery)
                || (topic.query_type === 'query' && !topic.isDslQueryValid)) {
                    isDisable = true;
                }
            });
        }
        if (narrativeID) {
            let isEdited = false;
            const newTopics = topics.filter((a) => {
                const isPresent = selectedNarrative.data.some((b) => b.id === a.id);
                return !isPresent;
            });
            if (newTopics.length !== 0) {
                newTopics.forEach(topic => {
                    if ((topic.boolean || topic.keywords.length !== 0) && !((topic.query_type === 'query' && isErrorValue)
                         || (topic.query_type === 'query' && !topic.dslQuery)
                         || (topic.query_type === 'query' && !topic.isDslQueryValid))) {
                        isEdited = true;
                    }
                });
            }
            else {
                topics.forEach(topic => {
                    if (narrativeName !== selectedNarrative.name) {
                        isEdited = true;
                    }
                    const foundNarrative = selectedNarrative.data.find(oldNarrative => oldNarrative.id === topic.id);
                    if (topic?.boolean !== foundNarrative?.boolean && !((topic.query_type === 'query' && isErrorValue)
                        || (topic.query_type === 'query' && !topic.dslQuery)
                        || (topic.query_type === 'query' && !topic.isDslQueryValid))) {
                        isEdited = true;
                    }
                    else if (topic.query_type !== 'query') {
                        topic.keywords.forEach(keyword => {
                            if (!foundNarrative.keywords.includes(keyword)) {
                                isEdited = true;
                            }
                        });
                    }
                });
            }
            isDisable = !isEdited;
        }
        return isDisable;
    };
    const handleCloseNarrativeModal = () => {
        if (narrativeName || topics.length > 0) {
            setModal({
                component: (
                    <div>
                        <h2>Are you sure you want to exit the screen?</h2>
                        <hr />
                        <p>Custom narrative data will not be saved.</p>
                        <hr />
                        <div className="d-flex flex-row">
                            <Button color="primary"
                                className="mr-2"
                                onClick={() => { dispatch(resetCustomNarratives());
                                    closeModal(); history.goBack(); }}
                            >Yes, exit
                            </Button>
                            <Button onClick={() => closeModal()}>No, back to create</Button>
                        </div>
                    </div>
                )
            });
        }
        else {
            history.goBack();
        }
    };

    useEffect(() => {
        if (data?.getCustomNarratives?.customNarratives && narrativeID) {
            const narrative = data?.getCustomNarratives?.customNarratives?.filter((a) => a.id === narrativeID);
            setSelectedNarrative(narrative[0]);
            setBooleanSwitch(narrative[0]?.data[0]?.query_type === 'query');
            setNarrativeName(narrative[0]?.name);
            const errorList = narrative[0].data.map((a) => ({ threat: a.threat, error: {} }));
            dispatch(
                saveCustomNarratives({
                    topics: narrative[0]?.data,
                    selectedTopic: narrative[0]?.data[0],
                    queryErrorList: errorList,
                })
            );
        }
    }, [data, dispatch, narrativeID]);
    useEffect(() => {
        dispatch(resetCustomNarratives());
    }, [dispatch]);

    return (
        <div className="d-flex flex-column flex-1">
            { isNarrativeVersion ? (
                <NarrativeVersions closeVersions={() => setIsNarrativeVersion(false)}
                    narrativeData={data.getCustomNarratives.filter(narrative => narrative.id === narrativeID)}
                    user
                    setRedirect={() => goToNarratives()}
                />
            )

                : (
                    <>
                        <div className="bg-light border-bottom py-2 px-4 d-flex align-items-center justify-content-between">
                            <h2 className="m-0">{narrativeID ? 'Edit custom narrative' : 'Create a new custom narrative'}</h2>
                            <div role="button"
                                onClick={() => handleCloseNarrativeModal()}
                            >
                                <X size={18} />
                            </div>
                        </div>
                        {!getNarrativesLoading ? (
                            <SplitViewNarratives expand={(!rightPanel && topics.length === 0)}
                                right={(
                                    <div className={(
                                        (!rightPanel && topics.length === 0))
                                        ? 'd-flex justify-content-center align-item-center h-100'
                                        : 'd-flex flex-column flex-1 z-index clear-float'}
                                    >
                                        {(!rightPanel && topics.length === 0) ? <EmptyStatePanel /> : (
                                            <EditorRightPanel booleanSwitch={booleanSwitch}
                                                topics={topics}
                                            />
                                        ) }
                                    </div>
                                )}
                                left={(
                                    <TopicLeftPanel topics={topics}
                                        narrativeName={narrativeName}
                                        handleNameChange={handleNameChange}
                                        nameError={nameError}
                                        setRightPanel={setRightPanel}
                                        rightPanel={rightPanel}
                                        setBooleanSwitch={setBooleanSwitch}
                                        selectedNarrative={selectedNarrative}
                                    />
                                )}
                            />
                        )
                            : <Loading relative />}
                        <div className="bg-light border-top py-2 px-4 d-flex align-items-center justify-content-end">
                            {!selectedTopic?.isSaved && (
                                <Button className="mr-auto"
                                    onClick={() => setBooleanSwitch(!booleanSwitch)}
                                >{!booleanSwitch ? 'Switch to Boolean' : 'Switch to Keywords'}
                                </Button>
                            )}
                            <div>
                                { narrativeID
                && (
                    <Button className="mr-2"
                        color="primary"
                        onClick={() => setIsNarrativeVersion(true)}
                    >
                        Version history
                    </Button>
                ) }
                                <Button onClick={() => handleSaveCustomNarrative()}
                                    disabled={checkNarrativeDetails()}
                                >{loading || updateNarrativeLoading ? <Spinner color="primary" size="sm" />
                                        : `${narrativeID ? 'Save custom narrative' : 'Create custom narrative'}`}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
        </div>
    ); };
