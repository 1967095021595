import React from 'react';
import { ChevronLeft } from 'react-feather';
import { TrendCard } from './TrendCard';
import { threatSignalTooltipText } from '../../../components/Organisms/ContentTableView/ThreatSignals/threatSignalTooltipText';
import { mergeThreatSignalsVolumeOverTime } from './ThreatSignalsVolumeOverTime';

type SignalData = {
    key: number;
    count: number;
};

type ThreatSignal = {
    key: string;
    name: string;
    display: boolean;
    isResolver: boolean;
    color: string
};

type ThreatSignalData = {
    signal: string;
    signalData: SignalData[];
    totalCount: number;
};

type ThreatSignalsTrendCardsProps = {
    data: ThreatSignalData[];
    showSignals: ThreatSignal[];
    setShowSignals: React.Dispatch<React.SetStateAction<ThreatSignal[]>>;
    displayResolvers: boolean;
    setDisplayResolvers: (value: boolean) => void;
}

export const ThreatSignalsTrendCards = ({ data, showSignals, setShowSignals, displayResolvers,
    setDisplayResolvers }: ThreatSignalsTrendCardsProps) => {
    const handleSetShow = (value: boolean, label: string) => {
        setShowSignals((prevSignals: ThreatSignal[]) => prevSignals.map((a) => (a.name === label ? { ...a, display: value } : a)));
    };
    const maxItems = (7 * 24 + 10).toString();
    const nonResolvers = showSignals.filter((a) => !a.isResolver);
    if (!displayResolvers) {
        return (
            <div className="threat-signal-cards scrollbar-small pr-2 bg-white p-3">
                {nonResolvers.map((a) => {
                    let itemData = [] as any[];
                    let itemCount = 0;
                    if (a.key === 'TOXIC') {
                        const resolversList = showSignals.filter((signal) => signal.isResolver).map((item) => item.key);
                        const filteredData = data.filter((signal: any) => resolversList.includes(signal.signal));
                        itemData = mergeThreatSignalsVolumeOverTime(filteredData);
                        mergeThreatSignalsVolumeOverTime(filteredData).forEach((item) => { itemCount = item.count + itemCount; });
                    } else {
                        itemData = data.filter((b) => b.signal === a.key)[0].signalData;
                        itemCount = data.filter((b) => b.signal === a.key)[0].totalCount;
                    }
                    return (
                        <TrendCard id={a.key}
                            label={a.name}
                            data={itemData}
                            show={a.display}
                            setShow={handleSetShow}
                            totalCount={itemCount}
                            interval={maxItems}
                            setDisplayResolvers={setDisplayResolvers}
                            tooltip={threatSignalTooltipText(a.name)}
                            color={a.color}
                        />
                    );
                })}
            </div>
        );
    }
    return (
        <div className="threat-signal-cards scrollbar-small pr-2 border-top bg-white p-3">
            <p className="text-primary cursor-pointer set-fit-content" onClick={() => setDisplayResolvers(false)}>
                <ChevronLeft className="text-primary" size={30} /> <span>Toxic information</span>
            </p>
            {showSignals.filter((a) => a.isResolver).map((a) => {
                const itemData = data.filter((b) => b.signal === a.key)[0].signalData;
                const itemCount = data.filter((b) => b.signal === a.key)[0].totalCount;
                return (
                    <TrendCard id={a.key}
                        label={a.name}
                        data={itemData}
                        show={a.display}
                        setShow={handleSetShow}
                        totalCount={itemCount}
                        interval={maxItems}
                        setDisplayResolvers={setDisplayResolvers}
                        tooltip={threatSignalTooltipText(a.name)}
                        color={a.color}
                    />
                );
            })}
        </div>
    ); };
