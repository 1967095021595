import { useQuery } from '@apollo/client';
import { getRoom } from '../../utils/variables';
import { gql } from '../../generated';

export const useSmartFilters = (skip = false) => {
    const room = getRoom();
    const ret = useQuery(GET_SMART_FILTERS, {
        variables: {
            projectId: room?.project_id
        },
        skip
    });

    return {
        ...ret,
        smartFilters: ret.data ? ret.data.getSmartFilters : []
    };
};

export const GET_SMART_FILTERS = gql(`
    query getSmartFilters($projectId: String!) {
        getSmartFilters(projectId: $projectId) {
            filterName
            filters {
                name
                values
            }
        }
    }
`);
