import React from 'react';
import { TooltipWrapper } from '../ToolTip/TooltipWrapper';
import { DropdownWithSearch } from './components/DropdownWithSearch';
import SmartFiltersLogo from '../../assets/svg/smartFiltersLogo.svg';
import { capitalizeFirstLetter, truncateString } from '../../utils/text';

type SmartFiltersProps = {
    smartFilter: string
    handleSelectSmartItem: (e: string) => void
    data: any
    loading: boolean
}

export const SmartFilters = ({ smartFilter, handleSelectSmartItem, data, loading }: SmartFiltersProps) => {
    const filtersData = data?.getSmartFilters || [];
    return (
        <div className="d-flex ml-3">
            <div className="d-flex align-items-center mr-2">
                <img src={SmartFiltersLogo} height={30} className="mr-1" />
                <TooltipWrapper
                    tooltipText={`Filter suggestions created by AI based on the data in your
                             situation room and frequently used filters. They are refreshed daily, but if you find one useful,
                              you can save it for future use.`}
                    id="smart-filters"
                >
                    <p className="font-weight-semibold widget-text-style set-fit-content mt-1">Smart filters</p>
                </TooltipWrapper>
            </div>
            <DropdownWithSearch name={truncateString(smartFilter, 13, false) || 'Select options'}
                placeholder="Search"
                items={filtersData.length > 0 ? filtersData
                    .map((filter: any) => {
                        const { filterName } = filter;
                        return { id: filterName, name: capitalizeFirstLetter(filterName) };
                    }) : []}
                handleSelectItem={handleSelectSmartItem}
                loading={loading}
                emptyStateText="No smart filters available"
                dataTestId="smart-filters"
            />
        </div>
    ); };
