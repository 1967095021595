import React, { useState, useRef } from 'react';
import { Options } from 'highcharts';
import { useQuery, gql } from '@apollo/client';
import { ChevronUp, Download } from 'react-feather';
import { Loading } from '../../../components/Loading';
import { EmptyState } from '../../../components/EmptyState';
import { Images } from '../../../utils/modeSwitch';
import { LineChart, LineChartRef } from './LineChart';
import { TooltipWrapper } from '../../../components/ToolTip/TooltipWrapper';
import { Spinner } from '../../../assets/svg/Spinner';
import { useContentFilters } from '../useFilters';

import { ThreatSignalsTrendCards } from './ThreatSignalsTrendCards';
import { largeNumber } from '../../../utils/NumberFormat';
import { getRoom } from '../../../utils/variables';

type NewThreatsVolumeOverTimeGraph = {
    preview?: boolean
    noAnnotations?: boolean
    totalThreatsCount: number
}
const initialKeysList = [
    { name: 'Toxic information', key: 'TOXIC', isResolver: false, display: true, color: '#00C0C7' },
    { name: 'Attack on commenter', key: 'ATTACK_ON_COMMENTER', isResolver: true, display: true, color: '#00C0C7' },
    { name: 'Attack on author', key: 'ATTACK_ON_AUTHOR', isResolver: true, display: true, color: '#D945D0' },
    { name: 'Attack on identity', key: 'IDENTITY_ATTACK', isResolver: true, display: true, color: '#FF6C4C' },
    { name: 'Insulting language', key: 'INSULT', isResolver: true, display: true, color: '#FFC700' },
    { name: 'Inflammatory language', key: 'INFLAMMATORY', isResolver: true, display: true, color: '#D945D0' },
    { name: 'Toxic language', key: 'TOXICITY', isResolver: true, display: true, color: '#FF6C4C' },
    { name: 'Severely toxic language', key: 'SEVERE_TOXICITY', isResolver: true, display: true, color: '#00C0C7' },
    { name: 'Threatening language', key: 'THREAT', isResolver: true, display: true, color: '#FFC700' },
    { name: 'Low source credibility', key: 'src-cred', isResolver: false, display: true, color: '#D945D0' },
    { name: 'Domestic extremism', key: 'domestic_extremism', isResolver: false, display: true, color: '#FF6C4C' },
    { name: 'Emerging boycott', key: 'boycott', isResolver: false, display: true, color: '#FF6C4C' },
    { name: 'Automated account', key: 'automation_score', isResolver: false, display: true, color: '#FFC700' }
];
export const ThreatSignalsVolumeOverTimeGraph = ({ preview, noAnnotations, totalThreatsCount }: NewThreatsVolumeOverTimeGraph) => {
    const [displayResolvers, setDisplayResolvers] = useState(false);
    const [displayGraph, setDisplayGraph] = useState(false);
    const room = getRoom();
    const contentFilters = useContentFilters({
        defaultFilters: {
            isThreat: true
        }
    });
    const interval = '1d';
    const maxItems = (7 * 24 + 10).toString();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];
    const { data, loading } = useQuery(GET_THREAT_SIGNALS_VOLUME_OVER_TIME, {
        variables: { ...contentFilters, interval: maxItems }
    });

    const filteredKeysList = initialKeysList.filter((a) => !disabledThreatTypes.includes(a.key));
    const [showSignals, setShowSignals] = useState(filteredKeysList);

    const dataExists = data?.getThreatSignalsVolumeOverTime?.threatSignalsData?.length
        && data?.getThreatSignalsVolumeOverTime?.threatSignalsData?.length > 1;

    const chartRef = useRef<LineChartRef>(null);
    const chartExportOptions: Options = {
        legend: {
            enabled: true,
            symbolWidth: 10
        },
        chart: {
            height: 500
        }
    };

    const layout = (children: React.ReactNode) => (
        <div data-testid="multi-small-charts" className="highcharts-stock-chart bg-white">
            {children}
        </div>
    );
    const finalSignalsList = displayResolvers ? showSignals.filter((a) => a.isResolver) : showSignals.filter((a) => !a.isResolver);

    if (loading) {
        return layout(<Loading relative height={500} />);
    }
    if (!dataExists) {
        return layout(<EmptyState title="No data available" icon={Images().overview} />);
    }

    return layout(
        <div className="flex flex-column">
            <div className="d-flex align-items-center p-3 justify-content-between">
                <div className="d-flex align-items-center">
                    <h3 className="mb-0 mt-0 mr-2">Threats volume over time</h3>
                    <p className="mb-0 bg-primary-light p-11">Total threats identified: {largeNumber(totalThreatsCount)}</p>
                </div>
                <div className="d-flex align-items-center">
                    <div onClick={() => setDisplayGraph(!displayGraph)} className="d-flex align-items-center mr-2 cursor-pointer">
                        <p className="text-primary m-0">{displayGraph ? 'Hide graph' : 'Show graph'}</p>
                        <ChevronUp className={`ml-11 transform-transition ${displayGraph ? 'transform-btn-icon' : ''} text-primary`} />
                    </div>
                    <TooltipWrapper id="downloadThreatsLineChartImage" placement="top-start" tooltipText="Download JPEG">
                        <Download onClick={() => chartRef.current?.exportChart({ chartOptions: chartExportOptions })}
                            className="text-primary cursor-pointer"
                        />
                    </TooltipWrapper>

                </div>
            </div>
            {displayGraph && (
                <div className="d-flex border-top">
                    {noAnnotations ? (
                        <div className="border rounded p-2 w-100">
                            <TooltipWrapper id="showThreatsTooltip"
                                placement="top-start"
                                tooltipText={threatsTooltip}
                            >
                                <p className="mb-1 widget-text-style">Threats</p>
                            </TooltipWrapper>
                            <div className="d-flex align-items-center">
                                <Spinner />
                                <TooltipWrapper id="noAnnotationsTooltip"
                                    placement="top-start"
                                    tooltipText={noAnnotationsTooltip}
                                    className="ml-1"
                                >
                                    <p className="mb-0 widget-text-style">Not available</p>
                                </TooltipWrapper>
                            </div>
                        </div>
                    ) : (
                        <ThreatSignalsTrendCards data={data?.getThreatSignalsVolumeOverTime?.threatSignalsData || []}
                            showSignals={showSignals}
                            setShowSignals={setShowSignals}
                            setDisplayResolvers={setDisplayResolvers}
                            displayResolvers={displayResolvers}
                        />

                    )}
                    <div className="flex-1 border p-3">
                        <p className="mb-3">To zoom in on a specific period or date, you can click and drag
                            horizontally within the graph
                        </p>
                        <LineChart ref={chartRef}
                            preview={preview}
                            interval={interval}
                            data={
                                finalSignalsList.map((a) => {
                                    let itemData = [] as any[];
                                    if (a.key !== 'TOXIC') {
                                        itemData = data?.getThreatSignalsVolumeOverTime.threatSignalsData.filter((b: any) =>
                                            b.signal === a.key)[0]?.signalData;
                                    } else {
                                        const resolversList = showSignals.filter((signal) => signal.isResolver).map((item) => item.key);
                                        const filteredData = data?.getThreatSignalsVolumeOverTime.threatSignalsData.filter((signal: any) =>
                                            resolversList.includes(signal.signal));
                                        itemData = mergeThreatSignalsVolumeOverTime(filteredData);
                                    }
                                    return {
                                        name: a.name,
                                        data: itemData.map((item: any) => ({ x: item.key, y: item.count })),
                                        color: a.color,
                                        visible: a.display
                                    };
                                })
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export const mergeThreatSignalsVolumeOverTime = (data: any) => {
    const mergedData: Record<string, number> = {};

    data.forEach((signal: any) => {
        signal.signalData.forEach((entry: any) => {
            const { key, count } = entry;
            mergedData[key] = (mergedData[key] || 0) + count;
        });
    });

    return Object.entries(mergedData).map(([key, count]) => ({ key: parseInt(key, 10), count }));
};
const threatsTooltip = `Threats are content that may harm individuals, communities, or the overall
online environment, particularly on social media platforms.`;
const noAnnotationsTooltip = `Threat information will be available once the enhancement is
completed for a few pieces of content.`;

export const GET_THREAT_SIGNALS_VOLUME_OVER_TIME = gql`
    query getThreatSignalsVolumeOverTime($projectId: String!, $filters: ContentFilters, $dslQuery: JSON, $interval: String ) {
        getThreatSignalsVolumeOverTime(projectId: $projectId, filters: $filters, dslQuery: $dslQuery, interval: $interval) {
            threatSignalsData {
                signalData {
                    key
                    count
                }
                totalCount
                signal
            }
            totalHits
        }
    }
`;
