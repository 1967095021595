import React, { useState } from 'react';
import { Tooltip, UncontrolledTooltipProps } from 'reactstrap';

type TextTooltipProps = {
    id: string,
    placement?: UncontrolledTooltipProps['placement'],
    children?: React.ReactNode | string | ((props: { closeTooltip: () => void }) => React.ReactNode)
    className?: string,
    tooltipText: React.ReactNode | string
    container?: string
    popperClassName?: string
}

export const TooltipWrapper = ({
    id = '', placement = 'bottom', children, className, tooltipText, container, popperClassName
}: TextTooltipProps) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const sanitizedId = sanitizeId(id);
    return (
        <span className={`p-0 mw-0 ${className}`} color="link">
            <span id={sanitizedId}>
                {typeof children === 'function' ? children({ closeTooltip: () => setTooltipOpen(false) }) : children}
            </span>
            <Tooltip placement={placement}
                isOpen={tooltipOpen}
                target={sanitizedId}
                toggle={toggle}
                innerClassName="hover p-1"
                container={container}
                offset="0, 0"
                popperClassName={popperClassName}
            >
                {tooltipText}
            </Tooltip>
        </span>
    );
};

const sanitizeId = (id: string) => id.replace(/[^a-zA-Z0-9-_]/g, '_');
