import { useSelector } from 'react-redux';
import { scaleLinear } from 'd3';
import { getCtype } from '../../../../../../utils/text';
import { NetworkNode } from '../../useNetworks';
import { createNode } from './createNode';
import { NetworksHistoryStore } from '../../../store';
import { getIconBase64 } from './getBase64Icon';

export const useContentNodes = (data: NetworkNode[]) => {
    const { sizeNodesBy } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);

    const maxEngagement = data.map(({ engagement }) => engagement).sort((a, b) => b - a)[0] || 1;
    const scaleEngagement = scaleLinear().domain([0, maxEngagement]).range([1, 30]);

    return Object.fromEntries(
        data.map(({ id, contentType, engagement, url, hashtags, sentiment, threatLevel, from }) => {
            const { icon, FontFamily, name } = getCtype(contentType);
            const { base64, height, width } = getIconBase64(icon, FontFamily);
            return [
                `content_${id}`,
                {
                    ...createNode({
                        image: base64,
                        height,
                        width
                    }),
                    data: {
                        type: 'content',
                        id,
                        hashtag: hashtags[0] || '', // can only combine by single items
                        url,
                        source: name,
                        sentiment,
                        threatLevel,
                        from
                    },
                    size: sizeNodesBy === 'engagement' ? scaleEngagement(engagement) : 1
                }
            ];
        })
    );
};
