import React, { useMemo, useState } from 'react';
import { getParam, unsetParam } from '../../../../../utils/urlParams';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import { BreadCrumbs } from '../../../../../components/BreadCrumbs';
import { SelectedActor } from './SelectedActor';

type ActorsModalProps ={
    actors: any
}

export const ActorsModal = ({ actors }: ActorsModalProps) => {
    const [selectedData, setSelectedData] = useState([]);
    const [coordinationId, setCoordinationId] = useState('');
    const [actorName, setActorName] = useState('');
    const generateBreadcrumbs = () => {
        const breadcrumbs = [];
        if (getParam('subTab')) {
            const type = getParam('subTab');
            breadcrumbs.push({
                title: capitalizeFirstLetter(type || '') || '',
                onClick: () => {
                    unsetParam('actor_id');
                    setSelectedData([]);
                    setCoordinationId('');
                    setActorName('');
                }
            });
        }
        if (getParam('actor_id')) {
            const id = getParam('actor_id');
            breadcrumbs.push({
                title: id || '',
                onClick: () => {
                    setSelectedData([]);
                    setCoordinationId('');
                    unsetParam('coordination_id');
                }
            });
        }
        if (getParam('coordination_id')) {
            const id = getParam('coordination_id');
            breadcrumbs.push({
                title: id || '',
            });
        }
        delete breadcrumbs[breadcrumbs.length - 1]?.onClick;
        return breadcrumbs;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const bread = useMemo(() => generateBreadcrumbs(), [selectedData, actorName]);

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <BreadCrumbs items={bread} />
            </div>
            <hr className="mb-3 mt-0" />
            <SelectedActor
                actors={actors}
                coordinationId={coordinationId}
                selectedData={selectedData}
                setCoordinationId={setCoordinationId}
                setSelectedData={setSelectedData}
                actorName={actorName}
                setActorName={setActorName}
            />
        </>
    ); };
