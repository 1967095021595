import React from 'react';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { IconsAndCount } from './ActorsAndPosts';
import { getCtype } from '../../../../utils/text';

type ActorDetailProps = {
    actor: any
    handleSetParam?: (data: any) => void;
}

export const ActorDetail = ({ actor, handleSetParam }: ActorDetailProps) => {
    const ctype = getCtype(actor?.ctype);
    return (
        <div className="bg-lightgray rounder-4 p-2 mb-2">
            <div className="d-flex align-items-center">
                <SourceIconLabel data={{ contentType: actor.ctype }} />
                <p className="mb-0">{actor.from} via {ctype.name}</p>
            </div>
            <div className="d-flex gap-2 mt-2">
                {actor?.ttps?.length && (
                    <span onClick={() => handleSetParam && handleSetParam(actor.from)}>
                        <IconsAndCount label="Coordinated activity" count={actor.ttps.length} link={!!handleSetParam} />
                    </span>
                )}
                <IconsAndCount label="Posts" count={actor?.count || actor?.network?.length || 0} />
            </div>
        </div>
    );
};
