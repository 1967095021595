import { useQuery } from '@apollo/client';
import { GET_CUSTOM_NARRATIVES } from '../../graphql';
import { getRoom } from '../../utils/variables';

export const useCustomNarratives = () => {
    const room = getRoom();
    const ret = useQuery(GET_CUSTOM_NARRATIVES, {
        variables: {
            situationRoom: room.id,
        },
    });

    return {
        ...ret,
        data: ret.data ? ret.data.getCustomNarratives?.customNarratives : []
    };
};
