import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { checkGrammer } from '../../../../utils/text/checkGrammer';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { CoordinatedMain } from './CoordinatedMain';
import { getParam, setParam, unsetParam } from '../../../../utils/urlParams';
import { CoordinationDataType } from './coordinationDataTypes';
import { CoordinatedTopKeowrds } from './CoordinatedTopKeowrds';
import { NetworksButton } from '../Utils/NetworksButton';
import { useSortedActors } from './useSortedActors';

type CooridnatedTacticsProps = {
    coordinationData?: CoordinationDataType[]
}

export const CooridnatedTactics = ({ coordinationData }: CooridnatedTacticsProps) => {
    const [ttpData, setTtpData] = useState([]);
    const [isSubTab, setIsSubTab] = useState<boolean>(!!getParam('subTab'));
    const coPost = coordinationData?.filter((item) => item.coordinationType === 'copost') || [];
    const coRePost = coordinationData?.filter((item) => item.coordinationType === 'corepost') || [];
    const coHastags = coordinationData?.filter((item) => item.coordinationType === 'cohashtag') || [];
    const coSimilarAccount = coordinationData?.filter((item) => item.coordinationType === 'similar_account') || [];
    const coPostingPattern = coordinationData?.filter((item) => item.coordinationType === 'similar_post_pattern') || [];
    const coHastagsSequence = coordinationData?.filter((item) => item.coordinationType === 'cohashtag_sequence') || [];

    const { sortedActorsData } = useSortedActors({ coordinationData });
    const handleSetParam = (name: string, ttp?: any) => {
        unsetParam('coordination_id');
        unsetParam('actor_id');
        setTtpData(ttp);
        setParam('subTab', name);
        setIsSubTab(true);
    };
    const handleActorRoute = (name: string) => {
        unsetParam('coordination_id');
        setParam('subTab', 'Coordination_actors');
        setParam('actor_id', name);
        setIsSubTab(true);
    };

    return (
        <div>
            {
                getParam('tab') === 'coordination' && !isSubTab
                    && (
                        <>
                            <p>Coordination info</p>
                            <hr />
                            <div className="p-2 bg-verylight rounder-4">
                                <div className="d-flex align-items-center">
                                    <h3>
                                        <WidgetTextTooltip widgetName={WidgetName.CoordinationTactics} />
                                    </h3>
                                    <span className="bg-secondary-alt
                                    rounder-4 text-white py-11 px-1 ml-1"
                                    >{coordinationData?.length} coordinated {checkGrammer(
                                            'network',
                                            (coordinationData || []).length > 1,
                                            (coordinationData || []).length
                                        )}
                                    </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <div className="p-2 rounder-4 bg-lightgray w-100 mr-2">
                                        <NetworksButton handleSetParam={handleSetParam}
                                            network={coPost}
                                            widgetName="CoordinatedPosts"
                                            type="Coordinated_posts"
                                        />
                                    </div>
                                    <div className="p-2 rounder-4 bg-lightgray w-100 mr-2">
                                        <NetworksButton handleSetParam={handleSetParam}
                                            network={coRePost}
                                            widgetName="CoordinatedReposts"
                                            type="Coordinated_reposts"
                                        />
                                    </div>
                                    <div className="p-2 rounder-4 bg-lightgray w-100">
                                        <NetworksButton handleSetParam={handleSetParam}
                                            network={coHastags}
                                            widgetName="CoordinatedHashtags"
                                            type="Coordinated_hashtags"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <div className="p-2 rounder-4 bg-lightgray w-100 mr-2">
                                        <NetworksButton handleSetParam={handleSetParam}
                                            network={coHastagsSequence}
                                            widgetName="CoordinatedHashtagSequences"
                                            type="Coordinated_posts"
                                        />
                                    </div>
                                    <div className="p-2 rounder-4 bg-lightgray w-100 mr-2">
                                        <NetworksButton handleSetParam={handleSetParam}
                                            network={coSimilarAccount}
                                            widgetName="SimilarAccountHandles"
                                            type="Similar_account_handles"
                                        />
                                    </div>
                                    <div className="p-2 rounder-4 bg-lightgray w-100">
                                        <NetworksButton handleSetParam={handleSetParam}
                                            network={coPostingPattern}
                                            widgetName="SimilarPostingPatterns"
                                            type="Similar_posting_patterns"
                                        />
                                    </div>
                                </div>
                                <div className="bg-white p-2 mt-2">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="m-0"><WidgetTextTooltip widgetName={WidgetName.Top3Actors} /></h3>
                                        <Button color="link"
                                            className="p-0 text-right"
                                            onClick={() => handleSetParam('Coordination_actors')}
                                        >View all
                                        </Button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-2">
                                        {
                                            sortedActorsData?.slice(0, 3).map((actor, idx) => (
                                                <div onClick={() => handleActorRoute(actor.from)}
                                                    className={`p-1 cursor-pointer rounder-4 w-100 d-flex 
                                                        justify-content-between align-items-center ${idx < 2 ? 'mr-2' : ''}
                                                 hover-state`}
                                                >
                                                    <span>
                                                        <SourceIconLabel data={{ contentType: actor.ctype }} className="pl-1 d-inline" />
                                                        <p className="m-0 d-inline">{actor.from}</p>
                                                    </span>
                                                    <p className="bg-secondary-alt py-11 px-1 rounder-4 text-white m-0">{actor.count}</p>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                <CoordinatedTopKeowrds coordinationData={coordinationData} />
                            </div>
                        </>
                    )
            }
            {isSubTab && <CoordinatedMain ttpData={ttpData} setIsSubTab={setIsSubTab} actorsData={sortedActorsData} />}
        </div>
    );
};
