import React, { useRef } from 'react';
import { Button, Label } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { color } from '../../../../utils/getColors';

export const UploadFile = ({ setFile, filename, accept = '.pdf', className, innerRef }) => {
    const inputRef = useRef(null);

    const removeFile = (e) => {
        e.preventDefault();
        setFile('');
    };
    const handleFile = (e) => {
        setFile(e.target?.files[0]);
    };
    const handleFileSelect = () => {
        if (inputRef) {
            inputRef.current.click();
        }
    };

    return (
        <div className={`mb-2 ${className}`}>
            {filename && (
                <Label>
                    <span className="mr-1">
                        <FeatherIcon.Check className="mr-11" color={color.blue[500]} />
                        {filename} <FeatherIcon.X size={14}
                            className="text-danger cursor-pointer"
                            onClick={(e) => removeFile(e)}
                            data-testid="remove-upload"
                        />
                    </span>
                </Label>
            )}
            <div className="upload-btn-wrapper d-inline-block">
                <Button size="sm" color="link" onClick={handleFileSelect} className="mw-0 p-0"><FeatherIcon.Upload /> Upload image</Button>
                <input type="file"
                    accept={accept}
                    name="myfile"
                    onChange={handleFile}
                    data-testid="upload-file"
                    ref={innerRef || inputRef}
                />
            </div>
        </div>
    );
};
