export type ActivityType = {
    similar_account: string;
    copost: string;
    cohashtag: string;
    similar_post_pattern: string;
    corepost: string;
}
export const activity: ActivityType = {
    similar_account: 'SimilarAccountHandles',
    copost: 'CoordinatedPosts',
    cohashtag: 'CoordinatedHashtags',
    similar_post_pattern: 'SimilarPostingPatterns',
    corepost: 'CoordinatedReposts'
};
