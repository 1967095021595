import React from 'react';
import { Col, Row } from 'reactstrap';
import { DatePicker } from '../components/DatePicker';
import { getRoom } from '../../../utils/variables';
import { SourcesFilter } from './SourcesFilter';
import { LanguagesFilter } from './LanguagesFilter';
import { KeywordsFilter } from './KeywordsFilter';
import { ActorsFilter } from './ActorsFilter';
import { SentimentFilter } from './SentimentFilter';
import { LabelsFilter } from './LabelsFilter';
import { ThreatTypesFilter } from './ThreatTypesFilter/ThreatTypesFilter';
import { LocationsFilter } from './LocationsFilter';
import { MultiKeywordsFilter } from './MultiKeywordsFilter';
import { TooltipWrapper } from '../../ToolTip/TooltipWrapper';
import { UrlsFilter } from './UrlsFilter';
import { DomainsFilter } from './DomainsFilter';
import { CoordinationTypeFilter } from './CoordinationTypeFilter';

type FiltersListProps = {
    watchlist?: boolean,
    contentIds?: string[],
    clusters?: boolean,
    appliedFilters?: any
}

export const FiltersList = ({ watchlist = false, contentIds = [], clusters = false, appliedFilters }: FiltersListProps) => {
    const room = getRoom();
    const labelsFeatureEnabled = !!room.instance?.plan?.others?.labels;
    const contentCoordinationEnabled = !!room?.instance?.plan?.others?.contentCoordination;

    return (
        <div className="border p-3 bg-white">
            <p className="font-weight-bold mb-3">Filters</p>
            <Row className="align-items-stretch pl-1">
                {clusters && (
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <p className="font-weight-semibold">Date</p>
                        <DatePicker />
                    </Col>
                )}
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper tooltipText="Sources from which the content is ingested" id="tooltip-sources-label" placement="top">
                        <p className="font-weight-semibold widget-text-style set-fit-content">Sources</p>
                    </TooltipWrapper>
                    <SourcesFilter clusters={clusters} appliedFilters={appliedFilters} />
                </Col>
                {!clusters && (
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <TooltipWrapper tooltipText="Website addresses where the most content is found (e.g., facebook.com)"
                            id="tooltip-domains-label"
                            placement="top"
                        >
                            <p className="font-weight-semibold widget-text-style set-fit-content">Domains</p>
                        </TooltipWrapper>
                        <DomainsFilter clusters={clusters} appliedFilters={appliedFilters} />
                    </Col>
                )}
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper tooltipText="Languages that have been used in the content" id="tooltip-languages-label" placement="top">
                        <p className="font-weight-semibold widget-text-style set-fit-content">Languages</p>
                    </TooltipWrapper>
                    <LanguagesFilter clusters={clusters} appliedFilters={appliedFilters} />
                </Col>
                {(!clusters) && (
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <TooltipWrapper id="tooltip-threats-label"
                            tooltipText={`Threats are AI generated classifiers we place on potentially harmful content which may originate
                                 from a suspicious source. These can be sources that are known to publish biased, non-factual information & 
                                 display traits of inauthentic behaviour.`}
                            placement="top"
                        >
                            <p className="font-weight-semibold widget-text-style set-fit-content">Threat type</p>
                        </TooltipWrapper>
                        <ThreatTypesFilter appliedFilters={appliedFilters} />
                    </Col>
                )}
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper id="tooltip-actors-label"
                        tooltipText="Accounts, groups, and websites that posted content available in your Situation Room"
                        placement="top"
                    >
                        <p className="font-weight-semibold widget-text-style set-fit-content">Actors</p>
                    </TooltipWrapper>
                    <ActorsFilter clusters={clusters} appliedFilters={appliedFilters} />
                </Col>
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper id="tooltip-terms-label"
                        placement="top"
                        tooltipText="Terms are identifiable words in content, including keywords, hashtags, entities, and mentions."
                    >
                        <p className="font-weight-semibold widget-text-style set-fit-content">Terms</p>
                    </TooltipWrapper>
                    {clusters ? <KeywordsFilter /> : <MultiKeywordsFilter appliedFilters={appliedFilters} />}
                </Col>
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper placement="top"
                        tooltipText="Locations mentioned in the content of this Situation Room"
                        id="tooltip-locations-label"
                    >
                        <p className="font-weight-semibold widget-text-style set-fit-content">Location mentions</p>
                    </TooltipWrapper>
                    <LocationsFilter clusters={clusters} appliedFilters={appliedFilters} />
                </Col>
                {!clusters && (
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <TooltipWrapper placement="top"
                            tooltipText="The most frequently found URLs in the content and their share counts."
                            id="tooltip-urls-label"
                        >
                            <p className="font-weight-semibold widget-text-style set-fit-content">Shared URLs</p>
                        </TooltipWrapper>
                        <UrlsFilter clusters={clusters} appliedFilters={appliedFilters} />
                    </Col>
                )}
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper id="tooltip-sentiment-label"
                        tooltipText="Sentiment types based on terms used, language patterns, and source credibility in the content"
                        placement="top"
                    >
                        <p className="font-weight-semibold widget-text-style set-fit-content">Sentiment</p>
                    </TooltipWrapper>
                    <SentimentFilter clusters={clusters} appliedFilters={appliedFilters} />
                </Col>
                {(contentCoordinationEnabled) && (
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <TooltipWrapper id="tooltip-coordinated-types-label"
                            tooltipText={`Coordination is when a group of people or accounts work together to 
            mislead others on social media or online platforms. Mimicking real user behaviour, like a grassroots movement, 
            to manipulate opinions, spread misinformation, or influence outcomes like elections.`}
                            placement="top"
                        >
                            <p className="font-weight-semibold widget-text-style set-fit-content">Coordinations</p>
                        </TooltipWrapper>
                        <CoordinationTypeFilter clusters={clusters} />
                    </Col>
                )}
                {(labelsFeatureEnabled && !clusters) && (
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <TooltipWrapper id="tooltip-labels-label"
                            placement="top"
                            tooltipText="Labels that have been applied to content by users of this Situation Room"
                        >
                            <p className="font-weight-semibold widget-text-style set-fit-content">Labels</p>
                        </TooltipWrapper>
                        <LabelsFilter watchlist={watchlist}
                            contentIdsList={contentIds}
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
};
