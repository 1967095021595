import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownWithSearchAndCheckboxes } from '../components/DropdownWithSearchAndCheckboxes';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { RootState } from '../../../store';
import { useTopSharedUrls } from '../../../services/Overview';

type UrlsFilterProps = {
    clusters?: boolean,
    appliedFilters: any
}
export const UrlsFilter = ({ clusters = false, appliedFilters }: UrlsFilterProps) => {
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();
    const { urls: selectedUrls }: { urls: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const { data, loading } = useTopSharedUrls({ clusters, size: 200, appliedFilters, searchValue });
    const urlsData = data?.map((url: {key: string, count: number}) => ({
        id: url.key,
        name: url.key,
        selected: selectedUrls.includes(url.key),
        count: url.count
    }));
    const handleSelectItem = (id: string) => {
        dispatch(saveSelectedFilters({
            urls: selectedUrls.includes(id)
                ? selectedUrls.filter(url => url !== id)
                : [...selectedUrls, id]
        }));
    };
    const countFilters = selectedUrls.length;
    return (
        <div>
            <DropdownWithSearchAndCheckboxes handleSelect={handleSelectItem}
                items={urlsData}
                placeholder="Search"
                description="Top 200 URLs"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                loading={loading}
                itemsPerPage={20}
                searchApi={setSearchValue}
            />
        </div>
    );
};
