import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomInput, FormGroup } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { saveSelectedFilters } from '../../../../pages/Room/store';
import { RootState } from '../../../../store';
import { Loading } from '../../../Loading';
import { useWordcloud } from '../../../../services/Overview';
import { LabelItem } from './LabelItem';

type KeywordsFilterProps = {
    searchQuery: string,
    appliedFilters: any
}

export const KeywordsFilter = ({ searchQuery = '', appliedFilters }: KeywordsFilterProps) => {
    const dispatch = useDispatch();
    const [dropdownId] = useState(uuid());

    const { keywords: selectedKeywords }: { keywords: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);

    const { keywords: topKeywords, loading } = useWordcloud({
        field: 'keywords',
        size: 30,
        defaultFilters: {
            wildcard: {
                search: searchQuery,
                field: 'keywords'
            }
        },
        search: searchQuery,
        appliedFilters
    });

    const keywordsData = topKeywords?.map((keyword: {key: string, keyword: string, count: number}) => ({
        id: keyword.key,
        name: keyword.keyword,
        selected: selectedKeywords.includes(keyword.key),
        count: keyword.count || 0
    })).sort((a: {count: number}, b: {count: number}) => b.count - a.count);

    const handleSelectItem = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(saveSelectedFilters({
            keywords: selectedKeywords.includes(id)
                ? selectedKeywords.filter(keyword => keyword !== id)
                : [...selectedKeywords, id]
        }));
    };

    return (
        <div>
            {(!searchQuery && keywordsData.length) ? <p>Top 30 terms</p> : ''}
            <div className="scrollbar-small tab-filter">
                {(keywordsData.length > 0 && !loading) && (
                    <>
                        {keywordsData.map((item: {id: string, name: string, selected: boolean, count: number}) => (
                            <FormGroup key={item.id}>
                                <CustomInput tag="checkbox"
                                    type="checkbox"
                                    checked={item.selected}
                                    className="custom-input-filter py-1"
                                    label={<LabelItem item={item} dropdownId={dropdownId} />}
                                    id={`${dropdownId}_${item.id}`}
                                    data-testid={`checkbox-${item.id}`}
                                    value={item.name}
                                    onChange={(e) => { handleSelectItem(item.id, e); }}
                                />
                            </FormGroup>
                        ))}
                    </>
                )}
                {loading && <Loading relative height={168} width={255} />}
                {(!keywordsData.length && !loading) && <p className="no-white-space">No results found</p>}
            </div>
        </div>
    );
};
