import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table } from 'reactstrap';
import { GET_CUSTOM_NARRATIVES } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { Loading } from '../../../../components/Loading';
import { CustomNarrativeItem } from './CustomNarrativeItem';
import { EmptyCustomNarrativeState } from './EmptyNarrativeState';
import { AddNewNarrativeButton } from './AddNewNarrativeButton';
import { SortToggle } from '../../../../components/Table/SortToggle';
import { lodashSort } from '../../../../utils/lib/tableSorting';
import { Pagination } from '../../../../components/Molecules';

export const CustomNarrativesOverview = () => {
    const [sort, setSort] = useState({});
    const [offset, setOffset] = useState(0);
    const room = getRoom();
    const { data, loading } = useQuery(GET_CUSTOM_NARRATIVES, {
        variables: {
            situationRoom: room.id,
            limit: 10,
            skip: offset || 0
        },
        fetchPolicy: 'no-cache'
    });
    const total = data?.getCustomNarratives?.total;
    const narratives = data?.getCustomNarratives?.customNarratives || [];

    if (loading) {
        return (
            <div className=" px-4 py-5 bg-white rounded">
                <div className="py-3" />
                <div className=" d-flex align-items-center justify-content-center">
                    <div className="py-5 d-flex flex-column align-items-center">
                        <Loading pointer={false} gif relative height={200} />
                    </div>
                </div>
            </div>
        );
    }
    if (narratives?.length === 0) {
        return <EmptyCustomNarrativeState />;
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-4 mt-4">
                <span className="d-flex align-items-center">
                    <h2>Custom Narratives</h2>
                    <p className="m-0 p-11 bg-primary-light rounder-4 ml-1">Total custom narrative created: {total}</p>
                </span>
                <AddNewNarrativeButton />
            </div>
            <Table className="mb-0 border" hover>
                <thead>
                    <tr>
                        <th><SortToggle sort={sort} setSort={setSort} name="Title" field="name" /></th>
                        <th>Topics</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {lodashSort(narratives, sort).map(narrative => (<CustomNarrativeItem narrative={narrative} key={narrative?.id} />))}
                </tbody>
            </Table>
            {setOffset && (
                <Pagination total={total}
                    itemsPerPage={10}
                    offset={offset}
                    setOffset={setOffset}
                    className="py-2 border"
                />
            )}
        </>
    );
};
