import { useQuery } from '@apollo/client';
import { gql } from '../../../../../generated';
import { getRoom } from '../../../../../utils/variables';
import { DateRangeInput } from '../../../../../generated/graphql';

export const useCustomNarrativesOverTime = ({ filters, dateFilter }: { filters: any, dateFilter?: DateRangeInput }) => {
    const room = getRoom();
    const { data, loading } = useQuery(GET_CUSTOM_NARRATIVES_OVER_TIME, {
        variables: { projectId: room.project_id, filters, dateFilter }
    });

    return {
        data: data?.getCustomNarrativesOverTime.data || [],
        total: data?.getCustomNarrativesOverTime.total || 0,
        loading
    };
};

export const GET_CUSTOM_NARRATIVES_OVER_TIME = gql(`
    query getCustomNarrativesOverTime($projectId: String!, $filters: JSON!, $dateFilter: DateRangeInput) {
        getCustomNarrativesOverTime(projectId: $projectId, filters: $filters, dateFilter: $dateFilter) {
            data {
                key
                total
                data {
                    date
                    count
                    engagement
                }
            }
            total
        }
    }
`);
