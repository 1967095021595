import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomInput, FormGroup } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { saveSelectedFilters } from '../../../../pages/Room/store';
import { RootState } from '../../../../store';
import { useWordcloud } from '../../../../services/Overview';
import { Loading } from '../../../Loading';
import { LabelItem } from './LabelItem';

type HashtagsFilterProps = {
    searchQuery: string,
    appliedFilters: any
}
export const HashtagsFilter = ({ searchQuery = '', appliedFilters }: HashtagsFilterProps) => {
    const dispatch = useDispatch();
    const [dropdownId] = useState(uuid());

    const { hashtags: selectedHashtags }: { hashtags: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);

    const { keywords: topHashtags, loading } = useWordcloud({
        field: 'hashtags',
        size: 30,
        defaultFilters: {
            wildcard: {
                search: searchQuery,
                field: 'hashtags'
            }
        },
        search: searchQuery,
        appliedFilters
    });

    const hashtagsData = topHashtags?.map((hashtag: {key: string, keyword: string, count: number}) => ({
        id: hashtag.key,
        name: hashtag.keyword,
        selected: selectedHashtags.includes(hashtag.key),
        count: hashtag.count || 0
    })).sort((a: {count: number}, b: {count: number}) => b.count - a.count);

    const handleSelectItem = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        dispatch(saveSelectedFilters({
            hashtags: selectedHashtags.includes(id)
                ? selectedHashtags.filter(hashtag => hashtag !== id)
                : [...selectedHashtags, id]
        }));
    };

    return (
        <div>
            {(!searchQuery && hashtagsData.length) ? <p>Top 30 hashtags</p> : ''}
            <div className="tab-filter">
                {(hashtagsData.length > 0 && !loading) && (
                    <>
                        {hashtagsData.map((item: {id: string, name: string, selected: boolean, count: number}) => (
                            <FormGroup key={item.id}>
                                <CustomInput tag="checkbox"
                                    type="checkbox"
                                    checked={item.selected}
                                    className="custom-input-filter py-1"
                                    label={<LabelItem item={item} dropdownId={dropdownId} />}
                                    id={`${dropdownId}_${item.id}`}
                                    data-testid={`checkbox-${item.id}`}
                                    value={item.name}
                                    onChange={(e) => { handleSelectItem(item.id, e); }}
                                />
                            </FormGroup>
                        ))}
                    </>
                )}
                {loading && <Loading relative height={168} width={255} />}
                {(!hashtagsData.length && !loading) && <p className="no-white-space">No results found</p>}
            </div>
        </div>
    );
};
