import React, { useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { X, ChevronLeft } from 'react-feather';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { SortToggle } from '../../Table/SortToggle';
import { SavedFilterRow } from './SavedFilterRow';
import { Loading } from '../../Loading';
import { Pagination } from '../../Molecules';
import { GET_ROOM_FILTERS, GET_ROOM_FILTERS_IN_CLUSTERS, RoomFilterProps, SaveFilterTypes } from '../FiltersLayout';
import { EditSavedFilter } from './EditSavedFilter';
import { EditSavedBoolean } from './EditSavedBoolean';
import { SaveFilterButton } from '../components/SaveFilter';
import { SaveBooleanButton } from '../components/SaveBoolean';
import { Images } from '../../../utils/modeSwitch';
import { useModal } from '../../../context/modal/ModalComponent';
import { color } from '../../../utils/getColors';
import { GET_SAVED_BOOLEAN_SEARCH } from '../BooleanSearch';
import { SavedBooleanRow } from './SavedBooleanRow';
import { DeleteBoolean } from './DeleteBoolean';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { DeleteFilter } from './DeleteFilter';

type ManageSavedItemsSort = {
    order: 'asc' | 'desc'
    field: 'project_name'
}

export type RoomBooleanProps = {
    query: string,
    name: string,
    id: string,
    createdAt: number,
    user: { displayName: string, id: string }
}

export interface ValidationErrors {[key: string]: { isError: boolean; markers: any[]}}

export const ManageSavedItems = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { setModal, closeModal } = useModal();
    const roomId = location.pathname.split('/')[2];
    const manageSavedBoolean = location.pathname.includes('manage-saved-boolean');
    const from = location.state?.from;
    const [itemName, setItemName] = useState('');
    const [userId, setUserId] = useState('');
    const [openEditSavedItem, setOpenEditSavedItem] = useState(false);
    const [editFilterId, setEditFilterId] = useState('');
    const [sort, setSort] = useState({ field: 'project_name', order: 'desc' } as ManageSavedItemsSort);
    const [offset, setOffset] = useState(0);
    const history = useHistory();
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
    const [invalidQuery, setInvalidQuery] = useState(false);
    const LIMIT = 5;

    const FILTERS_QUERY = from ? GET_ROOM_FILTERS_IN_CLUSTERS : GET_ROOM_FILTERS;
    const QUERY = manageSavedBoolean ? GET_SAVED_BOOLEAN_SEARCH : FILTERS_QUERY;

    const { data, loading } = useQuery(QUERY, {
        variables: {
            situationRoom: roomId,
            limit: LIMIT,
            skip: offset,
        },
        fetchPolicy: 'no-cache'
    });

    const manageSavedBooleanData: RoomBooleanProps[] = [...(data?.getSavedBooleanSearch?.booleanSearch || [])];
    const manageSavedFilterData: RoomFilterProps[] = (from ? data?.getRoomFiltersInClusters?.filters : data?.getRoomFilters?.filters) || [];
    const manageSavedBooleanCount: number = data?.getSavedBooleanSearch?.booleanSearchCount || 0;
    const manageSavedFilterCount: number = (from ? data?.getRoomFiltersInClusters?.filtersCount : data?.getRoomFilters?.filtersCount) || 0;

    const filtersData = manageSavedBoolean ? manageSavedBooleanData : manageSavedFilterData;
    const totalCount = manageSavedBoolean ? manageSavedBooleanCount : manageSavedFilterCount;

    const handleEditSavedFilter = (filterId: string) => {
        setEditFilterId(filterId);
        setOpenEditSavedItem(true);
        const editFilter = filtersData.find((a) => a.id === filterId);
        setUserId(editFilter?.user.id || '');
    };

    const handleBooleanDelete = (id: string) => {
        setModal({
            component: (<DeleteBoolean id={id} closeModal={closeModal} setOpenEditSavedItem={setOpenEditSavedItem} />)
        });
    };

    const sortData = (a: RoomBooleanProps | RoomFilterProps, b: RoomBooleanProps | RoomFilterProps) => {
        if (sort.order === 'asc') {
            return b.name?.localeCompare(a.name);
        }
        return a.name?.localeCompare(b.name);
    };
    const handleFilterDelete = (id: string) => {
        setModal({
            component: (
                <DeleteFilter id={id} clusters={from} openEditSavedItem={openEditSavedItem} setOpenEditSavedItem={setOpenEditSavedItem} />
            )
        });
    };
    const handleOnClose = () => {
        if (openEditSavedItem) {
            return setOpenEditSavedItem(false);
        } return history.goBack();
    };

    useEffect(() => {
        closeModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="bg-white d-flex flex-column flex-1 h-100">
            <div className="bg-light border-bottom py-2 px-4 d-flex align-items-center justify-content-between ">
                {!openEditSavedItem ? <h2 className="m-0">Manage saved {manageSavedBoolean ? 'Boolean searches' : 'filters'}</h2> : (
                    <h2 className="d-flex cursor-pointer text-center m-0">
                        <span className="mr-2">
                            <ChevronLeft size={20} color={color.blue[500]} onClick={() => setOpenEditSavedItem(false)} />
                        </span>
                        Edit saved {manageSavedBoolean ? 'Boolean search' : 'filter'}
                    </h2>
                )}
                <div role="button"
                    onClick={() => handleOnClose()}
                    data-testid="x-close-button"
                >
                    <X size={18} />
                </div>
            </div>
            <div className="d-flex flex-column flex-1 scrollbar-small h-100">
                {loading && <Loading gif relative height={400} />}
                {(manageSavedBoolean && !loading && !openEditSavedItem)
                && <p className="ml-4 mt-3 mb-0">{totalCount} saved Boolean {`search${totalCount > 1 ? 'es' : ''}`}</p> }
                {(!manageSavedBoolean && !loading && !openEditSavedItem)
                && <p className="ml-4 mt-3 mb-0">{totalCount} saved filter{`${totalCount > 1 ? 's' : ''}`}</p> }
                {(!openEditSavedItem && !loading && totalCount > 0) && (
                    <div className="border m-4 my-3">
                        <Table className="mb-0" hover>
                            <thead>
                                <tr>
                                    <th>
                                        <SortToggle sort={sort}
                                            setSort={setSort}
                                            name={manageSavedBoolean ? 'Name' : 'Filter name'}
                                            field="project_name"
                                        />
                                    </th>
                                    <th>Created by</th>
                                    <th>Created on</th>
                                    <th>{manageSavedBoolean ? 'Boolean query' : 'Selected filters'}</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtersData.sort(sortData).map((filter) =>
                                    (manageSavedBoolean
                                        ? (
                                            <SavedBooleanRow
                                                key={filter.id}
                                                boolean={filter as RoomBooleanProps}
                                                handleEditSavedFilter={handleEditSavedFilter}
                                            />
                                        ) : (
                                            <SavedFilterRow
                                                key={filter.id}
                                                filter={filter as RoomFilterProps}
                                                handleEditSavedFilter={handleEditSavedFilter}
                                                clusters={from}
                                            />
                                        )))}
                            </tbody>
                        </Table>
                        <Pagination total={totalCount}
                            itemsPerPage={LIMIT}
                            offset={offset}
                            setOffset={setOffset}
                            className=" px-2 pt-2 bg-white border-top"
                        />
                    </div>
                )}
                {(openEditSavedItem && !loading) && (
                    manageSavedBoolean
                        ? (
                            <EditSavedBoolean filterData={filtersData.find((a) => a.id === editFilterId) as RoomBooleanProps}
                                booleanName={itemName}
                                setFilterName={setItemName}
                                filterNamesList={filtersData.map((a) => a.name)}
                                validationErrors={validationErrors}
                                setValidationErrors={setValidationErrors}
                                setInvalidQuery={setInvalidQuery}
                            />
                        ) : (
                            <EditSavedFilter filterData={filtersData.filter((a) => a.id === editFilterId) as RoomFilterProps[]}
                                filterId={editFilterId}
                                filterName={itemName}
                                setFilterName={setItemName}
                                filterNamesList={filtersData.map((a) => a.name)}
                                clusters={from}
                            />
                        )
                )}
                {(totalCount === 0 && !loading) && (
                    <div className="border d-flex flex-column align-items-center justify-content-center p-4 m-4 h-50">
                        <img src={Images().overview}
                            alt="no-data-found"
                        />
                        <h2 className="my-2">There&apos;s nothing to see here</h2>
                        <Button onClick={() => history.goBack()} color="secondary">Close</Button>
                    </div>
                )}
            </div>
            <div className="border-top d-flex justify-content-end align-items-center bg-light position-fixed w-100
             z-index fixed-bottom pr-4"
            >
                {openEditSavedItem && manageSavedBoolean && (
                    <Button data-testid="clear-boolean-button"
                        color="link-danger my-2 mr-auto ml-3 py-0"
                        onClick={() => dispatch(saveSelectedFilters({ booleanQuery: '' }))}
                    >Clear Boolean query
                    </Button>
                )}
                <Button color="link my-2 py-0 mr-1" data-testid="close-button" onClick={() => handleOnClose()}>Close</Button>
                {openEditSavedItem && (manageSavedBoolean ? (
                    <>
                        <Button data-testid="save-boolean-button"
                            onClick={() => handleBooleanDelete(editFilterId)}
                            color="danger my-2 mr-3"
                        >Delete
                        </Button>
                        <SaveBooleanButton
                            setOpenEditSavedItem={setOpenEditSavedItem}
                            booleanName={itemName}
                            booleanNamesList={filtersData.map((a) => (a.id !== editFilterId ? a.name : ''))}
                            roomBoolean={filtersData.find((a) => a.id === editFilterId) as RoomBooleanProps}
                            validationErrors={validationErrors}
                            invalidQuery={invalidQuery}
                        />
                    </>
                ) : (
                    <>
                        <Button data-testid="save-filter-button"
                            onClick={() => handleFilterDelete(editFilterId)}
                            color="danger my-2 mr-3"
                        >Delete
                        </Button>
                        <SaveFilterButton text={SaveFilterTypes.Save}
                            defaultName={filtersData.filter((a) => a.id === editFilterId)[0].name}
                            filterId={editFilterId}
                            filterName={itemName}
                            userID={userId}
                            setOpenEditSavedFIlter={setOpenEditSavedItem}
                            filterNamesList={filtersData.map((a) => { if (a.id !== editFilterId) { return a.name; }
                                return '';
                            })}
                            roomFilters={filtersData as RoomFilterProps[]}
                            clusters={from}
                        />
                    </>

                ))}
            </div>
        </div>
    );
};
