import React, { useState } from 'react';
import { DateVisualizer } from '../../../../components/DateVisualizer';
import { ActorsAndPosts } from './ActorsAndPosts';
import { getCoordinatedDateType } from '../../../../utils/coordinatedTypes';
import { PatternChart } from './PatternChart';
import { getCtype } from '../../../../utils/text';
import { SingleNetworkProps } from './coordinationDataTypes';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { getPostedByName } from '../../../../utils/getPostedByName';

type SimilarPostingPatternProps = {
    data: any;
    header?: string;
    startDate?: Date | string | number;
    goToActors?: (name: string) => void;
}

export const SimilarPostingPattern = ({ data,
    header,
    startDate, goToActors }: SimilarPostingPatternProps) => {
    const uniqueActors = (item: any, key: string) => {
        if (!item?.network) {
            return [];
        }
        const sortedNetwork = [...item.network].sort((a, b) => a.pubdatetime - b.pubdatetime);
        const actorMap = new Map();
        sortedNetwork.forEach((item1: any) => {
            const actorKey = item1[key];
            if (!actorMap.has(actorKey)) {
                actorMap.set(actorKey, {
                    ...item1,
                    name: item1.from,
                    label: item1.from,
                    count: 1,
                    data: [1],
                    pubdatetimes: [item1.pubdatetime],
                });
            } else {
                const existing = actorMap.get(actorKey);
                actorMap.set(actorKey, {
                    ...existing,
                    data: [...existing.data, existing.data.length + 1],
                    count: existing.data.length + 1,
                    pubdatetimes: [...existing.pubdatetimes, item1.pubdatetime],
                });
            }
        });

        return Array.from(actorMap.values());
    };
    const [patternData, setPatternData] = useState(uniqueActors(data, 'from') || []);

    const handleChangeDataParamerters = (checkedValue: any) => {
        const filteredData = (uniqueActors(data, 'from') || []).filter(item => checkedValue[item.name]);
        setPatternData(filteredData);
    };
    return (
        <div className="p-2 rounded mb-2">
            <div className="d-flex justify-content-between align-items-center">

                <p className="m-0 font-weight-bold widget-text-style">
                    Pattern network
                </p>
                <DateVisualizer dateType={getCoordinatedDateType(header)}
                    startDate={patternData?.[0]?.pubdatetime}
                    endDate={patternData?.[patternData.length - 1]?.pubdatetime}
                />
            </div>
            <div className="bg-lightgray p-2">
                <div className="my-2">
                    <PatternChart data={patternData} />
                </div>
                <ActorsAndPosts data={uniqueActors(data, 'from') || []}
                    isCheckList
                    checkedValue={handleChangeDataParamerters}
                />

            </div>
            <div className="bg-white">
                <div className="actors-modal scrollbar-small mt-3">
                    {data.network?.map((network: SingleNetworkProps) => {
                        const ctype = getCtype(network?.ctype);
                        return (
                            <div key={network.docId}
                                className="bg-lightgray p-2 rounded mb-2"
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <SourceIconLabel data={{ contentType: network.ctype }} className="d-inline" />
                                        <span className="text-primary cursor-pointer dont-break-out"
                                            onClick={() => goToActors?.(network.from)}
                                        >
                                            {getPostedByName(network.from)}
                                        </span>
                                        {' '}<span>via {ctype.name}</span>
                                        <p className="m-0"><a href={network.url} rel="noreferrer" target="_blank">{network.url}</a></p>
                                    </div>
                                    <DateVisualizer dateType={getCoordinatedDateType(header)} startDate={network.pubdatetime} />
                                </div>
                                <p className="mt-2">{network.text}</p>
                            </div>
                        ); }) }
                </div>

            </div>
        </div>
    );
};
