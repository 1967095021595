import { checkFilters, makeQuery } from '../../pages/Room/CustomNarratives/Overview/CustomNarrativeDetails';
import { useCustomNarratives } from '../Narratives/getCustomNarratives';
import { useCustomNarrativesOverTime } from '../../pages/Room/CustomNarratives/Overview/CustomNarrativeDetails/getCustomNarrativesOverTime';

export const useCustomNarrativesAndThreats = ({ dateFilter }) => {
    const { data, loading } = useCustomNarratives();
    const narratives = data?.map(narrative => ({
        id: narrative.id,
        threats: narrative.data.map(threat => ({
            ...threat,
            query: makeQuery(threat)
        })),
        narrative_title: narrative.name
    }));
    const allQueries = narratives.map(a => a.threats).flat();

    const { data: customNarrativesOverTime, loading: loadingOverTime } = useCustomNarrativesOverTime({
        filters: Object.fromEntries(allQueries?.map((threat) => ([threat.id, checkFilters(threat)]))),
        dateFilter
    });

    return {
        data: !loadingOverTime ? narratives.map(narrative => ({
            ...narrative,
            threats: narrative.threats.map(threat => {
                const aggs = customNarrativesOverTime.find(a => a.key === threat.id);
                if (!aggs) { return false; }
                return {
                    ...threat,
                    aggs
                };
            }).filter(a => a)
        })) : [],
        loading: loading || loadingOverTime
    };
};
