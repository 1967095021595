import React, { useState } from 'react';
import { CustomInput, Row, Col } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { ExternalLink } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Content } from './Content';
import { ContentTableContentType } from './index';
import { ThreatLabels } from './ThreatLabels';
import { ContentActionsDropdown } from './ContentActionsDropdown';
import { ContentTableContext } from './ContentTableContext';
import { GET_CONTENT_LABELS, LabelDropdown } from '../../../pages/Room/Labels';
import { getRoom } from '../../../utils/variables';
import { TextTooltip } from '../../ToolTip/TextTooltip';
import { ContentFooter } from './ContentFooter';
import { ExternalLinkButton } from '../../LinkModal/ExternalLinkButton';
import { useGetWatchlist } from '../../../services/Threat/getWatchlist';
import { WatchlistButtonContainer } from '../../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { WATCHLIST_BUTTON_TYPES } from '../../../pages/Room/Threats/Threat/WatchlistButton';
import { ContentId } from '../../../pages/Room/AIClustering/types';
import { ThreatSignalLabels } from './ThreatSignals/ThreatSignalLabels';
import { getAllLabels } from './ThreatSignals/getAllLabels';
import { setSelectedContent } from '../../../pages/Room/store';
import { RootState } from '../../../store';
import { Coordination } from '../../../generated/graphql';

type ContentTableViewItemProps = {
    content: ContentTableContentType
    selectedContentItems: ContentTableContentType[]
    noActionsAllowed: boolean
    onSetSelectedContentItems: (values: ContentTableContentType[]) => void
    onRemoveContent?: (data: { id: string }[]) => void
    onMarkAsThreat?: (id: string) => void
    onMarkAsNotThreat?: (ids: string[]) => void
    clusterData?: ContentId[]
    cib?: Coordination[]
}

export const ContentTableViewItem = ({
    content,
    selectedContentItems,
    onSetSelectedContentItems,
    noActionsAllowed,
    onRemoveContent,
    onMarkAsThreat,
    onMarkAsNotThreat,
    clusterData,
    cib
}: ContentTableViewItemProps) => {
    const dispatch = useDispatch();
    const { removedContent, selectedContent, bulkContent } = useSelector((state: RootState) => state.selectedContent);
    const room = getRoom();
    const [textLimit, setTextLimit] = useState(400);
    const [sentimentExpanded, setSentimentExpanded] = useState(false);
    const [keywordsExpanded, setKeywordsExpanded] = useState(false);
    const [engagementExpanded, setEngagementExpanded] = useState(false);
    const [signalsExpanded, setSignalsExpanded] = useState(false);
    const checked = selectedContentItems.map(x => x.id).indexOf(content.id) === -1;
    const threatSignalsEnabled = !!room?.instance?.plan?.core?.threatSignals;

    const { watchlist, refetchWatchlist } = useGetWatchlist({
        situationRoomId: room.id
    });
    const isWatching = watchlist ? watchlist.filter(({ id }: {id: string}) => id === content.id).length > 0 : false;

    const handleTextLimitChange = (value: number) => {
        setTextLimit(value);
    };

    const handleCheckboxChange = () => {
        if (!checked) {
            if (bulkContent) {
                const filteredContent = removedContent.filter((a) => a !== content.id);
                dispatch(setSelectedContent({
                    removedContent: [...filteredContent, content.id]
                }));
            } else {
                const filteredContent = selectedContent.filter((a) => a !== content.id);
                dispatch(setSelectedContent({
                    selectedContent: [...filteredContent]
                }));
            }

            return onSetSelectedContentItems(
                selectedContentItems?.filter(selectedContentItem => selectedContentItem.id !== content.id)
            );
        }

        if (bulkContent) {
            const filteredContent = removedContent.filter((a) => a !== content.id);
            dispatch(setSelectedContent({
                removedContent: [...filteredContent]
            }));
        } else {
            const filteredContent = selectedContent.filter((a) => a !== content.id);
            dispatch(setSelectedContent({
                selectedContent: [...filteredContent, content.id]
            }));
        }

        return onSetSelectedContentItems([
            ...selectedContentItems,
            content
        ]);
    };

    const handleMetricsClick = (type: string) => {
        switch (type) {
            case 'sentiment':
                setSentimentExpanded(!sentimentExpanded);
                setKeywordsExpanded(false);
                setEngagementExpanded(false);
                setSignalsExpanded(false);
                break;
            case 'keywords':
                setSentimentExpanded(false);
                setKeywordsExpanded(!keywordsExpanded);
                setEngagementExpanded(false);
                setSignalsExpanded(false);
                break;
            case 'engagement':
                setSentimentExpanded(false);
                setKeywordsExpanded(false);
                setSignalsExpanded(false);
                setEngagementExpanded(!engagementExpanded);
                break;
            case 'signals':
                setSentimentExpanded(false);
                setKeywordsExpanded(false);
                setEngagementExpanded(false);
                setSignalsExpanded(!signalsExpanded);
                break;
            case 'closeAll':
                setSentimentExpanded(false);
                setKeywordsExpanded(false);
                setEngagementExpanded(false);
                setSignalsExpanded(false);
                break;
        }
    };

    const { data } = useQuery(GET_CONTENT_LABELS, {
        variables: {
            situationRoom: room?.id
        },
        fetchPolicy: 'network-only',
        skip: !(room?.instance?.plan?.others?.labels)
    });

    const allLabels = getAllLabels({
        contentId: content.id,
        userLabelsData: data?.getContentLabels || [],
        threatResolvers: content.threatObject?.threatResolvers || []
    });
    const isThreat = content?.threatObject?.threatLevel ? content?.threatObject?.threatLevel !== 'none' : false;

    if (!content) {
        return null;
    }
    return (
        <tr key={content.id}>
            {!noActionsAllowed && (
                <td className="pr-0 align-top">
                    <CustomInput type="checkbox"
                        checked={!checked}
                        id={`contentTableViewItem-${content.id}`}
                        onChange={handleCheckboxChange}
                        data-testid={`${content.id}-checkbox`}
                    />
                </td>
            )}
            <td className="p-0 align-top">
                <Row className="p-0 m-0">
                    <Col className="p-0">
                        <Row className="mt-2 mr-0">
                            <Col lg="8" className="p-0">
                                {!threatSignalsEnabled ? <ThreatLabels content={content} />
                                    : <ThreatSignalLabels content={content} handleSignalClick={() => handleMetricsClick('signals')} />}
                            </Col>
                            <Col lg="4" className="p-0 pr-1">
                                <div className="d-flex justify-content-end align-items-center content-top-bar">
                                    {content.url && (
                                        <TextTooltip placement="top"
                                            title="Open source"
                                            id={`content-${content.id}-link`}
                                            withIcon
                                            className="mr-1"
                                        >
                                            <ExternalLinkButton url={content.url}>
                                                <ExternalLink size={24} />
                                            </ExternalLinkButton>
                                        </TextTooltip>
                                    )}
                                    {!threatSignalsEnabled && (
                                        <TextTooltip placement="top"
                                            title="Labels"
                                            id={`content-${content.id}-labels`}
                                            withIcon
                                            className="mt-11 mr-2"
                                        >
                                            <LabelDropdown contentId={content.id} roomId={room?.id} />
                                        </TextTooltip>
                                    )}
                                    {isThreat ? (
                                        <TextTooltip placement="top"
                                            title={isWatching ? 'Remove to watchlist' : 'Add to watchlist'}
                                            id={`content-${content.id}-watchlist`}
                                            withIcon
                                            className="mr-1"
                                        >
                                            <WatchlistButtonContainer contents={[content]}
                                                isWatching={isWatching}
                                                onCompleted={refetchWatchlist}
                                                type={WATCHLIST_BUTTON_TYPES.TEXT_BUTTON}
                                                showText={false}
                                            />
                                        </TextTooltip>
                                    ) : null }
                                    {(!noActionsAllowed) && (
                                        <TextTooltip placement="top"
                                            title="Menu"
                                            id={`content-${content.id}-menu`}
                                            withIcon
                                        >
                                            <ContentActionsDropdown content={content}
                                                onRemoveContent={onRemoveContent}
                                                onMarkAsThreat={onMarkAsThreat}
                                                onMarkAsNotThreat={onMarkAsNotThreat}
                                            />
                                        </TextTooltip>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row className={`ml-0 mr-0 mb-2 ${(allLabels.length === 0 && !isThreat) && 'no-labels-header'}`}>
                            <div className="col col-lg-8 p-0">
                                <Content content={content}
                                    textLimit={textLimit}
                                    onReadMoreClick={() => handleTextLimitChange(999999)}
                                    onReadLessClick={() => handleTextLimitChange(400)}
                                    clusterData={clusterData}
                                />
                            </div>
                            <div className="col col-lg-4 p-0" />
                        </Row>
                        <ContentFooter content={content}
                            onSentimentClick={() => handleMetricsClick('')}
                            onKeywordsClick={() => handleMetricsClick('keywords')}
                            onEngagementClick={() => handleMetricsClick('engagement')}
                            clusterData={clusterData}
                            cib={cib}
                        />
                    </Col>
                    <ContentTableContext content={content}
                        engagementExpanded={engagementExpanded}
                        keywordsExpanded={keywordsExpanded}
                        signalsExpanded={signalsExpanded}
                        onClose={() => handleMetricsClick('closeAll')}
                    />
                </Row>
            </td>
        </tr>
    );
};
