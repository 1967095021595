import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { WatchlistButtonContentKeys } from '../../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { Content } from '../../../services/Content/contentInterface';
import { useQueryExtracts } from '../../../services/Content/getQueryExtracts';
import { SentimentCard } from './SentimentCard';
import { largeNumber } from '../../../utils/NumberFormat';
import { getRoom } from '../../../utils/variables';
import { WidgetName, WidgetTextTooltip } from '../../../pages/Room/AIClustering/SelectedClusterView/Summary/WidgetTextTooltip';
import { getIcons } from '../../../utils/getIcons';
import { UserLabels } from './ThreatSignals/userLabels';
import { ContentId } from '../../../pages/Room/AIClustering/types';
import { CoordinatedActivity } from './ContentCoorindation/CoordinationActivity';
import { GET_CONTENT_COORDINATION } from '../../../graphql';
import { Coordination } from '../../../generated/graphql';

type RoomPreviewKeywords = {
    highlightKeywords: string[]
}

type ContentTypes = 'body' | 'datetime' | 'title' | WatchlistButtonContentKeys

type ContentFooterProps = {
    content: Pick<Content, ContentTypes>
    onSentimentClick: () => void
    onKeywordsClick: () => void
    onEngagementClick: () => void
    isOverview?: boolean
    preview?: boolean
    clusterData?: ContentId[]
    openInNewTab?: boolean
    cib?: Coordination[]
}

export const ContentFooter = ({
    content,
    onSentimentClick,
    onKeywordsClick,
    onEngagementClick,
    isOverview = false,
    preview = false,
    clusterData = [],
    openInNewTab = false,
    cib
}: ContentFooterProps) => {
    const location = useLocation();
    const room = getRoom();
    const threatSignalsEnabled = !!room?.instance?.plan?.core?.threatSignals;
    const contentCoordinationEnabled = !!room?.instance?.plan?.others?.contentCoordination;
    const { data } = useQuery(GET_CONTENT_COORDINATION, {
        variables: {
            projectId: room.project_id,
            docIds: [content.id]
        },
        fetchPolicy: 'cache-and-network',
        skip: !contentCoordinationEnabled || Boolean(cib)
    });
    const coordinations = cib || data?.getCoordinations || [];

    const { highlightKeywords } = useSelector((state: {roomPreviewKeywords: RoomPreviewKeywords}) => state.roomPreviewKeywords);
    const passKeywords = location.pathname.includes('create-room') || location.pathname.includes('edit-room')
        || location.pathname.includes('edit-draft-room');

    const { keywordsMentioned } = useQueryExtracts({
        url: content.url,
        text: content.body || content.text,
        keywordsToHighlight: passKeywords ? highlightKeywords : [] as any
    });

    const cardsDisplay = content.sentiment || keywordsMentioned.length > 0 || content.engagement > 0
     || content?.threatObject?.threatLevel !== 'none';
    const allCardsPresent = keywordsMentioned.length > 0 && (content.engagement || content?.threatObject?.threatLevel !== 'none');

    return (
        <Row className={`ml-0 ${isOverview ? 'mr-1' : 'mr-4'} 
        justify-content-between ${cardsDisplay ? 'mb-1' : 'no-cards-display float-right'}`}
        >
            {(cardsDisplay) && (
                <div className={`${(isOverview && allCardsPresent) ? 'mb-1' : ''} card-group`}>
                    {content.engagement > 0 && (
                        <Col className="card content-view new-content-view mr-3 px-0 col-lg-4 col-xs-6 mb-1">
                            <div className="p-1 pl-2">
                                <WidgetTextTooltip widgetName={WidgetName.Engagement} classes="card-text title" />
                                <span className="d-flex align-items-center">{getIcons('engagement')}
                                    <p className={`card-text ml-1 m-0 ${!preview ? 'cursor-pointer text-primary' : ''} `}
                                        onClick={() => onEngagementClick()}
                                    >
                                        {content.engagement === -1 ? 'N/A' : largeNumber(content.engagement)}
                                    </p>
                                </span>
                            </div>
                        </Col>
                    )}
                    <Col className="card content-view new-content-view non-clickable mr-3 px-0 col-lg-4 col-xs-6 mb-1"
                        onClick={() => onSentimentClick()}
                    >
                        <SentimentCard sentiment={content.sentiment} />
                    </Col>
                    {keywordsMentioned.length > 0 && (
                        <Col className="card content-view new-content-view keywords mr-3 px-0 col-lg-4 col-xs-6 mb-1">
                            <div className="p-1 pl-2">
                                <WidgetTextTooltip widgetName={WidgetName.KeywordsMentions} classes="card-text title" />
                                <span className="d-flex align-items-center">{getIcons('keywordsMentions')}
                                    <p className={`card-text ml-1 ${!preview ? 'cursor-pointer text-primary' : ''}`}
                                        onClick={() => onKeywordsClick()}
                                    >
                                        {keywordsMentioned.length}
                                    </p>
                                </span>
                            </div>
                        </Col>
                    )}
                    {threatSignalsEnabled && <UserLabels contentId={content?.id} />}
                    {coordinations.length > 0 && contentCoordinationEnabled ? (
                        <CoordinatedActivity
                            content={content}
                            coordinationData={coordinations}
                        />
                    ) : null}
                </div>
            )}
            {!preview ? (
                <Link className={`${cardsDisplay ? 'mt-auto mb-1' : 'mt-3'}`}
                    to={{
                        pathname: `/situation-rooms/${room.id}/threats/${content.id}`,
                        state: { from: clusterData.length > 0 ? 'aiNarratives' : '' }
                    }}
                    target={openInNewTab ? '_blank' : '_self'}
                >
                    <Button color="secondary">View details</Button>
                </Link>
            ) : null}
        </Row>
    );
};
