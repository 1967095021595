import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Cluster } from '../../../../../AIClustering/types';
import { truncateString } from '../../../../../../../utils/text';
import { Engagement } from '../../../../../AIClustering/SelectedClusterView/Summary/ClusterStats/Engagement';
import { SubClusters } from '../../../../../AIClustering/SelectedClusterView/Summary/ClusterStats/SubClusters';
import { Content } from '../../../../../AIClustering/SelectedClusterView/Summary/ClusterStats/Content';
import { SentimentCard } from '../../../../../AIClustering/SelectedClusterView/Summary/ClusterStats/SentimentCard';

type NarrativesRowProps = {
    cluster: Cluster
    selected?: any
    toggleThreat?: (a: any, b: any) => void
    block?: boolean
}
const textLengthLimit = 300;
const expandedTextLimit = 999999;

export const NarrativesRow = ({ cluster, selected, toggleThreat, block = false }: NarrativesRowProps) => {
    const [textLimit, setTextLimit] = useState(textLengthLimit);
    const handleTextLimitChange = () => {
        setTextLimit(textLimit === expandedTextLimit ? textLengthLimit : expandedTextLimit);
    };

    return (
        <tr>
            <td>
                {!block ? <h5>{cluster.title}</h5> : <p className="font-weight-bold">{cluster.title}</p>}
                <p>{truncateString(cluster.description, textLimit)}
                    {(cluster.description.length > textLimit) && !block && (
                        <Button color="link" className="p-0 mw-0" onClick={handleTextLimitChange}>Read more</Button>
                    )}
                    {(textLimit === expandedTextLimit) && !block && (
                        <Button color="link" className="p-0 mw-0" onClick={handleTextLimitChange}>Read less</Button>
                    )}
                </p>
                <div className="cluster-stats-align mt-2">
                    <Engagement count={cluster.engagement} disabled />
                    <SubClusters disabled count={cluster.subClustersMappedId.length} />
                    <Content count={cluster.volume} disabled />
                    <SentimentCard sentiments={cluster.sentimentJson}
                        averageSentiment={cluster.averageSentiment}
                        id={`sentiment_${cluster.id}`}
                    />
                </div>
            </td>
            {!block && (
                <td>
                    {!selected ? (
                        <Button color="primary" className="set-max-content" onClick={() => toggleThreat && toggleThreat(cluster, selected)}>
                            Add to report
                        </Button>
                    ) : (
                        <Button color="danger" className="set-max-content" onClick={() => toggleThreat && toggleThreat(cluster, selected)}>
                            Remove from report
                        </Button>
                    )}
                </td>
            )}
        </tr>
    );
};
