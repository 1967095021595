import React from 'react';
import { Button } from 'reactstrap';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { DateVisualizer } from '../../../../components/DateVisualizer';
import { ReadMore } from '../../../../components/Readmore';
import { IconsAndCount } from './ActorsAndPosts';
import { getParam, setParam } from '../../../../utils/urlParams';
import { CoordinationDataType, SingleNetworkProps } from './coordinationDataTypes';

type CoordinatedSingleProps = {
    getWidgetName: string;
    getCoordinatedDateType: any;
    startDate: string | Date | number;
    endDate?: string | Date | number;
    item: CoordinationDataType;
    header: string;
    singleActor?: any;
    summaryText?: string;
    setCoordinationId: (data: any) => void;
    setSelectedData?: (data: any) => void;
    setCoordinationType?: (type: string) => void;
    getAllActors?: () => void

}

export const CoordinatedSingle = ({ getWidgetName, getCoordinatedDateType,
    startDate, item, header, singleActor, endDate, setSelectedData, summaryText,
    setCoordinationId, setCoordinationType, getAllActors }: CoordinatedSingleProps) => {
    const actorId = getParam('actor_id')?.replace('@', '');
    const uniqueActors = Array.from(
        new Map(item.network.map((item1: { from: string }) => [item1.from, item1])).values()
    );
    const actorsData = [
        {
            id: 1,
            label: 'Actors',
            count: uniqueActors.length
        },
        {
            id: 2,
            label: 'Posts',
            count: item.network.length
        }
    ];
    if (actorId) {
        const actorsPosts = item.network.filter((item1: SingleNetworkProps) => item1.from.includes(actorId));
        actorsData.push({
            id: 3,
            label: `${actorId} posts`,
            count: actorsPosts.length
        });
    }
    const text = item.network[0].text;
    // const text = summaryText || item.network[0].text;
    const handleSinglePost = () => {
        const networkData = [...item.network];
        setSelectedData?.(networkData.sort(
            (a: SingleNetworkProps, b: SingleNetworkProps) => {
                const dateA = new Date(a.pubdatetime);
                const dateB = new Date(b.pubdatetime);
                return dateB.getTime() - dateA.getTime(); // Compare timestamps
            }
        ));
        setParam('coordination_id', item.id);
        setCoordinationId(item.id);
        setCoordinationType?.(WidgetName[getWidgetName as keyof typeof WidgetName]);
    };
    return (
        <div key={item.coordinationSource} className="bg-lightgray p-2 rounded mb-2">
            <div className="d-flex justify-content-between align-items-center">

                <p className="m-0 font-weight-bold widget-text-style">
                    <WidgetTextTooltip noMargin
                        widgetName={WidgetName[getWidgetName as keyof typeof WidgetName]}
                    />
                </p>
                <DateVisualizer dateType={getCoordinatedDateType}
                    startDate={startDate}
                    endDate={endDate}
                />
            </div>
            <hr />
            {((header === 'Coordinated_posts')
                || (header === 'Coordinated_reposts')) && text.length > 400
                ? <ReadMore text={text} limit={400} className="mb-2" />
                : <p className="mb-2">{text}</p>}
            <div className="d-flex align-items-end justify-content-between">
                <div className="d-flex gap-2">
                    {
                        actorsData.map((x, index) => {
                            if (index === 0) {
                                return (
                                    <span key={x.label}
                                        onClick={getAllActors}
                                        className="cursor-pointer"
                                    >
                                        <IconsAndCount link label="Actors" count={uniqueActors.length} />
                                    </span>
                                );
                            }

                            return <IconsAndCount key={x.label} label={x.label} count={x.count} />;
                        })
                    }
                </div>
                <Button size="sm" onClick={handleSinglePost}>
                    View
                </Button>
            </div>
        </div>
    );
};
