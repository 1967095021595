import React from 'react';
import { TooltipWrapper } from '../../../ToolTip/TooltipWrapper';
import { truncateString } from '../../../../utils/text';
import { largeNumber } from '../../../../utils/NumberFormat';

type LabelItemProps = {
    item: {
        id: string,
        name: string,
        count: number
    },
    dropdownId: string
}

export const LabelItem = ({ item, dropdownId }: LabelItemProps) => {
    if (item.name.length >= 11) {
        return (
            <>
                <TooltipWrapper tooltipText={item.name} id={`tooltip_${dropdownId}_${item.id}`}>
                    <p className="truncate-this">{truncateString(item.name, 20)}</p>
                </TooltipWrapper>
                <p className="ml-1">({largeNumber(item.count)})</p>
            </>
        );
    }
    return (
        <>
            <p className="truncate-this">{truncateString(item.name, 20)}</p>
            &nbsp;
            <p>({largeNumber(item.count)})</p>
        </>
    );
};
