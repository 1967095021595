import { useQuery } from '@apollo/client';
import { gql } from '../../../../generated';
import { getRoom } from '../../../../utils/variables';

export const useCoordinationsTopKeywords = ({ ids }: { ids: string[] }) => {
    const room = getRoom();
    const { data, loading } = useQuery(GET_COORDINATIONS_TOP_KEYWORDS, {
        variables: {
            ids,
            projectId: room?.project_id
        },
        skip: !room?.project_id || !ids.length
    });
    return {
        data: data?.coordinationsTopKeywords || {
            hashtags: [],
            entities: []
        },
        loading
    };
};

const GET_COORDINATIONS_TOP_KEYWORDS = gql(`
    query getCoordinationsTopKeywords($ids: [String!]!, $projectId: String!) {
        coordinationsTopKeywords(ids: $ids, projectId: $projectId) {
            hashtags {
                hashtag
                count
            }
            entities {
                entity
                count
            }
        }
    }
`);
