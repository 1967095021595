import React, { useState, useRef } from 'react';
import { Loading } from '../../../../components/Loading';
import { SmallPagination } from '../../../../components/Molecules';
import { usePreviewData } from './usePreviewData';
import { ActivityItems } from '../../../../components/ActivityItem/ActivityItems';
import { MatchesHeader } from '../../../Room/Overview/Matches/header';

export const Matches = () => {
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState({ value: 'dateNew', name: 'Newest first' });
    const itemsPerPage = 5;
    const { loading, data: contents, count } = usePreviewData({
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        sort: sort.value
    });
    const matchesRef = useRef(null);
    return (
        <div className="bg-white p-3 h-100 d-flex flex-column" ref={matchesRef}>
            <div>
                <MatchesHeader count={count} sort={sort.name} setSort={setSort} isPreview />
                {loading && <Loading relative height={200} />}
            </div>
            {!loading && contents.length > 0 && (
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div>
                        {contents.map(content => {
                            let highlights = content.highlight?.text || [];
                            if (content.highlight?.body?.length > highlights.length) { highlights = content.highlight.body; }
                            return (
                                <div key={content.id}>
                                    <ActivityItems content={content}
                                        preview
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="d-flex justify-content-end">
                        <SmallPagination page={page}
                            setPage={(p) => {
                                setPage(p);
                                document.getElementById('right-panel')?.scrollTo({ top: matchesRef.current?.offsetTop || 0 });
                            }}
                            total={count}
                            itemsPerPage={itemsPerPage}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
