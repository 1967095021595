import React, { useState, useRef } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import moment, { Moment } from 'moment';
import { color } from '../../utils/getColors';

type DateRangePickerProps = {
    startDate?: Moment | Date | undefined
    minDate?: Moment | Date
    maxDate?: Moment | Date
    onCallback: () => void
    autoUpdateInput?: boolean
    placeholder?: string
    className?: string
    state?: 'error' | ''
}

export const DatePicker = ({
    startDate, minDate, maxDate, onCallback, autoUpdateInput, placeholder, className, state
}: DateRangePickerProps) => {
    const [focus, setFocus] = useState(false);
    const dateRef = useRef(null);

    return (
        <InputGroup border={focus ? 'active' : 'none'} className={`d-inline-flex mt-0 ${className || ''}`} state={state}>
            <Label className="d-flex w-100">
                <ReactDateRangePicker ref={dateRef}
                    onCallback={onCallback}
                    initialSettings={{
                        singleDatePicker: true,
                        startDate,
                        minDate,
                        maxDate,
                        autoUpdateInput
                    }}
                >
                    <input placeholder={placeholder || 'Select date range'}
                        type="text"
                        className="form-control mw-190"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        data-testid="with-date-input"
                        value={(autoUpdateInput || !startDate) ? '' : moment(startDate).format('DD/MM/YYYY')}
                    />
                </ReactDateRangePicker>
                <InputGroupAddon addonType="prepend" className={`small-border cursor-pointer ${focus ? 'border-left' : 'border-left'}`}>
                    <InputGroupText className="bg-white border-left-0"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                    >
                        <FeatherIcon.Calendar size={18}
                            color={color.blue[500]}
                            data-testid="date-picker-single"
                        />
                    </InputGroupText>
                </InputGroupAddon>
            </Label>
        </InputGroup>
    );
};
