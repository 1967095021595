import React, { useState } from 'react';
import {
    CustomInput,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    FormGroup
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { v4 as uuid } from 'uuid';
import * as FeatherIcon from 'react-feather';
import { useDropDown } from '../../../../utils/useDropdown';
import { Loading } from '../../../Loading';
import { color } from '../../../../utils/getColors';
import { allTypes } from '../../../../utils/lib/search';
import { largeNumber } from '../../../../utils/NumberFormat';

type DropdownWithCheckboxesProps = {
    name: string,
    items: {
        id: string,
        name: string,
        selected: boolean,
        count: number
    }[],
    handleSelect: (ids: string[]) => void,
    loading: boolean,
    className?: string
    children?: React.ReactNode
    error?: boolean,
};

export const DropdownWithCheckboxes = ({
    name, items, handleSelect, loading, className = '', children, error
}: DropdownWithCheckboxesProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const [newid] = useState(uuid());

    const handleSelectItem = (ids: string[]) => {
        handleSelect(ids);
    };
    const resolverIds = allTypes.filter((a) => a.isResolver).map((b) => b.key);
    const selectedValues = items.filter((item) => item.selected).map((item) => item.id);

    const handleOnChange = (checked: boolean, id: string) => {
        if (id === 'toxic') {
            if (checked) {
                const filteredIds = items.filter((item) => item.count !== 0).map((item) => item.id);
                const nonEmptyIds = resolverIds.filter((item) => filteredIds.includes(item));
                return handleSelectItem([...selectedValues, ...nonEmptyIds]);
            }
            return handleSelectItem(selectedValues.filter(a => (!resolverIds.includes(a))));
        }
        if (checked) {
            return handleSelectItem([...selectedValues, id]);
        }
        return handleSelectItem(selectedValues.filter(a => a !== id));
    };
    const handleChecked = (item: { id: string, count: number }) => {
        if (item.id === 'toxic') {
            return selectedValues.some((a) => resolverIds.includes(a));
        }
        return !(selectedValues.indexOf(item.id) === -1) && !(item.count === 0);
    };

    return (
        <Dropdown isOpen={dropdown}
            toggle={toggleDropdown}
        >
            <DropdownToggle tag="div"
                data-testid="dropdown-toggle"
                className={`dropdown-filter d-flex justify-content-between align-items-center cursor-pointer px-2 py-1 
                    ${dropdown && 'border border-primary'} ${error && 'border border-danger'} ${className}`}
            >
                <span>{name}</span>
                <ChevronDown size={20}
                    className="ml-11"
                    color={color.blue[500]}
                    style={{ transition: 'all .25s ease-in-out', transform: dropdown ? 'rotate(-180deg)' : 'none' }}
                />
            </DropdownToggle>
            <DropdownMenu className={`dropdown-filter-menu-checkboxes ${dropdown && 'border border-primary'}`}>
                <div className="dropdown-filter-menu-items border-bottom">
                    {children}
                    {!loading && items?.map((item, index) => (
                        <FormGroup key={item.id}
                            className={`${index === items.length - 1 ? 'mb-0' : ''}`}
                        >
                            <div className="d-flex">
                                {resolverIds.includes(item.id) && (
                                    <FeatherIcon.CornerDownRight size={20}
                                        color="#E5E6EB"
                                        className=""
                                    />
                                )}
                                <CustomInput type="checkbox"
                                    checked={handleChecked(item)}
                                    className="custom-control-filter grid-item"
                                    label={(
                                        <>
                                            <span>{item.name}</span>
                                            <span className="ml-1">({largeNumber(item.count)})</span>
                                        </>
                                    )}
                                    id={`filter-checkbox-${item.id}-${newid}`}
                                    data-testid={`checkbox-${item.id}`}
                                    value={item.name}
                                    onChange={({ target: { checked } }) => {
                                        handleOnChange(checked, item.id);
                                    }}
                                    disabled={item.count === 0}
                                />
                            </div>

                        </FormGroup>
                    ))}
                    {loading && <Loading relative height={168} width={255} />}
                    {(!items.length && !loading) && <p className="no-white-space">No results found</p>}
                </div>
            </DropdownMenu>
        </Dropdown>
    ); };
