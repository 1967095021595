import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { useDropDown } from '../../../../utils/useDropdown';
import { getSortOptions } from '../Utils/sortOptions';

type SortbyProps = {
    sort: {field: string, order: string, name: string},
    setSort: (({ field, order, name }: { field: string, order: string, name: string }) => void)
    classname?: string,
    sortFor:
    | 'Coordinated_posts'
    | 'Coordinated_reposts'
    | 'Coordinated_hashtags'
    | 'Coordinated_hashtag_sequences'
    | 'Similar_account_handles'
    | 'Similar_posting_patterns'
    | 'Posting_pattern'
    | string;
}
export const SortbyDropdown = ({
    sort,
    setSort,
    classname,
    sortFor
}: SortbyProps) => {
    const [dropdown, toggleDropdown] = useDropDown();

    const sortOptions = getSortOptions(sortFor);
    return (
        <div className="d-flex align-items-center mb-1">
            <p className="set-max-content mr-1 mb-0">Sort by</p>
            <Dropdown isOpen={dropdown} toggle={toggleDropdown} className={`set-min-width ${classname}`}>
                <DropdownToggle data-testid="search-sort-dropdown">
                    {sort.name || sortOptions[0]?.name}
                    <ChevronDown size={16} className="ml-11" />
                </DropdownToggle>
                <DropdownMenu
                    modifiers={{
                        flip: {
                            fn: (data) => ({
                                ...data,
                                placement: 'right-end'
                            })
                        },
                        offset: {
                            offset: '0,-40px'
                        }
                    }}
                >
                    {sortOptions.map(({ field, order, name }) => (
                        <DropdownItem key={field + order}
                            name={name}
                            onClick={() =>
                                setSort({ field, order, name })}
                        >{name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
