import { Button } from 'reactstrap';
import React, { useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useApolloClient } from '@apollo/client';
import { X } from 'react-feather';
import { useLocation } from 'react-router-dom';
import { ProgressBar } from '../../../ProgressBar';
import { useContent } from '../../../../services/Content/getContent';
import { getRoom } from '../../../../utils/variables';
import { BatchRemoveCancelledModal } from './BatchRemoveCancelledModal';
import { RemoveContentActions, useRemoveContent } from '../../../CommonModals/useRemoveContent';
import { BatchRemoveNoContentsModal } from './BatchRemoveNoContentsModal';
import { useModal } from '../../../../context/modal/ModalComponent';
import { BatchRemovePreparingModal } from './BatchRemovePreparingModal';
import { useRecordBulkRemoval } from './recordBulkRemoval';

/*
 * This modal will process the bulk removal of all documents provided by search query
 * It will fetch data and start removing data recursively until the job is done
 *
 * It is possible to pause the operation in which case it will pause from the next batch
 */

type BatchRemoveModalProps = {
    onCompleted?: ({ total, timeInSeconds }: { total: number, timeInSeconds: number }) => void;
    onAbort: () => void;
    contentType: string;
    unSelectedIds?: string[] | undefined
};

export const BatchRemoveModal = ({ onCompleted, onAbort, contentType, unSelectedIds = [] }: BatchRemoveModalProps) => {
    const [isPaused, setIsPaused] = useState(false);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [firstFetch, setFirstFetch] = useState(true);
    const [timeStart, setTimeStart] = useState(0);
    const [allIds, setAllIds] = useState<string[]>([]);

    const { closeModal } = useModal();
    const apolloClient = useApolloClient();
    const location = useLocation();
    const { value, type } = location.state || {};
    const defaultFilters = (type && value) ? { [type]: value } : {};

    const { recordBulkRemoval } = useRecordBulkRemoval({ ids: allIds });

    const { removeContent, loading: removeRequestProcessing } = useRemoveContent({
        type: contentType,
        action: RemoveContentActions.RemoveContent,
        refetchQueries: ['getContentToDelete'],
        bulkRemove: true
    });

    const { loading: fetchPending, count: currentTotal, data: content, refetch } = useContent({
        filters: defaultFilters,
        unSelectedIds,
        name: 'getContentToDelete',
        fragments: ['Metadata'],
        useGlobalFilters: true,
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        limit: 1000,
        onCompleted: () => {
            if (isPaused) return;
            if (firstFetch) {
                setTotalDocuments(currentTotal);
                setTimeStart(moment.now());
                if (currentTotal) {
                    setFirstFetch(false);
                }
            }
            if (currentTotal) {
                removeContent(content.map((item: any) => ({ id: item.id })));
                setAllIds([...allIds, ...content.map((item: any) => item.id)]);
            }
            if (!firstFetch && !currentTotal) {
                onCompleted && onCompleted({ total: totalDocuments, timeInSeconds: (moment.now() - timeStart) / 1000 });
                recordBulkRemoval();
                apolloClient.refetchQueries({ include: 'all' });
            }
        }
    });

    const pausePending = isPaused && removeRequestProcessing;

    const handleCancel = () => {
        setIsPaused(true);
        if (totalDocuments === 0) {
            closeModal();
        }
    };

    const handleResume = () => {
        setIsPaused(false);
        refetch();
    };

    if (firstFetch && !fetchPending && totalDocuments === 0 && currentTotal === 0) {
        return <BatchRemoveNoContentsModal />;
    }

    if (totalDocuments === 0) {
        return <BatchRemovePreparingModal handleCancel={handleCancel} />;
    }

    if (isPaused && !removeRequestProcessing && !fetchPending) {
        return <BatchRemoveCancelledModal onResume={handleResume} onAbort={onAbort} contentsToProcess={currentTotal} />;
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <h2 className="mt-0">In progress</h2>
                <X color="#050E2B"
                    size={20}
                    data-testid="close-modal"
                    className="cursor-pointer mt-1"
                    stroke="#050E2B"
                    onClick={onAbort}
                />
            </div>
            <hr className="mb-2 mt-0" />
            <BatchRemoveModalContent totalDocuments={totalDocuments} currentTotal={currentTotal} pausePending={pausePending} />
            <hr className="my-2" />
            <Button className="mr-2" color="secondary" disabled={pausePending} onClick={handleCancel}>Cancel</Button>
        </>
    );
};

type BatchRemoveModalContentProps = {
    totalDocuments: number;
    currentTotal: number;
    pausePending: boolean;
};
const BatchRemoveModalContent = ({ totalDocuments, currentTotal, pausePending }: BatchRemoveModalContentProps) => {
    const room = getRoom();
    const removedCount = totalDocuments > 0 ? totalDocuments - currentTotal : 0;
    let description = 'Bulk removal in progress';

    if (pausePending) {
        description = 'Cancelling, please wait';
    }

    return (
        <>
            <div className="mb-1">
                <FontAwesomeIcon icon={faSpinner as any} className="mr-1 fa-spin" /> {description}
            </div>
            <ProgressBar max={totalDocuments} current={removedCount < 0 ? 0 : removedCount} />
            <p className="mt-1">There are {currentTotal} contents left to be removed from {room.project_name}</p>
        </>
    );
};
