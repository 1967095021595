import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useLocation } from 'react-router-dom';
import { Loading } from '../../../components/Loading/index';
import { SearchFeedback } from '../../../components/SearchFeedback';
import { EmptySearchResults } from '../../../components/EmptySearchResults';
import { Layout } from './Layout';
import { OverviewEditBar } from './OverviewEditBar';
import { RemovedWidgets } from './RemovedWidgets';
import { CONTENT_TYPE } from '../../../utils/contentTypes';
import { useTotalMatches } from '../../../services/Overview/totalMatches';
import { SearchBarNew } from '../../../components/NewFilters';
import { SearchBar } from '../../../components/Search';
import { getRoom } from '../../../utils/variables';
import { initialState, saveFilters, saveSelectedFilters } from '../store';
import { EmptySituationRoomView } from './EmptySituationRoomView';
import { setLayoutBackup } from './store';
import { standardTemplate } from './Templates/Standard';
import { getCtype } from '../../../utils/text';

export const Overview = ({ bread, data }) => {
    const dispatch = useDispatch();
    const { search } = useLocation();

    const params = search.split(/[=?,]/);
    const startDate = params[2];
    const endDate = params[3];
    const category = params[5];
    const subcategory = params[7];

    useEffect(() => {
        if (category === 'ctypes') {
            const filters = {
                contentType: [subcategory],
                dateRange: {
                    startDate,
                    endDate
                },
                roomId: data.situationRoom.id,
                sourcesWithDomains: [subcategory].map((
                    item
                ) => ({ key: item, label: getCtype(item) })),
                sources: [subcategory],
                sentiment: [],
                threatTypes: []
            };
            dispatch(saveFilters(filters));
            dispatch(saveSelectedFilters(filters));
        }
        else if (category === 'sentiments') {
            const sentimentFilters = {
                sentiment: [subcategory],
                dateRange: {
                    startDate,
                    endDate
                },
                roomId: data.situationRoom.id,
                contentType: [],
                threatTypes: []

            };
            dispatch(saveFilters(sentimentFilters));
            dispatch(saveSelectedFilters(sentimentFilters));
        }
        else if (category === 'overall') {
            const overAllFilters = {
                contentType: [],
                dateRange: {
                    startDate,
                    endDate
                },
                roomId: data.situationRoom.id,
                sourcesWithDomains: [],
                sources: [],
                sentiment: [],
                threatTypes: []
            };
            dispatch(saveFilters(overAllFilters));
            dispatch(saveSelectedFilters(overAllFilters));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, endDate, startDate, subcategory, search]);

    const { loading: roomOverviewLoading } = useQuery(GET_ROOM_OVERVIEW, {
        variables: {
            roomId: data.situationRoom.id
        },
        onCompleted: (overview) => {
            dispatch(setLayoutBackup(overview?.getRoomOverview || standardTemplate));
        }
    });
    const { totalMatches, loading: totalMatchesLoading } = useTotalMatches();
    const { totalMatches: totalMatchesWithoutFilters, loading: totalMatchesLoadingB } = useTotalMatches({}, {}, false);

    if (roomOverviewLoading || totalMatchesLoading || totalMatchesLoadingB) {
        return <Loading gif height="100vh" pointer={false} />;
    }
    return (
        <div className="page-padding">
            <OverviewInner bread={bread} totalMatches={totalMatches} totalMatchesWithoutFilters={totalMatchesWithoutFilters} />
        </div>
    );
};

export const GET_ROOM_OVERVIEW = gql`
    query getRoomOverview($roomId: ID!) {
        getRoomOverview(roomId: $roomId) {
            situationRoom
            data {
                id
                type
                x
                y
                w
                h
                minH
                minW
                maxH
                maxW
                data {
                    type
                    size
                    title
                    columns
                    items {
                        type
                        id
                        title
                    }
                    percentage
                    fields
                }
                removed
                dateRemoved
            }
        }
    }
`;

const OverviewInner = ({ bread, totalMatches, error, totalMatchesWithoutFilters }) => {
    const { edit, hasChanges } = useSelector((state) => state.overview);
    const dispatch = useDispatch();
    const parseFilters = sessionStorage.getItem('initialFilters');
    const initialFilters = parseFilters ? JSON.parse(parseFilters) : null;
    const room = getRoom();

    if (initialFilters) {
        dispatch(saveFilters({ ...initialFilters, roomId: room?.id }));
        sessionStorage.removeItem('initialFilters');
    }
    const filters = useSelector((state) => state.filters.filters);
    const hasFilters = filters.origin?.length > 0
      || filters.sentiment?.length > 0
      || filters.sources?.length > 0
      || filters.dateRange?.startDate !== initialState.filters.dateRange.startDate
      || filters.query?.length > 0 || filters.urls?.length > 0 || filters.hashtags?.length > 0 || filters.keywords?.length > 0
      || filters.domains.length > 0 || filters.threatTypes.length > 0 || filters.languages.length > 0 || filters.from.length > 0;

    const settingUpRoom = new Date().getTime() < room.updatedAt + (2 * 60 * 60 * 1000); // 2 hours

    if (settingUpRoom && !totalMatchesWithoutFilters && !hasFilters && !filters.convertedBooleanQuery?.boosting?.positive?.bool) {
        return <EmptySituationRoomView />;
    }

    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;

    return (
        <div>
            <Prompt when={edit && hasChanges} message="Are you sure want to exit the screen? Changes you made will not be saved." />
            {edit && (
                <>
                    <OverviewEditBar />
                    <div className="overview-spacer" />
                    <RemovedWidgets />
                </>
            )}
            <div className="d-flex justify-content-between align-items-start">
                {bread}
            </div>
            <div className="d-flex justify-content-between align-items-center mt-0 mb-2">
                <h2>Overview</h2>
                <SearchFeedback totalCount={totalMatches} error={error} contentType={CONTENT_TYPE.MATCH} />
            </div>
            <div className={edit ? 'pointer-events-none' : ''}>
                {betaFiltersEnabled ? <SearchBarNew overview showBooleanSearch /> : <SearchBar name="Overview Filters" showBooleanSearch />}
            </div>
            {totalMatches === 0 ? (
                <EmptySearchResults mainHeading={totalMatchesWithoutFilters ? "We couldn't find any results" : ''}
                    subtitle={totalMatchesWithoutFilters ? 'Try adjusting your search or filter options' : ''}
                    filters={!!totalMatchesWithoutFilters}
                />
            ) : (
                <Layout />
            )}
        </div>
    );
};
