import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import createSingleLinePlugin from 'draft-js-single-line-plugin';
import Editor from 'draft-js-plugins-editor';
import { RichUtils, AtomicBlockUtils, EditorState, Modifier } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useDispatch, useSelector } from 'react-redux';
import { colorPickerPlugin } from './customColors';
import { setToolbarAction } from '../../../../../../redux/reports';
import { color as allColors } from '../../../../../../utils/getColors';
import { ImageComponent } from './ImageComponent';
import { UploadFile } from '../../../../../Admin/Investigations/InvestigationDetails/UploadFile';
import { useCustomStateWithCallback } from './useStateWithCallback';

const singleLinePlugin = createSingleLinePlugin();
export const TextEditor = forwardRef(({ value, onChange, placeholder, multiline, disabled, hasDarkBg, images, parentRef }) => {
    const dispatch = useDispatch();
    const { latestTool } = useSelector(state => state.createReport);
    const [editorStateValue, setState] = useCustomStateWithCallback(value || EditorState.createEmpty());
    const editorState = disabled ? value : editorStateValue;
    const [focus, setFocus] = useState(false);
    const [align, setAlign] = useState('left');
    const color = colorPickerPlugin(setState, () => value);
    const uploadImageRef = useRef(null);
    useEffect(() => {
        if (focus) {
            const currentStyles = Array.from(editorState.getCurrentInlineStyle());
            const toolbarStyles = Object.entries(latestTool).map(a => [a[0].toUpperCase(), a[1]]);
            let newVal = editorState;
            let shouldChange = false;
            for (const style of toolbarStyles) {
                if (['BOLD', 'ITALIC', 'UNDERLINE'].includes(style[0])) {
                    const isEnabledAlready = currentStyles.includes(style[0]);
                    const shouldToggle = (isEnabledAlready && !style[1]) || (!isEnabledAlready && style[1]);
                    if (shouldToggle) {
                        newVal = RichUtils.toggleInlineStyle(newVal, style[0]);
                        shouldChange = true;
                    }
                }
            }
            if (shouldChange) {
                handleChange(newVal);
            }
            if (latestTool.color && latestTool.color !== color.currentColor(editorState)) {
                color.addColor(latestTool.color);
            }
            if (latestTool.align && latestTool.align !== align) {
                setAlign(latestTool.align);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestTool, focus]);
    const handleFocus = () => {
        const currentStyles = Array.from(value.getCurrentInlineStyle());
        dispatch(setToolbarAction({
            align,
            color: color.currentColor(value),
            ...Object.fromEntries(['BOLD', 'ITALIC', 'UNDERLINE'].map(
                a => ([a.toLowerCase(), currentStyles.includes(a)])
            ))
        }));
        setFocus(true);
    };
    useImperativeHandle(parentRef, () => ({
        handleImageUpload: () => {
            uploadImageRef.current.click();
        }
    }));
    const removeExistingImage = () => {
        const contentState = editorState.getCurrentContent();
        const blockMap = contentState.getBlockMap();

        let newContentState = contentState;
        const selection = editorState.getSelection();

        blockMap.forEach((block) => {
            if (block.getType() === 'atomic') {
                const blockKey = block.getKey();
                const blockSelection = selection.merge({
                    anchorKey: blockKey,
                    anchorOffset: 0,
                    focusKey: blockKey,
                    focusOffset: block.getLength(),
                });

                newContentState = Modifier.removeRange(newContentState, blockSelection, 'backward');
            }
        });

        const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
        handleChange(newEditorState);
    };

    const handleImageUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                removeExistingImage();
                insertImage(reader.result, 'left'); // Default alignment is left
            };
            reader.readAsDataURL(file);
        }
    };

    const insertImage = (src, alignment = 'left') => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            { src, alignment, width: 200 }
        );
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorState,
            contentStateWithEntity.getLastCreatedEntityKey(), // Use the key from createEntity directly
            ' '
        );
        handleChange(newEditorState);
    };

    const replaceImage = () => {
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const blockMap = contentState.getBlockMap();

        let blockToBeMovedKey = null;
        let extractedImageData = null;

        blockMap.forEach((block) => {
            if (block.getType() === 'atomic') {
                blockToBeMovedKey = block;
                const entityKey = block.getEntityAt(0);
                if (entityKey) {
                    extractedImageData = contentState.getEntity(entityKey).getData();
                }
            }
        });

        if (blockToBeMovedKey && extractedImageData?.src) {
            const newEditorState = AtomicBlockUtils.moveAtomicBlock(
                editorState,
                blockToBeMovedKey,
                selection
            );

            handleChange(newEditorState);
        }
    };

    const onDragOver = (e) => {
        e.preventDefault();
        replaceImage();
    };
    const blockRendererFn = (block) => {
        if (block.getType() === 'atomic') {
            return {
                component: block?.getEntityAt?.(0) && ImageComponent,
                editable: false,
                props: {
                    editorState,
                    setState,
                    onDragOver,
                    disabled
                },
            };
        }
        return null;
    };
    const handleChange = (e) => {
        setState(() => e, () => onChange(e));
    };

    return (
        <div>
            <UploadFile
                accept="image/*"
                setFile={handleImageUpload}
                className={(images && !disabled) ? 'd-block' : 'd-none'}
                innerRef={parentRef ? uploadImageRef : null}
            />
            <Editor plugins={multiline ? [] : [singleLinePlugin]}
                editorState={editorState}
                onChange={(e) => {
                    if (!disabled) {
                        handleChange(e);
                    }
                }}
                placeholder={placeholder}
                customStyleFn={(styleProps) => {
                    const toolbarColor = color.customStyleFn(styleProps).color;
                    if (toolbarColor) return ({ color: toolbarColor });
                    return hasDarkBg ? { color: allColors.grey.white } : {};
                }}
                onFocus={handleFocus}
                onBlur={() => setFocus(false)}
                blockRendererFn={blockRendererFn}
                textAlignment={align}
                readOnly={disabled}
            />
        </div>
    );
});
