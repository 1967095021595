import React from 'react';
import { WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { getParam, setParam, unsetParam } from '../../../../utils/urlParams';
import { ActorDetail } from './ActorDetail';
import { Tabs } from '../../../../components/Tabs';
import { CoordinatedSingle } from './CoordinatedSingle';
import { getCoordinatedDateType } from '../../../../utils/coordinatedTypes';
import { activity, ActivityType } from '../../../../utils/text/coordinatedActivity';
import { ActorPost } from './ActorPosts';
import { getDates } from '../../../../components/Organisms/ContentTableView/ContentCoorindation/CoordinatedActivityList';

type AllActorsProps = {
    actors: any;
    refreshBreadCrums: () => void;
    setCoordinationId: (id: string) => void
    setSelectedData?: (data: any) => void;
    setCoordinationType?: (type: string) => void;
    getAllActors?: () => void
}

export const AllActors = ({ actors, refreshBreadCrums, setCoordinationId, setSelectedData, setCoordinationType,
    getAllActors }: AllActorsProps) => {
    const handleSetParam = (name: string) => {
        unsetParam('coordination_id');
        setParam('actor_id', name);
        refreshBreadCrums();
    };
    if (getParam('actor_id')) {
        const actorsId = getParam('actor_id');
        const actor = actors?.filter((item: any) => item.from.includes(actorsId))?.[0] || [];
        const dateType = getCoordinatedDateType('Coordinated_posts');
        const allPosts = actor?.allData?.map((item: any) => item.network).flat();
        const tabs = [{
            name: 'Coordinated activity',
            id: 'coordinated_activity',
            content: (
                <div className="actors-modal scrollbar-small">
                    {actor?.allData?.map((x: any) => (
                        <CoordinatedSingle
                            getCoordinatedDateType={dateType}
                            getWidgetName={activity[x.coordinationType as keyof ActivityType]}
                            header="Coordinated_posts"
                            item={x}
                            summaryText={actor.text}
                            singleActor={actor}
                            setSelectedData={setSelectedData}
                            startDate={getDates(x.network).startDate}
                            endDate={getDates(x.network).endDate}
                            setCoordinationId={setCoordinationId}
                            setCoordinationType={setCoordinationType}
                            getAllActors={getAllActors}
                        />
                    ))}
                </div>)
        }, {
            name: 'Posts',
            id: 'posts',
            content: (
                <div className="actors-modal scrollbar-small">
                    {allPosts?.map((x: any) => (
                        <ActorPost key={x.coordination_source} actor={x} />
                    ))}
                </div>)
        }];
        return (
            <div>
                <ActorDetail actor={actor} />
                <Tabs data={tabs} />
            </div>
        );
    }

    return (
        <>
            <div className="d-flex align-items-center">
                <h3 className="mr-1 my-0"><WidgetTextTooltip widgetName="Actors" noMargin /></h3>
                <span className="tag" color="dark">{actors.length} actors </span>
            </div>
            <div className="actors-modal scrollbar-small mt-3">
                {
                    actors.map((actor: any) => <ActorDetail actor={actor} key={actor.from} handleSetParam={handleSetParam} />)
                }
            </div>
        </>
    );
};
