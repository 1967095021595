import React from 'react';
import { DataAdd } from '../../../../assets/svg/DataAdd';
import { AddNewNarrativeButton } from './AddNewNarrativeButton';

export const EmptyCustomNarrativeState = () => (
    <div className="py-7 px-3 d-flex justify-content-center bg-white text-center mt-4">
        <div className="maxw-500">
            <DataAdd />
            <h3 className="d-block mx-auto py-1">No custom narratives created yet</h3>
            <p className="mb-2">There are no custom narratives to display right now.
                Once custom narratives are created, they’ll appear here.
            </p>
            <AddNewNarrativeButton color="secondary" />
        </div>
    </div>
);
