import React from 'react';
import { useQuery } from '@apollo/client';
import { TooltipItem } from '../../../../../components/ToolTip';
import { SingleCoordination } from '../../../../../components/Organisms/ContentTableView/ContentCoorindation/SingleCoordination';
import { GET_CONTENT_COORDINATION } from '../../../../../graphql';
import { getRoom } from '../../../../../utils/variables';
import { Loading } from '../../../../../components/Loading';
import { NoDataAvailableState } from '../../../../../components/EmptyState';

type CoordinationProps ={
    contentId: string
    contentUrl?: string
}

export const Coordination = ({ contentId, contentUrl }: CoordinationProps) => {
    const room = getRoom();
    const { data, loading } = useQuery(GET_CONTENT_COORDINATION, {
        variables: {
            projectId: room.project_id,
            docIds: [contentId]
        },
        fetchPolicy: 'cache-and-network'
    });

    const coordinations = data?.getCoordinations || [];

    if (loading) {
        return (
            <div className="p-2 my-3 bg-white rounded">
                <Loading relative height={200} />
            </div>
        );
    }

    return (
        <div className="p-2 my-3 bg-white rounded">
            <div className="d-flex align-items-center mb-0">
                <h3 className="m-0">Coordination detected</h3>
                <TooltipItem id="coordinationTooltip"
                    content={`Coordination is when a group of people or accounts work together to 
                mislead others on social media or online platforms. Mimicking real user behaviour, like a grassroots movement, 
                to manipulate opinions, spread misinformation, or influence outcomes like elections.`}
                    placement="right"
                    iconSize={20}
                />
            </div>
            <hr />
            {
                coordinations.length < 1
                    ? <NoDataAvailableState subText="This content is not associated with any network." />
                    : (
                        <SingleCoordination coordinationData={coordinations}
                            contentUrl={contentUrl}
                            isSummaryPage
                        />
                    )
            }
        </div>
    );
};
