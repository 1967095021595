import React, { useState } from 'react';
import {
    CustomInput,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem
} from 'reactstrap';
import { MoreVertical } from 'react-feather';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useMarkAsRead } from '../../../services/Alerts/useMarkAsRead';
import { GET_ALERTS } from '../../../graphql';
import { NotificationCard } from './NotificationsCard';
import { Pagination } from '../../Molecules';
import { Loading } from '../../Loading';
import { useArchiveAlert } from '../../../services/Alerts/useArchiveAlert';

type AlertSituation = {
    id: string,
    name: string
   }

type NotificationDefaultProps = {
    id: string,
    projectId: string,
    date: number,
    category: string,
    subcategory: string,
    description: string,
    situationRoom: AlertSituation,
    read: boolean,
    archived?: boolean
};

export const Inbox = () => {
    const [showUnread, setShowUnread] = useState(false);
    const [offset, setOffset] = useState(0);
    const { data, loading } = useQuery(GET_ALERTS, {
        variables: {
            offset,
            limit: 10,
            unreadOnly: showUnread,
        },
        fetchPolicy: 'cache-and-network'
    });
    const notifications = (data?.getAlerts?.alerts || []) as NotificationDefaultProps[];
    const total = data?.getAlerts?.total || 0;
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    const todays = notifications?.filter(item =>
        moment(item.date).format('DD/MM/YY') === moment(new Date()).format('DD/MM/YY')) || [];
    const yesterdays = notifications?.filter(item =>
        moment(item.date).format('DD/MM/YY') === moment(yesterday).format('DD/MM/YY')) || [];
    const older = notifications?.filter(item =>
        (moment(item.date).format('DD/MM/YY') !== moment(new Date()).format('DD/MM/YY'))
        && (moment(item.date).format('DD/MM/YY') !== moment(yesterday).format('DD/MM/YY'))) || [];

    const { markAsRead } = useMarkAsRead({
        alertIds: notifications.map(item => item.id),
        markAllAsRead: true,
        showUnread
    });

    const { archiveAlert } = useArchiveAlert({
        alertIds: notifications.map(item => item.id),
    });

    return (
        <div>
            {loading ? <Loading height={200} relative /> : (
                <>
                    <div className="d-flex my-3 align-items-center">
                        <p className="mb-0">{total} notifications</p>
                        <CustomInput type="switch"
                            id="unreadSwitch"
                            data-testid="unreadSwitch"
                            className="custom-control-input-big mr-1 ml-auto primary"
                            onChange={(e) => setShowUnread(e.target.checked)}
                            checked={showUnread}
                        />
                        <p className="mr-3 mb-0">Unread only</p>
                        <UncontrolledDropdown direction="left" className="mw-0">
                            <DropdownToggle tag="div">
                                <MoreVertical size={24} role="button" className="text-primary d-block" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem className="px-2 py-1" onClick={() => markAsRead()}>
                                    Mark all as read
                                </DropdownItem>
                                <DropdownItem className="px-2 py-1" onClick={() => archiveAlert()}>
                                    Archive all on this page
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <div className="noti-container px-3 pt-3 pb-2">
                        <div>
                            {todays?.length !== 0 && (
                                <>
                                    <p className="pb-2 font-size-14">Today</p>
                                    {todays?.map(item => (
                                        <div key={item.id}>
                                            <NotificationCard notification={item} showUnread={showUnread} full />
                                        </div>
                                    ))}
                                </>
                            )}
                            {yesterdays?.length !== 0 && (
                                <>
                                    <p className="pb-2 font-size-14">Yesterday</p>
                                    {yesterdays?.map(item => (
                                        <div key={item.id}>
                                            <NotificationCard notification={item} showUnread={showUnread} full />
                                        </div>
                                    ))}
                                </>
                            )}
                            {older?.length !== 0 && (
                                <>
                                    <p className="pb-2 font-size-14">Older</p>
                                    {older?.map(item => (
                                        <div key={item.id}>
                                            <NotificationCard notification={item} showUnread={showUnread} full />
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                    {total > 10 && (
                        <div className="bg-white">
                            <hr className="m-0" />
                            <Pagination total={total || 0}
                                itemsPerPage={10}
                                offset={offset}
                                setOffset={setOffset}
                                className="p-2 mb-0"
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
