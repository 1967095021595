export const getSortOptions = (sortFor: string) => {
    switch (sortFor) {
        case 'Coordinated_posts':
            return [
                { field: 'from', order: 'asc', name: 'Actors (Most)' },
                { field: 'from', order: 'dse', name: 'Actors (Least)' },
                { field: 'network', order: 'asc', name: 'Posts (Most)' },
                { field: 'network', order: 'dse', name: 'Posts (Least)' },
                { field: 'shortest_timeframe', order: 'dse', name: 'Shortest timeframe' }
            ];
        case 'Coordinated_reposts':
            return [
                { field: 'from', order: 'asc', name: 'Actors (Most)' },
                { field: 'from', order: 'dse', name: 'Actors (Least)' },
                { field: 'network', order: 'asc', name: 'Posts (Most)' },
                { field: 'network', order: 'dse', name: 'Posts (Least)' },
                { field: 'shortest_timeframe', order: 'dse', name: 'Shortest timeframe' }
            ];
        case 'Coordinated_hashtags':
            return [
                { field: 'from', order: 'asc', name: 'Actors (Most)' },
                { field: 'from', order: 'dse', name: 'Actors (Least)' },
                { field: 'network', order: 'asc', name: 'Posts (Most)' },
                { field: 'network', order: 'dse', name: 'Posts (Least)' },
                { field: 'shortest_timeframe', order: 'dse', name: 'Shortest timeframe' }
            ];
        case 'Coordinated_hashtag-sequences':
            return [
                { field: 'from', order: 'asc', name: 'Actors (Most)' },
                { field: 'from', order: 'dse', name: 'Actors (Least)' },
                { field: 'network', order: 'asc', name: 'Posts (Most)' },
                { field: 'network', order: 'dse', name: 'Posts (Least)' },
                { field: 'shortest_timeframe', order: 'dse', name: 'Shortest timeframe' }
            ];
        case 'Similar_account_handles':
            return [
                { field: 'from', order: 'asc', name: 'Actors (Most)' },
                { field: 'from', order: 'dse', name: 'Actors (Least)' },
                { field: 'network', order: 'asc', name: 'Posts (Most)' },
                { field: 'network', order: 'dse', name: 'Posts (Least)' },
                { field: 'shortest_timeframe', order: 'dse', name: 'Shortest timeframe' }
            ];
        case 'Coordination_actors':
        case 'Actors':
            return [
                { field: 'count', order: 'asc', name: 'Coordinations (Most)' },
                { field: 'count', order: 'dse', name: 'Coordinations (Least)' },
                { field: 'network', order: 'asc', name: 'Posts (Most)' },
                { field: 'network', order: 'dse', name: 'Posts (Least)' },
                { field: 'creation_time', order: 'asc', name: 'Creation time (First)' },
                { field: 'creation_time', order: 'dse', name: 'Creation time (Last)' }
            ];
        case 'Similar_posting_patterns':
        case 'Posting_pattern': // Handles both cases
            return [
                { field: 'from', order: 'asc', name: 'Actors (Most)' },
                { field: 'from', order: 'dse', name: 'Actors (Least)' },
                { field: 'network', order: 'asc', name: 'Posts (Most)' },
                { field: 'network', order: 'dse', name: 'Posts (Least)' },
                { field: 'reposts', order: 'asc', name: 'Reposts (Most)' },
                { field: 'reposts', order: 'dse', name: 'Reposts (least)' },
                { field: 'shortest_timeframe', order: 'dse', name: 'Shortest timeframe' }
            ];

        default:
            return [];
    }
};
