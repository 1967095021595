import React, { useState } from 'react';
import {
    CustomInput,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { v4 as uuid } from 'uuid';
import * as FeatherIcon from 'react-feather';
import { useDropDown } from '../../../utils/useDropdown';
import { Loading } from '../../Loading';
import { color } from '../../../utils/getColors';
import { TooltipWrapper } from '../../ToolTip/TooltipWrapper';
import { Pagination } from '../../Molecules';
import { largeNumber } from '../../../utils/NumberFormat';
import { truncateString } from '../../../utils/text';

type DropdownWithSearchAndCheckboxesProps = {
    name: string,
    placeholder?: string,
    description?: string,
    items: {
        id: string,
        name: string,
        selected: boolean,
        count?: number
    }[],
    handleSelect: (id: string) => void,
    loading: boolean,
    className?: string
    children?: React.ReactNode
    error?: boolean,
    itemsPerPage: number,
    searchApi?: (search: string) => void,
};

export const DropdownWithSearchAndCheckboxes = ({
    name, placeholder, description, items, handleSelect, loading, className = '', children, error, itemsPerPage, searchApi
}: DropdownWithSearchAndCheckboxesProps) => {
    const [offset, setOffset] = useState(0);
    const [dropdown, toggleDropdown] = useDropDown();
    const [searchValue, setSearchValue] = useState('');
    const [dropdownId] = useState(uuid());

    const filteredItems = (searchValue && !searchApi)
        ? items.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())) : items;

    const handleSelectItem = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        handleSelect(id);
    };
    const handleSearch = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        setOffset(0);
        setSearchValue(searchValue);
        searchApi && searchApi(searchValue);
    };
    const truncateCharLimit = itemsPerPage === 20 ? 30 : 20;
    return (
        <Dropdown isOpen={dropdown}
            toggle={toggleDropdown}
        >
            <DropdownToggle tag="div"
                data-testid="dropdown-toggle"
                className={`dropdown-filter d-flex justify-content-between align-items-center cursor-pointer px-2 py-1 
                    ${dropdown && 'border border-primary'} ${error && 'border border-danger'} ${className}`}
            >
                <span>{name}</span>
                <ChevronDown size={20}
                    className="ml-11"
                    color={color.blue[500]}
                    style={{ transition: 'all .25s ease-in-out', transform: dropdown ? 'rotate(-180deg)' : 'none' }}
                />
            </DropdownToggle>
            <DropdownMenu className={`dropdown-filter-menu-checkboxes ${dropdown && 'border border-primary'}`}>
                {(items.length > 0 && !searchApi) && (
                    <div className="p-2 border-bottom">
                        <Input value={searchValue}
                            onChange={e => { setSearchValue(e.target.value); setOffset(0); }}
                            placeholder={placeholder || 'Search'}
                            data-testid="dropdown-search-input"
                        />
                    </div>
                )}
                {searchApi
                && (
                    <div className="p-2 border-bottom">
                        <form onSubmit={(e) => handleSearch(e)} className="d-block" data-testid="search-input-component">
                            <InputGroup border={searchValue ? 'active' : 'none'} className="mb-0">
                                <Input value={searchValue}
                                    onChange={e => { setSearchValue(e.target.value); }}
                                    placeholder="Search"
                                />
                                {searchValue && (
                                    <InputGroupAddon addonType="prepend" className="border-right small-border">
                                        <InputGroupText role="button" onClick={() => { setSearchValue(''); searchApi(''); }}>
                                            <FeatherIcon.X color={color.blue[500]} size={16} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                )}
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText role="button" onClick={(e) => handleSearch(e)}>
                                        <FeatherIcon.Search size={16} color={color.blue[500]} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </form>
                    </div>
                )}
                <div className="dropdown-filter-menu-items border-bottom">
                    {children}
                    {(filteredItems.length > 0 && !loading) && (
                        <>
                            {description && <div>{description}</div>}
                            <div className={itemsPerPage === 20 ? 'grid-container-2' : 'grid-container-3'}>
                                {filteredItems.slice(offset, offset + itemsPerPage).map((item) => (
                                    <FormGroup key={item.id}>
                                        <CustomInput tag="checkbox"
                                            type="checkbox"
                                            checked={item.selected}
                                            className="custom-control-input-checkbox py-1"
                                            id={`${dropdownId}_${item.id}`}
                                            data-testid={`checkbox-${item.id}`}
                                            value={item.name}
                                            onChange={(e) => { handleSelectItem(item.id, e); }}
                                            label={item.name.length > truncateCharLimit
                                                ? (
                                                    <>
                                                        <TooltipWrapper tooltipText={item.name} id={`tooltip_${dropdownId}_${item.id}`}>
                                                            <p className="space-no-wrap">{truncateString(item.name, truncateCharLimit)}</p>
                                                        </TooltipWrapper>
                                                        <p className="ml-1">({largeNumber(item.count)})</p>
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        <p className="space-no-wrap">{truncateString(item.name, truncateCharLimit)}</p>
                                                        <p className="ml-1">({largeNumber(item.count)})</p>
                                                    </>
                                                // eslint-disable-next-line react/jsx-indent
                                                )}
                                        />
                                    </FormGroup>
                                ))}
                            </div>
                        </>
                    )}
                    {loading && <Loading relative height={168} width={255} />}
                    {(!filteredItems.length && !loading) && <p className="no-white-space">No results found</p>}
                </div>
                {filteredItems.length > itemsPerPage && !loading && (
                    <Pagination total={filteredItems.length}
                        itemsPerPage={itemsPerPage}
                        offset={offset}
                        setOffset={setOffset}
                        className="p-2 mb-0"
                    />
                )}
            </DropdownMenu>
        </Dropdown>
    ); };
