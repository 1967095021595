import { useMutation } from '@apollo/client';
import { GET_SITUATION_ROOM_DRAFTS, UPDATE_SITUATION_ROOM } from '../../graphql';
import { getRoom } from '../../utils/variables';

export const useUpdateSituationRoom = (id) => {
    const [updateSituationRoom, { loading }] = useMutation(UPDATE_SITUATION_ROOM, {
        refetchQueries: [{ query: GET_SITUATION_ROOM_DRAFTS, variables: { instance: id } }],
        update: (cache) => {
            cache.evict({ fieldName: 'situationRooms' });
            cache.evict({ fieldName: 'situationRoom' });
        },
        onCompleted: () => {
            getRoom({});
        }
    });
    return {
        loading,
        updateSituationRoom
    };
};
