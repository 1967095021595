import React, { useEffect, useState } from 'react';
import { Input, Pagination as ReactPagination, PaginationItem, PaginationLink } from 'reactstrap';

type PaginationProps = {
    total: number;
    itemsPerPage: number;
    setItemsPerPage?: (e: number) => void;
    offset: number;
    setOffset: (e: number) => void;
    className?: string;
}

export const Pagination = ({
    total,
    itemsPerPage,
    setItemsPerPage,
    offset,
    setOffset,
    className = ''
}: PaginationProps) => {
    const [page, setPage] = useState(offset / itemsPerPage);
    useEffect(() => {
        setPage(Math.floor(offset / itemsPerPage));
    }, [offset, itemsPerPage]);
    // TODO: update pagination logic. 1,2,3,4,5,...10 1,...,4,5,6,7,8,...10 1,...,6,7,8,9,10
    let pageMin = 0;
    const pageMax = Math.ceil(total / itemsPerPage) - 1;
    if (page > 2) { pageMin = page - 2; }
    const pagination = [];
    if (pageMax < 5) { pageMin = 0; }
    const maxPages = 5;
    for (let i = pageMin; i <= pageMax; i++) {
        if (pagination.length < maxPages) {
            pagination.push(i + 1);
        }
    }

    if (total === 0 || total <= itemsPerPage) {
        return null;
    }

    const handleGoToPage = (goToPage: number) => {
        setPage(goToPage);
        setOffset(goToPage * itemsPerPage);
    };

    return (
        <div data-testid="pagination"
            className={`d-flex align-items-center bg-white justify-content-end ${className?.includes('mb-') ? '' : 'mb-2'} ${className}`}
        >
            {total >= itemsPerPage
            && (
                <ReactPagination listClassName="m-0">
                    <PaginationItem disabled={page === 0} className="mr-1">
                        <PaginationLink first onClick={() => handleGoToPage(0)} className="pb-11 m-0 line-height-1" />
                    </PaginationItem>
                    <PaginationItem disabled={page === 0} className="mr-1">
                        <PaginationLink previous onClick={() => handleGoToPage(page - 1)} className="pb-11 m-0 line-height-1" />
                    </PaginationItem>
                    {pagination.map((item) => (
                        <PaginationItem key={item} active={page + 1 === item} className="mr-1">
                            <PaginationLink onClick={() => {
                                if (page + 1 !== item) {
                                    handleGoToPage(item - 1);
                                }
                            }}
                            >
                                {item}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem disabled={page === Math.ceil(total / itemsPerPage) - 1} className="mr-1">
                        <PaginationLink next onClick={() => handleGoToPage(page + 1)} className="pb-11 m-0 line-height-1" />
                    </PaginationItem>
                    <PaginationItem disabled={page === Math.ceil(total / itemsPerPage) - 1}>
                        <PaginationLink last
                            className="pb-11 m-0 line-height-1"
                            onClick={() => handleGoToPage(Math.ceil(total / itemsPerPage) - 1)}
                        />
                    </PaginationItem>
                </ReactPagination>
            )}
            {setItemsPerPage
            && (
                <Input type="select"
                    className="w-fit-content ml-1"
                    value={itemsPerPage}
                    onChange={(e) => {
                        setItemsPerPage(parseInt(e.target.value, 10));
                        handleGoToPage(0);
                    }}
                >
                    {[10, 25, 50].map((item) => <option key={item} value={item}>{item}</option>)}
                </Input>
            )}
        </div>
    );
};
