import React, { useEffect, useState } from 'react';
import { useAddToWatchlist } from '../../../../services/Threat/addToWatchlist';
import { useRemoveFromWatchlist } from '../../../../services/Threat/removeFromWatchlist';
import { WatchlistButton } from './WatchlistButton';
import { getRoom } from '../../../../utils/variables';
import { Content } from '../../../../services/Content/contentInterface';

export type WatchlistButtonContentKeys = 'id' | 'text' | 'from' | 'contentType' | 'engagement' | 'displayName' | 'locationsDetected' | 'url'
| 'whyathreat' | 'sentiment' | 'lang' | 'detectedLangs' | 'langVerbose' | 'pubdatetime' | 'isToxic' | 'threatObject' | 'extractedUrls'
 | 'domain';

type WatchlistButtonContainerProps = {
    contents: Pick<Content, WatchlistButtonContentKeys>[],
    isWatching?: boolean,
    onCompleted?: () => void,
    onError?: () => void,
    type: string,
    className?: string,
    disabled?: boolean
    showText?: boolean
}

export const WatchlistButtonContainer = ({
    contents,
    isWatching: isWatchingProp,
    onCompleted,
    onError,
    type,
    className = '',
    disabled = false,
    showText = true
}: WatchlistButtonContainerProps) => {
    const room = getRoom();
    const [isWatching, setIsWatching] = useState(isWatchingProp);
    useEffect(() => {
        setIsWatching(isWatchingProp); // to keep state up to date with latest watchlist data
    }, [isWatchingProp]);
    const { addToWatchlist, loading: addToWatchlistLoading } = useAddToWatchlist({
        situationRoomId: room.id,
        onCompleted,
        onError
    });
    const { removeFromWatchlist, loading: removeFromWatchlistLoading } = useRemoveFromWatchlist({
        situationRoomId: room.id,
        onCompleted,
        onError
    });
    const handleAddToWatchlist = () => {
        addToWatchlist(contents);
        setIsWatching(true);
    };
    const handleRemoveFromWatchlist = () => {
        removeFromWatchlist({ contentId: contents.map(({ id }) => id) });
        setIsWatching(false);
    };

    const loading = removeFromWatchlistLoading || addToWatchlistLoading;
    const watchlistEnabled = room.instance?.plan?.integrations?.watchlist;

    if (!watchlistEnabled) return null;

    return (
        <WatchlistButton isWatching={!!isWatching}
            isWatchingSpinner={loading}
            addToWatchlist={handleAddToWatchlist}
            removeFromWatchlist={handleRemoveFromWatchlist}
            type={type}
            className={className as string}
            disabled={!!disabled}
            showText={!!showText}
        />
    );
};
