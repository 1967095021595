import React, { useState } from 'react';
import * as FeatherIcon from 'react-feather';
import {
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    Tooltip
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { color } from '../../utils/getColors';
import { handleReturnReduxFuntion } from '../NewFilters/components/SelectedFilters/SelectedFilter';

type SearchInputProps ={
    placeholder: string,
    searchVal: string,
    setVal: (e: any) => void,
    handleSearch: (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => void,
    resetFn?: () => void,
    showBooleanSearch?: boolean,
    clusters?: boolean
    className?: string
    autoFocus?: boolean
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const SearchInput = ({
    placeholder,
    searchVal,
    setVal,
    handleSearch,
    resetFn,
    showBooleanSearch = false,
    clusters = false,
    className = '',
    autoFocus,
    onKeyDown
}: SearchInputProps) => {
    const dispatch = useDispatch();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [openOnce, setOpenOnce] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const onResetQuery = () => {
        setVal('');
        dispatch(handleReturnReduxFuntion(clusters)({ query: '' }));
        if (resetFn) {
            resetFn();
        }
    };

    const handleChange = (e: any) => {
        e.preventDefault();
        setVal(e.target.value);
        const keywordRegex = /\s(AND|OR|NOT|NEAR\/\d+)\s|(?<=[\s"^]|^)(from:|domain:|url:|links:|country:|title:|language:|mentions:)\s*|(?<=\s)(from:|domain:|url:|links:|country:|title:|language:|mentions:)(?=\s|$)/i;
        const insideQuotesRegex = /".*?"/;
        if (keywordRegex.test(e.target.value) && !insideQuotesRegex.test(e.target.value)) {
            setTooltipOpen(true);
        } else {
            setTooltipOpen(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSearch} className={className || 'mr-2 d-block'} data-testid="search-input-component">
                <InputGroup border={searchVal ? 'active' : 'none'}>
                    <Input value={searchVal}
                        onChange={handleChange}
                        placeholder={placeholder || 'Search - Enter a search term to update the room overview and press ENTER'}
                        data-testid="search-input"
                        id="search-input"
                        autoFocus={autoFocus}
                        onKeyDown={onKeyDown}
                    />
                    {searchVal && (
                        <InputGroupAddon addonType="prepend" className="border-right small-border">
                            <InputGroupText role="button" onClick={onResetQuery} data-testid="x-icon">
                                <FeatherIcon.X color={color.blue[500]} size={16} />
                            </InputGroupText>
                        </InputGroupAddon>
                    )}
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText role="button" onClick={handleSearch as React.MouseEventHandler} data-testid="search-icon">
                            <FeatherIcon.Search size={16} color={color.blue[500]} />
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </form>
            {(tooltipOpen && !openOnce && showBooleanSearch) && (
                <Tooltip placement="bottom"
                    isOpen={tooltipOpen}
                    target="search-input"
                    toggle={toggleTooltip}
                    trigger=""
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0">Are you trying to write a Boolean? This search only supports keywords,
                            use the Boolean search to filter by Boolean query.
                        </p>
                        <FeatherIcon.X onClick={() => { setTooltipOpen(false); setOpenOnce(true); }} role="button" size={40} />
                    </div>
                </Tooltip>
            )}
        </>

    );
};
