import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import { Cpu } from 'react-feather';
import { CoordinationDataType } from '../../CoordinatedModals/coordinationDataTypes';
import { WidgetName, WidgetTextTooltip } from '../Summary/WidgetTextTooltip';
import { SourceIconLabel } from '../../../../../components/SourceIconLabel';
import { setParam } from '../../../../../utils/urlParams';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { ActorsModal } from './ActorsModal';

type TopActorsProps ={
    coordinations: CoordinationDataType[]
}

export const TopActors = ({ coordinations }: TopActorsProps) => {
    const { setModal, closeModal } = useModal();
    const sortedActors = useMemo(() => {
        const occurrences: { [key: string]: any } = {};
        coordinations?.map((x) => x.network).flat().forEach(item => {
            if (item.from) {
                if (!occurrences[item.from]) {
                    occurrences[item.from] = { count: 0, ctype: item.ctype };
                }
                occurrences[item.from].count += 1;
            }
        });

        // Sort occurrences by count (descending)
        return Object.entries(occurrences)
            .sort((a, b) => b[1].count - a[1].count)
            .map(([key, value]) => ({ from: key, count: value.count, ctype: value.ctype }));
    }, [coordinations]);
    const actorsData = sortedActors.map((actor) => {
        const allData = coordinations?.filter(a => a.network.find(b => b.from === actor.from));
        const pubtime = allData?.map(a => a.network.map(b => b.pubdatetime)).flat();
        const ttps = allData?.map(a => a.id);
        return {
            ...actor,
            ttps,
            pubtime,
            allData
        };
    });
    const handleViewAll = () => {
        setParam('subTab', 'actors');
        setModal({
            header:
    <div className="d-flex align-items-center">
        <WidgetTextTooltip widgetName={WidgetName.CoordinationDetected} /><Cpu className="ml-1 mb-1" />
    </div>,
            component: <ActorsModal actors={actorsData} />,
            size: 'xl',
            onClose: closeModal
        });
    };
    return (
        <div className="bg-white p-2 mt-2">
            <div className="d-flex justify-content-between">
                <h3 className="m-0"><WidgetTextTooltip widgetName={WidgetName.Top3Actors} /></h3>
                <Button color="link" className="p-0 text-right" onClick={handleViewAll}>View all</Button>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2">
                {
                    actorsData.slice(0, 3).map((actor, idx) => (
                        <div className={`p-1 rounder-4 bg-lightgray w-100 d-flex 
                                justify-content-between align-items-center ${idx < 2 ? 'mr-2' : ''}`}
                        >
                            <span>
                                <SourceIconLabel data={{ contentType: actor.ctype }} className="pl-1 d-inline" />
                                <p className="m-0 d-inline">{actor.from}</p>
                            </span>
                            <p className="bg-secondary-alt py-11 px-1 rounder-4 text-white m-0">{actor.count}</p>
                        </div>
                    ))
                }

            </div>
        </div>
    );
};
