import React from 'react';
import { Button } from 'reactstrap';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { checkGrammer } from '../../../../utils/text/checkGrammer';
import { CoordinationDataType } from '../CoordinatedModals/coordinationDataTypes';

type NetworksButtonProps = {
    widgetName: 'CoordinatedPosts' | 'CoordinatedReposts' | 'CoordinatedHashtags' |
     'CoordinatedHashtagSequences' | 'SimilarAccountHandles' | 'SimilarPostingPatterns'
    handleSetParam: (widgetName: string, network: CoordinationDataType[]) => void
    network: CoordinationDataType[]
    type: string
}

export const NetworksButton = ({ widgetName, handleSetParam, network, type }: NetworksButtonProps) => (
    <>
        <WidgetTextTooltip widgetName={WidgetName[widgetName]} />
        {network.length > 0
            ? (
                <Button
                    color="link"
                    className="text-left p-0 font-weight-bold"
                    onClick={() => (network || []).length > 0 && handleSetParam(type, network)}
                >{network?.length} {checkGrammer('network', (network || []).length > 1, (network || []).length)}
                </Button>
            ) : (
                <p className="m-0 text-primary-dark font-weight-bold">
                    {network?.length} {checkGrammer('network', (network || []).length > 1, (network || []).length)}
                </p>
            )}
    </>
);
