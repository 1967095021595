import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { TranslateButton } from '../../../../components/Atoms/TranslateButton';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { useTranslateMultipleText } from '../../../../services/google/translate';
import { getHighlightedContent } from '../../../../utils/getHighlightedContent';
import { getPostedByName } from '../../../../utils/getPostedByName';
import { useQueryExtracts } from '../../../../services/Content/getQueryExtracts';
import { getRoom } from '../../../../utils/variables';
import { convertToDate, formatDateLocal } from '../../../../utils/getTimeFrom';
import { TranslatedFromLanguage } from '../../../../utils/TranslatedFromLanguage';
import { ExternalLinkButton } from '../../../../components/LinkModal/ExternalLinkButton';
import { Content } from '../../../../services/Content/contentInterface';
import { TextTooltip } from '../../../../components/ToolTip/TextTooltip';
import { getCtype } from '../../../../utils/text';
import { highlightedKeywordsTooltipText, highlightedKeywordsTooltipTextSearch } from '../../../../utils/text/getFoundText';
import { RootState } from '../../../../store';

type ContentTextProps = {
    data: Content;
    translateEntities: () => void;
};

export const ContentText = ({ data, translateEntities }: ContentTextProps) => {
    const room = getRoom();
    const [translationClicked, setTranslationClicked] = useState(false);
    const isKeywordSearched = useSelector((state: RootState) => state.filters.filters.query);
    const dateInLocalTimeZone = room.instance?.plan?.others?.dateInLocalTimeZone;
    const { highlightedText, highlightedURL } = useQueryExtracts({
        text: data?.text,
        url: data.url
    });
    const { highlightedText: highlightedTitle } = useQueryExtracts({
        text: data?.title
    });
    const {
        getTranslation: translateText,
        data: text,
        loading: translateLoading
    } = useTranslateMultipleText({ extracts: [highlightedText, highlightedTitle] });
    const translatedBody = text.length ? text[0].translations[0] : highlightedText;
    const translatedTitle = text.length ? text[1].translations[0] : highlightedTitle;
    const ctype = getCtype(data?.contentType);
    const utcDate = moment.utc(convertToDate(data.pubdatetime)).format('DD/MM/YYYY @ HH:mm:ss z');
    const longText = (highlightedText + data.text).length > 2000 ? 'set-max-height scrollbar-small' : '';
    return (
        <div className="bg-white p-2 rounded-sm">
            <div className="d-flex align-items-center justify-content-between flex-wrap mb-1">
                <h3 className="m-0">Content</h3>
                <div className="d-flex">
                    <TextTooltip placement="top"
                        title="Translate"
                        id={`content-${data.id}-translate`}
                        withIcon
                    >
                        <TranslateButton loading={translateLoading}
                            onClick={() => {
                                translateText();
                                if (translateEntities) translateEntities();
                                setTranslationClicked(!translationClicked);
                            }}
                            lang={data.langVerbose}
                            detectedLangs={data.detectedLangs}
                            showText={false}
                        />
                    </TextTooltip>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <p>
                    <SourceIconLabel data={{ contentType: data.contentType }} className="d-inline" />
                    {getPostedByName(data.from, data.displayName)} via <span className="ctype">{ctype?.name}</span>
                    {dateInLocalTimeZone && ` | ${utcDate} | ${formatDateLocal(data.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z')}`}
                </p>
            </div>
            {data.url && (
                <div className="single-line-ellipsis highlight-em maxw-none mb-1">
                    <ExternalLinkButton url={data.url}>
                        {getHighlightedContent(highlightedURL, isKeywordSearched
                            ? highlightedKeywordsTooltipTextSearch : highlightedKeywordsTooltipText)}
                    </ExternalLinkButton>
                </div>
            )}
            <div className={`my-0 dont-break-out ${longText}`}>
                {(data.title && data.title !== data.text && data.text) && (
                    <p className="highlight-em">
                        {translationClicked && !translateLoading && (
                            <TranslatedFromLanguage languageVerbose={data?.langVerbose}
                                detectedLangs={data?.detectedLangs}
                            />
                        )}
                        <span className={data.text === 'undefined contents' ? '' : 'font-weight-bold'}>
                            {getHighlightedContent(translatedTitle, isKeywordSearched
                                ? highlightedKeywordsTooltipTextSearch : highlightedKeywordsTooltipText)}
                        </span>
                    </p>
                )}
                {data?.text !== 'undefined contents' && (
                    <p className="highlight-em d-inline">
                        {translationClicked && !translateLoading && (
                            <TranslatedFromLanguage languageVerbose={data?.langVerbose} detectedLangs={data?.detectedLangs} />
                        )}
                        {getHighlightedContent(translatedBody, isKeywordSearched
                            ? highlightedKeywordsTooltipTextSearch : highlightedKeywordsTooltipText)}
                    </p>
                )}
            </div>
        </div>
    );
};
