import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { getUser } from '../../../../utils/variables';
import { NarrativeThemesRemovedContent } from './NarrativeThemeRemovedContent';
import { NarrativesRemovedCotent } from './NarrativeRemovedContent';
import { ThreatsAndMatchesRemovedContent } from './ThreatsAndMatchesRemovedContent';

export const Feedback = () => {
    const user = getUser();
    const [selectedTab, setSelectedTab] = useState(0);
    return (
        <div className="bg-white px-3 pt-3 pb-0">
            <h3 className="m-0">Removed content</h3>
            <hr className="my-3" />
            <p className="mb-3">
                You can find all removed content (matches, threats, narratives) here.
                {user?.role !== 'ADMIN' && ' You can only delete the "removed content" in your name.'}
            </p>
            <Row className="align-items-center">
                <Col xs={12}>
                    <div className="multi-tabs mb-4">
                        <div className={`mr-4 cursor-pointer ${selectedTab === 0 ? 'selected-tab ' : ''}`}
                            onClick={() => setSelectedTab(0)}
                        >
                            <h4>Threats</h4>
                        </div>
                        <div className={`mr-4 cursor-pointer ${selectedTab === 1 ? 'selected-tab ' : ''}`}
                            onClick={() => setSelectedTab(1)}
                        >
                            <h4>Narrative themes</h4>
                        </div>
                        <div className={`mr-4 cursor-pointer ${selectedTab === 2 ? 'selected-tab ' : ''}`}
                            onClick={() => setSelectedTab(2)}
                        >
                            <h4>Narratives</h4>
                        </div>
                    </div>
                    {selectedTab === 0 && <ThreatsAndMatchesRemovedContent />}
                    {selectedTab === 1 && <NarrativeThemesRemovedContent />}
                    {selectedTab === 2 && <NarrativesRemovedCotent />}
                </Col>
            </Row>
        </div>
    );
};
