import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownWithMultiCheckbox } from '../components/DropdownWIthMultiCheckbox';
import { RootState } from '../../../store';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { useSentiment } from '../../../services/Overview';
import { sentiments } from '../../../utils/lib/search';

type SentimentFilterProps = {
    clusters?: boolean,
    appliedFilters: any
}

type Sentiment = {
    key: string,
    count?: number,
    label?: string,
    sentiment?: string
}

export const SentimentFilter = ({ clusters, appliedFilters }: SentimentFilterProps) => {
    const dispatch = useDispatch();
    const { sentiment: selectedSentiment }: { sentiment: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const { data, loading } = useSentiment({ clusters, appliedFilters });
    const finalData = data?.length > 0 ? data : [];
    const sentimentData = (clusters ? sentiments : finalData).map((sentiment: Sentiment) => ({
        id: sentiment.key,
        name: sentiment.label || sentiment.sentiment,
        selected: selectedSentiment.includes(sentiment.key),
        count: sentiment.count || 0
    }));
    const handleSelectItem = (ids: string[]) => {
        dispatch(saveSelectedFilters({
            sentiment: ids
        }));
    };
    const countFilters = selectedSentiment.length;

    return (
        <div>
            <DropdownWithMultiCheckbox value="sentiment"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                filterOptions={sentimentData}
                handleSelectItem={handleSelectItem}
                singleDropdown
                loading={loading}
                cluster={clusters}
            />
        </div>
    ); };
