/* eslint-disable no-nested-ternary */
import React from 'react';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

type BreadCrumbsProps = {
    items?: { title: string; href?: string, onClick?: () => void }[];
}

export const BreadCrumbs = ({ items }: BreadCrumbsProps) => (
    <Breadcrumb data-testid="breadcrumb-component">
        {items?.filter(({ title }) => title).map((item, index) => (
            <BreadcrumbItem key={item.title} active={index !== items.length - 1}>
                {item.onClick ? <Button onClick={item.onClick} color="link" className="p-0 mw-0">{item.title}</Button> : (
                    index === items.length - 1 || !item.href ? item.title.includes('-') ? item.title.replace('-', ' ') : item.title : (
                        <Link className="text-primary" to={item.href} data-testid={`breadcrumb-link-${item.title}`}>
                            {item.title}
                        </Link>
                    )
                )}
            </BreadcrumbItem>
        ))}
    </Breadcrumb>
);
