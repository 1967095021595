import React from 'react';
import { WentWrong } from '../../../../assets/svg/WentWrong';

export const UnexpectedError = () => (
    <div className="d-flex align-items-center justify-content-center h-100vh">
        <div className="p-4">
            <div className="mb-4 text-center">
                <WentWrong />
            </div>
            <div className="text-center">
                <h1 className="mb-2 mt-0">Something went wrong.</h1>
                <p className="mb-0">We&apos;re sorry, but an unexpected error occurred.</p>
                <p>Please try again later.</p>
            </div>
        </div>
    </div>
);
