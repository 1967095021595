import React from 'react';
import { TooltipWrapper } from '../../../ToolTip/TooltipWrapper';
import { threatSignalTooltipText } from './threatSignalTooltipText';

export enum SignalName {
    ToxicInformation = 'Toxic information',
    AttackOnCommenter = 'Attack on commenter',
    AttackOnAuthor = 'Attack on author',
    AttackOnIdentity = 'Attack on identity',
    InsultingLanguage = 'Insulting language',
    Inflammatory = 'Inflammatory language',
    ToxicLanguage = 'Toxic language',
    severlyToxic = 'Severely toxic language',
    ThreateningLanguage = 'Threatening language',
    Automated = 'Automated account',
    Domestic = 'Domestic extremism',
    SourceCredibility = 'Low source credibility',
    CustomThreat = 'Custom threat',
    EmergingBoycott = 'Emerging boycott'
}

type WidgetTextTooltipProps = {
    widgetName: SignalName | string
    noMargin?: boolean
    className?: string
    classes?: string
    tag?: boolean
}

export const ThreatSignalTooltip = ({ widgetName, noMargin = false, className = '', classes = '', tag }: WidgetTextTooltipProps) => {
    const tooltipText = threatSignalTooltipText(widgetName);
    const id = `tooltip-${widgetName}`;

    return (
        <TooltipWrapper tooltipText={tooltipText} id={id} placement="top-start" className={className}>
            {!tag ? <p className={`mb-${noMargin ? '0' : '1'} ${classes} widget-text-style`}>{widgetName}</p>
                : (
                    <div color="dark" className="tag no-hover" data-testid="threat-signal">
                        {widgetName}
                    </div>
                )}
        </TooltipWrapper>
    );
};
