import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownWithMultiCheckbox, ItemProps } from '../components/DropdownWIthMultiCheckbox';
import { RootState } from '../../../store';
import { saveSelectedFilters, setTempCoordinatedTypes } from '../../../pages/Room/store';
import { useCoordinationTypes } from '../../../services/Coordination/getCoordinationTypes';
import { getWidgetName } from '../../../utils/coordinatedTypes';
import { WidgetName } from '../../../pages/Room/AIClustering/SelectedClusterView/Summary/WidgetTextTooltip';

export const CoordinationTypeFilter = ({ clusters }: { clusters?: boolean }) => {
    const dispatch = useDispatch();
    const {
        filters: { coordinationTypes: selectedCoordinationTypes },
        tempCoordinationTypes
    } = useSelector((state: RootState) => state.selectedFilters);

    const { coordinationTypes, loading } = useCoordinationTypes();

    const filterOptions = coordinationTypes.map((coordinationType) => {
        const widgetName = getWidgetName(coordinationType.name);
        if (!widgetName) return undefined;
        return {
            id: coordinationType.name,
            name: WidgetName[widgetName],
            selected: selectedCoordinationTypes.map(({ name }) => name).includes(coordinationType.name),
            count: clusters ? (coordinationType.narrativesCount || 0) : (coordinationType.count || 0)
        };
    }).filter((option) => option !== undefined) as ItemProps[];

    const handleSelectItem = React.useCallback((ids: string[]) => {
        const newSelectedCoordinationTypes = coordinationTypes.filter(({ name }) => ids.includes(name))
            .map(c => ({
                name: c.name,
                ids: c.ids
            }));

        dispatch(saveSelectedFilters({
            coordinationTypes: newSelectedCoordinationTypes
        }));
    }, [coordinationTypes, dispatch]);

    useEffect(() => {
        /* when a saved filter is applied, since we only store the coordination type name and not the ID's,
        we need to wait until the coordinationTypes load, then update the selected filters */
        if (tempCoordinationTypes?.length && coordinationTypes.length) {
            handleSelectItem(tempCoordinationTypes);
            dispatch(setTempCoordinatedTypes([]));
        }
    }, [tempCoordinationTypes, coordinationTypes, dispatch, handleSelectItem]);

    const countFilters = selectedCoordinationTypes?.length;

    return (
        <div className="dont-truncate">
            <DropdownWithMultiCheckbox value="coordinationTypes"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                filterOptions={filterOptions}
                handleSelectItem={handleSelectItem}
                singleDropdown
                loading={loading}
                dontTruncate
                noSearch
            />
        </div>
    );
};
