import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownWithSearchAndCheckboxes } from '../components/DropdownWithSearchAndCheckboxes';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { RootState } from '../../../store';
import { useTopDomains } from '../../../services/Overview/useTopDomains';

type DomainsFilterProps = {
    clusters?: boolean,
    appliedFilters: any
}
export const DomainsFilter = ({ clusters = false, appliedFilters }: DomainsFilterProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchValue, setSearchValue] = useState('');
    const { projectId } = location.state || {};
    const isThreatsPage = location.pathname?.includes('/threats') || location.pathname?.includes('/watchlist');
    const { domains: selectedDomains }: { domains: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const { data, loading } = useTopDomains({ clusters, size: 300, projectId, searchValue, appliedFilters, isThreatsPage });
    const domainsData = data?.map((domain: {key: string, count: number}) => ({
        id: domain.key,
        name: domain.key,
        selected: selectedDomains.includes(domain.key),
        count: domain.count
    }));
    const handleSelectItem = (id: string) => {
        dispatch(saveSelectedFilters({
            domains: selectedDomains.includes(id)
                ? selectedDomains.filter(domain => domain !== id)
                : [...selectedDomains, id]
        }));
    };
    const countFilters = selectedDomains.length;
    const handleApiSearch = (search: string) => {
        setSearchValue(search);
    };
    return (
        <div>
            <DropdownWithSearchAndCheckboxes handleSelect={handleSelectItem}
                items={domainsData}
                placeholder="Search"
                description="Top 300 domains"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                loading={loading}
                itemsPerPage={30}
                searchApi={handleApiSearch}
            />
        </div>
    );
};
