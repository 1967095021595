import React, { useState } from 'react';
import { CustomInput } from 'reactstrap';
import moment from 'moment';
import { PermanentDeleteModal } from './PermanentDeleteModal';
import { truncateString } from '../../../../utils/text';
import { ContentDetailsModal } from './ContentDetailsModal';
import { useQueryExtracts } from '../../../../services/Content/getQueryExtracts';
import { useContent } from '../../../../services/Content/getContent';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';
import { useModal } from '../../../../context/modal/ModalComponent';
import { getHighlightedContent } from '../../../../utils/getHighlightedContent';

const urlRegex = /(?:https?):\/\/[\n\S]+./gim;

export const RemovedContentListItem = ({ item, checkBoxValues, setCheckBoxValues, checked, user, setPage }) => {
    const { setModal } = useModal();
    const { data, loading } = useContent({
        limit: 1,
        filters: {
            ids: item?.doc_id
        },
        skipRequest: !item?.doc_id,
        fragments: ['Document']
    });
    const [itemDetailsModal, setItemDetailsModal] = useState(false);
    const handleRemovePermanentlySuccess = () => {
        setCheckBoxValues([]);
        setPage(0);
    };
    const deletePermanently = () => setModal({
        header: 'Are you sure you want to permanently delete selected content?',
        component: <PermanentDeleteModal ids={[item?.doc_id]} onSuccess={handleRemovePermanentlySuccess} />
    });

    const contentType = (listItem) => {
        if (listItem?.type === CONTENT_TYPE.MATCH) {
            return 'Match';
        }
        if (listItem?.type === CONTENT_TYPE.THREAT) {
            return 'Threat';
        }
        if (listItem?.type === CONTENT_TYPE.BULK_REMOVE) {
            return 'Bulk remove';
        }
        return 'N/A';
    };

    const contentDoc = data[0];
    const fullText = contentDoc?.body?.length > 0 ? contentDoc?.body?.replace(urlRegex, '') : contentDoc?.text?.replace(urlRegex, '');

    const { highlightedText } = useQueryExtracts({
        url: contentDoc?.url,
        text: fullText
    });

    const getContentText = () => {
        if (item.type === CONTENT_TYPE.BULK_REMOVE) {
            return `${item.doc_ids?.length} content`;
        }
        return contentDoc ? getHighlightedContent(truncateString(highlightedText, 400))
            : truncateString(item?.additional_info?.text, checkBoxValues.length < 1 ? 75 : 100);
    };

    const contentText = getContentText();

    return (
        <>
            <tr key={item.doc_id} className="contentTable">
                <td>
                    <div className="d-flex align-items-center pl-2">
                        {
                            (user?.role === 'ADMIN' && item?.type !== CONTENT_TYPE.BULK_REMOVE) && (
                                <CustomInput type="checkbox"
                                    checked={checkBoxValues?.indexOf(item?.doc_id) !== -1}
                                    className="custom-control-input-big position-fix"
                                    id={`threatcontent-${item?.doc_id}`}
                                    onChange={() => {
                                        if (!checked) {
                                            setCheckBoxValues(
                                                checkBoxValues?.filter((a) => a !== item?.doc_id)
                                            );
                                        } else {
                                            setCheckBoxValues([...checkBoxValues, item?.doc_id]);
                                        }
                                    }}
                                />
                            )
                        }
                        {contentType(item)}
                    </div>
                </td>
                <td>
                    {loading ? 'loading...' : contentText}
                </td>
                <td>{item?.created_by}</td>
                <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
                {checkBoxValues.length < 1 && (
                    <td className="d-block set-max-content actions-tab">
                        <div className="text-primary m-0"
                            role="button"
                            onClick={() => setItemDetailsModal(true)}
                        >
                            View details
                        </div>
                        {(user?.role === 'ADMIN' || user?.displayName === item?.created_by || user?.firstName === item?.created_by)
                        && <div className="text-primary ml-2" role="button" onClick={deletePermanently}>Delete</div>}
                    </td>
                )}
            </tr>
            <ContentDetailsModal open={itemDetailsModal}
                setOpen={setItemDetailsModal}
                item={item}
                user={user}
                contentText={contentText}
                setPage={setPage}
            />
        </>
    );
};
