import React, { useState } from 'react';
import { AlertTriangle, X } from 'react-feather';
import { Alert, Button } from 'reactstrap';

type WarningAlertProps = {
    children: React.ReactElement | string
    className?: string
    onClose?: () => void
}

export const WarningAlert = ({ children, className, onClose }: WarningAlertProps) => {
    const [isOpen, setIsOpen] = useState(true);
    if (!isOpen) return null;
    return (
        <Alert color="warning" className={`d-flex align-items-center ${className || ''}`}>
            <AlertTriangle className="mr-2 text-warning flex-shrink-0" />
            {children}
            {onClose && (
                <Button color="link" className="mw-0 p-0 ml-auto" onClick={() => { setIsOpen(false); onClose?.(); }}>
                    <X />
                </Button>
            )}
        </Alert>
    );
};
