import { useMutation } from '@apollo/client';
import { ObjectID } from 'bson';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import { useModal } from '../../../context/modal/ModalComponent';
import { useUser } from '../../../context/user/UserContext';
import { DELETE_SITUATION_ROOM_DRAFT } from '../../../graphql';
import { formatRoomDataToUpdate } from '../../../services/SituationRooms/formatToUpdate';
import { useCreateSituationRoom } from '../../../services/SituationRooms/useCreateSituationRoom';
import { useCreateSituationRoomDraft } from '../../../services/SituationRooms/useCreateSituationRoomDraft';
import { useUpdateSituationRoom } from '../../../services/SituationRooms/useUpdateSituationRoom';
import { useUpdateSituationRoomDraft } from '../../../services/SituationRooms/useUpdateSituationRoomDraft';
import { StatusModalBodyNew, STATUS_TYPES } from '../../../utils/SuccessErrorModal';
import { useRoomNameAvailability } from './inputs/useRoomNameAvailability';
import { useRoomEditor } from './RoomEditorContext';
import { useQueryValidator } from './RoomPreview/useQueryValidator';
import { useSituationRoomByID } from '../../../services/SituationRooms/useSituationRoomByID';
import { BooleanVersionModal } from '../../../components/Organisms/Modals/BooleanVersionModal/BooleanVersionModal';
import { useSituationRoomDraftByID } from '../../../services/SituationRooms/useSituationRoomDraftByID';
import { getToast } from '../../../utils/getToast';
import { RoomCreatedModal } from './RoomCreatedModal';

export const RoomEditorFooter = ({ goBack, goToRoom, setIsVersions, isExistingRoomDraft, activeTab, setActiveTab }) => {
    const { setModal, closeModal } = useModal();
    const { user, instance } = useUser();
    const history = useHistory();
    const toast = getToast();
    const {
        isBooleanQuery,
        setRoom,
        room,
        setExistingRoom,
        oldRoom,
        changesMade,
        draft,
        edit,
        canCreateRoom,
        errors,
        setErrors,
        validationErrors,
        hasQueryChanged,
        hasDateChanged
    } = useRoomEditor();
    const { nameNotAvailable } = useRoomNameAvailability({ roomName: room.project_name });
    const { data: existingRoom, loading: existingRoomLoading } = useSituationRoomByID({ id: room?.situationRoomId });

    useEffect(() => {
        if (isExistingRoomDraft && existingRoom) {
            setRoom({
                ...existingRoom,
                project_name: oldRoom.project_name,
                reason: oldRoom.reason,
                booleanTitle: oldRoom.title,
                query: { included: oldRoom.query.included,
                    excluded: oldRoom.query.excluded,
                    boolean: oldRoom.query.boolean?.replace(/(?:[^\S\n]|\t)+/g, ' ').trim() }
            });
            setExistingRoom(existingRoom);
        }
    }, [existingRoom, isExistingRoomDraft, oldRoom, setExistingRoom, setRoom]);

    // save as draft
    const filteredRoom = formatRoomDataToUpdate(room);
    const { createSituationRoomDraft, loading: loadingA } = useCreateSituationRoomDraft({ filteredRoom });
    const { updateSituationRoomDraft, loading: loadingB } = useUpdateSituationRoomDraft(
        { room: { ...filteredRoom, id: room.draftId || room.id } }
    );
    const { data: draftRoom } = useSituationRoomDraftByID({ id: room?.draftId });

    // save room
    const [saveRoomLoading, setSaveLoading] = useState(false);
    const { validateQuery, data: validated } = useQueryValidator({ room, lazy: true, roomBoolean: room?.query?.boolean });
    const { createSituationRoom, loading: loadingC } = useCreateSituationRoom(room.instance.id || '');
    const { updateSituationRoom, loading: loadingD } = useUpdateSituationRoom(room.instance.id || '');
    const [deleteSituationRoomDraft] = useMutation(DELETE_SITUATION_ROOM_DRAFT);

    const loading = loadingA || loadingB || saveRoomLoading || loadingC || loadingD || existingRoomLoading;

    const setStatusModal = ({ message, title, type, action }) => {
        setModal({
            header: false,
            onClose: () => {
                if (action) { action(); }
                closeModal();
            },
            component: <StatusModalBodyNew title={title} message={message} messageType={type} action={action} />
        });
    };
    const handleSaveEditRoom = () => {
        setRoom({
            ...room,
            query: {
                ...room.query,
                boolean: room.query?.boolean?.replace(/(?:[^\S\n]|\t)+/g, ' ').trim()
            }
        });
        if (Object.values(validationErrors).find((error) => error.isError) || (!changesMade && (draft ? !canCreateRoom : true))) {
            setErrors({
                ...errors,
                validationErrors: Object.values(validationErrors).find((error) => error.isError),
                emptyBoolean: room.query_type === 'boolean' && !room.query.boolean,
                emptyKeywords: !room.query.included.length && !room.query.excluded.length,
                noChanges: !changesMade
            });
            toast.error('Resolve all errors before continuing');
        } else {
            handleSaveRoom(true);
        }
    };

    const setSaveBooleanVersionModal = ({ onConfirm, onCancel }) => {
        setModal({
            header: false,
            component: <BooleanVersionModal onConfirm={onConfirm} onCancel={onCancel} modalTitle="Save New Version" roomId={room.id} />
        });
    };

    const updateRoom = async (newRoom) => {
        try {
            await updateSituationRoom({
                variables: formatRoomDataToUpdate({ // remmove __typename and extra fields
                    ...newRoom,
                    start_date: moment(newRoom.start_date).format('YYYY-MM-DD'),
                    updatedBy: {
                        id: user.id
                    }
                })
            });
            if (isExistingRoomDraft) {
                await deleteSituationRoomDraft({
                    variables: { instance: instance.id || instance, id: oldRoom.id }
                });
            }
            setSaveLoading(false);
            setStatusModal({
                title: `“${room.project_name}” situation room was successfully updated!`,
                type: STATUS_TYPES.success,
                message: 'It might take few days for the changes appear to the whole team.',
                action: goToRoom
            });
        } catch (err) { // error when saving the room
            setSaveLoading(false);
            setStatusModal({
                title: false,
                type: STATUS_TYPES.error,
                message: 'Error occurred, Please try again later'
            });
        }
    };

    const handleSaveRoom = async (alert) => {
        setRoom({
            ...room,
            query: {
                ...room.query,
                boolean: room.query.boolean?.replace(/(?:[^\S\n]|\t)+/g, ' ').trim()
            }
        });
        if (Object.values(validationErrors).find((error) => error.isError) || (!changesMade && (draft ? !canCreateRoom : true))) {
            setErrors({
                ...errors,
                validationErrors: Object.values(validationErrors).find((error) => error.isError),
                emptyBoolean: room.query_type === 'boolean' && (/^\s*$/.test(room.query.boolean)),
                emptyKeywords: !room.query.included.length,
                noChanges: !changesMade
            });
            toast.error('Resolve all errors before continuing');
        } else {
            setSaveLoading(true);
            try {
                const { data } = await validateQuery();
                if (isBooleanQuery && !data.validateQuery.isValid) {
                    // query validation error
                    setErrors({
                        query: data.validateQuery.errors
                    });
                    setSaveLoading(false);
                } else if (edit) { // if updating existing room
                    if (isBooleanQuery && hasQueryChanged) {
                        setSaveBooleanVersionModal({ // open boolean version modal
                            onConfirm: ({ title, notes }) => {
                                updateRoom({ // save room in callback
                                    ...room,
                                    narrative_boolean: alert,
                                    project_id: new ObjectID().toString(),
                                    booleanTitle: title,
                                    query: { included: room.query.included,
                                        excluded: room.query.excluded,
                                        boolean: room.query.boolean?.replace(/(?:[^\S\n]|\t)+/g, ' ').trim() },
                                    notes,
                                    draftId: isExistingRoomDraft ? null : room.id
                                });
                                closeModal();
                            },
                            onCancel: () => { setSaveLoading(false); closeModal(); }
                        });
                    } else if (!isBooleanQuery && hasQueryChanged) {
                        updateRoom({
                            ...room,
                            narrative_boolean: alert,
                            draftId: isExistingRoomDraft ? null : room.id
                        });
                    } else if (hasDateChanged) {
                        updateRoom({
                            ...room,
                            narrative_boolean: alert,
                            project_id: new ObjectID().toString()
                        });
                    } else {
                        updateRoom({
                            ...room,
                            draftId: isExistingRoomDraft ? null : room.id
                        });
                    }
                } else { // if creating a new room
                    try {
                        const { data: createRoomResponseData } = await createSituationRoom({
                            variables: formatRoomDataToUpdate({
                                ...room,
                                narrative_boolean: false,
                                query: {
                                    included: validated?.newQuery.included || room.query.included,
                                    excluded: validated?.newQuery.excluded || room.query.excluded,
                                    boolean: validated?.newQuery?.boolean?.replace(/(?:[^\S\n]|\t)+/g, ' ').trim() || room.query.boolean?.replace(/(?:[^\S\n]|\t)+/g, ' ').trim()
                                },
                                start_date: moment(room.start_date).format('YYYY-MM-DD')
                            })
                        });
                        const createSituationRoomData = createRoomResponseData?.createSituationRoom;

                        try {
                            if (draft) { // if draft exists then delete it
                                await deleteSituationRoomDraft({
                                    variables: { instance: instance.id || instance, id: oldRoom.id }
                                });
                            }
                            setSaveLoading(false);
                            const handleGoToRoom = () => history.push(`/situation-rooms/${createSituationRoomData.id}`);
                            setModal({
                                header: false,
                                component: <RoomCreatedModal goToRoom={handleGoToRoom} />
                            });
                        } catch (error) {
                            setSaveLoading(false);
                            goBack();
                        }
                    } catch (error) {
                        setSaveLoading(false);
                        setStatusModal({
                            title: false,
                            type: STATUS_TYPES.error,
                            message: 'Error occurred, Please try again later'
                        });
                    }
                }
            } catch (error) {
                setErrors({
                    query: [] // [{ errorCode: 500, message: 'Oops, looks like something went wrong' }]
                });
                setSaveLoading(false);
            }
            setSaveLoading(false);
        }
    };

    const saveAsDraft = async () => {
        if (!canCreateRoom || !changesMade) {
            setErrors({
                ...errors,
                validationErrors: Object.values(validationErrors).find((error) => error.isError),
                emptyBoolean: room.query_type === 'boolean' && (/^\s*$/.test(room.query.boolean)),
                emptyKeywords: !room.query.included.length && !room.query.excluded.length,
                noChanges: !changesMade
            });
        } else if ((draft || room.draftId) && draftRoom) {
            await updateSituationRoomDraft();
            setStatusModal({
                title: false,
                type: STATUS_TYPES.success,
                message: `“${room.project_name}” situation room draft was successfully updated!`,
                action: goBack
            });
        } else {
            await createSituationRoomDraft();
            setStatusModal({
                title: false,
                type: STATUS_TYPES.success,
                message: `“${room.project_name}” situation room draft was successfully created!`,
                action: goBack
            });
        }
    };
    const isRoomCreation = activeTab === 'room-creation';

    const handleCreateRoomNext = () => {
        if (nameNotAvailable || !room?.project_name || !room?.reason || !room?.start_date) {
            setErrors({
                ...errors,
                nameNotAvailable,
                roomName: !room?.project_name,
                reason: !room?.reason,
                startDate: !room?.start_date
            });
            toast.error('Resolve all errors before continuing');
        } else {
            setActiveTab('room-creation');
            setErrors({
                ...errors,
                emptyBoolean: false,
                emptyKeywords: false,
                noChanges: false,
                startDate: false
            });
        }
    };

    return (
        <div className="py-2" data-testid="room-editor-footer">
            {loading && <Loading />}
            <Button className="opacity-0">spacer</Button>
            <div className="position-fixed w-100 z-index px-4 py-2 fixed-bottom bg-light d-flex justify-content-end">
                {(oldRoom && (!draft || (draft && edit))) ? (
                    <div>
                        {
                            isRoomCreation
                            && (
                                <>
                                    <Button className="mr-2"
                                        onClick={() => setActiveTab('room-details')}
                                        data-testid="previous-button"
                                    >
                                        Previous
                                    </Button>
                                    {(isBooleanQuery && !draft) && (
                                        <Button className="mr-2"
                                            color="primary"
                                            onClick={() => setIsVersions(true)}
                                        >
                                            Version history
                                        </Button>
                                    )}
                                    <Button className="mr-2"
                                        color="primary"
                                        onClick={saveAsDraft}
                                        data-testid="save-draft-button"
                                        disabled={saveRoomLoading || loading}
                                    >
                                        Save as Draft
                                    </Button>
                                    <Button color="primary"
                                        onClick={handleSaveEditRoom}
                                        data-testid="save-room-button"
                                        disabled={saveRoomLoading || loading}
                                    >
                                        Save
                                    </Button>
                                </>
                            )
                        }
                        {!isRoomCreation && (
                            <>
                                {(isBooleanQuery && !draft) && (
                                    <Button className="mr-2"
                                        color="primary"
                                        onClick={() => setIsVersions(true)}
                                    >
                                        Version history
                                    </Button>
                                )}
                                <Button color="primary"
                                    onClick={handleCreateRoomNext}
                                    data-testid="next-button"
                                >
                                    Next
                                </Button>
                            </>
                        )}

                    </div>
                ) : (
                    <div className="d-flex justify-content-end">
                        {
                            isRoomCreation && (
                                <>
                                    <Button className="mr-2"
                                        onClick={() => setActiveTab('room-details')}
                                        data-testid="previous-button"
                                    >
                                        Previous
                                    </Button>
                                    <Button className="mr-2"
                                        color="primary"
                                        onClick={saveAsDraft}
                                        data-testid="save-draft-button"
                                        disabled={saveRoomLoading || loading}
                                    >
                                        Save as Draft
                                    </Button>
                                    <Button color="primary"
                                        onClick={handleSaveRoom}
                                        data-testid="create-room-button"
                                        disabled={saveRoomLoading || loading}
                                    >
                                        Create Situation Room
                                    </Button>
                                </>

                            )
                        }
                        {!isRoomCreation && (
                            <Button color="primary"
                                onClick={handleCreateRoomNext}
                                data-testid="next-button"
                            >
                                Next
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
