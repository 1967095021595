import { WARNING_MESSAGES } from '../BooleanValidationErrors';
import { convertToMarkers } from '../convertToMarkers';

const QUERY_LENGTH_WARNING = 400;

export const queryLengthValidator = (query: string, queryByLines: string[], lineLengths: number[]) => {
    if (query.length > QUERY_LENGTH_WARNING) {
        return {
            isValid: false,
            markers: convertToMarkers(
                [
                    [QUERY_LENGTH_WARNING, query.length]
                ],
                queryByLines,
                lineLengths,
                WARNING_MESSAGES.queryLengthValidator,
                2
            ),
        };
    }

    return { isValid: true, markers: [] };
};
