import React from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from '../../../../../../components/Form/Dropdown';
import { createEditorState } from '../createEditorState';
import { setReportAction } from '../../../../../../redux/reports';

export const AddPageDropdown = () => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);

    const availablePageOptions = pageOptions.filter(a => !report.pages.find(page => page.type === a.key) || a.key === 'summary');
    if (!availablePageOptions.length) { return null; }
    return (
        <div className="p-4 border-bottom">
            <Dropdown label="Add new page"
                options={availablePageOptions}
                value={{ key: '1', name: 'Select page' }}
                onChange={(item) => dispatch(setReportAction({
                    ...report,
                    pages: [
                        ...report.pages,
                        {
                            id: uuid(),
                            type: item.key,
                            pageTitle: createEditorState({
                                blocks: [
                                    {
                                        text: item.name.replace(' page', '')
                                    }
                                ],
                                entityMap: {}
                            }),
                            // ...(item.key === 'summary' ? {
                            bodyText: createEditorState({ blocks: [], entityMap: {} })
                            // } : { blocks: [] })
                        }
                    ]
                }))}
            />
        </div>
    );
};

export const pageOptions = [
    {
        key: 'overview',
        name: 'Overview page'
    },
    {
        key: 'summary',
        name: 'Summary page'
    },
    {
        key: 'threats',
        name: 'Top Threats page'
    },
    {
        key: 'customNarratives',
        name: 'Top Custom Narratives page'
    },
    {
        key: 'aiNarratives',
        name: 'Top AI Narratives page'
    },
    {
        key: 'threatsWatchlist',
        name: 'Watchlist - Threats'
    }
];
