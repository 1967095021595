import React from 'react';
import { TooltipWrapper } from '../../../../../components/ToolTip/TooltipWrapper';
import { checkGrammer } from '../../../../../utils/text/checkGrammer';

export enum WidgetName {
    Engagement = 'Engagement',
    Narratives = 'Narratives',
    Content = 'Content',
    Duplicates = 'Duplicates',
    Sentiment = 'Sentiment',
    OriginalPoster = 'Original poster',
    NarrativeTheme = 'Narrative theme',
    Top3Actors = 'Top actors',
    Actors = 'Actors (No. of content)',
    Keywords = 'Keywords (No. of content)',
    Sources = 'Sources (No. of content)',
    LocationMentions = 'Location mentions (No. of content)',
    Timeline = 'Timeline',
    ContentSentiment = 'Sentiment ',
    KeywordsMentions= 'Keywords mentions',
    Labels = 'Labels',
    Languages = 'Languages',
    UrlMentions = 'Top URL mentions',
    Coordination = 'Coordination',
    CoordinationDetected = 'Coordination detected',
    CoordinationTactics = 'Coordination tactics',
    CoordinatedPosts = 'Coordinated posts',
    PostNetwork = 'Post network',
    CoordinatedReposts = 'Coordinated reposts',
    RepostNetwork = 'Repost network',
    CoordinatedHashtags = 'Coordinated hashtags',
    HashtagNetwork = 'Hashtag network',
    CoordinatedHashtagSequences = 'Coordinated hashtag sequences',
    SimilarAccountHandles = 'Similar account handles',
    AccountNetwork = 'Account handle network',
    SimilarPostingPatterns = 'Similar posting patterns',
    SimilarPostingPattern = 'Similar posting pattern',
    CoordinationActors = 'Actors',
    Entities = 'Entities',
    Hastags = 'Hastags',
    CoordinationSentiment = 'Sentiment',
    CoordinatedActivity = 'Coordinated behaviour',
    CurrentActor = 'CurrentActor'
}

type WidgetTextTooltipProps = {
    widgetName: WidgetName | string
    clusterId?: string
    noMargin?: boolean
    className?: string
    classes?: string
    isMultiple?: boolean
}

export const WidgetTextTooltip = ({ widgetName, clusterId, noMargin = false, className = '', classes = '',
    isMultiple = false }: WidgetTextTooltipProps) => {
    let tooltipText = '';
    const id = ['Original poster', 'Narrative theme'].includes(widgetName)
        ? `tooltip-${widgetName.replace(/ /g, '_')}-${clusterId}`
        : `tooltip-${widgetName}`;
    switch (widgetName) {
        case WidgetName.Engagement:
            tooltipText = 'The level of engagement and response to a piece of content e.g. likes, shares, comments.';
            break;
        case WidgetName.Narratives:
            tooltipText = 'The number of narratives within this narrative theme.';
            break;
        case WidgetName.Content:
            tooltipText = 'The number of pieces of content in total. This number is rounded to the nearest 1000 in this summary.';
            break;
        case WidgetName.Duplicates:
            tooltipText = `The number of duplicates detected. These are nested into one duplicate so 
            each duplicate may contain multiple pieces of content.`;
            break;
        case WidgetName.Sentiment:
            tooltipText = `We determine the aggregated sentiment of a narrative by 
            assessing each piece of content within it for semantic signals to identify if it is overall positive, negative or neutral.`;
            break;
        case WidgetName.OriginalPoster:
            tooltipText = 'The Actor who posted the first piece of content in the cluster';
            break;
        case WidgetName.NarrativeTheme:
            tooltipText = 'Narratives grouped into themes based on similarities and commonalities.';
            break;
        case WidgetName.Actors:
        case WidgetName.Top3Actors:
            tooltipText = 'The top actors that contribute and a count of their contributions';
            break;
        case WidgetName.Sources:
            tooltipText = 'A breakdown of the sources for the content in a narrative eg. YouTube, Telegram';
            break;
        case WidgetName.Keywords:
            tooltipText = 'Key terms that appear and a count of their recurrences';
            break;
        case WidgetName.LocationMentions:
            tooltipText = 'The amount of times a location is mentioned in a narrative';
            break;
        case WidgetName.Timeline:
            tooltipText = `Content engagement refers to the level of engagement and response to a piece of content,
                E.g. likes, shares, comments`;
            break;
        case WidgetName.ContentSentiment:
            tooltipText = `Sentiment is how Logically Intelligence perceives this content to be towards the subject that 
            it’s discussing.`;
            break;
        case WidgetName.KeywordsMentions:
            tooltipText = 'Keywords that are mentioned within the content.';
            break;
        case WidgetName.UrlMentions:
            tooltipText = 'URLs that are mentioned within the content.';
            break;
        case WidgetName.Languages:
            tooltipText = 'Languages that are mentioned within the content.';
            break;
        case WidgetName.Labels:
            tooltipText = 'Labels allows you to manually label documents within this platform for easier filtering.';
            break;
        case WidgetName.Coordination:
            tooltipText = `Coordination is defined as a group of people or accounts working together to
             mislead others on social media or online platforms. Mimicking real user behaviour, like a grassroots movement,
              to manipulate opinions, spread misinformation, or influence outcomes like elections.`;
            break;
        case WidgetName.CoordinationDetected:
            tooltipText = `Coordination is defined as a group of people or accounts working together to
             mislead others on social media or online platforms. Mimicking real user behaviour, like a grassroots movement,
              to manipulate opinions, spread misinformation, or influence outcomes like elections.`;
            break;
        case WidgetName.CoordinationTactics:
            tooltipText = `Coordination tactics are methods used by a group to organise their actions to deceive or manipulate 
            an online audience. This involves planning efforts to make their activities appear genuine and independent to achieve a 
            specific, deceptive goal.`;
            break;
        case WidgetName.CoordinatedPosts:
            tooltipText = `Network of posts that are broadcasting the same message seemingly independent of one another from different
             accounts managed by a person or a team.`;
            break;
        case WidgetName.PostNetwork:
            tooltipText = 'A network broadcasting the same message.';
            break;
        case WidgetName.CoordinatedReposts:
            tooltipText = `Network of accounts that amplify messages by repeatedly 
            reposting the same 3rd party messages of the same accounts.`;
            break;
        case WidgetName.RepostNetwork:
            tooltipText = 'A network of actors repeatedly reposting the same message to amplify it.';
            break;
        case WidgetName.CoordinatedHashtags:
            tooltipText = 'Network of accounts that tweet the same hashtag within a time threshold.';
            break;
        case WidgetName.HashtagNetwork:
            tooltipText = 'A network of accounts that have tweeted the same hashtag within a time threshold.';
            break;
        case WidgetName.CoordinatedHashtagSequences:
            tooltipText = `Network of accounts may try to obfuscate their coordination by paraphrasing similar text in messages. 
            The paraphrased text is likely to include the same hashtags based on the targets of a coordinated campaign.`;
            break;
        case WidgetName.SimilarAccountHandles:
            tooltipText = `Accounts with suspicious coordinated creation behaviour,
             accounts that deemed to have similarities within the username.`;
            break;
        case WidgetName.SimilarPostingPattern:
        case WidgetName.SimilarPostingPatterns:
            tooltipText = `Networks of posts and reposts with detected similar posting rhythms, useful for identifying disinformation 
            campaigns or coordinated behaviour by analysing posting patterns across platforms.`;
            break;
        case WidgetName.CoordinationActors:
            tooltipText = 'Actors within this network that have contributed to the most coordinations.';
            break;
        case WidgetName.Entities:
            tooltipText = `An entity is a person (e.g. Boris Johnson), location, or organization. 
            These are also known as key players in platform. The sentiment is the feeling/attitude 
            (positive/negative/neutral/unknown) of a person (author/publisher) towards an entity.`;
            break;
        case WidgetName.Hastags:
            tooltipText = 'Hashtags used most frequently in this network.';
            break;
        case WidgetName.CoordinationSentiment:
            tooltipText = `A high-level overview of the sentiment analysis of all content within the network, 
            sentiment within the network can be positive, neutral or negative.`;
            break;
        case WidgetName.CoordinatedActivity:
            tooltipText = `Coordination is when a group of people or accounts work together to 
            mislead others on social media or online platforms. Mimicking real user behaviour, like a grassroots movement, 
            to manipulate opinions, spread misinformation, or influence outcomes like elections.`;
            break;
        case WidgetName.AccountNetwork:
            tooltipText = 'A single handle that has been used multiple times with some variation across a network.';
            break;
        case WidgetName.CurrentActor:
            tooltipText = 'Post from selected actor';
            break;
    }
    const widgetNameModified = widgetName === 'Top3Actors' ? 'Top 3 actors' : widgetName;
    return (
        <TooltipWrapper tooltipText={tooltipText} id={id} placement="top-start" className={className}>
            <p className={`mb-${noMargin ? '0' : '1'} ${classes} widget-text-style`}>{checkGrammer(widgetNameModified, isMultiple)}</p>
        </TooltipWrapper>
    );
};
