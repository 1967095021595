import React from 'react';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdatedReportPages } from './AddOverviewWidgetsModal';
import { setReportAction } from '../../../../../../../redux/reports';

export const DeleteOverviewBlockButton = ({ block, id, pageId }) => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const blocks = report.pages.filter(a => a.type === 'overview').map(a => a.blocks).flat();
    return (
        <div role="button"
            data-testid="delete-button"
            className="rounded-circle border border-white bg-danger d-flex align-items-center
        justify-content-center icon-delete-button text-white"
            onClick={() => {
                let pages = {};
                if (block === 'textEditor') {
                    pages = getUpdatedReportPages(report, blocks.filter(a => a.id !== id));
                    dispatch(setReportAction({
                        ...report,
                        pages
                    }));
                } else {
                    pages = getUpdatedReportPages(report, blocks.filter(a => a.type !== block));
                }
                dispatch(setReportAction({
                    ...report,
                    pages
                }));
            }}
        >
            <X size={15} />
        </div>
    );
};
