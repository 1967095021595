import { Button } from 'reactstrap';
import React from 'react';
import { X } from 'react-feather';
import { useModal } from '../../../../context/modal/ModalComponent';
import { getRoom } from '../../../../utils/variables';

type BatchRemoveCompletedModalProps = {
    totalRemoved: number;
    timeInSeconds: number;
};

export const BatchRemoveCompletedModal = ({ totalRemoved, timeInSeconds }: BatchRemoveCompletedModalProps) => {
    const room = getRoom();
    const { closeModal } = useModal();
    const { time, timeText } = getExecutionTime({ timeInSeconds: totalRemoved === 0 ? 0 : timeInSeconds });

    return (
        <>
            <div className="d-flex justify-content-between">
                <h2 className="mt-0">Bulk removal completed successfully</h2>
                <X color="#050E2B"
                    size={20}
                    data-testid="close-modal"
                    className="cursor-pointer mt-1"
                    stroke="#050E2B"
                    onClick={closeModal}
                />
            </div>
            <hr className="mb-2 mt-0" />
            <div>
                {totalRemoved} contents have been successfully removed from {room.project_name}{' '}
                in approximately {time} {timeText}{time > '1' ? 's' : ''}.
            </div>
            <hr className="my-3" />
            <Button color="primary" className="mr-2" onClick={closeModal}>Close</Button>
        </>
    );
};

export const getExecutionTime = ({ timeInSeconds }: { timeInSeconds: number }) => {
    const timeInMinutes = Number(timeInSeconds / 60).toFixed(0);
    const time = timeInSeconds > 60 ? timeInMinutes : Number(timeInSeconds).toFixed(0);
    const timeText = timeInSeconds > 60 ? 'minute' : 'second';

    return { time, timeText };
};
