import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getRoom } from '../../../utils/variables';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { RootState } from '../../../store';
import { DropdownWithSearchAndCheckboxes } from '../components/DropdownWithSearchAndCheckboxes';

type KeywordsData = {
    keyword: string
    count: number
    contentCount: number
}

export const KeywordsFilter = () => {
    const room = getRoom();
    const location = useLocation();
    const dispatch = useDispatch();
    const { projectId } = location.state || {};

    const { keywords: selectedKeywords }: { keywords: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);

    const { data, loading } = useQuery(TOP_KEYWORDS_IN_CLUSTERS, {
        variables: {
            projectId: room?.project_id || projectId,
            size: 30
        }
    });
    let topKeywords = [];

    topKeywords = (data?.getTopKeywordsInClusters?.data || []);

    const keywordsData = topKeywords?.map((keyword: KeywordsData) => ({
        id: keyword.keyword,
        name: keyword.keyword,
        selected: selectedKeywords.includes(keyword.keyword),
        count: keyword.contentCount || 0
    })).sort((a: {count: number}, b: {count: number}) => b.count - a.count);

    const handleSelectItem = (id: string) => {
        dispatch(saveSelectedFilters({
            keywords: selectedKeywords.includes(id)
                ? selectedKeywords.filter(keyword => keyword !== id)
                : [...selectedKeywords, id]
        }));
    };
    const countFilters = selectedKeywords.length;

    return (
        <div>
            <DropdownWithSearchAndCheckboxes handleSelect={handleSelectItem}
                items={keywordsData}
                placeholder="Search"
                description={`${keywordsData.length} terms`}
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                loading={loading}
                itemsPerPage={30}
            />
        </div>
    );
};

export const TOP_KEYWORDS_IN_CLUSTERS = gql`
    query getTopKeywordsInClusters(
        $projectId: String
        $size: Int
    ) {
        getTopKeywordsInClusters(
           projectId: $projectId
           size: $size 
        ) {
            data {
                keyword
                count
                contentCount
            }
            total
        }
    }

`;
