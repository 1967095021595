import React from 'react';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { getCtype } from '../../../../utils/text';
import { DateVisualizer } from '../../../../components/DateVisualizer';
import { getParam } from '../../../../utils/urlParams';

type ActorPostProps = {
    actor: any
}

export const ActorPost = ({ actor }: ActorPostProps) => {
    const ctype = getCtype(actor?.ctype);
    const currentActor = `@${getParam('actor_id')}`;
    return (
        <div className={`${!currentActor.includes(actor.from) ? 'bg-lightgray' : 'bg-hover'} rounder-4 p-2 mb-2`}>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <SourceIconLabel data={{ contentType: actor.ctype }} />
                    <p className="mb-0"><span>{actor.from}</span> via {ctype.name}</p>
                </div>
                <DateVisualizer startDate={actor.pubdatetime} dateType="PostTime" />
            </div>
            <a target="_blank"
                href={actor.url}
                className="text-primary"
                rel="noreferrer"
            >{actor.url}
            </a>
            <p>{actor.text}</p>
        </div>
    );
};
