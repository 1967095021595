import React, { useState } from 'react';
import { Loading } from '../../../../components/Loading';
import { getFoundText } from '../../../../utils/text/getFoundText';
import { usePreviewData } from '../../../SituationRooms/RoomEditor/RoomPreview/usePreviewData';
import { ContentTableTypes, ContentTables } from '../../../../components/ContentTable';
import { elasticFieldMapping } from './contentModal';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';

export const ContentPreviewModal = ({ filter = {}, name = '', contentType = CONTENT_TYPE.MATCH, preview, count: total = 0 }) => {
    const [offset, setOffset] = useState(0);
    const foundText = getFoundText(filter.type, name);
    const handleLimit = () => {
        if (total <= 10) {
            return total;
        } if ((offset + 10) > total) {
            return total - offset;
        }
        return 10;
    };
    const { data: contents, loading } = usePreviewData({
        filters: {
            ...(filter.type ? {
                [elasticFieldMapping[filter.type] || filter.type]: filter.value
            } : {}),
            ...(filter.filter || {})
        },
        offset,
        limit: handleLimit(),
        sort: 'dateNew',
        fetchPolicy: 'no-cache'
    });

    if (loading) return <Loading height={200} relative />;

    return (
        <div className="threats" data-testid="content-modal">
            <p className="mb-2">{total} matches {foundText}</p>
            <div className="mx-n3">
                <ContentTables contents={contents}
                    pagination={{ offset, setOffset, count: total }}
                    tableType={ContentTableTypes.PREVIEW}
                    contentType={contentType}
                    preview={preview}
                    total={total}
                    contentsToDisplay={total}
                />
            </div>
        </div>
    );
};
