import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { AddAINarrativesModal } from './AddAINarrativesModal';
import { useModal } from '../../../../../../../context/modal/ModalComponent';
import { DeleteBlockButton } from '../getUpdatedReportPages';
import { NarrativesRow } from './NarrativesRow';

export const AINarratives = ({ data }: any) => {
    const { setModal } = useModal();
    if (data.blocks.length === 0) {
        return (
            <div className="w-100 flex-1 d-flex align-items-center justify-content-center">
                <Button color="link"
                    className="text-decoration-none"
                    onClick={() => setModal({
                        header: false,
                        size: 'full',
                        component: <AddAINarrativesModal />
                    })}
                >
                    + Add AI narratives
                </Button>
            </div>
        );
    }

    return (
        <div className="font-size-10">
            {data.blocks.map((narrative: any) => {
                if (!narrative) return null;
                return (
                    <div key={narrative.id} className="bg-verylight border p-1 mb-2 position-relative">
                        <Row noGutters>
                            <div className="position-absolute report-right-edit-position">
                                <DeleteBlockButton block={narrative} type="aiNarratives" />
                            </div>
                            <Col>
                                <NarrativesRow cluster={narrative} block />
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </div>
    );
};
