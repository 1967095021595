import React from 'react';
import { ArrowDownCircle, ArrowUpCircle, ChevronRight } from 'react-feather';
import { CheckBox } from '../../../components/Form/CheckBox';
import { TooltipWrapper } from '../../../components/ToolTip/TooltipWrapper';
import { largeNumber } from '../../../utils/NumberFormat';
import { getTotalInPastXDays } from '../Overview/Visualisation/TotalMatchesAndThreatsOverTime/trend';

type TrendCardProps = {
    data: { key: number, count: number }[]
    show: boolean
    setShow: (show: boolean, label: string) => void
    totalCount: number
    id: string
    tooltip?: string | React.ReactNode | undefined;
    label: string
    interval?: string
    setDisplayResolvers: (e: boolean) => void
    color: string
}

export const TrendCard = ({ data, show, setShow, totalCount, id, tooltip, label, interval = '1d', color,
    setDisplayResolvers }: TrendCardProps) => {
    const trendInPastXDays = getTotalInPastXDays({ data, interval });
    const handleOnClick = () => {
        setDisplayResolvers(true);
    };
    return (
        <div className="mr-2 border rounded p-2 w-100 mb-2">
            <div className="d-flex align-items-top">
                <CheckBox id={id}
                    name={id}
                    value={id}
                    checked={show}
                    onChange={() => setShow(!show, label)}
                    className={`checkbox-color-${color.slice(1, color.length)}`}
                />
                <div>
                    <TooltipWrapper id={`${id}Tooltip`}
                        tooltipText={tooltip}
                        className="d-inline-block"
                    >
                        <p className="mb-1 widget-text-style">{label}</p>
                    </TooltipWrapper>
                    <div className="d-flex align-items-center">
                        <strong className="mr-1 mb-0">{largeNumber(totalCount)}</strong>
                        {trendInPastXDays.trending ? <ArrowUpCircle className="text-success" />
                            : <ArrowDownCircle className="text-danger" />}
                    </div>
                    {trendInPastXDays.message && (
                        <p className="mb-0">{trendInPastXDays.message}</p>
                    )}
                </div>
                {id === 'TOXIC' && <ChevronRight className="text-primary cursor-pointer" size={30} onClick={handleOnClick} />}
            </div>
        </div>
    );
};
