import React from 'react';
import moment from 'moment';
import { ArrowRight } from 'react-feather';
import { checkGrammer } from '../../utils/text/checkGrammer';

enum DateType {
    PostTime = 'Post time',
    RepostTime = 'Repost time',
    OrignalPostTime = 'Orignal post time',
    CreationTime = 'Creation time'
}
type DateVisualizerProps = {
    dateType: DateType | 'PostTime' | 'RepostTime' | 'OrignalPostTime' | 'CreationTime'
    startDate: Date | string | number
    endDate?: Date | string | number
    className?: string
}
export const DateVisualizer = ({ dateType, startDate, endDate, className }: DateVisualizerProps) => {
    let dateTypeText = '';
    switch (dateType) {
        case 'PostTime':
            dateTypeText = checkGrammer(DateType.PostTime, !!endDate);
            break;
        case 'CreationTime':
            dateTypeText = checkGrammer(DateType.CreationTime, !!endDate);
            break;
        case 'OrignalPostTime':
            dateTypeText = checkGrammer(DateType.OrignalPostTime, !!endDate);
            break;
        case 'RepostTime':
            dateTypeText = checkGrammer(DateType.RepostTime, !!endDate);
            break;
        default:
            dateTypeText = checkGrammer(DateType.PostTime, !!endDate);
    }
    const startingDate = moment(startDate).format('L - HH:mm:ss');
    const endingDate = moment(endDate).format('L - HH:MM:SS');
    return (
        <div className={`${className}rounded tag py-1`} color="dark">
            <small className="d-flex align-items-center gap-1"><b className="text-white">{dateTypeText}: </b>{startingDate} {endDate && (
                <>
                    <ArrowRight /> {endingDate}
                </>
            )}
            </small>
        </div>
    );
};
