import { gql, useQuery } from '@apollo/client';
import { capitalize, merge, omit } from 'lodash';
import { useContentFilters } from '../../pages/Room/useFilters';
import { entityTypes } from '../../pages/Room/Overview/Visualisation/utils';
import { deduplicate } from '../../utils/deduplicate';

export const useWordcloud = ({ dateFilter = undefined, field = 'keywords', size = 30, defaultFilters = {}, search = '',
    appliedFilters = undefined } = {}) => {
    const filters = useContentFilters({
        useGlobalFilters: true,
        defaultFilters: {
            date: dateFilter,
            ...defaultFilters
        }
    });
    const sanitizedAppliedFilters = appliedFilters?.filters
        ? omit(appliedFilters.filters, [field])
        : {};
    const ret = useQuery(WORDCLOUD, {
        variables: {
            ...filters,
            filters: omit(merge(filters.filters, sanitizedAppliedFilters), ['labels']),
            field,
            size,
            search
        }
    });

    const keywords = ret.data ? ret.data?.wordcloud?.map(item => ({
        ...item,
        key: item.keyword,
        keyword: capitalize(item.keyword)
    })) || [] : [];

    if (field === 'entities') {
        const entities = entityTypes.map(type => {
            const topHits = deduplicate({
                data: keywords.filter(item => item.type === type.key),
                field: 'keyword',
                countField: 'count',
                useLevenshtein: type.key !== 'LOC'
            }).slice(0, 10);
            const total = topHits.reduce((partialSum, a) => partialSum + a.count, 0);
            return topHits.map(item => ({
                ...item,
                count: (100 * item.count) / total,
            }));
        }).flat();
        return {
            ...ret,
            keywords: entities
        };
    }

    return {
        ...ret,
        keywords
    };
};

export const WORDCLOUD = gql`
    query wordcloud(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $field: WordcloudField
        $size: Int
        $search: String
    ) {
        wordcloud(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            field: $field
            size: $size
            search: $search
        ) {
            keyword
            count
            type
        }
    }
`;
