import React from 'react';
import { useHistory } from 'react-router-dom';
import { RemoveBulkContentButton } from './RemoveBulkContentButton';
import { RemoveContentButton } from './RemoveContentButton';
import { ContentTableContentType } from './index';

type RemoveContentButtonsProps = {
    bulkContentsSelected?: boolean
    total: number
    selectedContentItems: ContentTableContentType[]
    onRemoveContent?: (data: { id: string }[]) => void
    onBatchContentRemove?: () => void
}

export const RemoveContentButtons = ({
    bulkContentsSelected,
    total,
    selectedContentItems,
    onRemoveContent,
    onBatchContentRemove
}: RemoveContentButtonsProps) => {
    const history = useHistory();
    if (bulkContentsSelected && !history.location.pathname.includes('watchlist')) {
        return (
            <RemoveBulkContentButton onBatchContentRemove={onBatchContentRemove} />
        );
    }

    return (<RemoveContentButton selectedContentItems={selectedContentItems} onRemoveContent={onRemoveContent} />);
};
