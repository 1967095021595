import { color } from '../../../../../../utils/getColors';

type CreateNodeProps = {
    text?: string
    fontIcon?: string
    fontFamily?: string
    image?: string
    height?: number
    width?: number
}

export const createNode = ({ text, fontIcon, fontFamily, image, height, width }: CreateNodeProps) => {
    const maxH = 30;
    const maxW = 30;
    let maxHeight = maxH;
    let maxWidth = maxW;
    if (height && width) {
        const aspectRatio = height / width;
        if (aspectRatio > 1) {
            maxHeight = maxH;
            maxWidth = maxH / aspectRatio;
        } else {
            maxHeight = maxW * aspectRatio;
            maxWidth = maxW;
        }
    }
    return {
        color: color.blue[500],
        label: [
            {
                backgroundColor: 'transparent',
                fontSize: 45,
                padding: {
                    top: 3
                },
                ...(image ? {
                    image,
                    maxHeight,
                    maxWidth
                } : {}),
                ...(fontIcon ? {
                    fontIcon: {
                        text: fontIcon,
                        color: '#fff',
                        ...(fontFamily ? { fontFamily } : {})
                    }
                } : {})
            },
            ...(text ? [{
                text,
                padding: {
                    top: 15
                },
                backgroundColor: 'transparent'
            }] : [])
        ]
    };
};
