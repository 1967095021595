import React, { useState } from 'react';
import { Cpu, List, Users } from 'react-feather';
import { v4 as uuid } from 'uuid';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { CheckBox } from '../../../../components/Form/CheckBox';

type ActorsAndPostsProps = {
    data: IconsAndCountPropsTypes[];
    isSinglePost?: boolean;
    isCheckList?: boolean;
    checkedValue?: (data: any) => void;
}

type IconsAndCountProps = {
    label: string
    count: number
    link?: boolean
}
type IconsAndCountPropsTypes = {
    name?: string;
    label: string
    count: number
    link?: boolean
}
type CheklistItemsAndCountProps = {
    label: string;
    count: number;
    checklist: string[];
    checkboxState: { [key: string]: boolean };
    setCheckboxState: (data: any) => void;
    colorKey: string;
}

export const ActorsAndPosts = ({ data, isSinglePost = true, isCheckList = false, checkedValue }: ActorsAndPostsProps) => {
    const [checkboxState, setCheckboxState] = useState<{ [key: string]: boolean }>(
        data.reduce((acc: { [key: string]: boolean }, item) => { // Explicitly type acc
            if (item.name && typeof item.name === 'string') {
                acc[item.name] = true;
            }
            return acc;
        }, {})
    );
    const handleCheckboxState = (checkedState: any) => {
        if (checkedValue) {
            checkedValue(checkedState);
        }
        setCheckboxState(checkedState);
    };
    return (
        <div className="d-flex justify-content-between">
            <div className="d-flex gap-2">
                {data.map((item, index) =>
                    (isCheckList ? (
                        <CheklistItemsAndCount
                            label={item.label || item.name || ''}
                            count={item.count}
                            key={uuid()}
                            checklist={Object.keys(checkboxState)}
                            checkboxState={checkboxState}
                            setCheckboxState={handleCheckboxState}
                            colorKey={index % 2 ? 'pink' : 'green'}
                        />
                    ) : (
                        <IconsAndCount label={item.label || item.name || ''} count={item.count} key={item.label} />
                    )))}
            </div>
        </div>
    );
};

const CheklistItemsAndCount = ({
    label,
    count,
    checklist,
    checkboxState,
    setCheckboxState,
    colorKey
}: CheklistItemsAndCountProps) => {
    const getTooltipText = () =>
        `There are ${count} posts for Actor ${label.toLowerCase()} within this coordination.`;
    const handleChange = (item: string) => {
        const updatedState = {
            ...checkboxState,
            [item]: !checkboxState[item],
        };
        setCheckboxState(updatedState);
    };

    return (
        <div className="bg-white px-2 py-1 rounded border">
            <TooltipWrapper tooltipText={getTooltipText()} id={`tooltip-card-${label}`} placement="top-start">
                <div className="d-flex align-items-start">
                    {checklist.includes(label)
                    && (
                        <CheckBox
                            checked={checkboxState[label] || false}
                            className={`labelmr-0 checkbox-color-${colorKey}`}
                            id={uuid()}
                            name={label}
                            value={label}
                            onChange={() => handleChange(label)}
                        />
                    )}
                    <div>
                        <p className="widget-text-style">{label}</p>
                        {checklist.includes(label)
                    && (
                        <p className="mb-0">{ count }</p>
                    )}

                    </div>
                </div>
            </TooltipWrapper>
            <div className="d-flex gap-1">
                {!checklist.includes(label) && (
                    <>
                        <Users />
                        {count}
                    </>
                )}
            </div>
        </div>
    );
};

export const IconsAndCount = ({ label, count, link }: IconsAndCountProps) => {
    const getTooltipText = () => {
        switch (label) {
            case 'Actors': return `There are ${count} actors contributing to this coordination.`;
            case 'Posts': return `There are ${count} posts within this coordination.`;
            case 'Coordinated activity': return 'Coordination networks this actor has been involved in.';
            default: return '';
        }
    };
    const getIcon = () => {
        switch (label) {
            case 'Actors': return <Users />;
            case 'Posts': return <List />;
            case 'Coordinated activity': return <Cpu />;
            default: return <List />;
        }
    };
    return (
        <div className="bg-white px-2 py-1 rounded border">
            {getTooltipText() ? (
                <TooltipWrapper tooltipText={getTooltipText()} id={`tooltip-card-${uuid()}`} placement="top-start">
                    <p className="mb-1 widget-text-style">{label}</p>
                </TooltipWrapper>
            )
                : <p>{label}</p>}
            <div className="d-flex gap-1">
                {getIcon()}
                <span className={link ? 'text-primary cursor-pointer' : ''}>{count}</span>
            </div>
        </div>
    );
};
