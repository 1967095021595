import React, { useEffect, useState } from 'react';
import { Button, Row } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { useMutation } from '@apollo/client';
import { QueryVersionSelector } from '../../../components/Organisms';
import { useBooleanVersions } from './useBooleanVersions';
import { UPDATE_SITUATION_ROOM } from '../../../graphql';
import { SmallPagination } from '../../../components/Molecules';
import { useModal } from '../../../context/modal/ModalComponent';
import { StatusModalBodyNew, STATUS_TYPES } from '../../../utils/SuccessErrorModal';
import { Loading } from '../../../components/Loading';
import { ActionConfirmationModal } from './ActionConfirmationModal';
import { useCookies } from '../../../context/cookies/CookiesContext';

const ITEMS_PER_PAGE = 10;

export const Versions = ({ closeVersions, roomData, setRedirect }) => {
    const { setModal, closeModal } = useModal();
    const [isApplyingVersion, setIsApplyingVersion] = useState(false);
    const { getCookie } = useCookies();
    const doNotShowBooleanApplyWarning = getCookie('doNotShowBooleanVersionApplyWarning') === 'true';
    const situationRoomId = roomData.id;
    const currentQuery = {
        projectId: roomData.project_id,
        boolean: roomData.query.boolean,
        user: {
            displayName: roomData.updatedBy?.displayName || roomData.createdBy?.displayName
        },
        updatedAt: roomData.updatedAt || roomData.createdAt,
        booleanTitle: 'Default'
    };
    const [selectedVersion, setSelectedVersion] = useState(currentQuery);
    const [page, setPage] = useState(0);

    const setStatusModal = ({ message, title, type, action }) => {
        setModal({
            header: false,
            onClose: () => { action(); closeModal(); },
            component: <StatusModalBodyNew title={title} message={message} messageType={type} action={action} />
        });
    };

    const setActionConfirmationModal = ({ onConfirm, onCancel }) => {
        setModal({
            header: false,
            component: <ActionConfirmationModal onConfirm={onConfirm} onCancel={onCancel} cookieId="doNotShowBooleanVersionApplyWarning" />
        });
    };

    const { getBooleanVersions, loading: booleanVersionsLoading, error, data } = useBooleanVersions(situationRoomId);
    const [updateSituationRoom] = useMutation(UPDATE_SITUATION_ROOM, {
        onCompleted: () => {
            setIsApplyingVersion(false);
            setStatusModal({
                title: false,
                type: STATUS_TYPES.success,
                message: 'Version successfully applied to situation room!',
                action: setRedirect
            });
        },
        onError: () => {
            setIsApplyingVersion(false);
            setStatusModal({
                title: false,
                status: STATUS_TYPES.error,
                message: 'Error occurred, Please try again later',
            });
        },
        update: (cache) => {
            cache.evict({ fieldName: 'situationRooms' });
            cache.evict({ fieldName: 'situationRoom' });
        }
    });

    useEffect(() => {
        getBooleanVersions();
    }, [getBooleanVersions]);

    if (booleanVersionsLoading || error || isApplyingVersion) {
        return <Loading />;
    }

    const versionData = data?.getBooleanVersions || [];
    const queryVersions = versionData.find(version => version.projectId === currentQuery.projectId)
        ? versionData : [currentQuery, ...versionData];

    return (
        <div className="content-container">
            <Row className="d-flex flex-direction-row align-items-center px-4 py-2 m-0">
                <h2 className="m-0">Situation room versions</h2>
                <div className="ml-auto">
                    <FeatherIcon.X color="#050E2B"
                        size={20}
                        className="settings ml-3 cursor-pointer"
                        stroke="#050E2B"
                        onClick={closeVersions}
                    />
                </div>
            </Row>
            <div className="versions-row">
                <QueryVersionSelector currentQuery={currentQuery}
                    queryVersions={queryVersions}
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    getBooleanVersions={getBooleanVersions}
                    page={page}
                    itemsPerPage={ITEMS_PER_PAGE}
                    setPage={setPage}
                />
            </div>
            <div className="fixed-footer">
                <div className="d-flex border-top px-4 py-2 bg-lightgray">
                    <SmallPagination total={queryVersions.length} itemsPerPage={ITEMS_PER_PAGE} page={page} setPage={setPage} />
                    <Button className="ml-auto mr-2" color="secondary" onClick={closeVersions} data-testid="versions-back-button">
                        Back
                    </Button>
                    <Button color="primary"
                        data-testid="apply-version-button"
                        disabled={currentQuery.projectId === selectedVersion.projectId}
                        onClick={() => {
                            if (doNotShowBooleanApplyWarning) {
                                setIsApplyingVersion(true);
                                updateSituationRoom({
                                    variables: {
                                        id: situationRoomId,
                                        project_id: selectedVersion.projectId,
                                        query: {
                                            boolean: selectedVersion.boolean
                                        }
                                    }
                                });
                            } else {
                                setActionConfirmationModal({
                                    onConfirm: () => {
                                        setIsApplyingVersion(true);
                                        updateSituationRoom({
                                            variables: {
                                                id: situationRoomId,
                                                project_id: selectedVersion.projectId,
                                                query: {
                                                    boolean: selectedVersion.boolean
                                                }
                                            }
                                        });
                                        closeModal();
                                    },
                                    onCancel: () => { closeModal(); }
                                });
                            }
                        }}
                    >
                        Apply version to room
                    </Button>
                </div>
            </div>
        </div>
    ); };
