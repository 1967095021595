import React, { useRef } from 'react';
import { Options } from 'highcharts';
import { ResponsiveChart } from '../../../../components/Highcharts';
import { ResponsiveChartRef } from '../../../../components/Highcharts/ResponsiveChart';
import { color } from '../../../../utils/getColors';

type PatternChartProps = {
    data: any;
}

export const PatternChart = ({ data }: PatternChartProps) => {
    const chartRef = useRef<ResponsiveChartRef>(null);
    const resetZoomButtonRef = useRef<Highcharts.SVGElement | null>(null);
    const defaultColors = [color.graphColors.robinsEggBlue, color.graphColors.magenta];

    const options = {
        title: {
            text: '',
            align: 'left',
            style: {
                color: color.darkblue['700'],
                fontFamily: '"Nunito", sans-serif',
                fontSize: '18px',
                fontWeight: 'bold'
            }
        },
        exporting: {
            enabled: false,
        },
        chart: {
            height: 350,
            zoomType: 'x',
            plotBorderWidth: 1,
            type: 'line',
            style: {
                fontFamily: 'serif'
            },
            events: {
                selection() {
                    const chart = this as Highcharts.Chart;
                    const { renderer, xAxis } = chart;

                    if (resetZoomButtonRef.current) {
                        resetZoomButtonRef.current.destroy();
                    }

                    resetZoomButtonRef.current = renderer.button('Reset zoom', 0, 0, () => {
                        xAxis[0].setExtremes();
                        if (resetZoomButtonRef.current) {
                            resetZoomButtonRef.current.destroy();
                            resetZoomButtonRef.current = null;
                        }
                    }, {
                        zIndex: 7,
                        fill: '#fff',
                        stroke: '#006FF9',
                        style: {
                            color: '#006FF9'
                        }
                    }, {
                        fill: '#006FF9',
                        style: {
                            color: '#fff'
                        }
                    }).attr({
                        id: 'resetZoom',
                        align: 'right',
                        title: 'Reset zoom level'
                    }).add().align({
                        align: 'right',
                        x: -20,
                        y: 20
                    }, false);
                }
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        xAxis: {
            type: 'datetime', // Ensure x-axis is time-based
            title: {
                text: ''
            },
            labels: {
                format: '{value:%d/%m/%y %H:%M}' // Format x-axis labels as DD/MM HH:MM
            },
            accessibility: {
                rangeDescription: 'Time-based data'
            }
        },
        tooltip: {
            shared: true,
            split: false,
            backgroundColor: '#fff',
            borderWidth: 0,
            padding: 0,
            headerFormat: `<div class="d-flex justify-content-between p-1">
                <span class="mr-4">Date</span>
                <span>{point.key}</span>
            </div><hr style="margin: 2px 0;" />`,
            pointFormatter() {
                const { series, y } = this;
                return `<div class="d-flex justify-content-between p-1">
                    <div class="d-flex align-items-center">
                        <div class="mr-1" style="background-color: ${(series as any).color};
                        height: 12px; width: 12px; border-radius: 2px;"></div>
                        ${series.name}
                    </div>
                    <span>${y}</span>
                </div><hr class="m-0" />`;
            },
            xDateFormat: '%d/%m/%Y %l:%M %p',
            style: {
                color: color.darkblue[700],
                fontFamily: '"Nunito", sans-serif',
                fontSize: '14px'
            },
            useHTML: true
        },
        legend: {
            enabled: false
        },
        series: data.map((item: any, index: number) => ({
            type: 'line',
            name: item.name,
            data: item?.pubdatetimes?.map((datetime: number, i: number) => [
                datetime,
                item.data[i] // Use the incremental data from the data array.
            ]) || [],
            // color: data.reduce((acc: { [x: string]: any; }, innerClusterData: { name: string; }, index1: number) => {
            //     acc[innerClusterData.name] = defaultColors[index1];
            //     return acc;
            // })
            color: defaultColors[index % defaultColors.length], // Cycle through colors
        })),
    } as Options;
    return (
        <ResponsiveChart options={options} ref={chartRef} />

    );
};
