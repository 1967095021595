import { useQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Loading } from '../../../../../components/Loading';
import { GET_CUSTOM_NARRATIVES } from '../../../../../graphql';
import { processDateRange } from '../../../../../utils/getTimeFrom';
import { CustomNarrativeInfo } from './CustomNarrativeInfo';
import { ThreatsList } from './ThreatsList';
import { MultiSeriesLine } from '../../../../../components/Highcharts/MultiSeriesLine';
import { useCustomNarrativesOverTime } from './getCustomNarrativesOverTime';
import { SearchSortDropdown } from '../../../../../components/Search/SearchSortDropdown';
import { sortOptions } from '../../../../../components/NewFilters';

export const CustomNarrativePage = ({ project, projectId, bread }) => {
    const match = useRouteMatch();
    const { data, loading } = useQuery(GET_CUSTOM_NARRATIVES, {
        variables: { situationRoom: project, id: match.params.narrative },
        fetchPolicy: 'no-cache'
    });
    const { data: threats = [] } = data ? data.getCustomNarratives.customNarratives[0] : false;

    const { data: customNarrativeOverTime, loading: contentLoading } = useCustomNarrativesOverTime({
        filters: Object.fromEntries(threats?.map((threat) => ([threat.threat.replace(/\s/g, '_'), checkFilters(threat)])))
    });

    const [state, setState] = useState({
        endDate: null,
        startDate: null,
        selection: null,
        yAxis: 'count'
    });
    const [sort, setSort] = useState('score');
    const chartRef = useCallback((node) => {
        if (node !== null) {
            setState((current) => ({ ...current, series: node.chart.series }));
        }
    }, []);
    const customNarrative = data ? data.getCustomNarratives.customNarratives[0] : false;

    const handleSort = (sortNum) => {
        const sortOptionsKeys = [
            'dateNew',
            'dateOld',
            'engagement',
            'score'
        ];
        setSort(sortOptionsKeys[sortNum - 1]);
    };
    const changeYAxis = (e) => {
        setState((current) => ({ ...current, yAxis: e }));
    };
    const changeDate = (e) => {
        const { startDate, endDate } = processDateRange({ rangeFilterOption: e.id });
        return setState((current) => ({ ...current, selection: null, startDate: startDate || null, endDate: endDate || null }));
    };

    if (loading || contentLoading) {
        return (
            <div className="m-5 p-5 d-flex align-items-center justify-content-center">
                <Loading gif height={400} relative />
            </div>
        );
    }

    return (
        <div className="page-padding threat-page">
            <Row>
                <Col xs="12" lg="8" xl="9">
                    {bread}
                    <Row className="mb-4">
                        <Col>
                            {customNarrative && <h1 className="mt-0 mb-0">Custom Narrative: {customNarrative.name}</h1>}
                        </Col>
                        <Col xs="auto" />
                    </Row>
                    {customNarrativeOverTime.length ? (
                        <>
                            <div className="content-deck-date-picker">
                                <div className="content-deck-date-picker__preset">
                                    <div data-at="all"
                                        id="all"
                                        onClick={(e) => changeDate(e.target)}
                                        className="content-deck-date-picker_chip"
                                    >
                                        All
                                    </div>
                                    <div id="month"
                                        onClick={(e) => changeDate(e.target)}
                                        className="content-deck-date-picker_chip"
                                    >
                                        30 Days
                                    </div>
                                    <div id="fortnight"
                                        onClick={(e) => changeDate(e.target)}
                                        className="content-deck-date-picker_chip"
                                    >
                                        14 Days
                                    </div>
                                    <div id="week"
                                        onClick={(e) => changeDate(e.target)}
                                        className="content-deck-date-picker_chip"
                                    >
                                        7 Days
                                    </div>
                                    <div id="day"
                                        onClick={(e) => changeDate(e.target)}
                                        className="content-deck-date-picker_chip"
                                    >
                                        24 Hours
                                    </div>
                                </div>
                            </div>
                            <div className="content-deck-controls">
                                <select id="yaxis"
                                    name="yaxis"
                                    value={state.yAxis}
                                    onChange={(e) => changeYAxis(e.target.value)}
                                >
                                    <option key="engagement" value="engagement">
                                        Engagement
                                    </option>
                                    <option key="count" value="count">
                                        Count
                                    </option>
                                </select>
                            </div>

                            <MultiSeriesLine data={customNarrativeOverTime}
                                type={state.yAxis}
                                setState={setState}
                                chartRef={chartRef}
                                startDate={state.startDate}
                                endDate={state.endDate}
                            />

                            <div className="mt-4">
                                <SearchSortDropdown score sortOption={sortOptions[sort]} handleSort={handleSort} />
                            </div>
                            <div className="content-deck-wrapper">
                                {customNarrativeOverTime.length
                                    ? threats.map((threat, index) => (
                                        <div key={threat.threat}>
                                            <ThreatsList threat={threat}
                                                index={index}
                                                state={state}
                                                projectId={projectId}
                                                sort={sort}
                                            />
                                        </div>
                                    )) : null}
                            </div>
                        </>
                    ) : null}
                </Col>
                <Col xs="12" lg="4" xl="3" className="right-side-bar">
                    {customNarrative && <CustomNarrativeInfo customNarrative={customNarrative} />}
                </Col>
            </Row>
        </div>
    );
};

export const makeQuery = (threat) => {
    if (threat.boolean) {
        return threat.boolean.replace(/NEAR\/[0-9]/g, 'AND').replace(/[{}]/g, '');
    }
    if (threat.nearQuery) {
        return threat.nearQuery.replace(/NEAR\/[0-9]/g, 'AND').replace(/[{}]/g, '');
    }
    return threat.keywords.join(' OR ');
};

export const checkFilters = (threat, EsQuery, startDate, endDate, projectId, selection) => {
    if (threat?.boolean && threat?.query_type === 'query') {
        return JSON.parse(
            JSON.stringify(threat.dslQuery).replaceAll('.cs_special_characters', '')
                .replaceAll(',"analyzer":"whitespace"', '')
                .replaceAll('#', 'hashtags:#')
                .replaceAll('hashtags:hashtags:', 'hashtags:')
                .replaceAll('text_meta', 'text')
        );
    }
    if (!(threat?.query_type === 'query') && EsQuery) {
        return {
            bool: {
                filter: [
                    {
                        term: {
                            project_id__keyword: {
                                value: projectId,
                            },
                        },
                    },
                    {
                        query_string: {
                            query: threat?.keywords.join(' OR '),
                            fields: ['text'],
                            lenient: true,
                        },
                    },
                    {
                        range: {
                            date: {
                                gte: (selection !== null || startDate !== null) ? new Date(selection || startDate * 1000) : null,
                                lte: (selection !== null || endDate !== null) ? new Date(selection || endDate * 1000) : null,
                            },
                        },
                    }
                ],
            },
        };
    }
    return {
        bool: {
            filter: [
                {
                    query_string: {
                        query: threat?.keywords.join(' OR '),
                        fields: ['text'],
                        lenient: true,
                    },
                }
            ],
        }
    };
};
