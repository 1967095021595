import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as ActiveState } from 'react-router-dom';
import { useSuperAdminUser } from '../../pages/Admin/useSuperAdminUser';
import { gql } from '../../generated';
import { Dropdown } from '../Form/Dropdown';
import { UPDATE_USER } from '../../graphql';
import { useUser } from '../../context/user/UserContext';
import { Loading } from '../Loading';
import { color } from '../../utils/getColors';

type ClientNameProps = {
    showClientNameInUI: boolean
    clientName: string
    clientId: string
}
export const ClientName = ({ showClientNameInUI, clientName, clientId }: ClientNameProps) => {
    const { superAdminUser } = useSuperAdminUser();
    if (superAdminUser) {
        return (
            <>
                <SelectClient value={clientId} />
                <NavItem>
                    <NavLink tag={ActiveState}
                        isActive={(match: any) => match?.isExact}
                        to="/admin"
                        className="nav-link"
                    >
                        Admin
                    </NavLink>
                </NavItem>
            </>
        );
    }
    if (showClientNameInUI) {
        return <p className="m-0 px-3 pt-3 text-white">{clientName}</p>;
    }
    return null;
};

const SelectClient = ({ value }: { value: string }) => {
    const { user } = useUser();
    const { data: instancesData, loading: instancesLoading } = useQuery(GET_INSTANCES);
    const [updateUser, { loading }] = useMutation(UPDATE_USER, {
        onCompleted: () => {
            window.location.reload();
        }
    });
    if (instancesLoading || loading) return <div className="pl-3 pr-5 align-self-center"><Loading relative /></div>;
    const instances = instancesData?.instances || [];

    const dropdownOptions = instances.map(instance => ({ key: instance?.id || '', name: instance?.clientName || '' }));
    const onChange = ({ key }: { key: string }) => {
        updateUser({
            variables: {
                data: {
                    uid: user.uid,
                    instance: key
                }
            }
        });
    };
    return (
        <Dropdown options={dropdownOptions}
            value={dropdownOptions.find(option => option.key === value) || dropdownOptions[0]}
            onChange={onChange}
            dropdownToggleClass="nav-link text-white border-left-0 border-top-0 border-bottom-0 border border-nav-border"
            search
            arrowColor={color.grey.white}
        />
    );
};

const GET_INSTANCES = gql(`
    query instances {
        instances {
            id
            clientName
        }
    }
`);
