import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomInput, FormGroup } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { saveSelectedFilters } from '../../../../pages/Room/store';
import { RootState } from '../../../../store';
import { useWordcloud } from '../../../../services/Overview';
import { Loading } from '../../../Loading';
import { LabelItem } from './LabelItem';

type EntitiesFilterProps = {
    searchQuery: string,
    appliedFilters: any
}

export const EntitiesFilter = ({ searchQuery = '', appliedFilters }: EntitiesFilterProps) => {
    const dispatch = useDispatch();
    const [dropdownId] = useState(uuid());

    const { entities: selectedEntities }: { entities: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);

    const { keywords: topEntities, loading } = useWordcloud({
        field: 'entities',
        size: 15,
        defaultFilters: {
            wildcard: {
                search: searchQuery,
                field: 'entities'
            }
        },
        search: searchQuery,
        appliedFilters
    });
    const entitiesKeys = [] as string[];
    const entitesList = topEntities.filter((a: {key: string}) => {
        if (entitiesKeys.includes(a.key)) {
            return false;
        }
        entitiesKeys.push(a.key);
        return true;
    });

    const entitiesData = entitesList?.slice(0, 30)?.map((entity: {key: string, keyword: string, doc_count: number}) => ({
        id: entity.key,
        name: entity.keyword,
        selected: selectedEntities.includes(entity.key),
        count: entity.doc_count || 0
    })).sort((a: {count: number}, b: {count: number}) => b.count - a.count);

    const handleSelectItem = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(saveSelectedFilters({
            entities: selectedEntities.includes(id)
                ? selectedEntities.filter(entity => entity !== id)
                : [...selectedEntities, id]
        }));
    };

    return (
        <div>
            {(!searchQuery && entitiesData.length) ? <p>Top 20 entities</p> : ''}
            <div className=" tab-filter">
                {(entitiesData.length > 0 && !loading) && (
                    <>
                        {entitiesData.map((item: {id: string, name: string, selected: boolean, count: number}) => (
                            <FormGroup key={item.id}>
                                <CustomInput tag="checkbox"
                                    type="checkbox"
                                    checked={item.selected}
                                    className="custom-input-filter py-1"
                                    label={<LabelItem item={item} dropdownId={dropdownId} />}
                                    id={`${dropdownId}_${item.id}`}
                                    data-testid={`checkbox-${item.id}`}
                                    value={item.name}
                                    onChange={(e) => { handleSelectItem(item.id, e); }}
                                />
                            </FormGroup>
                        ))}
                    </>
                )}
                {loading && <Loading relative height={168} width={255} />}
                {(!entitiesData.length && !loading) && <p className="no-white-space">No results found</p>}
            </div>
        </div>
    );
};
