import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import { NavLink as ActiveState, useLocation, Link } from 'react-router-dom';
import { UserDropdown } from './UserDropdown';
import { HelpDropdown } from './HelpDropdown';
import Logo from '../../assets/images/logo.svg';
import { useUser } from '../../context/user/UserContext';
import { adminRoutesLinks, topBarRoutesLinks } from '../../routes';
import { useGetInstance } from '../../services/Instance/getInstance';
import { NotificationsPanel } from './Notifications/NotificationsPanel';
import { ClientName } from './ClientName';

export const NavBar = () => {
    const { user, notAllowed } = useUser();
    const { instance } = useGetInstance({ instanceId: user?.instance?.id });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const isAdminRoute: boolean = useLocation().pathname.split('/')[1] === 'admin';
    const handleToggle = () => setIsOpen(!isOpen);

    if (!instance || !user) {
        return null;
    }

    const isAlertsEnabled = instance.plan?.others?.alerts;
    return (
        <div data-testid="navbar">
            <Navbar className="navbar-custom flex-wrap"
                dark
                expand="md"
            >
                <NavbarBrand tag={Link} href="/" to="/">
                    <span className="logo-lg">
                        <img src={Logo}
                            alt=""
                            height="24"
                        />
                        <span className="d-inline h5 ml-2 text-logo dark">Intelligence</span>
                    </span>
                </NavbarBrand>
                <NavbarToggler onClick={handleToggle} className="mr-2" data-testid="navbar-toggle" />
                <Collapse isOpen={isOpen} className="mr-auto" navbar data-testid="navbar-collapse">
                    <div className="topnav-menu">
                        <Nav className="navbar-dark" navbar>
                            {!isAdminRoute
                                ? (
                                    <>
                                        <ClientName showClientNameInUI={instance?.showClientNameInUI}
                                            clientName={instance?.clientName}
                                            clientId={instance?.id}
                                        />
                                        {topBarRoutesLinks
                                            .filter(item => !(
                                                item.path === '/team'
                                            && (
                                                notAllowed.includes('teamPage')
                                                || (
                                                    instance?.plan?.restrictedTeamRole
                                                    && user.role !== 'ADMIN'
                                                )
                                            )
                                            ))
                                            .map(item => (
                                                <NavItem key={item.path} data-testid={`top-bar-routes-${item.path}`}>
                                                    <NavLink tag={ActiveState}
                                                        isActive={(match: any) => match}
                                                        to={item.path}
                                                        className="nav-link"
                                                    >
                                                        {item.name}
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                    </>
                                )
                                : adminRoutesLinks.map(item => (
                                    <NavItem key={item.path} data-testid={`admin-routes-${item.path}`}>
                                        <NavLink tag={ActiveState}
                                            isActive={(match: any) => match?.isExact}
                                            to={item.path}
                                            className="nav-link"
                                        >
                                            {item.name}
                                        </NavLink>
                                    </NavItem>
                                ))}
                        </Nav>
                    </div>
                    {user && (
                        <ul className=" flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0">
                            {isAlertsEnabled && <NotificationsPanel />}
                            <HelpDropdown />
                            <UserDropdown />
                        </ul>
                    )}
                </Collapse>
            </Navbar>
        </div>
    );
};
