import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getRoom } from '../../../utils/variables';
import { GET_CONTENT_LABELS } from '../../../pages/Room/Labels';
import { ItemProps } from '../components/DropdownWIthMultiCheckbox';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { RootState } from '../../../store';
import { DropdownWithSearchAndCheckboxes } from '../components/DropdownWithSearchAndCheckboxes';

type LabelsFilterProps = {
    contentIdsList?: string[],
    watchlist: boolean
}
export const LabelsFilter = ({ watchlist = false, contentIdsList }: LabelsFilterProps) => {
    const room = getRoom();
    const dispatch = useDispatch();
    const labelsFeatureEnabled = !!room.instance?.plan?.others?.labels;
    const { labels: selectedLabels, ids: selectedIds }:
    { labels: string[], ids: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const { data, loading } = useQuery(GET_CONTENT_LABELS, {
        variables: {
            situationRoom: room?.id
        },
        fetchPolicy: 'cache-and-network',
        skip: !labelsFeatureEnabled
    });

    const contentLabels = data ? data?.getContentLabels : [];
    const labelsData = contentLabels.map(({ label, contentIds }: {label: string, contentIds: string[]}) => ({
        id: label,
        name: label,
        selected: selectedLabels.includes(label),
        count: contentIds.length,
        contentIds
    }));
    const sortedFilters = labelsData.sort((a: ItemProps, b: ItemProps) => a.name.localeCompare(b.name));
    useEffect(() => {
        if (!watchlist) {
            if (!selectedLabels.length && selectedIds.length) {
                dispatch(saveSelectedFilters({
                    ids: []
                }));
            } else {
                const idsList = labelsData.filter((a: ItemProps) => selectedLabels
                    .includes(a.id)).map((b: ItemProps) => b.contentIds).flat();
                if (!_.isEqual(idsList, selectedIds)) {
                    dispatch(saveSelectedFilters({
                        ids: idsList
                    }));
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, labelsData, selectedLabels]);

    const handleContentIds = (id: string) => {
        if (selectedLabels.includes(id)) {
            const filteredSelectedLabels = selectedLabels.filter(label => label !== id);
            const labelsList = labelsData.filter((a: ItemProps) => filteredSelectedLabels.includes(a.id));
            const idsList = labelsList.map((b: ItemProps) => b.contentIds).flat();
            return [...idsList];
        }
        const selectedContentIds = labelsData.filter((a: ItemProps) => a.id === id).map((b: ItemProps) => b.contentIds).flat();
        return [...selectedIds, ...selectedContentIds];
    };
    const handleSelectItem = (id: string) => {
        if (!watchlist) {
            dispatch(saveSelectedFilters({
                labels: selectedLabels.includes(id)
                    ? selectedLabels.filter(label => label !== id)
                    : [...selectedLabels, id],
                ids: handleContentIds(id)
            }));
        } else {
            dispatch(saveSelectedFilters({
                labels: selectedLabels.includes(id)
                    ? selectedLabels.filter(label => label !== id)
                    : [...selectedLabels, id],
                ids: handleContentIds(id)
            }));
        }
    };

    const countFilters = selectedLabels.length;

    return (
        <div>
            <DropdownWithSearchAndCheckboxes handleSelect={handleSelectItem}
                items={sortedFilters}
                placeholder="Search"
                description={`${labelsData.length} labels`}
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                loading={loading}
                itemsPerPage={30}
            />
        </div>
    );
};
