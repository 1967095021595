import { useMutation } from '@apollo/client';
import { gql } from '../../../../generated';
import { SituationRoom } from '../../../../generated/graphql';
import { getRoom } from '../../../../utils/variables';

export const useRecordBulkRemoval = ({ ids }: { ids: string[] }) => {
    const room = getRoom() as SituationRoom;

    const [recordBulkRemoval] = useMutation(RECORD_BULK_REMOVAL, {
        variables: {
            data: {
                projectId: room?.project_id || '',
                situationRoomId: room?.id || '',
                docIds: ids
            }
        }
    });

    return {
        recordBulkRemoval
    };
};

export const RECORD_BULK_REMOVAL = gql(`
    mutation recordBulkRemoval($data: BulkRemovalRecordInput!) {
        recordBulkRemoval(data: $data)
    }
`);
