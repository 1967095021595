import React from 'react';
import { getParam, setParam, unsetParam } from '../../../../../utils/urlParams';
import { ActorPost } from '../../CoordinatedModals/ActorPosts';
import { ActorDetail } from '../../CoordinatedModals/ActorDetail';
import { Tabs } from '../../../../../components/Tabs';
import { CoordinatedDetail } from '../../CoordinatedModals/CoordinatedDetail';
import { CoordinatedSingle } from '../../CoordinatedModals/CoordinatedSingle';
import { activity, ActivityType } from '../../../../../utils/text/coordinatedActivity';
import { getDates } from './CoordinatedList';
import { getCoordinatedDateType } from '../../../../../utils/coordinatedTypes';
import { WidgetTextTooltip } from '../Summary/WidgetTextTooltip';

type SelectedActorProps = {
    actors: any
    coordinationId: string
    setCoordinationId: (id: string) => void
    setSelectedData: (data: []) => void
    selectedData: any
    actorName: string
    setActorName: (name: string) => void
}

export const SelectedActor = ({ actors, coordinationId, setCoordinationId, setSelectedData,
    selectedData, actorName, setActorName }: SelectedActorProps) => {
    if (actorName && !coordinationId) {
        const actorsId = getParam('actor_id');
        const actor = actors.filter((item: any) => item.from === `${actorsId}`)[0];
        const dateType = getCoordinatedDateType('Coordinated_posts');
        const allPosts = actor?.allData.map((item: any) => item.network).flat();
        const tabs = [{
            name: 'Coordinated activity',
            id: 'coordinated_activity',
            content:
    <div className="actors-modal scrollbar-small">
        {actor.allData.map((x: any) => (
            <CoordinatedSingle
                getCoordinatedDateType={dateType}
                getWidgetName={activity[x.coordinationType as keyof ActivityType]}
                header="Coordinated_posts"
                item={x}
                singleActor={actor}
                setSelectedData={setSelectedData}
                startDate={getDates(x.network).startDate}
                endDate={getDates(x.network).endDate}
                setCoordinationId={setCoordinationId}
            />
        ))}
    </div>
        }, {
            name: 'Posts',
            id: 'posts',
            content:
    <div className="actors-modal scrollbar-small">
        {allPosts.map((x: any) => (
            <ActorPost key={x.coordination_source} actor={x} />
        ))}
    </div>
        }];
        return (
            <div className="mt-4">
                <ActorDetail actor={actor} />
                <Tabs data={tabs} />
            </div>
        );
    }
    return (
        <div>
            {
                coordinationId && (
                    <CoordinatedDetail
                        selectedCoordination={coordinationId}
                        singleNetworkData={selectedData}
                    />
                )
            }
            {!actorName && !coordinationId
                && (
                    <>
                        <div className="d-flex align-items-center mt-3">
                            <h3 className="mr-1 my-0"><WidgetTextTooltip widgetName="Actors" noMargin /></h3>
                            <span className="tag" color="dark">{actors.length} actors </span>
                        </div>
                        <div className="actors-modal scrollbar-small mt-3">
                            {
                                actors.slice(0, 100).map((actor: any) => (
                                    <ActorDetail actor={actor}
                                        key={actor.from}
                                        handleSetParam={(name) =>
                                        { setParam('actor_id', name);
                                            unsetParam('coordination_id'); setActorName(name); }}
                                    />
                                ))
                            }
                        </div>
                    </>
                )}
        </div>

    );
};
