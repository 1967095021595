import moment from 'moment';
import React, {
    useState,
    useMemo,
    useContext,
    createContext,
    useCallback
} from 'react';
import { ObjectID } from 'bson';
import { isEqual } from 'lodash';
import { useUser } from '../../../context/user/UserContext';

const RoomEditorContext = createContext({});

export const useRoomEditor = () => {
    const context = useContext(RoomEditorContext);
    if (context === undefined) {
        throw new Error('useRoomEditor must be used within a RoomEditorContextProvider');
    }
    return context;
};

export const RoomEditorContextProvider = ({ children, edit, room: defaultRoom, draft }) => {
    const { user, instance } = useUser();
    const date = new Date();
    const endDate = new Date(date.setFullYear(date.getFullYear() + 1));
    const [existingRoom, setExistingRoom] = useState(defaultRoom);
    const [room, setRoom] = useState(defaultRoom || {
        instance: {
            id: instance.id || instance
        },
        reason: '',
        project_name: '',
        project_id: new ObjectID().toString(),
        createdBy: {
            id: user.id
        },
        query_type: 'boolean',
        query: {
            included: [],
            excluded: [],
            boolean: ''
        },
        start_date: '',
        end_date: moment(new Date(endDate)).format('yyyy-MM-DD')
    });

    const [errors, setErrors] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [selectedKeywordData, setSelectedKeyword] = useState(false);
    const [convertedQuery, setConvertedQuery] = useState(false);
    const findKeyword = (item) => item.value === selectedKeywordData?.value;
    const selectedKeyword = room.query.included.find(findKeyword) || room.query.excluded.find(findKeyword) ? selectedKeywordData : false;
    const canCreateRoom = useCallback(() => {
        if (!room.project_name) {
            return false;
        }
        if (!room.reason) {
            return false;
        }
        if (room.query_type === 'boolean') {
            if (!room.query.boolean || (/^\s*$/.test(room.query.boolean))) {
                return false;
            }
        } else if (!room.query.included.length && !room.query.excluded.length) {
            return false;
        }
        return true;
    }, [room]);
    const hasQueryChanged = useCallback(() => {
        if (!existingRoom) {
            return false;
        }
        if (room.query_type === 'boolean') {
            if (room.query.boolean.replace(/(?:[^\S\n]|\t)+/g, ' ').trim()
            !== existingRoom.query.boolean.replace(/(?:[^\S\n]|\t)+/g, ' ').trim() && !((/^\s*$/.test(room.query.boolean.replace(/(?:[^\S\n]|\t)+/g, ' ').trim())))) {
                return true;
            }
        } else {
            if (!isEqual(room.query.included, existingRoom.query.included) && room.query.included.length) {
                return true;
            }
            if (!isEqual(room.query.excluded, existingRoom.query.excluded) && room.query.excluded.length && room.query.included.length) {
                return true;
            }
        }
        return false;
    }, [room, existingRoom]);
    const hasDateChanged = useCallback(() => {
        if (!existingRoom) {
            return false;
        }
        if (!isEqual(room.start_date, existingRoom.start_date)) {
            return true;
        }
        return false;
    }, [room, existingRoom]);
    const haveChangesBeenMade = useCallback(() => {
        if (existingRoom) {
            if ((existingRoom.project_name !== room.project_name
                || existingRoom.query_type !== room.query_type || existingRoom.reason !== room.reason
                || existingRoom.start_date.format('yyyy-MM-DD') !== room.start_date.format('yyyy-MM-DD')) && !(room.query_type === 'boolean' ? (/^\s*$/.test(room.query.boolean)) : (!room.query.included.length))) {
                return true;
            }
            return hasQueryChanged();
        }
        return canCreateRoom();
    }, [canCreateRoom, existingRoom, room, hasQueryChanged]);
    const changesMade = haveChangesBeenMade();
    const context = useMemo(() => ({
        room: {
            ...room,
            query: {
                ...room.query,
                included: formatCountryKeywords(room.query.included),
                excluded: formatCountryKeywords(room.query.excluded)
            }
        },
        setRoom,
        existingRoom,
        setExistingRoom,
        edit,
        draft,
        errors,
        setErrors,
        validationErrors,
        setValidationErrors,
        isBooleanQuery: room.query_type === 'boolean',
        selectedKeyword,
        setSelectedKeyword,
        isQueryEmpty: room.query_type === 'boolean'
            ? !room.query.boolean : !(room.query.included.length || room.query.excluded.length),
        oldRoom: defaultRoom,
        changesMade,
        canCreateRoom: canCreateRoom(),
        hasQueryChanged: hasQueryChanged(),
        hasDateChanged: hasDateChanged(),
        setConvertedQuery,
        convertedQuery
    }), [room, existingRoom, edit, draft, errors, validationErrors, selectedKeyword, defaultRoom, changesMade,
        canCreateRoom, hasQueryChanged, hasDateChanged, convertedQuery]);
    return (
        <RoomEditorContext.Provider value={context}>
            { children }
        </RoomEditorContext.Provider>
    );
};

const formatCountryKeywords = (keywords) => {
    const ret = [];
    for (const keyword of keywords) {
        if (keyword.type === 'country') {
            const alreadyExists = keywords.find(({ value, type }) => value === keyword.value && type === 'phrase');
            if (!alreadyExists) {
                ret.push({ value: keyword.value, type: 'phrase' });
            }
        }
    }
    return [...keywords, ...ret];
};
