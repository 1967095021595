import { useQuery } from '@apollo/client';
import { useSessionUser } from '../../auth0';
import { GET_ADMIN_USERS } from '../../graphql';

export const useSuperAdminUser = () => {
    const { user: sessionUser, loading: sessionDataLoading } = useSessionUser();
    const uid = 'uid' in sessionUser && sessionUser.uid as string;
    const { data, loading: adminDataLoading } = useQuery(GET_ADMIN_USERS, {
        variables: {
            where: {
                uid
            }
        },
        skip: uid === false
    });

    const user = data ? data.adminUsers[0] : false;

    return {
        superAdminUser: user,
        loading: sessionDataLoading || adminDataLoading
    };
};
