import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../store';
import { saveSelectedFilters } from '../../../../pages/Room/store';
import { getRoom } from '../../../../utils/variables';
import { DropdownWithCheckboxes } from './DropdownWithCheckboxes';
import { useThreatSignals } from '../../../../services/Overview/threatSignals';

export const signalsList = [{ name: 'Toxic information', key: 'toxic', isResolver: false },
    { name: 'Attack on commenter', key: 'ATTACK_ON_COMMENTER', isResolver: true },
    { name: 'Attack on author', key: 'ATTACK_ON_AUTHOR', isResolver: true },
    { name: 'Attack on identity', key: 'IDENTITY_ATTACK', isResolver: true },
    { name: 'Insulting language', key: 'INSULT', isResolver: true },
    { name: 'Inflammatory language', key: 'INFLAMMATORY', isResolver: true },
    { name: 'Toxic language', key: 'TOXICITY', isResolver: true },
    { name: 'Severely toxic language', key: 'SEVERE_TOXICITY', isResolver: true },
    { name: 'Threatening language', key: 'THREAT', isResolver: true },
    { name: 'Low source credibility', key: 'src-cred', isResolver: false },
    { name: 'Domestic extremism', key: 'domestic_extremism', isResolver: false },
    { name: 'Emerging boycott', key: 'boycott', isResolver: false },
    { name: 'Automated account', key: 'automation_score', isResolver: false }
];
type ThreatTypesFilterProps = {
    appliedFilters: any
}
export const ThreatTypesFilter = ({ appliedFilters }: ThreatTypesFilterProps) => {
    const room = getRoom();
    const location = useLocation();
    const dispatch = useDispatch();
    const { projectId } = location.state || {};
    const { data, loading } = useThreatSignals({ useGlobalFilters: true, projectId, appliedFilters });
    const { threatTypes: selectedThreatTypes }: { threatTypes: string[] } = useSelector((state:
         RootState) => state.selectedFilters.filters);
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];

    const resolverIds = signalsList.filter((a) => a.isResolver).map((b) => b.key);
    let resolverTotalCount = 0;
    const mappedData = signalsList.map(({ name, key, isResolver }) => {
        const docCount = data?.find((item: { signal: string }) => item.signal === key)?.count || 0;
        if (resolverIds.includes(key)) {
            resolverTotalCount += docCount;
        }
        return {
            id: key,
            name,
            selected: selectedThreatTypes.includes(key),
            count: docCount,
            isResolver
        };
    }).filter(({ id }) => !disabledThreatTypes.includes(id) && !disabledThreatTypes.includes(id.slice(4, id.length).replace('-', '_')));

    const resolverData = mappedData
        .filter(({ isResolver }) => isResolver)
        .sort((a, b) => b.count - a.count);

    const nonResolverData = mappedData
        .filter(({ isResolver, id }) => !isResolver && id !== 'toxic')
        .sort((a, b) => b.count - a.count);
    const toxicInfo = {
        id: 'toxic',
        name: 'Toxic information',
        selected: selectedThreatTypes.includes('toxic'),
        count: resolverTotalCount
    };
    let inserted = false;
    const sortedList = [];

    for (const item of nonResolverData) {
        if (!inserted && toxicInfo.count >= item.count) {
            sortedList.push(toxicInfo);
            sortedList.push(...resolverData);
            inserted = true;
        }
        sortedList.push(item);
    }
    if (!inserted) {
        sortedList.push(toxicInfo);
        sortedList.push(...resolverData);
    }
    const threatTypesData = sortedList;

    const handleSelectItem = (ids: string[]) => {
        dispatch(saveSelectedFilters({
            threatTypes: ids
        }));
    };

    const countFilters = selectedThreatTypes.length;

    return (
        <div>
            <DropdownWithCheckboxes
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                items={threatTypesData}
                handleSelect={handleSelectItem}
                loading={loading}
            />
        </div>

    );
};
