import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import { AlertTriangle } from 'react-feather';
import { gql, useQuery } from '@apollo/client';
import * as FeatherIcon from 'react-feather';
import { RootState } from '../../../store';
import { saveFilters, saveSelectedFilters } from '../../../pages/Room/store';
import { useQueryValidator } from '../../../pages/SituationRooms/RoomEditor/RoomPreview/useQueryValidator';
import { findAndReplaceInObject } from '../../../utils/lib/findAndReplaceInObject';
import { DropdownWithSearch } from '../components/DropdownWithSearch';
import { getRoom } from '../../../utils/variables';
import { BooleanSearchContent } from './BooleanSearchContent';
import { useModal } from '../../../context/modal/ModalComponent';
import { SaveBooleanQueryModal } from './SaveBooleanQueryModal';
import { STATUS_TYPES, StatusModalBodyNew } from '../../../utils/SuccessErrorModal';
import { Loading } from '../../Loading';
import { color } from '../../../utils/getColors';

import { truncateString } from '../../../utils/text';
import { getToast } from '../../../utils/getToast';
import { checkForNegativeQuery } from './checkForNegativeQuery';

type BooleanSearchProps = {
    setIsBooleanOpen: (e: boolean) => void,
    showBooleanSearch: boolean
}

export const BooleanSearch = ({ setIsBooleanOpen, showBooleanSearch }: BooleanSearchProps) => {
    const dispatch = useDispatch();
    const room = getRoom();
    const { setModal, closeModal } = useModal();
    const [searching, setSearching] = useState(false);
    const [emptyQuery, setEmptyQuery] = useState(false);
    const { booleanQuery, queryName } = useSelector((state: RootState) => state.selectedFilters.filters);
    const [validationErrors, setValidationErrors] = useState({});
    const toast = getToast();
    const [invalidQuery, setInvalidQuery] = useState(false);
    const { data, loading } = useQuery(GET_SAVED_BOOLEAN_SEARCH, {
        variables: {
            situationRoom: room?.id,
        },
        skip: !showBooleanSearch,
        fetchPolicy: 'cache-and-network'
    });

    const { validateQuery } = useQueryValidator({
        lazy: true,
        room: {
            query_type: 'boolean',
            query: {
                included: [],
                excluded: [],
                boolean: booleanQuery
            }
        },
        roomBoolean: room?.query?.boolean ? room?.query?.boolean : ''
    });

    const booleanSearchData = data?.getSavedBooleanSearch?.booleanSearch || [];
    const setStatusModal = ({ message, title, type }: {message: string, title: boolean, type: string}) => {
        setModal({
            header: false,
            component: <StatusModalBodyNew title={title} message={message} messageType={type} />
        });
    };

    const handleSearch = async () => {
        const hasErrors = Boolean(Object.values(validationErrors)
            .find((error: any) => error.isError === true));
        if (booleanQuery && !hasErrors) {
            const finalBooleanQuery = checkForNegativeQuery({
                included: room?.query?.included ? room?.query.included.map((a: {value: string}) => a.value) : [],
                excluded: room?.query.excluded ? room?.query.excluded.map((a: {value: string}) => a.value) : [],
                boolean: room?.query?.boolean ? room?.query?.boolean : '',
                userQuery: booleanQuery

            });
            setSearching(true);
            setInvalidQuery(false);
            try {
                const { data: ret } = await validateQuery({
                    variables: {
                        query: {
                            included: [],
                            excluded: [],
                            boolean: finalBooleanQuery
                        },
                        query_type: 'boolean'
                    }
                });

                if (ret.validateQuery.isValid) {
                    dispatch(saveFilters({
                        convertedBooleanQuery: findAndReplaceInObject(
                            ret.validateQuery.query?.query || {},
                            'minimum_should_match',
                            (a: any) => a.toString()
                        ),
                        booleanQueryKeywords: ret.validateQuery.keywords || [],
                        booleanQuery
                    }));
                    setIsBooleanOpen(false);
                } else {
                    setInvalidQuery(true);
                }
                return setSearching(false);
            } catch (err) {
                setStatusModal({
                    title: false,
                    type: STATUS_TYPES.error,
                    message: 'Validation error occurred, Please try again later'
                });
                return setSearching(false);
            }
        } else {
            if (!booleanQuery) {
                setEmptyQuery(true);
            }
            return toast.error('Resolve all errors before continuing');
        }
    };

    const handleResetBoolean = () => {
        setModal({
            header: 'Reset Boolean search',
            component: (
                <div>
                    <p>Are you sure that you want to reset this Boolean search?</p>
                    <hr />
                    <div>
                        <Button color="secondary" onClick={() => closeModal()} className="mr-2">Cancel</Button>
                        <Button color="danger"
                            data-testid="reset-boolean"
                            onClick={() => { setValidationErrors({}); setInvalidQuery(false); setEmptyQuery(false);
                                dispatch(saveFilters({
                                    booleanQuery: '',
                                    convertedBooleanQuery: '',
                                    booleanQueryKeywords: []
                                }));
                                dispatch(saveSelectedFilters({
                                    booleanQuery: ''
                                }));
                                closeModal();
                            }}
                        >Reset
                        </Button>
                    </div>
                </div>
            )
        });
    };
    const handleSaveBooleanSearch = () => {
        const hasErrors = Boolean(Object.values(validationErrors)
            .find((error: any) => error.isError === true));
        if (booleanQuery && !hasErrors) {
            setModal({
                header: 'Save Boolean search',
                component: (
                    <SaveBooleanQueryModal roomId={room?.id}
                        booleanQuery={booleanQuery}
                        queryNamesList={booleanSearchData.map((a: {name: string}) => a.name)}
                    />
                )
            });
        } else {
            if (!booleanQuery) {
                setEmptyQuery(true);
            }
            toast.error('Resolve all errors before continuing');
        }
    };
    const handleSelectItem = (id: string) => {
        const selectedItem = booleanSearchData.filter((item: {id: string}) => item.id === id);
        return dispatch(saveSelectedFilters({
            booleanQuery: selectedItem[0].query,
            queryName: selectedItem[0].name
        }));
    };

    if (queryName) {
        const filteredItem = booleanSearchData.filter((a: {name: string, query: string}) => a.name === queryName);
        if (filteredItem.length > 0 && filteredItem[0].query !== booleanQuery) {
            dispatch(saveSelectedFilters({
                queryName: ''
            }));
        }
    }
    const handleSetBooleanQuery = (query: string) => {
        if (queryName) {
            dispatch(saveSelectedFilters({
                booleanQuery: query,
                queryName: ''
            }));
        } else {
            dispatch(saveSelectedFilters({
                booleanQuery: query
            }));
        }
        if (emptyQuery) {
            setEmptyQuery(false);
        }
    };

    return (
        <div className="bg-white border mb-3">
            <div className="d-flex justify-content-between py-2 px-3 border-bottom">
                <div className="d-flex align-items-center">
                    <p className="mr-2 mt-1">Saved Boolean searches</p>
                    <DropdownWithSearch name={truncateString(queryName, 15, false) || 'Select an option'}
                        placeholder="Search"
                        items={booleanSearchData.length > 0 ? booleanSearchData
                            .map((boolean: {id: string, name: string, query: string}) => {
                                const { id } = boolean;
                                const { name } = boolean;
                                const { query } = boolean;
                                return { id, name, query };
                            }) : []}
                        handleSelectItem={handleSelectItem}
                        loading={loading}
                        emptyStateText="Saved searches will appear here"
                    />
                </div>
                <Link className="mt-1 p-0 mw-0 link-zindex-1"
                    to={{ pathname: `/situation-rooms/${room?.id}/manage-saved-boolean`,
                        state: { from: 'boolean', projectId: room.project_id } }}
                >
                    Manage saved Boolean searches
                </Link>
            </div>
            <div className="p-3">
                <h3 className="mb-2 mt-0">Search using Boolean</h3>
                <BooleanSearchContent booleanQuery={booleanQuery}
                    setBooleanQuery={handleSetBooleanQuery}
                    disabled={searching}
                    setValidationErrors={setValidationErrors}
                    validationErrors={validationErrors}
                    setInvalidQuery={setInvalidQuery}
                    emptyQuery={emptyQuery}
                />
                {emptyQuery && <p className="text-danger">Boolean query cannot be empty</p>}
            </div>
            {invalidQuery && (
                <div className="border-top py-2 px-3">
                    <Alert color="danger" className="mb-0 d-flex align-items-center set-fit-content">
                        <AlertTriangle size={20} color={color.red[200]} className="mr-2" />
                        We cannot submit your query, please visit our boolean ‘best practice’
                        link above and revise your query before resubmitting.
                        <FeatherIcon.X size={20}
                            role="button"
                            onClick={() => setInvalidQuery(false)}
                            className="ml-2"
                            data-testid="boolean-invalid-error"
                        />
                    </Alert>
                </div>
            )}
            <div className="d-flex justify-content-between border-top py-2 px-3">
                {searching ? (
                    <div className="d-flex">
                        <Loading relative />
                        <p className="set-max-content ml-4 mb-0 pt-11">We&apos;re just validating your query...</p>
                    </div>
                ) : (
                    <>
                        <Button color="link-danger"
                            className={booleanQuery ? 'pl-0' : 'invisible'}
                            onClick={() => handleResetBoolean()}
                        >Reset Boolean search
                        </Button>
                        <div>
                            <Button color="link"
                                data-testid="boolean-close"
                                className="pr-3"
                                onClick={() => setIsBooleanOpen(false)}
                            >Close
                            </Button>
                            {!queryName && <Button className="mr-3" onClick={() => handleSaveBooleanSearch()}>Save Boolean search</Button>}
                            <Button color="primary" onClick={handleSearch}>Search</Button>
                        </div>
                    </>
                )}

            </div>
        </div>
    );
};

export const GET_SAVED_BOOLEAN_SEARCH = gql`
    query getSavedBooleanSearch($situationRoom: ID!, $limit: Int, $skip: Int) {
            getSavedBooleanSearch(situationRoom: $situationRoom, limit: $limit, skip: $skip) {
                booleanSearch {
                    query
                    name
                    id
                    createdAt
                    user {
                        displayName
                        id
                    }
                }
                booleanSearchCount
            }
    }
`;
