import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ArrowLeft } from 'react-feather';
import { useLocation } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import { SearchBar } from '../../../components/Search';
import { SearchFeedback } from '../../../components/SearchFeedback';
import { EmptySearchResults } from '../../../components/EmptySearchResults';
import { getRoom } from '../../../utils/variables';
import {
    openBooleanFilters,
    resetSelectedContent,
    saveFilters,
    saveSelectedFilters,
    setSort
} from '../store';
import { useContent } from '../../../services/Content/getContent';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { getParam, setParam } from '../../../utils/urlParams';
import { ContentTableTypes, ContentTables } from '../../../components/ContentTable';
import { CONTENT_TYPE } from '../../../utils/contentTypes';
import { checkAndGetUnix } from '../../../utils/getTimeFrom';
import { SearchBarNew } from '../../../components/NewFilters';
import { ThreatSignalsVolumeOverTimeGraph } from './ThreatSignalsVolumeOverTime';

export const Threats = () => {
    const dispatch = useDispatch();
    const room = getRoom();
    const page = getParam('page') || 1;
    const limit = 10;
    const [offset, setOffsetState] = useState((parseInt(page, 10) - 1) * 10);
    const setOffset = (newOffset) => {
        setOffsetState(newOffset);
        setParam('page', (newOffset / limit) + 1);
    };
    const [selectedDate, setSelectedDate] = useState(0);
    const unixSelectedDate = checkAndGetUnix(selectedDate);
    const parseFilters = sessionStorage.getItem('initialFilters');
    const initialFilters = parseFilters ? JSON.parse(parseFilters) : null;
    const threatResolvers = ['ATTACK_ON_COMMENTER', 'ATTACK_ON_AUTHOR',
        'IDENTITY_ATTACK', 'INSULT', 'INFLAMMATORY', 'TOXICITY', 'SEVERE_TOXICITY', 'THREAT'];

    const { search } = useLocation();
    const params = search.split(/[=?,&]/);
    const startDate = params[2];
    const endDate = params[3];
    const category = params[5];
    const subcategory = params[7];

    useEffect(() => {
        if (category === 'threat_types') {
            const types = (subcategory === 'not-applicable') ? [] : [subcategory];
            const threatFilters = {
                threatTypes: subcategory === 'deep-toxic-classifier'
                    ? [...threatResolvers] : types,
                isThreat: true,
                dateRange: {
                    startDate,
                    endDate
                },
                contentType: [],
                sentiment: [],
                roomId: room.id
            };
            dispatch(saveFilters(threatFilters));
            dispatch(saveSelectedFilters(threatFilters));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, endDate, startDate, subcategory, search]);

    if (initialFilters) {
        dispatch(saveFilters({ ...initialFilters, roomId: room?.id }));
        sessionStorage.removeItem('initialFilters');
    }
    const { filters, sort } = useSelector((state) => state.filters);
    const { loading, data: contents, count, error } = useContent({
        filters: {
            isThreat: true,
            ...(selectedDate ? {
                date: {
                    startDate: filters?.dateRange?.startDate > unixSelectedDate ? filters.dateRange.startDate : unixSelectedDate,
                    endDate: unixSelectedDate + (60 * 60 * 24) - 1
                }
            } : {})
        },
        limit,
        skip: offset,
        sort,
        fetchPolicy: 'no-cache',
        skipRequest: !room.project_id,
        name: 'getThreats',
        useGlobalFilters: true
    });

    const total = error ? 0 : count;

    const sortApplied = (sortNum) => {
        const sortOptions = [
            'dateNew',
            'dateOld',
            'engagement',
            'score'
        ];
        dispatch(setSort(sortOptions[sortNum - 1]));
        setOffset(0);
    };

    const emptySearchResult = () => {
        if (!loading && contents.length === 0) {
            if (filters.convertedBooleanQuery?.length) {
                return (
                    <div>
                        <EmptySearchResults mainHeading="No search results"
                            subtitle="Your boolean query returned no results, please try again"
                            bread={<div />}
                            boolean
                            handleReset={() => {
                                dispatch(saveFilters({
                                    convertedBooleanQuery: '',
                                    roomId: room?.id
                                }));
                                dispatch(openBooleanFilters());
                            }}
                        />
                    </div>
                );
            }
            return <EmptySearchResults bread={<div />} />;
        }
    };

    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'Threats' }
    ];
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    useEffect(() => {
        dispatch(resetSelectedContent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page-padding threats">
            <BreadCrumbs items={bread} />
            {selectedDate ? (
                <Button className="d-flex align-items-center p-0 my-4" color="link" onClick={() => setSelectedDate(0)}>
                    <ArrowLeft size={20} className="mr-11" />
                    <span className="line-height-1">Back</span>
                </Button>
            ) : null}
            <div className="d-flex justify-content-between align-items-center mt-0 my-3">
                <h2>Threats {selectedDate ? `- ${moment.utc(selectedDate).format('DD/MM/YYYY')}` : ''}</h2>
                {!loading ? (
                    <div className="mt-1">
                        <SearchFeedback totalCount={total} error={error} contentType={CONTENT_TYPE.THREAT} filters />
                    </div>
                ) : ''}

            </div>
            <div className="mb-n2">
                {betaFiltersEnabled ? (
                    <SearchBarNew sortApplied={sortApplied}
                        score
                        sort
                        sortOptionKey="relevance"
                        defaultFilters={{ isThreat: true }}
                        showBooleanSearch
                    />
                )
                    : (
                        <SearchBar placeholder="Search - Enter a search term to update the threats and press ENTER"
                            name="Page Filters"
                            additionalFilters={['whyathreat']}
                            sort
                            sortApplied={sortApplied}
                            score
                            showBooleanSearch
                        />
                    )}
            </div>
            <div className="my-4">
                <ThreatSignalsVolumeOverTimeGraph totalThreatsCount={total} />
            </div>

            <div className="mt-4">
                <ContentTables contents={contents}
                    pagination={{ offset, setOffset, count }}
                    tableType={ContentTableTypes.THREATS}
                    total={total}
                    contentType={CONTENT_TYPE.THREAT}
                    contentsToDisplay={limit}
                />
            </div>
            {emptySearchResult()}
            {loading && <Loading pointer={false} gif relative height={200} />}
        </div>
    );
};
