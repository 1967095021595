import React from 'react';
import { getIcons } from '../../../../../../utils/getIcons';
import { WidgetName, WidgetTextTooltip } from '../WidgetTextTooltip';
import { unsetParam } from '../../../../../../utils/urlParams';
import { checkGrammer } from '../../../../../../utils/text/checkGrammer';
import { getRoom } from '../../../../../../utils/variables';

type CoordinationsProps = {
    count?: number
    setTab?: (a: string) => void
    onClick?: () => void
    disabled?: boolean
}

export const Coordinations = ({
    count = 10,
    setTab,
    onClick,
    disabled
}: CoordinationsProps) => {
    const room = getRoom();
    const narrativeCoordinationEnabled = !!room?.instance?.plan?.others?.narrativesCoordination;

    if (!narrativeCoordinationEnabled) return null;
    const handleClick = () => {
        if (disabled) return;
        if (onClick) return onClick();
        if (setTab) setTab('coordination');
        unsetParam('hasDuplicates');
    };

    return (
        <div className="cluster-stat-container" onClick={handleClick}>
            <WidgetTextTooltip widgetName={WidgetName.Coordination} />
            <span className="font-weight-bold d-flex align-items-center">{getIcons('coordination')}
                <p className={`m-0 ml-1 font-weight-normal ${disabled ? '' : 'btn btn-link d-inline p-0 mw-0'}`}>
                    {count} {checkGrammer('network', count > 1)}
                </p>
            </span>
        </div>
    );
};
