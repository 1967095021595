import { icon, IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

const cachedIcons: { [key: string]: { base64: string, height: number, width: number } } = {};

export const getIconBase64 = (iconKey: string, fontFamily: string): { base64?: string, height?: number, width?: number } => {
    let prefix: 'fas' | 'fab';
    if (fontFamily === 'Font Awesome 6 Brands') {
        prefix = 'fab';
    } else if (fontFamily === 'Font Awesome 6 Free') {
        prefix = 'fas';
    } else {
        return {};
    }

    // Check if the icon is cached
    if (cachedIcons[iconKey]) {
        return cachedIcons[iconKey];
    }

    const iconName = iconKey.replace(/^fa-/, '') as IconName;

    // Add the icon libraries to the library
    library.add(fas, fab);

    // Retrieve the icon
    const faIcon = icon({ prefix, iconName });

    // If the icon is not found, return an empty object
    if (!faIcon) {
        return {};
    }

    let svg = faIcon.html[0];

    // Extract the viewBox to get original width and height
    const viewBoxMatch = svg.match(/viewBox="([\d\s]+)"/);
    let viewBox = '0 0 512 512';
    if (viewBoxMatch) {
        viewBox = viewBoxMatch[1];
    }

    const [, , originalWidth, originalHeight] = viewBox.split(' ').map(Number);

    // Replace the width and height in the SVG
    svg = svg.replace('<svg', `<svg width="${originalWidth}" height="${originalHeight}"`);

    // Apply white fill if not already set
    if (!svg.includes('fill=')) {
        svg = svg.replace('<svg', '<svg fill="white"');
    } else {
        svg = svg.replace(/fill="currentColor"/g, 'fill="white"');
    }

    // Convert the SVG to base64
    const base64 = `data:image/svg+xml;base64,${btoa(svg)}`;

    // Cache the icon data
    cachedIcons[iconKey] = { base64, height: originalHeight, width: originalWidth };

    // Return the cached icon data
    return cachedIcons[iconKey];
};
