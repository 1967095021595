import * as FeatherIcon from 'react-feather';

export const routes = {
    loggedOut: {
        login: '/login',
        ssoAuth: '/sso-auth',
        userDeleted: '/user-deleted',
        resetPassword: '/reset-password',
        signUpInvite: '/signup/:invite'
    },
    loggedIn: {
        base: '/',
        teams: '/team',
        team: '/team/:uid',
        profile: '/profile',
        signUpInvite: '/signup/:invite',
        situationRooms: '/situation-rooms',
        situationRoomCreate: '/situation-room/create-room',
        situationRoomDraftCreate: '/situation-room/create-room/:draftId',
        situationRoomDraftEdit: '/situation-room/edit-draft-room/:draftId',
        situationRoomEdit: '/situation-room/edit-room/:roomId',
        createCustomNarrative: '/situation-room/:roomId/create-custom-narrative',
        notifications: '/notifications',
    },
    situationRoom: {
        base: '/situation-rooms/:situation',
        overview: '/situation-rooms/:situation/overview',
        matches: '/situation-rooms/:situation/overview/matches',
        threats: '/situation-rooms/:situation/threats',
        threat: '/situation-rooms/:situation/threats/:id',
        match: '/situation-rooms/:situation/matches/:id',
        customNarratives: '/situation-rooms/:situation/custom-narratives',
        customNarrative: '/situation-rooms/:situation/custom-narrative/:narrative',
        factChecks: '/situation-rooms/:situation/fact-checks',
        factCheck: '/situation-rooms/:situation/fact-checks/:factcheck',
        settings: '/situation-rooms/:situation/settings',
        reportsCreate: '/situation-rooms/:situation/report/create-report',
        reports: '/situation-rooms/:situation/reports',
        watchlist: '/situation-rooms/:situation/watchlist',
        reportsSaved: '/situation-rooms/:situation/report',
        aiClustering: '/situation-rooms/:situation/ai-narratives',
        aiNarrativeComparision: '/situation-rooms/:situation/ai-narratives/narrative-comparison',
        aiNarrativeThemeComparision: '/situation-rooms/:situation/ai-narratives/narrative-theme-comparison',
        aiNarrativesContent: '/situation-rooms/:situation/ai-narratives/:type/:id/content',
        aiNarrativesThemeDetails: '/situation-rooms/:situation/ai-narratives/narrative-theme/:id',
        aiNarrativesDetails: '/situation-rooms/:situation/ai-narratives/narrative/:id',
        networks: '/situation-rooms/:situation/networks',
        locationsMap: '/situation-rooms/:situation/location-mentions',
        manageSavedFilters: '/situation-rooms/:roomId/manage-saved-filters',
        manageSavedBoolean: '/situation-rooms/:roomId/manage-saved-boolean',
    },
    admin: {
        base: '/admin',
        instances: '/admin/instances',
        instance: '/admin/instances/:id',
        users: '/admin/users',
        allUsers: '/admin/allusers',
        allUsersUser: '/admin/allusers/:id',
        investigation: '/admin/investigation/:id',
        situationRooms: '/admin/situation-rooms',
        situationRoom: '/admin/situation-rooms/:id',
        cTypes: '/admin/ctypes',
        cType: '/admin/ctypes/:id',
        contentPreview: '/admin/content-preview',
    }
};
export const topBarRoutesLinks = [
    {
        path: '/situation-rooms',
        name: 'Situation Rooms',
    },
    {
        path: '/team',
        name: 'Team',
    }
];
export const sidebarRoutesLinks = [
    {
        path: '/overview',
        id: 'overview',
        name: 'Overview',
        icon: FeatherIcon.Compass,
        header: 'Overview',
    },
    {
        path: '/threats',
        name: 'Threats',
        id: 'threats',
        icon: FeatherIcon.AlertTriangle,
    },
    {
        path: '/custom-narratives',
        name: 'Custom Narratives',
        id: 'custom-narratives',
        icon: FeatherIcon.Airplay,
    },
    {
        path: '/ai-narratives',
        name: 'AI Narratives',
        id: 'aiClustering',
        icon: FeatherIcon.Codesandbox,
    },
    {
        path: '/networks',
        name: 'Networks',
        id: 'networks',
        icon: FeatherIcon.Share2,
    },
    {
        path: '/fact-checks',
        name: 'Fact Checks',
        id: 'logicallyFactChecks',
        icon: FeatherIcon.CheckCircle,
    },
    {
        path: '/location-mentions',
        name: 'Location mentions',
        id: 'locationMentions',
        icon: FeatherIcon.Globe,
    }
];
export const integrationsRoutesLinks = [
    {
        path: '/report',
        name: 'Reports',
        id: 'reports',
        icon: FeatherIcon.FileText,
    },
    {
        path: '/watchlist',
        name: 'Watchlist',
        id: 'watchlist',
        icon: FeatherIcon.Star,
    }
];

export const configureRoutesLinks = [
    {
        path: '/settings',
        name: 'Room Settings',
        id: 'roomSettings',
        icon: FeatherIcon.Sliders,
    }
];
export const adminRoutesLinks = [
    {
        path: '/admin',
        name: 'Admin dashboard',
    }
];

export const adminSidebarRoutes = [
    {
        path: '/admin/instances',
        name: 'Instances',
        id: 'instances',
        icon: FeatherIcon.Layout
    },
    {
        path: '/admin/allusers',
        name: 'Users',
        id: 'users',
        icon: FeatherIcon.Users
    },
    {
        path: '/admin/users',
        name: 'Super admin users',
        id: 'superAdminUsers',
        icon: FeatherIcon.UserPlus
    },
    {
        path: '/admin/situation-rooms',
        name: 'Situation rooms',
        id: 'situationRooms',
        icon: FeatherIcon.Grid
    },
    {
        path: '/admin/ctypes',
        name: 'Source mapping',
        id: 'sourceMapping',
        icon: FeatherIcon.Anchor
    },
    {
        path: '/admin/content-preview',
        name: 'Preview exclusions',
        id: 'ContentPreviewExclusions',
        icon: FeatherIcon.EyeOff
    }
];

export const minimalRoomLayout = [
    'situationRoom_aiNarrativeThemeComparision',
    'situationRoom_aiNarrativeComparision',
    'situationRoom_manageSavedFilters',
    'situationRoom_manageSavedBoolean'
];
