import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useUser } from '../../../../context/user/UserContext';
import { getRoom } from '../../../../utils/variables';

export const AddNewNarrativeButton = ({ className, color }) => {
    const room = getRoom();
    const { notAllowed } = useUser();
    if (notAllowed.includes('manageCustomNarratives')) { return null; }
    return (
        <div className={className}>
            <Link to={{ pathname: `/situation-room/${room.id}/create-custom-narrative`, state: { roomId: room.id } }}>
                <Button color={color}>Create custom narrative</Button>
            </Link>
        </div>
    );
};
