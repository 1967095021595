import React, { useState } from 'react';
import { SmallPagination } from '../../../../../components/Molecules';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import { ThreatItem } from './ThreatItem';
import { Loading } from '../../../../../components/Loading';
import { checkFilters } from './index';
import { useContent } from '../../../../../services/Content/getContent';

export const ThreatsList = ({ state, threat, projectId, sort }) => {
    const { series, selection, startDate, endDate } = state;
    const { threat: name } = threat;
    const color = series
        ? series.filter((a) => a?.name === threat.threat)[0]?.color
        : '#333';
    const [page, setPage] = useState(0);
    const query = checkFilters(threat, 'EsQuery', startDate, endDate, projectId, selection);

    const selectionDate = selection ? selection / 1000 : null;

    const { data: content, loading, count } = useContent({
        limit: 10,
        skip: page * 10,
        sort,
        dslQuery: query,
        filters: {
            date: {
                startDate: selectionDate || startDate,
                endDate: selectionDate ? (selectionDate + (60 * 60 * 12)) : endDate
            }
        }
    });

    if (loading) {
        return (
            <Loading relative height={300} />
        );
    }

    return (
        <div key={name} className="content-deck">
            <div className="content-deck-header">
                <div style={{ background: `${color}` }} className="content-deck-header-color" />
                <h3>{capitalizeFirstLetter(name)} ({count && count})</h3>
            </div>
            <div className="content-wrapper">
                {content && content.map((threatItem) => (
                    <div key={threatItem._id}>
                        <ThreatItem threat={threatItem} />
                    </div>
                ))}
            </div>
            <div className="content-wrapper mt-1">
                <div className="custom-narrative-pagination">
                    <SmallPagination total={count || 0}
                        itemsPerPage={10}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            </div>
        </div>
    );
};
