import { useMemo } from 'react';
import { CoordinationDataType } from './coordinationDataTypes';

type SortType = {
    field: string;
    order: string;
    name: string;
};

type UseSortedActorsTypes = {
    coordinationData?: CoordinationDataType[];
    sort?: SortType;
    header?: string;
    loading?: boolean
};

export const useSortedActors = ({ coordinationData, sort, header, loading }: UseSortedActorsTypes) => {
    const sortedActors = useMemo(() => {
        if (coordinationData?.length) {
            const occurrences: Record<string, {
                text: string; count: number; ctype: string;
}> = {};
            coordinationData?.flatMap((x) => x.network).forEach((item) => {
                if (item.from) {
                    occurrences[item.from] = occurrences[item.from] || { count: 0, ctype: item.ctype, text: item.text };
                    occurrences[item.from].count += 1;
                }
            });

            return Object.entries(occurrences)
                .sort((a, b) => b[1].count - a[1].count)
                .map(([key, value]) => ({ from: key, count: value.count, ctype: value.ctype, text: value.text }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coordinationData, loading]);
    const sortedActorsData = useMemo(() => {
        if (!coordinationData) return [];

        return sortedActors?.map((actor) => {
            const allData = coordinationData.filter((a) => a.network.find((b) => b.from === actor.from));
            const pubtime = allData.flatMap((a) => a.network.map((b) => b.pubdatetime));
            const ttps = allData.map((a) => a.id);
            return { ...actor, ttps, pubtime, allData };
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedActors, coordinationData, loading]);
    const uniqueActors = (item: any[], key: string) =>
        Array.from(new Map(item?.map((entry) => [entry[key], entry])).values());
    const getRelevantData = (item: any, sortfield: string) => {
        if (item.allData) {
            return item.allData;
        } if (sortfield) {
            return item[sortfield];
        }
        return item;
    };
    const sortFn = useMemo(() => (a: (any), b: (any)) => {
        if (header === 'Coordination_actors') {
            if (sort?.field === 'count') {
                return sort.order === 'desc' ? b.ttps.length - a.ttps.length : a.ttps.length - b.ttps.length;
            }
            if (sort?.field === 'from') {
                const uniqueA = uniqueActors(getRelevantData(a, sort?.field), sort.field);
                const uniqueB = uniqueActors(getRelevantData(b, sort?.field), sort.field);
                return sort.order === 'desc' ? uniqueB.length - uniqueA.length : uniqueA.length - uniqueB.length;
            }

            if (sort?.field === 'network') {
                return sort.order === 'desc' ? b.count - a.count : a.count - b.count;
            }

            if (sort?.field === 'creation_time') {
                const firstTimeA = Math.min(...a.pubtime.map(Number));
                const firstTimeB = Math.min(...b.pubtime.map(Number));
                return sort.order === 'desc' ? firstTimeB - firstTimeA : firstTimeA - firstTimeB;
            }
        }

        if (sort?.field === 'network') {
            if (a.allData.length) {
                return sort.order === 'desc' ? b.allData.length - a.allData.length : a.allData.length - b.allData.length;
            }
            return sort.order === 'desc' ? b.network.length - a.network.length : a.network.length - b.network.length;
        }

        if (sort?.field === 'from') {
            const uniqueA = uniqueActors(getRelevantData(a, sort?.field), sort.field);
            const uniqueB = uniqueActors(getRelevantData(b, sort?.field), sort.field);
            return sort.order === 'desc' ? uniqueB.length - uniqueA.length : uniqueA.length - uniqueB.length;
        }

        return 0;
    }, [sort, header]);

    return { sortedActorsData, sortFn, uniqueActors };
};
