import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyState } from '../../../../components/EmptyState';
import { useGetWatchlist } from '../../../../services/Threat/getWatchlist';
import NotFoundFC from '../../../../assets/images/noFactChecks.svg';
import { Loading } from '../../../../components/Loading';
import { SearchBar } from '../../../../components/Search';
import { getRoom } from '../../../../utils/variables';
import { ThreatsWatchlistCsv } from './ThreatsWatchlistCsv';
import { ContentTableTypes, ContentTables } from '../../../../components/ContentTable';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';
import { SearchBarNew } from '../../../../components/NewFilters';
import { signalsList } from '../../../../components/NewFilters/FiltersList/ThreatTypesFilter/ThreatTypesFilter';
import { setSort } from '../../store';

const initialState = {
    dateRangeOption: 'all',
    dateRange: {},
    sources: [],
    origin: [],
    sentiment: [],
    threatTypes: [],
    query: '',
    languages: [],
    from: [],
    keywords: [],
    labels: [],
    entities: [],
    hashtags: [],
    mentions: [],
    urls: [],
    domains: [],
};

export const WatchListThreats = () => {
    const [offset, setOffset] = useState(0);
    const match = useRouteMatch();
    const room = useReactiveVar(getRoom);
    const finalFilters = useSelector((state) => state.watchlistFilters.filters);
    const { filters: { dateRange }, sort } = useSelector((state) => state.filters);
    const dispatch = useDispatch();
    const filters = {
        ...finalFilters,
        sentiment: finalFilters.sentiment || [],
        sources: finalFilters.sources || [],
        languages: finalFilters.languages || [],
        from: finalFilters.from || [],
        keywords: finalFilters.keywords || [],
        labels: finalFilters.labels || [],
        origin: finalFilters.origin || [],
        threatTypes: finalFilters.threatTypes || [],
        entities: finalFilters.entities || [],
        hashtags: finalFilters.hashtags || [],
        mentions: finalFilters.mentions || [],
        sourcesWithDomains: finalFilters?.sourcesWithDomains || [],
        urls: finalFilters?.urls || [],
        domains: finalFilters?.domains || [],
        coordinationTypes: finalFilters?.coordinationTypes || [],
    };
    let filteredContentData;
    const [types] = useState([
        'fn-type',
        'deep-toxic-classifier',
        'src-cred',
        'automation_score',
        'custom',
        'account_based'
    ]);

    const idsFilter = filters.coordinationTypes.map(({ ids }) => ids).flat();

    const { watchlist, loading, contentIds } = useGetWatchlist({
        situationRoomId: match.params.situation,
        getFullContent: true,
        sort,
        idsFilter
    });
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    const sortApplied = (sortNum) => {
        const sortOptions = [
            'dateNew',
            'dateOld',
            'engagement',
            'score'
        ];
        dispatch(setSort(sortOptions[sortNum - 1]));
        setOffset(0);
    };

    if (watchlist && (filters === initialState)) {
        filteredContentData = watchlist;
    } else if (watchlist) {
        let tempData;
        // Filter by date
        if (dateRange?.startDate) {
            const startDate = new Date(dateRange.startDate * 1000);
            const endDate = new Date(dateRange.endDate * 1000);
            tempData = watchlist.filter(
                watchlistItem => new Date(watchlistItem.pubdatetime * 1000) > startDate
                    && new Date(watchlistItem.pubdatetime * 1000) < endDate
            );
        }
        // Filter by source
        if (filters.sources?.length > 0 || filters.sourcesWithDomains?.length > 0) {
            if (betaFiltersEnabled) {
                tempData = (tempData || watchlist).filter(
                    watchlistItem => filters.sources.some((b) => b === watchlistItem.contentType)
                );
            } else {
                tempData = (tempData || watchlist).filter(
                    watchlistItem => filters.sourcesWithDomains.some((b) => b.key === watchlistItem.contentType)
                );
            }
        }
        // Filter by sentiment
        if (filters.sentiment.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.sentiment.some((b) => b === watchlistItem.sentiment)
            );
        }
        // Filter by origin
        if (filters.origin.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.origin.some((b) => b === watchlistItem.locationsDetected[0])
            );
        }
        // Filter by query string
        if (filters.query?.trim() !== '') {
            tempData = (tempData || watchlist).filter(
                watchlistItem => (watchlistItem.body?.length > 0 ? watchlistItem.body : watchlistItem.text).includes(filters.query)
            );
        }
        // Filter by languages
        if (filters.languages.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.languages.some((b) => b === watchlistItem.langVerbose)
            );
        }
        // Filter by publishers
        if (filters.from.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.from.some((b) => b === watchlistItem.from)
            );
        }
        // Filter by keywords
        if (filters.keywords.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.keywords.some((b) => watchlistItem.keywords.includes(b))
            );
        }
        // Filter by entities
        if (filters.entities.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.entities.some((b) => watchlistItem.entities.includes(b))
            );
        }
        // Filter by mentions
        if (filters.entities.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.mentions.some((b) => watchlistItem.mentions.includes(b))
            );
        }
        // Filter by hashtags
        if (filters.hashtags.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.hashtags.some((b) => watchlistItem.hashtags.includes(b))
            );
        }
        // Filter by labels
        if (filters.labels.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.ids.some((b) => b === watchlistItem.id)
            );
        }
        // Filter by Urls
        if (filters.urls.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.urls.some((b) => watchlistItem.extractedUrls.includes(b))
            );
        }
        // Filter by Domains
        if (filters.domains.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => filters.domains.some((b) => watchlistItem.domain === b)
            );
        }
        // Filter by threat type

        if (filters.threatTypes.length > 0) {
            if (betaFiltersEnabled) {
                const filterLabels = signalsList.filter((a) => !a.isResolver && (filters.threatTypes.indexOf(a.key) !== -1));
                const filterResolvers = signalsList.filter((a) => a.isResolver && (filters.threatTypes.indexOf(a.key) !== -1));
                let finalLabelData = [];
                let finalResolverData = [];
                if (tempData) {
                    if (filterLabels.length > 0) {
                        finalLabelData = tempData.filter(
                            watchlistItem => filterLabels.some((b) => watchlistItem?.threatObject?.threatLabels?.some((c) => b.key === c))
                        );
                    }
                    if (filterResolvers.length > 0) {
                        finalResolverData = tempData.filter(
                            watchlistItem => filterResolvers.some((b) =>
                                watchlistItem.threatObject?.threatResolvers?.some((c) => b.key === c.resolver))
                        );
                    }

                    tempData = [...finalLabelData, ...finalResolverData];
                } else {
                    if (filterLabels.length > 0) {
                        finalLabelData = watchlist.filter(
                            watchlistItem => filterLabels.some((b) => watchlistItem.threatObject.threatLabels?.some((c) => b.key === c))
                        );
                    }
                    if (filterResolvers.length > 0) {
                        finalResolverData = watchlist.filter(
                            watchlistItem => filterResolvers.some((b) =>
                                watchlistItem.threatObject.threatResolvers?.some((c) => b.key === c.resolver))
                        );
                    }
                    tempData = [...finalLabelData, ...finalResolverData];
                }
            } else {
                const filterTypes = types.filter((a) => filters.threatTypes.indexOf(a) !== -1);
                if (tempData) {
                    tempData = tempData.filter(
                        watchlistItem => !filterTypes.some((b) => watchlistItem.threatObject.threatLabels.some((c) => b === c))
                    );
                } else {
                    tempData = watchlist.filter(
                        watchlistItem => !filterTypes.some((b) => watchlistItem.threatObject.threatLabels.some((c) => b === c))
                    );
                }
            }
        }

        filteredContentData = tempData || watchlist;
    }

    const linkToThreats = { name: 'View threats', to: `/situation-rooms/${room.id}/threats` };

    if (loading && watchlist.length === 0) {
        return (
            <div className="minh-200 position-relative" data-testid="threats-watchlist">
                <Loading gif relative height={400} />
            </div>
        );
    } if (watchlist.length === 0) {
        return (
            <div data-testid="threats-watchlist">
                <EmptyState title="No threats added to watchlist"
                    summary="Check threats section and use 'Add to watchlist' button to add the threats to this space"
                    icon={NotFoundFC}
                    action={linkToThreats}
                />
            </div>
        );
    }

    return (
        <div data-testid="threats-watchlist">
            <div className="d-flex">
                <div className="flex-fill">
                    {betaFiltersEnabled ? (
                        <SearchBarNew sortApplied={sortApplied}
                            score
                            sort
                            contentIds={contentIds}
                            watchlist
                        />
                    ) : (
                        <SearchBar placeholder="Search threats"
                            name="Page Filters"
                            data={room}
                            additionalFilters={['whyathreat']}
                            sort
                            sortApplied={sortApplied}
                            watchlist
                        />
                    )}
                </div>
                <ThreatsWatchlistCsv contents={filteredContentData} />
            </div>
            {filteredContentData.length > 0 ? (
                <ContentTables contents={filteredContentData.slice(offset, offset + 10)}
                    pagination={{ offset, setOffset, count: filteredContentData?.length || 0 }}
                    tableType={ContentTableTypes.WATCHLIST}
                    contentType={CONTENT_TYPE.THREAT}
                    total={filteredContentData.length}
                    allContents={filteredContentData}
                />
            ) : (
                <EmptyState title="No threats found in the watchlist"
                    summary="We can’t find any items matching your search"
                    icon={NotFoundFC}
                    action={linkToThreats}
                />
            )}
        </div>
    );
};
