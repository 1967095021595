import { gql, useQuery } from '@apollo/client';
import { merge, omit } from 'lodash';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useTopSharedUrls = ({
    dateFilter = undefined,
    size = 10,
    clusters = false,
    appliedFilters = undefined,
    searchValue = ''
} = {}) => {
    const filters = useContentFilters({ useGlobalFilters: true, defaultFilters: { date: dateFilter } });
    const sanitizedAppliedFilters = appliedFilters?.filters
        ? omit(appliedFilters.filters, ['urls'])
        : {};
    const ret = useQuery(TOP_SHARED_URLS, {
        variables: {
            ...filters,
            filters: omit(merge(filters.filters, sanitizedAppliedFilters), ['labels']),
            size,
            searchValue
        },
        skip: clusters
    });

    return {
        ...ret,
        data: ret.data?.topSharedURLs?.map(item => ({
            ...item,
            key: item.url
        })) || []
    };
};

export const TOP_SHARED_URLS = gql`
    query topSharedURLs(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $size: Int
        $searchValue: String
    ) {
        topSharedURLs(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            size: $size
            searchValue: $searchValue
        ) {
            url
            count
        }
    }
`;
