import React, { useMemo, useState } from 'react';
import { CoordinationDataType, SingleNetworkProps } from '../../CoordinatedModals/coordinationDataTypes';
import { getParam, setParam, unsetParam } from '../../../../../utils/urlParams';
import { getHeaderName, getWidgetName } from '../../../../../utils/coordinatedTypes';
import { CoordinatedDetail } from '../../CoordinatedModals/CoordinatedDetail';
import { SimilarPostingPattern } from '../../CoordinatedModals/SimilarPostingPattern';
import { checkGrammer } from '../../../../../utils/text/checkGrammer';
import { SingleCoordination } from '../../../../../components/Organisms/ContentTableView/ContentCoorindation/SingleCoordination';
import { WidgetName, WidgetTextTooltip } from '../Summary/WidgetTextTooltip';
import { BreadCrumbs } from '../../../../../components/BreadCrumbs';

type CoordinatedListProps = {
    coordinationData: CoordinationDataType[];
}

export const getDates = (networkData?: SingleNetworkProps[]) => {
    const sortedData = [...(networkData || [])].sort(
        (a: SingleNetworkProps, b: SingleNetworkProps) => {
            const dateA = new Date(a.pubdatetime);
            const dateB = new Date(b.pubdatetime);
            return dateB.getTime() - dateA.getTime(); // Compare timestamps
        }
    );
    const endDate = sortedData?.[0]?.pubdatetime || new Date();
    const startDate = sortedData?.[sortedData.length - 1].pubdatetime ?? new Date();
    return { startDate, endDate };
};

export const CoordinatedList = ({ coordinationData }: CoordinatedListProps) => {
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [networkType, setNetworkType] = useState('');
    const [selectedNetworkData, setSelectedNetworkData] = useState<SingleNetworkProps[]>();

    const handleViewDetails = (item: CoordinationDataType) => {
        const networkData = [...(item.network || [])];
        setSelectedNetwork(item.coordinationSource);
        setSelectedNetworkData(networkData.sort(
            (a: SingleNetworkProps, b: SingleNetworkProps) => {
                const dateA = new Date(a.pubdatetime);
                const dateB = new Date(b.pubdatetime);
                return dateB.getTime() - dateA.getTime(); // Compare timestamps
            }
        ));
        setNetworkType(item.coordinationType);
        setParam('coordinationId', item.id);
    };
    const generateBreadcrumbs = () => {
        const breadcrumbs = [];
        if (getParam('subTab')) {
            const type = getParam('subTab');
            breadcrumbs.push({
                title: type ? type.replace(/_/g, ' ') : '',
                onClick: () => {
                    unsetParam('coordinationId');
                    setSelectedNetwork('');
                    setNetworkType('');
                }
            });
        }
        if (getParam('coordinationId')) {
            const id = getParam('coordinationId');
            breadcrumbs.push({
                title: id ? id.replace(/([a-z])([A-Z])/g, '$1 $2') : '',
            });
        }
        delete breadcrumbs[breadcrumbs.length - 1]?.onClick;
        return breadcrumbs;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const bread = useMemo(() => generateBreadcrumbs(), [selectedNetwork]);

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <BreadCrumbs items={bread} />
            </div>
            <hr className="m-0" />
            {selectedNetwork && networkType !== 'similar_post_pattern'
                && (
                    <CoordinatedDetail
                        selectedCoordination={selectedNetwork}
                        singleNetworkData={selectedNetworkData}
                        header={getHeaderName(networkType)}
                    />
                )}
            {selectedNetwork && networkType === 'similar_post_pattern'
                && (
                    <SimilarPostingPattern data={selectedNetworkData}
                        header={getHeaderName(networkType)}
                        startDate={getDates(selectedNetworkData).startDate}
                    />
                )}
            {!selectedNetwork && (
                <>
                    <div className="d-flex align-items-center mt-3 mb-4">
                        <h3 className="my-0 mr-1 widget-text-style">
                            <WidgetTextTooltip noMargin
                                widgetName={WidgetName[getWidgetName(coordinationData[0].coordinationType) as
                                                                                   keyof typeof WidgetName]}
                            />
                        </h3>
                        <div className="bg-secondary-alt go-back-investigations rounder-4 text-white p-1 max">
                            {coordinationData?.length} {checkGrammer('coordination', coordinationData?.length > 1)}
                        </div>
                    </div>
                    <SingleCoordination coordinationData={coordinationData} handleViewDetails={handleViewDetails} />
                </>
            )}
        </div>

    );
};
