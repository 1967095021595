import { convertToRaw } from 'draft-js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useState } from 'react';
import { useUser } from '../../context/user/UserContext';
import { getParam, setParam } from '../../utils/urlParams';
import { getRoom } from '../../utils/variables';
import { useCreateReport } from './createReport';
import { useUpdateReport } from './updateReport';
import { getReportPages } from '../../pages/Room/Reports/CreateReport/Export';
import { scaleH } from '../../pages/Room/Reports/CreateReport/Export/ExportAsPDF';
import { useFileUploadSignedURL } from '../google/getFileUploadSignedURL';
import { getToast } from '../../utils/getToast';

export const useSaveReport = () => {
    const { createReport, loading, data } = useCreateReport();
    const { updateReport, loading: loadingB } = useUpdateReport();
    const { getSignedUrl, loading: loadingSignedUrl } = useFileUploadSignedURL();
    const toast = getToast();
    const [loadingPdf, setLoadingPdf] = useState(false);
    const { user } = useUser();
    const paramId = getParam('id');
    if (data && data.id !== paramId) {
        setParam('id', data.id);
    }
    return {
        saveReport: async (report) => {
            try {
                setLoadingPdf(true);
                const reportPDF = await generateReportPDF(report);
                const filename = `${report.name}_${Date.now()}.pdf`;
                const signedUrlData = await getSignedUrl({
                    filename,
                    contentType: reportPDF.type,
                    feature: 'reports'
                });
                const signedUrl = signedUrlData.data?.getFileUploadSignedURL;
                await fetch(signedUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': reportPDF.type,
                    },
                    body: reportPDF,
                });
                setLoadingPdf(false);
                const variables = createReportVariables(report, user, paramId, filename);
                if (paramId) {
                    updateReport(variables);
                } else {
                    createReport(variables);
                }
            } catch {
                setLoadingPdf(false);
                toast.error('Report not saved, Please try again');
            }
        },
        loading: loading || loadingB || loadingPdf || loadingSignedUrl
    };
};

const generateReportPDF = async (report) => {
    const pagesToExport = getReportPages(report);
    const allPages = pagesToExport.map((page) => new Promise((res) => {
        const element = document.getElementById(`page-${page.id}`);
        html2canvas(element, { scale: 2.5 }).then((canvas) => {
            const imgData = canvas.toDataURL();
            res({ imgData, h: element.getAttribute('clientheight') });
        });
    }));

    const generatedPages = await Promise.all(allPages);
    const pdf = jsPDF('l', 'mm', [776, 438], true);
    for (let i = 0; i < generatedPages.length; i++) {
        const { imgData, h } = generatedPages[i];
        const pageHeight = scaleH(h);
        if (i !== 0) { pdf.addPage([776, pageHeight]); }
        pdf.addImage(imgData, 'JPEG', 0, 0, 776, pageHeight);
    }
    return new Blob([pdf.output('blob')], { type: 'application/pdf' });
};

const createReportVariables = (report, user, id, fileName) => {
    const room = getRoom();
    return {
        data: {
            ...report,
            ...(id ? {
                id,
                updatedAt: new Date()
            } : {
                createdAt: new Date()
            }),
            situationRoom: room.id,
            createdBy: user.id,
            updatedBy: user.id,
            pages: report.pages.map(page => ({
                ...page,
                ...(page.heading ? {
                    heading: convertToRaw(page.heading.getCurrentContent())
                } : {}),
                ...(page.subtitle ? {
                    subtitle: convertToRaw(page.subtitle.getCurrentContent())
                } : {}),
                ...(page.pageTitle ? {
                    pageTitle: convertToRaw(page.pageTitle.getCurrentContent())
                } : {}),
                ...(page.bodyText ? {
                    bodyText: convertToRaw(page.bodyText.getCurrentContent())
                } : {}),
                ...(page.footer ? {
                    footer: convertToRaw(page.footer.getCurrentContent())
                } : {})
            })),
            ...(report.seenBy ? {
                seenBy: report.seenBy.map(a => ({
                    ...a,
                    user: a.user.id
                }))
            } : {}),
            template: report.template.id,
            fileName
        }
    };
};
