import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { AlertTriangle } from 'react-feather';
import _, { capitalize } from 'lodash';
import { useQuery } from '@apollo/client';
import { ItemProps } from '../DropdownWIthMultiCheckbox';
import { SelectedFilter } from './SelectedFilter';
import { resetFilters, resetWatchlistFilters, resetSelectedFilters, resetClusterFilters } from '../../../../pages/Room/store';
import { RootState } from '../../../../store';
import { color } from '../../../../utils/getColors';
import { getCtype } from '../../../../utils/text';
import { GET_CONTENT_LABELS } from '../../../../pages/Room/Labels';
import { getRoom } from '../../../../utils/variables';
import { SelectedBooleanSearch } from './SelectedBooleanSearch';
import { signalsList } from '../../FiltersList/ThreatTypesFilter/ThreatTypesFilter';
import { getWidgetName } from '../../../../utils/coordinatedTypes';
import { WidgetName } from '../../../../pages/Room/AIClustering/SelectedClusterView/Summary/WidgetTextTooltip';

type SelectedFiltersProps = {
    start_date: number,
    setFilterName?: (e: string) => void,
    edit?: boolean,
    display?: boolean
    contentIds?: string[],
    watchlist?: boolean,
    clusters?: boolean,
    saveFilterError?: boolean
};

export const SelectedFilters = ({
    start_date,
    setFilterName,
    edit = false,
    display = false,
    contentIds,
    watchlist = false,
    clusters = false,
    saveFilterError = false
}: SelectedFiltersProps) => {
    const room = getRoom();
    const labelsFeatureEnabled = !!room.instance?.plan?.others?.labels;

    const { data: labelsData } = useQuery(GET_CONTENT_LABELS, {
        variables: {
            situationRoom: room?.id
        },
        fetchPolicy: 'cache-and-network',
        skip: !labelsFeatureEnabled || clusters
    });
    const finalData = labelsData ? labelsData.getContentLabels : [];
    const handleIdsList = (id: string) => finalData.filter(({ label }: {label: string}) =>
        label === id).map((a: ItemProps) => a.contentIds);
    const dispatch = useDispatch();
    const {
        keywords: selectedKeywords,
        languages: selectedLanguages,
        contentType: selectedSources,
        from: selectedActors,
        sentiment: selectedSentiment,
        labels: selectedLabels,
        dateRange: selectedDateRange,
        threatTypes: selectedThreatTypes,
        origin: selectedLocations,
        entities: selectedEntities,
        mentions: selectedMentions,
        hashtags: selectedHashtags,
        booleanQuery: selectedBoolean,
        urls: selectedUrls,
        domains: selectedDomains,
        coordinationTypes: selectedCoordinationTypes

    } = useSelector((state: RootState) => state.selectedFilters.filters);

    const clearAllFilters = () => {
        dispatch(resetSelectedFilters());
        if (setFilterName) {
            setFilterName('Filters');
            if (watchlist) {
                return dispatch(resetWatchlistFilters()); }
            if (clusters) {
                return dispatch(resetClusterFilters());
            }
            return dispatch(resetFilters());
        }
    };

    const arrayFilters = {
        dateRange: [],
        ThreatType: selectedThreatTypes.map(threatType => ({ id: threatType,
            name: signalsList.filter(a => a.key === threatType)[0]?.name,
            selected: true })),
        Actors: selectedActors.map(actor => ({ id: actor, name: actor, selected: true })),
        Keywords: selectedKeywords.map(word => ({ id: word, name: word, selected: true })),
        Languages: selectedLanguages.map(lang => ({ id: lang, name: capitalize(lang), selected: true })),
        LocationMentions: selectedLocations.map(loc => ({ id: loc, name: loc, selected: true })),
        Sources: selectedSources.map(source => {
            const { name } = getCtype(source);
            return { id: source, name, selected: true };
        }),
        Labels: selectedLabels.map((label) => ({ id: label,
            name: capitalize(label),
            selected: true,
            contentIds: handleIdsList(label) })),
        Sentiment: selectedSentiment.map((sentiment) => ({ id: sentiment, name: capitalize(sentiment), selected: true })),
        Entities: selectedEntities.map((entity) => ({ id: entity, name: capitalize(entity), selected: true })),
        Hashtags: selectedHashtags.map((hashtag) => ({ id: hashtag, name: capitalize(hashtag), selected: true })),
        Mentions: selectedMentions.map((mention) => ({ id: mention, name: capitalize(mention), selected: true })),
        Urls: selectedUrls.map(url => ({ id: url, name: url, selected: true })),
        Domains: selectedDomains.map(domain => ({ id: domain, name: domain, selected: true })),
        Coordinations: selectedCoordinationTypes.map(coordinationType => ({
            id: coordinationType.name,
            name: WidgetName[getWidgetName(coordinationType.name) as keyof typeof WidgetName],
            selected: true
        }))
    };

    const isEmpty = !(Object.values(arrayFilters).map((a: ItemProps[]) => a.find(b => b.selected))).some(item => item !== undefined);

    const dateCheck = !clusters || _.isEqual({
        startDate: 0,
        endDate: 0,
    }, selectedDateRange);

    const returnHeading = () => {
        if (display) {
            if ((!isEmpty || !dateCheck) && !selectedBoolean) {
                return 'Selected filters';
            } if (isEmpty && dateCheck && selectedBoolean) {
                return 'Selected Boolean search';
            } return 'Selected filters and Boolean search';
        } return 'Selected filters';
    };

    if (!edit && isEmpty && dateCheck && !selectedBoolean) {
        return null;
    }

    return (
        <div className={`${display ? 'border p-3 mb-3' : ''} bg-white selected-filters-apply scrollbar-small`}>
            <div className="d-flex flex-column align-items-start">
                <div className="d-flex mb-2">
                    <strong>{returnHeading()}</strong>
                    {(!isEmpty || !dateCheck) && <Button color="link" className="p-0" onClick={clearAllFilters}>Clear all</Button>}
                </div>
                {(edit && isEmpty && dateCheck && !saveFilterError) && (
                    <Alert color="danger" className="mb-0 d-flex w-100 align-items-center set-fit-content">
                        <AlertTriangle size={20} color={color.red[200]} className="mr-2 " />
                        You have removed all filters. Please select options to save this filter.
                    </Alert>
                )}
                <div className="d-flex flex-wrap">
                    {(selectedBoolean && display) && <SelectedBooleanSearch booleanValue={selectedBoolean} />}
                    {Object.entries(arrayFilters).map(([key, data]) => {
                        const filterData = data.find((item: ItemProps) => item.selected);
                        if (filterData) {
                            return (
                                <SelectedFilter key={key}
                                    filter={key}
                                    items={data}
                                    oldFilterData={data}
                                    setFilterName={setFilterName}
                                    display={display}
                                    contentIds={contentIds || []}
                                    dateCheck={dateCheck}
                                    watchlist={watchlist}
                                    startDate={start_date}
                                    clusters={clusters}
                                    boolean={!!selectedBoolean}
                                />
                            );
                        }
                        return null;
                    })}
                    {(!dateCheck) ? (
                        <SelectedFilter key="dateRange"
                            filter="Date"
                            date={selectedDateRange}
                            setFilterName={setFilterName}
                            display={display}
                            contentIds={contentIds || []}
                            dateCheck={dateCheck}
                            watchlist={watchlist}
                            startDate={start_date}
                            clusters={clusters}
                            boolean={!!selectedBoolean}
                        />
                    ) : null}
                </div>

            </div>
        </div>
    );
};
