import React from 'react';
import { useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { merge, omit } from 'lodash';
import { getRoom } from '../../../utils/variables';
import { GET_LOCATIONS } from '../../../graphql';
import { RootState } from '../../../store';
import { DropdownWithSearchAndCheckboxes } from '../components/DropdownWithSearchAndCheckboxes';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { useContentFilters } from '../../../pages/Room/useFilters';

type LocationsFilterProps = {
    clusters: boolean,
    appliedFilters: any
}

type Locations = {
    location: string,
    count: number,
    contentCount: number
}

export const LocationsFilter = ({ clusters = false, appliedFilters }: LocationsFilterProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const room = getRoom();
    const { projectId } = location.state || {};
    const filters = useContentFilters({ useGlobalFilters: true });
    const { origin: selectedLocations }: { origin: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const sanitizedAppliedFilters = appliedFilters?.filters
        ? omit(appliedFilters.filters, ['locations'])
        : {};
    const { data, loading } = useQuery(!clusters ? GET_LOCATIONS : GET_LOCATIONS_MENTIONS_IN_CLUSTERS, {
        variables: {
            ...(!clusters ? filters : {}),
            projectId: room?.project_id || projectId,
            ...(!clusters ? { filters: omit(merge(filters.filters, sanitizedAppliedFilters), ['labels']) } : {}),
            threats: location.pathname?.includes('/threats') || location.pathname?.includes('/watchlist'),
            size: 1000
        }
    });
    let locations = [];
    if (clusters) {
        locations = data?.getLocationMentionsInClusters?.data || [];
    } else {
        locations = data?.getLocations?.data || [];
    }
    const filteredLocations = locations?.filter((a: Locations) => a.location);
    const locationsData = filteredLocations?.map((loc: Locations) => ({
        id: loc.location,
        name: loc.location,
        selected: selectedLocations.includes(loc.location),
        count: (clusters ? loc.contentCount : loc.count) || 0
    })).sort((a: {count: number}, b: {count: number}) => b.count - a.count);

    const handleSelectItem = (id: string) => {
        dispatch(saveSelectedFilters({
            origin: selectedLocations.includes(id)
                ? selectedLocations.filter((loc: string) => loc !== id)
                : [...selectedLocations, id]
        }));
    };
    const countFilters = selectedLocations.length;

    return (
        <DropdownWithSearchAndCheckboxes placeholder="Search for locations"
            items={locationsData}
            handleSelect={handleSelectItem}
            description={`${locationsData.length} locations`}
            name={countFilters > 0
                ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
            loading={loading}
            itemsPerPage={30}
        />
    );
};

export const GET_LOCATIONS_MENTIONS_IN_CLUSTERS = gql`
    query getLocationMentionsInClusters(
        $projectId: String
        $size: Int
    ) {
        getLocationMentionsInClusters( projectId: $projectId, size: $size ) {
            data {
                location
                count
                contentCount
            }
            total
        }
    }
`;
