import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import HCTimeline from 'highcharts/modules/timeline';
import { ResizeObserver } from 'resize-observer';

HCExporting(Highcharts);
HCTimeline(Highcharts);

type ResponsiveChartProps = {
    options: Options
}

export type ResponsiveChartRef = {
    getHighchartInstance: () => Highcharts.Chart
}

export const ResponsiveChart = forwardRef(({ options }: ResponsiveChartProps, parentRef) => {
    const ref = useRef<HTMLDivElement>(null);
    const highchartRef = useRef<HighchartsReact.RefObject>(null);
    const getSize = useCallback(() => {
        if (!ref || !ref.current) { return window.innerWidth - 100; }
        return ref.current.offsetWidth - 48;
    }, []);
    const [size, setSize] = useState(getSize());

    useImperativeHandle(parentRef, () => ({
        getHighchartInstance: () => highchartRef.current?.chart
    }));

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        const handleResize = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                const newSize = getSize();
                if (newSize !== size && setSize) {
                    setSize(newSize);
                    highchartRef?.current?.chart?.reflow();
                }
            }, 300);
        };
        handleResize();
        if (!ref?.current) { return () => clearTimeout(timer); }
        const ro = new ResizeObserver(handleResize);
        ro.observe(ref.current);
        const current = ref?.current;
        return () => {
            clearTimeout(timer);
            if (current) {
                ro.unobserve(current);
            }
        };
    }, [ref, getSize, size]);
    return (
        <div ref={ref} className="h-100">
            <HighchartsReact highcharts={Highcharts}
                options={{
                    ...options,
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    }
                }}
                ref={highchartRef}
                containerProps={{ style: { height: '100%' } }}
            />
        </div>
    );
});
