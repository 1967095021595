import React, { useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { AlertTriangle, Check } from 'react-feather';
import { useDispatch } from 'react-redux';
import { SelectedFilters } from '../components/SelectedFilters/index';
import { RoomFilterProps } from '../FiltersLayout';
import { getRoom } from '../../../utils/variables';
import { color } from '../../../utils/getColors';
import { saveSelectedFilters, setTempCoordinatedTypes } from '../../../pages/Room/store';
import { FiltersList } from '../FiltersList';

type EditSavedFilterProps = {
    filterData: RoomFilterProps[],
    filterId: string,
    filterName: string,
    setFilterName: (e: string) => void,
    filterNamesList: string[],
    clusters: boolean
}

export const EditSavedFilter = ({ filterData, filterId, filterName, setFilterName,
    filterNamesList, clusters = false }: EditSavedFilterProps) => {
    const dispatch = useDispatch();
    const room = getRoom();
    const [error, setError] = useState(false);
    const [queriesFinished, setQueriesFinished] = useState(false);
    const [defaultName, setDefaultName] = useState('');
    useEffect(() => {
        if ((filterData && !queriesFinished)) {
            setFilterName(filterData[0].name);
            setDefaultName(filterData[0].name);
            const deletedTypenames = filterData.map((a) => {
                const dataNew = a.data;
                dataNew.map((item) => {
                    const newFilter = { ...item };
                    delete newFilter.__typename;
                    return newFilter;
                });

                return { ...a, data: dataNew };
            });

            const selectedFilter = deletedTypenames.find((a) => a.id === filterId);
            if (selectedFilter) {
                const sourceFilter = selectedFilter.data.find((a) => a.filter === 'Sources');
                const languagesFilter = selectedFilter.data.find((a) => a.filter === 'Languages');
                const sentimentFilter = selectedFilter.data.find((a) => a.filter === 'Sentiment');
                const keywordFilter = selectedFilter.data.find((a) => a.filter === 'Keywords');
                const actorsFilter = selectedFilter.data.find((a) => a.filter === 'Actors');
                const labelsFilter = selectedFilter.data.find((a) => a.filter === 'Labels');
                const threatTypesFilter = selectedFilter.data.find((a) => a.filter === 'ThreatType');
                const locationsFilter = selectedFilter.data.find((a) => a.filter === 'Locations');
                const hashtagsFilter = selectedFilter.data.find((a) => a.filter === 'Hashtags');
                const mentionsFilter = selectedFilter.data.find((a) => a.filter === 'Mentions');
                const entitiesFilter = selectedFilter.data.find((a) => a.filter === 'Entities');
                const urlsFilter = selectedFilter.data.find((a) => a.filter === 'Urls');
                const domainsFilter = selectedFilter.data.find((a) => a.filter === 'Domains');
                const coordinationsFilter = selectedFilter.data.find((a) => a.filter === 'Coordinations');
                dispatch(setTempCoordinatedTypes(coordinationsFilter?.items || []));
                dispatch(saveSelectedFilters({
                    keywords: keywordFilter?.items,
                    languages: languagesFilter?.items,
                    contentType: sourceFilter?.items,
                    from: actorsFilter?.items,
                    dateRange: selectedFilter.dateRange,
                    sentiment: sentimentFilter?.items,
                    labels: labelsFilter?.items,
                    threatTypes: threatTypesFilter?.items,
                    origin: locationsFilter?.items,
                    entities: entitiesFilter?.items || [],
                    hashtags: hashtagsFilter?.items || [],
                    mentions: mentionsFilter?.items || [],
                    urls: urlsFilter?.items || [],
                    domains: domainsFilter?.items || [],
                    coordinationTypes: (coordinationsFilter?.items || []).map(a => ({ name: a, ids: [] }))
                }));
            }

            setQueriesFinished(true);
        }
    }, [dispatch, filterData, filterId, queriesFinished, setFilterName]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const finalName = e.target.value;
        if (e.target.value.length <= 70) {
            if (filterNamesList?.includes(finalName) && (finalName !== defaultName)) {
                setError(true);
            } else {
                setError(false);
            }
            setFilterName(e.target.value);
        }
    };

    return (
        <>
            <div className="d-flex flex-column mx-4 mt-3">
                <div className="d-flex flex-column w-25">
                    <p>Filter name</p>
                    <InputGroup border="none"
                        state={(error || !filterName.trim()) ? 'error' : ''}
                        className="mb-0"
                    >
                        <Input placeholder="Filter name"
                            autoFocus
                            value={filterName}
                            onChange={(e) => handleOnChange(e)}
                            data-testid="filter-name"

                        />
                        <span className={(error || ((filterName?.trim() && !error))) ? 'text-disabled mt-1 ml-1'
                            : 'text-disabled mt-1 mr-2 ml-1'}
                        >
                            ({filterName?.length}/70)
                        </span>
                        {(error || !filterName.trim()) && (
                            <InputGroupAddon addonType="append">
                                <InputGroupText className="border-0 p-1 bg-white">
                                    <AlertTriangle color={color.red[200]} size={20} />
                                </InputGroupText>
                            </InputGroupAddon>
                        )}
                        {(filterName?.trim() && !error) && (
                            <InputGroupAddon addonType="append">
                                <InputGroupText className="border-0 p-1 bg-white">
                                    <Check color={color.green[200]} size={16} strokeWidth={3} />
                                </InputGroupText>
                            </InputGroupAddon>
                        )}
                    </InputGroup>
                    {(!filterName.trim()) && (
                        <p className="text-danger mt-1">
                            Your filter must have a name.
                        </p>
                    )}
                    {(error && filterName.trim()) && (
                        <p className="text-danger mt-1">
                            This filter name already exists. Choose a different name.
                        </p>
                    )}
                </div>
                <hr className="w-100 my-3" />
                <FiltersList clusters={clusters} />
            </div>
            <hr className="mb-0 mt-3 mx-n4" />
            <div className="p-3 bg-white ml-1">
                <SelectedFilters edit start_date={room?.start_date} />
            </div>

        </>

    );
};
