/* eslint-disable max-len */
import React from 'react';

export const WentWrong = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={113}
        height={82}
        fill="none"
    >
        <path fill="#E5E6EB"
            d="M108.13 0h-104A4.124 4.124 0 0 0 .006 4.124v7.536h112.248V4.124A4.125 4.125 0 0 0 108.13 0Z"
        />
        <path fill="#F8F8F8"
            d="M0 69.514a4.124 4.124 0 0 0 4.124 4.124h104a4.123 4.123 0 0 0 4.124-4.124V11.66H0v57.854Z"
        />
        <path fill="#FDFDFD"
            d="M40.044 22.664H11.532c-.962 0-1.741.78-1.741 1.74v34.177c0 .961.78 1.74 1.74 1.74h28.513c.962 0 1.74-.779 1.74-1.74V24.404c0-.96-.778-1.74-1.74-1.74ZM102.06 22.664H52.212c-.961 0-1.74.78-1.74 1.74v12.898c0 .96.779 1.74 1.74 1.74h49.848a1.74 1.74 0 0 0 1.741-1.74V24.404a1.74 1.74 0 0 0-1.741-1.74ZM102.06 42.648H52.212c-.961 0-1.74.779-1.74 1.74v12.897c0 .961.779 1.74 1.74 1.74h49.848a1.74 1.74 0 0 0 1.741-1.74V44.388a1.74 1.74 0 0 0-1.741-1.74Z"
        />
        <path fill="#F8F8F8" d="m23.283 35.373-9.008 10.786H32.29l-9.007-10.786Z" />
        <path fill="#E5E6EB"
            d="M30.234 34.161 20.216 46.16h20.04L30.233 34.16ZM18.122 36.255a2.094 2.094 0 1 0 0-4.188 2.094 2.094 0 0 0 0 4.188Z"
        />
        <path fill="#FDFDFD"
            d="M6.751 7.673a1.796 1.796 0 1 0 0-3.592 1.796 1.796 0 0 0 0 3.592Z"
        />
        <path fill="#F8F8F8"
            d="M11.158 7.673a1.796 1.796 0 1 0 0-3.592 1.796 1.796 0 0 0 0 3.592ZM15.832 7.673a1.796 1.796 0 1 0 0-3.592 1.796 1.796 0 0 0 0 3.592Z"
        />
        <path fill="#E5E6EB"
            d="M97.185 29.018H56.13a.635.635 0 0 1 0-1.27h41.055a.635.635 0 0 1 0 1.27ZM97.185 33.96H56.13a.635.635 0 0 1 0-1.27h41.055a.635.635 0 0 1 0 1.27ZM97.185 49.002H74.218a.635.635 0 0 1 0-1.27h22.967a.635.635 0 0 1 0 1.27ZM97.185 53.944H74.218a.635.635 0 0 1 0-1.27h22.967a.635.635 0 1 1 0 1.27Z"
        />
        <path fill="#F84367"
            d="M53.188 52.613 39.44 76.273c-1.25 2.15.312 4.84 2.813 4.84h27.495c2.5 0 4.062-2.688 2.813-4.84l-13.749-23.66c-1.25-2.15-4.375-2.15-5.624 0Z"
        />
        <rect width={2.914}
            height={15.543}
            x={54.543}
            y={57.8}
            fill="#fff"
            rx={1.457}
        />
        <rect width={2.914}
            height={2.914}
            x={54.543}
            y={74.314}
            fill="#fff"
            rx={1.457}
        />
    </svg>
);
