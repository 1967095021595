import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTopLanguages } from '../../../services/Overview';
import { RootState } from '../../../store';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { languagesList } from '../../../utils/variables';
import { DropdownWithSearchAndCheckboxes } from '../components/DropdownWithSearchAndCheckboxes';

type MultiSelectLanguageFiltersProps = {
    clusters: boolean,
    appliedFilters: any
}

export const LanguagesFilter = ({ clusters = false, appliedFilters }: MultiSelectLanguageFiltersProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { projectId } = location.state || {};
    const { data, loading } = useTopLanguages({ size: 1000, useGlobalFilters: true, clusters, projectId, appliedFilters });

    const { languages: selectedLanguages }: { languages: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const languagesData = data?.length > 0 ? data : [];

    const languagesDataList = languagesData.map((lang: any) => ({
        id: lang.key,
        name: lang.language,
        selected: selectedLanguages.includes(lang.key),
        count: (clusters ? lang.contentCount : lang.count) || 0
    })).sort((a: {count: number}, b: {count: number}) => b.count - a.count);
    if (!languagesList().length) {
        languagesList(data.map(({ key }: { key: string }) => key));
    }

    const handleSelectItem = (id: string) => {
        dispatch(saveSelectedFilters({
            languages: selectedLanguages.includes(id)
                ? selectedLanguages.filter(lang => lang !== id)
                : [...selectedLanguages, id]
        }));
    };

    const countFilters = selectedLanguages.length;

    return (
        <div>
            <DropdownWithSearchAndCheckboxes handleSelect={handleSelectItem}
                items={languagesDataList}
                placeholder="Search"
                description={`${languagesData.length} languages`}
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                loading={loading}
                itemsPerPage={30}
            />
        </div>
    ); };
