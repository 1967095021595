import React, { useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { X } from 'react-feather';
import { color } from '../../../../utils/getColors';
import { truncateString } from '../../../../utils/text';

type ListDropdownProps = {
    items: { id: string, name: string }[],
    filterName: string,
    clearFilters: (id: string) => void
}

export const ListDropdown = ({ items, filterName, clearFilters }: ListDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const togglePopover = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <div id={`${filterName}-selected-filters`}
                className="mt-2 ml-2 cursor-pointer"
            >
                <p className="text-primary">+{items.length} more</p>
            </div>
            <Popover
                isOpen={isOpen}
                toggle={togglePopover}
                position="bottom"
                placement="bottom"
                target={`${filterName}-selected-filters`}
                trigger="legacy"
                className="filters-popover"
            >
                <PopoverBody>
                    <div className="p-2">
                        {items.map((item) => (
                            <div className="d-flex m-1 justify-content-between" key={item.id}>
                                <p className="mr-1">{truncateString(item.name, 20, false)}</p>
                                <div className="d-flex align-items-center pl-1 cursor-pointer"
                                    data-testid={`x-icon-${item.id}`}
                                    onClick={() => clearFilters(item.id)}
                                >
                                    <X color={color.blue[500]} />
                                </div>
                            </div>
                        ))}
                    </div>
                </PopoverBody>
            </Popover>
        </>

    ); };
