import { useState, useCallback } from 'react';

export const useCustomStateWithCallback = (initialState) => {
    const [state, setState] = useState(initialState);

    const setStateWithCallback = useCallback((newState, callback) => {
        setState((prevState) => {
            const updatedState = typeof newState === 'function' ? newState(prevState) : newState;
            if (callback) {
                // Schedule the callback to run after the state update
                Promise.resolve().then(() => callback(updatedState));
            }
            return updatedState;
        });
    }, []);

    return [state, setStateWithCallback];
};
