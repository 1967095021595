import React, { useState } from 'react';
import { Col, Popover, PopoverBody, Row } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { AddPageDropdown, pageOptions } from './AddPageDropdown';
import { Page } from './Page';
import { PagesList } from './PagesList';
import { Toolbar } from './Toolbar';
import { createEditorState } from '../createEditorState';
import { setReportAction } from '../../../../../../redux/reports';

export const Pages = () => {
    const { report } = useSelector(state => state.createReport);
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const togglePopover = () => {
        setOpen(!isOpen);
    };

    const availablePageOptions = pageOptions.filter(a => !report.pages.find(page => page.type === a.key) || a.key === 'summary');
    if (!availablePageOptions.length) { return null; }
    return (
        <Row noGutters>
            <Col xs={12} md={9} className="border-right border-bottom position-relative">
                <Toolbar />
                <div className="pt-4 pr-4">
                    {report.pages.map((page, index) => <Page key={page.id} data={page} pageNumber={index + 1} />)}
                </div>
                <p className="text-primary text-center cursor-pointer pb-2" id="addNewPage">+ Add new page</p>
                <Popover container="body"
                    isOpen={isOpen}
                    toggle={togglePopover}
                    placement="bottom"
                    target="addNewPage"
                    trigger="legacy"
                    modifiers={{
                        preventOverflow: {
                            enabled: false
                        }
                    }}
                >
                    <PopoverBody className="p-0 bg-primary border text-white">
                        {
                            availablePageOptions.map((item) => (
                                <div key={item.key}
                                    className="p-2 cursor-pointer border-bottom"
                                    onClick={() => {
                                        togglePopover();
                                        dispatch(setReportAction({
                                            ...report,
                                            pages: [
                                                ...report.pages,
                                                {
                                                    id: uuid(),
                                                    type: item.key,
                                                    pageTitle: createEditorState({
                                                        blocks: [
                                                            {
                                                                text: item.name.replace(' page', '')
                                                            }
                                                        ],
                                                        entityMap: {}
                                                    }),
                                                    ...(item.key === 'summary' ? {
                                                        bodyText: createEditorState({ blocks: [], entityMap: {} })
                                                    } : { blocks: [] })
                                                }
                                            ]
                                        }));
                                    }}
                                >
                                    {item.name}
                                </div>
                            ))
                        }
                    </PopoverBody>
                </Popover>
            </Col>
            <Col xs={12} md={3}>
                <AddPageDropdown />
                <PagesList />
            </Col>
        </Row>
    );
};
