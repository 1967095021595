import React from 'react';
import {
    Copy,
    Cpu,
    Disc,
    FileText,
    Frown,
    Key,
    Meh,
    PieChart,
    Smile
} from 'react-feather';
import { color } from './getColors';

// This is a file to manadate a particular icon for a particular use case throughout the application

export const getIcons = (icon?: string, sentiment?: string) => {
    switch (icon) {
        case 'engagement': { return <PieChart size={20} color={color.darkblue[700]} />; }
        case 'keywordsMentions': { return <Key size={20} color={color.darkblue[700]} />; }
        case 'subClusters': { return <Disc size={20} color={color.darkblue[700]} />; }
        case 'contents': { return <FileText size={20} color={color.darkblue[700]} />; }
        case 'duplicates': { return <Copy size={20} color={color.darkblue[700]} />; }
        case 'sentiment': {
            if (sentiment === 'positive') return <Smile size={20} color={color.green[200]} />;
            if (sentiment === 'negative') return <Frown size={20} color={color.red[200]} />;
            return <Meh size={20} color={color.yellow[300]} />;
        }
        case 'coordination': { return <Cpu size={20} color={color.darkblue[700]} />; }
        default:
            return null;
    }
};
