import { reportWebVitals } from './reportWebVitals';
import 'moment/locale/en-gb';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { ApolloClient, InMemoryCache, ApolloProvider, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import './assets/scss/theme.scss';
import LogRocket from 'logrocket';
import { setContext } from '@apollo/client/link/context';
import { getToken } from "./auth0";
import { onError } from "@apollo/client/link/error";
import ErrorBoundary from "./components/ErrorBoundary";
import { Provider } from 'react-redux'
import { store } from './store'
import './amplitude';

if (process.env.NODE_ENV === 'production') {
  console.log = function () { };
}

if (window.location.origin !== "http://localhost:3000") {
  LogRocket.init('lwybt8/logically-intelligence');
}
window.twttr = (function (d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0],
    t = window.twttr || {};
  if (d.getElementById(id)) return t;
  js = d.createElement(s);
  js.id = id;
  js.src = 'https://platform.twitter.com/widgets.js';
  fjs.parentNode.insertBefore(js, fjs);

  t._e = [];
  t.ready = function (f) {
    t._e.push(f);
  };

  return t;
})(document, 'script', 'twitter-wjs');

const uri = window.REACT_APP_GRAPHQL_URL || 'http://localhost:8080/';

const uploadLink = createUploadLink({
  uri,
  headers: {
    'Apollo-Require-Preflight': true
  }
})

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([authLink, errorLink, uploadLink]),
  cache: new InMemoryCache()
});

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log(`%c \n\n\n
  ╦   ╔══╗ ╔══ ╦ ╔══ ╔═╗ ╦   ╦   ╦ ╦
  ║   ║  ║ ║ ╗ ║ ║   ╠═╣ ║   ║   ╚╦╝
  ╚══ ╚══╝ ╚═╝ ╩ ╚══ ╩ ╩ ╚══ ╚══  ╩
╦ ╔═╗ ═╦═ ╔══ ╦   ╦   ╦ ╔══ ╔══ ╔═╗ ╔══ ╔══
║ ║ ║  ║  ╠═  ║   ║   ║ ║ ╗ ╠═  ║ ║ ║   ╠═ 
╩ ╩ ╩  ╩  ╚══ ╚══ ╚══ ╩ ╚═╝ ╚══ ╩ ╩ ╚══ ╚══
\n\n\n`, 'color: #030e3a;')