import { gql, useQuery } from '@apollo/client';
import { merge, omit } from 'lodash';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useTopDomains = ({ dateFilter = undefined, size = 10, clusters = false, projectId = '', searchValue = '',
    appliedFilters = undefined, isThreatsPage = false } = {}) => {
    const filters = useContentFilters({ useGlobalFilters: true, defaultFilters: { date: dateFilter } });
    const sanitizedAppliedFilters = appliedFilters?.filters
        ? omit(appliedFilters.filters, ['domains'])
        : {};
    const ret = useQuery(GET_TOP_DOMAINS, {
        variables: {
            ...filters,
            filters: omit(merge(filters.filters, sanitizedAppliedFilters), ['labels']),
            projectId: filters.projectId || projectId,
            size,
            domainQuery: searchValue,
            threats: isThreatsPage
        },
        skip: clusters
    });

    return {
        ...ret,
        data: ret.data?.topDomains?.data?.map(item => ({
            ...item,
            key: item.domain
        })) || []
    };
};

export const GET_TOP_DOMAINS = gql`
    query topDomains($projectId: String!, $filters: ContentFilters, $size: Int!, $dslQuery: JSON, $domainQuery: String, $threats: Boolean) {
        topDomains(projectId: $projectId, filters: $filters, size: $size, dslQuery: $dslQuery, domainQuery: $domainQuery,
         threats: $threats) {
            data {
                domain
                count
            }
            total
        }
    }
`;
