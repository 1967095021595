import React, { useState } from 'react';

import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import * as FeatherIcon from 'react-feather';
import { useSelector } from 'react-redux';
import { useDropDown } from '../../../../utils/useDropdown';
import { color } from '../../../../utils/getColors';
import { Tabs } from '../../../Tabs';
import { KeywordsFilter } from './KeywordsFilter';
import { EntitiesFilter } from './EntitiesFilter';
import { HashtagsFilter } from './HashtagsFilter';
import { MentionsFilter } from './MentionsFilter';
import { RootState } from '../../../../store';

type MultiKeywordsFilterProps = {
    appliedFilters: any
}

export const MultiKeywordsFilter = ({ appliedFilters }: MultiKeywordsFilterProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const [searchValue, setSearchValue] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const { hashtags, mentions, entities, keywords } = useSelector((state: RootState) => state.selectedFilters.filters);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearchValue(e.target.value);
    };
    const handleSearch = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        setSearchQuery(searchValue);
    };
    const countFilters = hashtags.length + mentions.length + entities.length + keywords.length;

    return (
        <Dropdown isOpen={dropdown}
            toggle={toggleDropdown}
        >
            <DropdownToggle tag="div"
                data-testid="dropdown-toggle"
                className={`dropdown-filter d-flex justify-content-between align-items-center cursor-pointer px-2 py-1 
                    ${dropdown && 'border border-primary'}`}
            >
                <span>{countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                </span>
                <ChevronDown size={20}
                    className="ml-11"
                    color={color.blue[500]}
                    style={{ transition: 'all .25s ease-in-out', transform: dropdown ? 'rotate(-180deg)' : 'none' }}
                />
            </DropdownToggle>
            <DropdownMenu className={`dropdown-filter-tabs ${dropdown && 'border border-primary'}`}>
                <div className="p-2 border-bottom">
                    <form onSubmit={(e) => handleSearch(e)} className="d-block" data-testid="search-input-component">
                        <InputGroup border={searchValue ? 'active' : 'none'} className="mb-0">
                            <Input value={searchValue}
                                onChange={(e) => handleChange(e)}
                                placeholder="Search"
                            />
                            {searchValue && (
                                <InputGroupAddon addonType="prepend" className="border-right small-border">
                                    <InputGroupText role="button" onClick={() => { setSearchValue(''); setSearchQuery(''); }}>
                                        <FeatherIcon.X color={color.blue[500]} size={16} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            )}
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText role="button" onClick={(e) => handleSearch(e)}>
                                    <FeatherIcon.Search size={16} color={color.blue[500]} />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </form>
                </div>
                <div className="scrollbar-small my-2 ml-2 mr-0">
                    <Tabs small
                        data={[
                            { id: 'keywords',
                                name: 'Keywords',
                                content: <KeywordsFilter searchQuery={searchQuery}
                                    appliedFilters={appliedFilters}
                                /> },
                            { id: 'hashtags',
                                name: 'Hashtags',
                                content: <HashtagsFilter searchQuery={searchQuery}
                                    appliedFilters={appliedFilters}
                                /> },
                            { id: 'entities',
                                name: 'Entities',
                                content: <EntitiesFilter searchQuery={searchQuery}
                                    appliedFilters={appliedFilters}
                                /> },
                            { id: 'mentions',
                                name: 'Mentions',
                                content: <MentionsFilter searchQuery={searchQuery}
                                    appliedFilters={appliedFilters}
                                /> }
                        ]}
                    />
                </div>
            </DropdownMenu>
        </Dropdown>
    ); };
