import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomInput, FormGroup } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { saveSelectedFilters } from '../../../../pages/Room/store';
import { RootState } from '../../../../store';
import { useWordcloud } from '../../../../services/Overview';
import { Loading } from '../../../Loading';
import { LabelItem } from './LabelItem';

type MentionsFilterProps = {
    searchQuery: string,
    appliedFilters: any
}

export const MentionsFilter = ({ searchQuery = '', appliedFilters }: MentionsFilterProps) => {
    const dispatch = useDispatch();
    const [dropdownId] = useState(uuid());

    const { mentions: selectedMentions }: { mentions: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);

    const { keywords: topMentions, loading } = useWordcloud({
        field: 'mentions',
        size: 30,
        defaultFilters: {
            wildcard: {
                search: searchQuery,
                field: 'mentions'
            }
        },
        search: searchQuery,
        appliedFilters
    });

    const mentionsData = topMentions?.map((mention: {key: string, keyword: string, count: number}) => ({
        id: mention.key,
        name: mention.keyword,
        selected: selectedMentions.includes(mention.key),
        count: mention.count || 0
    })).sort((a: {count: number}, b: {count: number}) => b.count - a.count);

    const handleSelectItem = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        dispatch(saveSelectedFilters({
            mentions: selectedMentions.includes(id)
                ? selectedMentions.filter(mention => mention !== id)
                : [...selectedMentions, id]
        }));
    };

    return (
        <div>
            {(!searchQuery && mentionsData.length) ? <p>Top 30 mentions</p> : ''}
            <div className="scrollbar-small tab-filter">
                {(mentionsData.length > 0 && !loading) && (
                    <>
                        {mentionsData.map((item: {id: string, name: string, selected: boolean, count: number}) => (
                            <FormGroup key={item.id}>
                                <CustomInput tag="checkbox"
                                    type="checkbox"
                                    checked={item.selected}
                                    className="custom-input-filter py-1"
                                    label={<LabelItem item={item} dropdownId={dropdownId} />}
                                    id={`${dropdownId}_${item.id}`}
                                    data-testid={`checkbox-${item.id}`}
                                    value={item.name}
                                    onChange={(e) => { handleSelectItem(item.id, e); }}
                                />
                            </FormGroup>
                        ))}
                    </>
                )}
                {loading && <Loading relative height={168} width={255} />}
                {(!mentionsData.length && !loading) && <p className="no-white-space">No results found</p>}
            </div>
        </div>
    );
};
