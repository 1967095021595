import React from 'react';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { CoordinationDataType } from './coordinationDataTypes';
import { useCoordinationsTopKeywords } from './getCoordinationsTopKeywords';
import { Loading } from '../../../../components/Loading';

type CoordinatedTopKeowrdsProps = {
    coordinationData?: CoordinationDataType[]
}

export const CoordinatedTopKeowrds = ({ coordinationData }: CoordinatedTopKeowrdsProps) => {
    const allDocIds = coordinationData?.map(({ docIds }) => docIds).flat() || [];
    const { data: { hashtags, entities }, loading } = useCoordinationsTopKeywords({ ids: allDocIds });
    return (
        <div className="m-0 mt-2 py-2 d-flex justify-content-between align-items-center gap-2">
            {entities.length < 1 && loading
            && <div className="bg-lightgray p-2 rounder-4 w-100"><Loading relative /></div>}
            {entities.length > 1 && !loading ? (
                <div className="bg-lightgray p-2 rounder-4 w-100">
                    <h3 className="m-0"><WidgetTextTooltip widgetName={WidgetName.Entities} /></h3>
                    <div className="d-flex align-items-center mt-2 gap-4">
                        {entities.map(({ entity, count }) => (
                            <span key={entity}>
                                <p className="bg-secondary-alt py-11 px-1 rounder-4 text-white m-0 d-inline">
                                    {count}
                                </p> {entity}
                            </span>
                        ))}
                    </div>
                </div>
            ) : null}
            {hashtags.length < 1 && loading
            && <div className="bg-lightgray p-2 rounder-4 w-100"><Loading relative /></div>}
            {hashtags.length > 1 && !loading ? (
                <div className="bg-lightgray p-2 rounder-4 w-100">
                    <h3 className="m-0"><WidgetTextTooltip widgetName={WidgetName.Hastags} /></h3>
                    <div className="d-flex align-items-center gap-4 mt-2">
                        {hashtags.map(({ hashtag, count }) => (
                            <span key={hashtag}>
                                <p className="bg-secondary-alt py-11 px-1 rounder-4 text-white m-0 d-inline">
                                    {count}
                                </p> {hashtag}
                            </span>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    ); };
