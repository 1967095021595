import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';
import { MoreVertical } from 'react-feather';
import { v4 as uuid } from 'uuid';
import { useModal } from '../../../../context/modal/ModalComponent';
import { useUser } from '../../../../context/user/UserContext';
import { DELETE_CUSTOM_NARRATIVE } from '../../../../graphql';
import { StatusModalBodyNew, STATUS_TYPES } from '../../../../utils/SuccessErrorModal';
import { getRoom } from '../../../../utils/variables';
import { useGetActiveQueries } from '../../../../services/getActiveQueries';
import { useDropDown } from '../../../../utils/useDropdown';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';

export const CustomNarrativeItem = ({ narrative }) => {
    const room = getRoom();
    const [actionId] = useState(uuid());
    const [dropdown, toggleDropdown] = useDropDown();
    const { notAllowed } = useUser();
    const { setModal, closeModal } = useModal();
    const setStatusModal = ({ message, type }) => {
        setModal({
            header: false,
            component: <StatusModalBodyNew message={message} messageType={type} />
        });
    };

    const refetchQueries = useGetActiveQueries(['getCustomNarratives']);
    const [deleteCustomNarrative] = useMutation(DELETE_CUSTOM_NARRATIVE, {
        refetchQueries,
        onCompleted: () => {
            closeModal();
            setStatusModal({
                title: 'Delete Custom Narrative',
                type: STATUS_TYPES.success,
                message: 'Custom Narrative Deleted Successfully'
            });
        }
    });

    const handleDeleteCustomNarrative = (id) => {
        deleteCustomNarrative({
            variables: { id }
        });
    };
    const removeNarrative = () => {
        setModal({
            component: (
                <div>
                    <h2 className="m-0">Delete custom narrative</h2>
                    <hr />
                    <p className="py-2">Are you sure, you want to delete this Custom Narrative?</p>
                    <hr />
                    <Button color="danger" onClick={() => handleDeleteCustomNarrative(narrative.id)}>Yes</Button>
                    <Button className="ml-3" onClick={() => closeModal()}>No</Button>
                </div>
            )
        });
    };
    const _id = `actions_${narrative.id}_${actionId}`;
    return (
        <tr>
            <td>
                <Link className="d-block" to={`/situation-rooms/${room?.id}/custom-narrative/${narrative.id}`}>
                    <p className="m-0 text-primary">{narrative.name}</p>
                </Link>
            </td>
            <td>
                <div>
                    {narrative.data.map((item, index) => {
                        if (index > 2) { return null; }
                        return (
                            <span
                                key={item.id}
                                className="mr-2 p-11 bg-primary-light rounder-4"
                            >
                                {item.threat}
                            </span>
                        );
                    })}
                    {narrative.data.length > 3 && (
                        <span className="mb-0">
                            +{narrative.data.length - 3} more
                        </span>
                    )}
                </div>
            </td>
            {!notAllowed.includes('manageCustomNarratives') && (
                <td className="text-right" id={`custom-narrative-${narrative.id}`}>
                    <Dropdown onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                        className="d-inline mw-0 mr-0"
                        isOpen={dropdown}
                        toggle={toggleDropdown}
                        tag="div"
                        direction={window.innerWidth < 540 ? 'left' : 'right'}
                        data-testid="cluster-actions"
                        id={`dropdown_${_id}`}
                    >
                        <DropdownToggle className="bg-transparent border-0 mw-0 mr-n1 my-n1 pr-0" data-testid="custom-narrative-toggle">
                            <span>
                                <TooltipWrapper tooltipText="More actions"
                                    id={_id}
                                    placement="top-start"
                                    container={`custom-narrative-${narrative.id}`}
                                >
                                    <Button color="link" className="p-0 mw-0" tag="div">
                                        <MoreVertical data-testid="ellipses-vertical-icon" />
                                    </Button>
                                </TooltipWrapper>
                            </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem className="px-3 py-2">
                                <Link
                                    to={{ pathname: `/situation-room/${room.id}/create-custom-narrative`,
                                        state: { roomId: room.id,
                                            narrativeId: narrative.id } }}

                                >
                                    <p role="button">
                                        Edit
                                    </p>
                                </Link>
                            </DropdownItem>
                            <DropdownItem onClick={removeNarrative} className="px-3 py-1">
                                <span>Remove</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </td>
            )}
        </tr>
    );
};
