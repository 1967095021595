import { useQuery } from '@apollo/client';
import { GET_WATCHLIST } from '../../graphql';
import { removeAllTypename } from '../../utils/removeAllTypename';
import { useContent } from '../Content/getContent';

type UseGetWatchlistProps = {
    situationRoomId: string
    getFullContent?: boolean
    sort?: string
    idsFilter?: string[]
}

export const useGetWatchlist = ({ situationRoomId, getFullContent, sort = 'dateNew', idsFilter }: UseGetWatchlistProps) => {
    const {
        data,
        refetch: refetchWatchlist,
        loading: watchlistIdsLoading
    } = useQuery(GET_WATCHLIST, {
        variables: { situationRoom: situationRoomId, contentIds: idsFilter },
        fetchPolicy: 'cache-and-network',
        skip: !situationRoomId
    });
    const watchlistData = data?.getSituationRoomThreatsWatchlist || [];
    const ids = watchlistData.map((watchlistItem: any) => watchlistItem.content_id);
    const { loading, data: contents } = useContent({
        filters: {
            ids
        },
        limit: watchlistData.length,
        fetchPolicy: 'cache-and-network',
        skipRequest: !situationRoomId || !watchlistData.length,
        name: 'getWatchlistContent',
        fragments: getFullContent ? undefined : ['Metadata'],
        sort,
        watchlist: true
    });
    const watchlist = removeAllTypename(contents);

    return {
        watchlist,
        refetchWatchlist,
        loading: watchlistIdsLoading || loading,
        contentIds: contents.map((a: any) => a.id)
    };
};
