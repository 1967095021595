export const getCoordinatedDateType = (header?: string) => {
    switch (header) {
        case 'Coordinated_posts':
            return 'PostTime';
        case 'Coordinated_reposts':
            return 'RepostTime';
        case 'copost':
            return 'PostTime';
        case 'corepost':
            return 'RepostTime';
        default: return 'PostTime';
    }
};

export const getCoordinatedSummaryHeader = (header: string) => {
    switch (header) {
        case 'Coordinated_post':
            return 'Summary';
        case 'Coordinated_repost':
            return 'Orignal post';
        default: return header;
    }
};

export const getWidgetName = (type: string) => {
    switch (type) {
        case 'copost': return 'CoordinatedPosts';
        case 'corepost': return 'CoordinatedReposts';
        case 'cohashtag': return 'CoordinatedHashtags';
        case 'similar_account': return 'SimilarAccountHandles';
        case 'similar_post_pattern': return 'SimilarPostingPatterns';
        case 'cohashtag_sequence': return 'CoordinatedHashtagSequences';
    }
};

export const getHeaderName = (type: string) => {
    switch (type) {
        case 'copost': return 'Post_network';
        case 'corepost': return 'Repost_network';
        case 'cohashtag': return 'Hashtag_network';
        case 'similar_account': return 'Account_network';
        case 'similar_post_pattern': return 'SimilarPostingPattern';
        case 'cohashtag_sequence': return 'Sequence_network';
    }
};
