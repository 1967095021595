import React from 'react';
import { TranslateButton } from '../../../Atoms/TranslateButton';
import { ReadMore } from '../../../Readmore';
import { useTranslateMultipleText } from '../../../../services/google/translate';
import { CoordinationDataType } from '../../../../pages/Room/AIClustering/CoordinatedModals/coordinationDataTypes';

type SummaryTextProps = {
    item: CoordinationDataType
    summaryText: string
}

export const SummaryText = ({ item, summaryText }: SummaryTextProps) => {
    const isNonEnglish = /[^\x20-\x7E]/.test(summaryText);
    const { getTranslation, data: translated, loading } = useTranslateMultipleText({
        text: summaryText
    });
    const translatedText = translated.length ? translated[0].translations[0] : summaryText;
    return (
        <div className="d-flex">
            {((item.coordinationType === 'copost')
            || (item.coordinationType === 'corepost')) && translatedText.length > 400
                ? <ReadMore text={translatedText} limit={400} className="mb-2" />
                : <p className="mb-2">{translatedText}</p>}
            {isNonEnglish ? (
                <TranslateButton className="ml-1"
                    onClick={(e: any) => {
                        e.preventDefault();
                        getTranslation();
                    }}
                    loading={loading}
                />
            ) : null}
        </div>
    );
};
