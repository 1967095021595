import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { TranslateIcon } from '../../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION } from '../../../../../graphql';
import { getContentLink } from '../../../../../utils/getContentLink';
import { getCtype } from '../../../../../utils/text';
import { color } from '../../../../../utils/getColors';
import { convertToDate } from '../../../../../utils/getTimeFrom';

export const ThreatItem = ({ threat: content }) => {
    const [isOpen, setOpen] = useState(false);
    const [getTranslation, { data }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: { text: content.text, target: 'en' }
        },
    });
    const translation = (e) => {
        e.preventDefault();
        getTranslation();
        setOpen(!isOpen);
    };
    const outputText = data ? data?.translateMultipleText[0]?.translations[0] : content.text;
    const { icon, FontFamily, name, nodeColor, iconColor } = getCtype(content.contentType);
    const contentLink = getContentLink({
        id: content.id,
        threatLevel: content.threatObject?.threatLevel
    });
    const likesCount = Number.isNaN(Number(content.likesCount)) ? -1 : Number(content.likesCount);
    const sharesCount = Number.isNaN(Number(content.sharesCount)) ? -1 : Number(content.sharesCount);
    const engagementCount = Number.isNaN(Number(content.engagement)) ? -1 : Number(content.engagement);

    return (
        <Link to={contentLink}>
            <div className="post-wrap">
                <div className="post-wrap-heading">
                    <div>Engagement:&nbsp;{engagementCount < 0 ? 'NA' : engagementCount}</div>
                    <div>Likes:&nbsp;{likesCount < 0 ? 'NA' : likesCount}</div>
                    <div>Shares:&nbsp;{sharesCount < 0 ? 'NA' : sharesCount}</div>
                </div>
                {(content.threatObject ? content.threatObject.threatLevel : content.threatLevel) === 'high' && (
                    <div className="post-wrap-threat">
                        <div style={{ color: '#fff' }}>{content.threatObject?.threatLabels[0]}</div>
                    </div>
                )}
                <div className="post-wrap-body">
                    <div className="d-flex justify-content-between">
                        <div className="post-wrap-author">
                            <div className="post-wrap-author-name">{content.displayName}</div>
                            <div className="post-wrap-author-handle">{content.from}</div>
                        </div>
                        {!['en', 'un'].includes(content.lang) && (
                            <span role="button" className="ml-auto" onClick={(e) => translation(e)}>
                                <TranslateIcon color={color.blue[500]} size={30} />
                            </span>
                        )}
                    </div>
                    {content.image && content.image !== 'undefined' && content.image !== 'unknown' && (
                        <div className="post-wrap-media">
                            <img src={content.image}
                                alt=""
                            />
                        </div>
                    )}

                    <div style={{ color: '#3f3f3f', lineHeight: '1.5rem', wordBreak: 'break-word' }}>
                        {isOpen ? outputText : content.text}
                    </div>
                    <div className="post-wrap-footer">
                        <div className="post-wrap-icon rounded-circle d-flex align-items-center justify-content-center"
                            style={{ backgroundColor: nodeColor, minHeight: 30, minWidth: 30 }}
                        >
                            <i className={`font-size-14 fa ${icon}`}
                                style={
                                    FontFamily ? { fontFamily: `"${FontFamily}"`, color: iconColor } : { color: iconColor }
                                }
                            />
                        </div>
                        <span>
                            <span className="font-weight-semibold">{name}</span>
                            {' - '}
                            {moment.utc(convertToDate(content.pubdatetime)).format('DD/MM/YYYY @ HH:mm:ss z')}
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
};
