import React, { useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DateVisualizer } from '../../../DateVisualizer';
import { getCoordinatedDateType, getHeaderName, getWidgetName } from '../../../../utils/coordinatedTypes';
import { CoordinatedDetail } from '../../../../pages/Room/AIClustering/CoordinatedModals/CoordinatedDetail';
import { getParam, setParam, unsetParam } from '../../../../utils/urlParams';
import { BreadCrumbs } from '../../../BreadCrumbs';
import { SimilarPostingPattern } from '../../../../pages/Room/AIClustering/CoordinatedModals/SimilarPostingPattern';
import { checkGrammer } from '../../../../utils/text/checkGrammer';
import { Content } from '../../../../services/Content/contentInterface';
import { getCtype } from '../../../../utils/text';
import { SourceIconLabel } from '../../../SourceIconLabel';
import { getPostedByName } from '../../../../utils/getPostedByName';
import { SingleCoordination } from './SingleCoordination';
import { CoordinationDataType, SingleNetworkProps } from '../../../../pages/Room/AIClustering/CoordinatedModals/coordinationDataTypes';
import { AllActors } from '../../../../pages/Room/AIClustering/CoordinatedModals/AllActors';
import { useSortedActors } from '../../../../pages/Room/AIClustering/CoordinatedModals/useSortedActors';
import { GET_CONTENT_COORDINATION } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { Loading } from '../../../Loading';

type ContentTypes = 'text' | 'datetime' | 'title' | 'threatObject' | 'contentType' | 'from' | 'url' | 'pubdatetime';

type CoordinatedActivityListProps = {
    coordinationData: any;
    content?: Pick<Content, ContentTypes>
}

export const getDates = (networkData?: SingleNetworkProps[]) => {
    const sortedData = [...(networkData || [])].sort(
        (a: SingleNetworkProps, b: SingleNetworkProps) => {
            const dateA = new Date(a.pubdatetime);
            const dateB = new Date(b.pubdatetime);
            return dateB.getTime() - dateA.getTime(); // Compare timestamps
        }
    );
    const endDate = sortedData?.[0]?.pubdatetime ?? new Date();
    const startDate = sortedData?.[sortedData.length - 1].pubdatetime ?? new Date();
    return { startDate, endDate };
};

export const CoordinatedActivityList = ({ coordinationData, content }: CoordinatedActivityListProps) => {
    const room = getRoom();
    const [data, setData] = useState<CoordinationDataType[]>([...coordinationData]);
    const [getSelectedActorData, { loading }] = useLazyQuery(GET_CONTENT_COORDINATION, {
        onCompleted: (res) => {
            const actorId = getParam('actor_id');
            const actorData = res.getCoordinations?.[0]
            && res.getCoordinations?.[0]?.network.filter((item: any) => item.from === actorId || item.from.includes(actorId));
            const filteredList = actorData?.[0] && {
                ...actorData[0],
                text: '',
                network: (res.getCoordinations.flatMap((coordination: any) =>
                    coordination.network.filter((item: any) => item.from === actorId || item.from.includes(actorId)))),
                allData: res.getCoordinations.flatMap((coordination: any) =>
                    coordination.network.filter((item: any) => item.from === actorId || item.from.includes(actorId))),
                ttps: res.getCoordinations.flatMap((coordination: any) => [coordination.coordinationType]),
                from: actorId,
            };
            if (actorId) {
                setActor(filteredList);
                setData(res.getCoordinations.map((item: any, index: number) => {
                    if (index === 0) {
                        return {
                            ...item,
                            coordinationSource: actorData[0]?.docId,
                        };
                    }
                    return item;
                }));
            } else {
                setActor({});
                setData(res.getCoordinations);
            }
            setSelectedNetwork('');
            setNetworkType('');
        }
    });
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [networkType, setNetworkType] = useState('');
    const [selectedNetworkData, setSelectedNetworkData] = useState<SingleNetworkProps[]>();
    const [actor, setActor] = useState<any>(getParam('actor_id') ? { ...content } : {});
    const isThreat = actor?.threatObject?.threatLevel !== 'none';
    const ctype = getCtype(actor?.contentType);
    const [coordinationId, setCoordinationId] = useState('');
    const { sortedActorsData, sortFn } = useSortedActors({ coordinationData: data, loading });

    const handleViewDetails = (item: any) => {
        const networkData = [...(item.network || [])];
        setSelectedNetwork(item.coordinationSource);
        setSelectedNetworkData(networkData.sort(
            (a: SingleNetworkProps, b: SingleNetworkProps) => {
                const dateA = new Date(a.pubdatetime);
                const dateB = new Date(b.pubdatetime);
                return dateB.getTime() - dateA.getTime(); // Compare timestamps
            }
        ));
        setNetworkType(item.coordinationType);
        setActor({});
        setParam('subTab', getWidgetName(item.coordinationType));
    };
    const generateBreadcrumbs = () => {
        const breadcrumbs = [];
        if (getParam('tab')) {
            breadcrumbs.push({
                title: isThreat ? 'Threat' : 'Match',
                onClick: () => {
                    setData(coordinationData);
                    unsetParam('coordination_id');
                    unsetParam('subTab');
                    unsetParam('proxyTab');
                    unsetParam('actor_id');
                    setSelectedNetwork('');
                    setNetworkType('');
                    setActor({});
                }
            });
        }
        if (getParam('subTab')) {
            const type = getParam('subTab');
            breadcrumbs.push({
                title: type ? type.replace(
                    /([a-z])([A-Z])|_/g,
                    (match, lower, upper) => (lower && upper ? `${lower} ${upper}` : ' ')
                ) : '',
                onClick: () => {
                    unsetParam('actor_id');
                    unsetParam('coordination_id');
                    unsetParam('proxyTab');
                    setSelectedNetwork('');
                    setNetworkType('');
                    setActor({});
                }
            });
        }
        if (getParam('actor_id')) {
            const type = getParam('actor_id');
            breadcrumbs.push({
                title: type ? type.replace(/([a-z])([A-Z])/g, '$1 $2') : '',
                onClick: () => {
                    unsetParam('coordination_id');
                    unsetParam('proxyTab');
                    setSelectedNetwork('');
                    setNetworkType('');
                }
            });
        }
        if (getParam('actor_id') && getParam('proxyTab')) {
            const type = getParam('proxyTab');
            breadcrumbs.push({
                title: type ? type.replace(
                    /([a-z])([A-Z])|_/g,
                    (match, lower, upper) => (lower && upper ? `${lower} ${upper}` : ' ')
                ) : '',
            });
        }
        if (getParam('coordination_id') && !getParam('actor_id') && !getParam('proxyTab')) {
            const type = getParam('coordination_id');
            breadcrumbs.push({
                title: type ? type.replace(/([a-z])([A-Z])/g, '$1 $2') : '',
                onClick: () => {
                    unsetParam('actor_id');
                    setActor({});
                }
            });
        }
        delete breadcrumbs[breadcrumbs.length - 1]?.onClick;
        return breadcrumbs;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const bread = useMemo(() => generateBreadcrumbs(), [selectedNetwork, actor, coordinationId]);

    const goToActors = (name = '') => {
        setParam('subTab', 'Coordination_actors');
        unsetParam('proxyTab');
        let actorId;

        if (name) {
            actorId = name;
        } else {
            actorId = content?.from as string;
        }
        setParam('actor_id', (name || content?.from));
        getSelectedActorData({
            variables: {
                projectId: room.project_id,
                actors: [actorId]
            },
            fetchPolicy: 'cache-and-network',
        });
    };
    const handleSetParam = (name: string) => {
        const coordinatedType: CoordinationDataType| undefined = data.find(item => item.id === name);
        if (coordinatedType?.coordinationType) {
            setCoordinationId(name);
            setSelectedNetwork(name);
            const type = getHeaderName(coordinatedType?.coordinationType);
            setParam('proxyTab', type);
            setNetworkType(type || '');
            setActor({});
        }
    };

    const getAllActors = () => {
        setParam('subTab', 'Coordination_actors');
        unsetParam('actor_id');
        unsetParam('coordination_id');
        unsetParam('proxyTab');
        setSelectedNetwork('');
        setNetworkType('');
        setActor({});
    };
    if (loading) {
        return <div><Loading relative height={200} /></div>;
    }

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <BreadCrumbs items={bread} />
            </div>
            <hr className="mt-0" />
            {!loading && selectedNetwork && networkType !== 'similar_post_pattern'
                && (
                    <CoordinatedDetail
                        selectedCoordination={selectedNetwork}
                        singleNetworkData={selectedNetworkData}
                        header={getHeaderName(networkType)}
                        originalPostedByName={content?.url}
                        goToActors={goToActors}
                    />
                )}
            {!loading && selectedNetwork && networkType === 'similar_post_pattern'
                && (
                    <SimilarPostingPattern data={data?.[0]}
                        header={getHeaderName(networkType)}
                        startDate={getDates(selectedNetworkData).startDate}
                        goToActors={goToActors}
                    />
                )}
            {loading && <div className="bg-white"><Loading relative height={400} /> </div>}
            { !loading && !selectedNetwork && (actor?.from || getParam('subTab') === 'Coordination_actors') ? (
                <AllActors actors={sortedActorsData?.sort(sortFn)}
                    refreshBreadCrums={() => goToActors(getParam('actor_id') || '')}
                    setCoordinationId={handleSetParam}
                    getAllActors={getAllActors}
                    setSelectedData={setSelectedNetworkData}
                    setCoordinationType={setNetworkType}
                />
            ) : !loading && !selectedNetwork && (
                <>
                    <div className="bg-lightgray p-2 rounded">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="w-75">
                                <div className="d-flex gap-1">
                                    <div className="text-primary dont-break-out cursor-pointer" onClick={() => goToActors(actor?.from)}>
                                        <SourceIconLabel
                                            data={{ contentType: (actor?.contentType || actor?.ctype || content?.contentType) }}
                                            className="mr-0"
                                        />
                                        {getPostedByName(actor?.from || content?.from)}
                                    </div>
                                    {' '}
                                    <span>via {ctype?.name || getCtype(content?.contentType).name}</span>
                                </div>
                                <p className="m-0">
                                    <a href={actor?.url || content?.url} rel="noreferrer" target="_blank">
                                        {actor?.url || content?.url}
                                    </a>
                                </p>
                            </div>
                            <DateVisualizer
                                dateType={getCoordinatedDateType('Coordinated_posts')}
                                startDate={(actor?.pubdatetime || content?.pubdatetime || 0) * 1000}
                            />
                        </div>
                        <p className="mt-2">{actor?.text || content?.text}</p>
                    </div>
                    <div className="bg-secondary-alt go-back-investigations rounder-4 text-white p-1 my-3 max">
                        {data?.length} {checkGrammer('coordination', data?.length > 1)}
                    </div>
                    <SingleCoordination coordinationData={data} handleViewDetails={handleViewDetails} getAllActors={getAllActors} />
                </>
            )}
        </div>

    );
};
