import React, { lazy, Suspense, useEffect, useRef } from 'react';
import {
    Route,
    Redirect,
    Switch,
    useLocation,
    matchPath
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SituationRoom } from './pages/Room';
import { useUser } from './context/user/UserContext';
import { routes } from './routes';
import { Loading } from './components/Loading';
import { SignUp } from './pages/SignUp';
import { Profile } from './pages/Profile';
import { Team } from './pages/Team';
import { RoomEditor } from './pages/SituationRooms/RoomEditor';
import { SituationRooms } from './pages/SituationRooms';
import { CreateCustomNarrative } from './pages/Room/CustomNarratives/Overview/CreateCustomNarrative';
import { Notifications } from './components/NavBar/Notifications/index';
import { resetFilters } from './pages/Room/store';

const Admin = lazy(() => import('./pages/Admin').then((module) => ({
    default: module.Admin
})));

export const LoggedInApp = () => {
    const { user, notAllowed } = useUser();
    const dispatch = useDispatch();
    const location = useLocation();
    const previousRoomId = useRef(null);
    const wasInSituationRoom = useRef(false);

    useEffect(() => {
        const match = matchPath(location.pathname, {
            path: '/situation-rooms/:roomId/*',
            exact: false,
            strict: false,
        });

        if (match) {
            const currentRoomId = match.params.roomId;
            wasInSituationRoom.current = true;

            if (previousRoomId.current && previousRoomId.current !== currentRoomId) {
                dispatch(resetFilters({ roomId: previousRoomId.current }));
            }

            previousRoomId.current = currentRoomId;
        } else {
            if (wasInSituationRoom.current) {
                dispatch(resetFilters());
            }
            wasInSituationRoom.current = false;
            previousRoomId.current = null;
        }
    }, [dispatch, location.pathname]);

    if (!user) return <Redirect to="/login" />;

    const isAlertsEnabled = user?.instance?.plan?.others?.alerts;

    return (
        <div className="content-page d-flex flex-column position-relative" id="content-page">
            <Switch>
                <Suspense fallback={<Loading relative height={500} />}>
                    <Route exact path={routes.loggedIn.base}>
                        <Redirect to={routes.loggedIn.situationRooms} />
                    </Route>
                    <Route path={routes.loggedIn.situationRooms} exact>
                        <SituationRooms />
                    </Route>
                    <Route path={routes.loggedIn.situationRoomCreate} exact>
                        <RoomEditor />
                    </Route>
                    <Route path={routes.loggedIn.situationRoomDraftCreate}>
                        <RoomEditor draft />
                    </Route>
                    <Route path={routes.loggedIn.situationRoomDraftEdit}>
                        <RoomEditor draft edit />
                    </Route>
                    <Route path={routes.loggedIn.situationRoomEdit}>
                        <RoomEditor edit />
                    </Route>
                    <Route path={routes.admin.base}>
                        {user.uid && <Admin />}
                    </Route>
                    {!notAllowed.includes('teamPage') && (
                        <Route path={routes.loggedIn.teams}>
                            <Team />
                        </Route>
                    )}
                    <Route path={routes.loggedIn.signUpInvite}>
                        <SignUp />
                    </Route>
                    <Route path={routes.loggedIn.profile}>
                        <Profile />
                    </Route>
                    <Route path={routes.situationRoom.base}>
                        <SituationRoom />
                    </Route>
                    <Route path={routes.loggedIn.createCustomNarrative} exact>
                        <CreateCustomNarrative />
                    </Route>
                    {isAlertsEnabled && (
                        <Route path={routes.loggedIn.notifications} exact>
                            <Notifications />
                        </Route>
                    )}
                </Suspense>
            </Switch>
        </div>
    );
};
