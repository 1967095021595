import React from 'react';
import { Button } from 'reactstrap';
import { getRoom } from '../../../../utils/variables';
import { RecoverSuccessModal } from './RecoverSuccessModal';
import { useModal } from '../../../../context/modal/ModalComponent';
import { useRecoverContent } from '../../../../components/Organisms/Modals/BatchRecoverModal/useRecoverContent';

export const RecoverContentModal = ({ contentIds, onSuccess, id }) => {
    const { setModal, closeModal } = useModal();
    const room = getRoom();
    const { recoverContent, loading } = useRecoverContent({
        onCompleted: () => {
            if (onSuccess) onSuccess();
            handleSuccess();
        },
        id
    });

    const handleRecoverContent = () => recoverContent(contentIds);
    const handleSuccess = () => setModal({
        component: <RecoverSuccessModal contentIds={contentIds} />
    });

    return (
        <>
            <p>
                {contentIds > 1 ? 'These contents' : 'This content'} will be moved back to the &quot;{room?.project_name}&quot;
                situation room
                It will be visible to you and your team in the corresponding section.
            </p>
            <hr />
            <Button onClick={closeModal}>Cancel</Button>
            <Button color="primary" disabled={loading} className="ml-2" onClick={handleRecoverContent}>Recover</Button>
        </>
    );
};
