import { gql, useQuery } from '@apollo/client';
import { merge, omit } from 'lodash';
import { formatLabel } from '../../pages/Room/Overview/Visualisation/utils';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useSentiment = ({ defaultFilters = {}, clusters = false, appliedFilters = undefined } = {}) => {
    const filters = useContentFilters({ useGlobalFilters: true, defaultFilters });
    const sanitizedAppliedFilters = appliedFilters?.filters
        ? omit(appliedFilters.filters, ['sentiment'])
        : {};
    const ret = useQuery(CURRENT_SENTIMENT, {
        variables: {
            ...filters,
            filters: omit(merge(filters.filters, sanitizedAppliedFilters), ['labels']),
        },
        skip: clusters
    });

    return {
        ...ret,
        data: ret.data ? ret.data?.currentSentiment?.map(item => ({
            ...item,
            sentiment: formatLabel(item.sentiment, 'sentiment__keyword'),
            key: item.sentiment
        })) || [] : []
    };
};

export const CURRENT_SENTIMENT = gql`
    query currentSentiment(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
    ) {
        currentSentiment(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
        ) {
            sentiment
            count
        }
    }
`;
