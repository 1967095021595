import React, { useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { SortbyDropdown } from './SortbyDropdown';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { CoordinatedDetail } from './CoordinatedDetail';
import { getCoordinatedDateType } from '../../../../utils/coordinatedTypes';
import { getParam, setParam, unsetParam } from '../../../../utils/urlParams';
import { SimilarPostingPattern } from './SimilarPostingPattern';
import { AllActors } from './AllActors';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { CoordinatedSingle } from './CoordinatedSingle';
import { getSortOptions } from '../Utils/sortOptions';
import { getDates } from '../../../../components/Organisms/ContentTableView/ContentCoorindation/CoordinatedActivityList';
import { CoordinationDataType, SingleNetworkProps } from './coordinationDataTypes';

type CoordinatedPostsType = {
    header: 'Coordinated_posts' | 'Coordinated_reposts' | 'Coordinated_hashtags' | 'Coordinated_hashtag_sequences'
    | 'Similar_account_handles' | 'Similar_posting_patterns' | 'Posting_pattern' | 'Coordination_actors' | string
}

type CoordinatedMainProps = {
    ttpData: CoordinationDataType[]
    setIsSubTab: (isSubTab: boolean) => void
    actorsData: any
}

export const CoordinatedMain = ({ ttpData, setIsSubTab, actorsData }: CoordinatedMainProps) => {
    const [coordinationId, setCoordinationId] = useState<string>(getParam('coordination_id') || '');
    const [selectedData, setSelectedData] = useState<[SingleNetworkProps]>();
    const [header, setHeader] = useState<CoordinatedPostsType['header']>(getParam('subTab') || '');
    const [actorId, setActorId] = useState(getParam('actor_id'));
    const clusterId = getParam('narrative-theme');
    const singleHeader = capitalizeFirstLetter(`${header.split('_')[1].replace(/s$/, '')}_network`);
    const initalSort = getSortOptions(header)?.[0];
    const [sort, setSort] = useState(initalSort || {});
    const [coordinationType, setCoordinationType] = useState<string>('');

    const sortedActors = useMemo(() => {
        const occurrences: { [key: string]: any } = {};
        ttpData?.map((x) => x.network).flat().forEach(item => {
            if (item.from) {
                if (!occurrences[item.from]) {
                    occurrences[item.from] = { count: 0, ctype: item.ctype };
                }
                occurrences[item.from].count += 1;
            }
        });
        // Sort occurrences by count (descending)
        return Object.entries(occurrences)
            .sort((a, b) => b[1].count - a[1].count)
            .map(([key, value]) => ({ from: key, count: value.count, ctype: value.ctype }));
    }, [ttpData]);

    const occurrences1: { [key: string]: number } = {};
    ttpData?.filter((x) => x.coordinationType === 'cohashtag').forEach(item => {
        occurrences1[item.coordinationSource] = occurrences1[item.coordinationSource] ? occurrences1[item.coordinationSource] + 1 : 1;
    });
    const uniqueActors = (item: any, key: string) =>
        Array.from(new Map(item?.network?.map((item1: any) => [item1[key], item1])).values());
    const sortFn = (a: any, b: any) => {
        if (header === 'Coordination_actors') {
            if (sort.field === 'count') {
                return sort.order === 'dse'
                    ? a.ttps.length - b.ttps.length
                    : b.ttps.length - a.ttps.length;
            }

            if (sort.field === 'from') {
                const uniqueA: any[] = uniqueActors(a, sort.field);
                const uniqueB: any[] = uniqueActors(b, sort.field);

                if (uniqueA.length === 0 && uniqueB.length === 0) return 0; // Handle both empty
                if (uniqueA.length === 0) return sort.order === 'asc' ? -1 : 1; // a is empty
                if (uniqueB.length === 0) return sort.order === 'asc' ? 1 : -1; // b is empty

                return sort.order !== 'dse'
                    ? uniqueA.length - uniqueB.length
                    : uniqueB.length - uniqueA.length;
            }
            if (sort.field === 'network') {
                return sort.order === 'dse'
                    ? a.count - b.count
                    : b.count - a.count;
            }
            if (sort.field === 'creation_time') {
                const firstPostedTime = a.pubtime.sort((first: number, second: number) => first - second)[0];
                const secondPostedTime = b.pubtime.sort((first: number, second: number) => first - second)[0];
                return sort.order === 'dse'
                    ? firstPostedTime - secondPostedTime : secondPostedTime - firstPostedTime;
            }
            return 0;
        }
        if (sort.field === 'network') {
            return sort.order === 'dse'
                ? a.network.length - b.network.length
                : b.network.length - a.network.length;
        }
        if (sort.field === 'from') {
            const uniqueA: any[] = uniqueActors(a, sort.field);
            const uniqueB: any[] = uniqueActors(b, sort.field);
            if (uniqueA.length === 0 && uniqueB.length === 0) return 0; // Handle both empty
            if (uniqueA.length === 0) return sort.order === 'asc' ? -1 : 1; // a is empty
            if (uniqueB.length === 0) return sort.order === 'asc' ? 1 : -1; // b is empty

            return sort.order !== 'asc'
                ? uniqueA.length - uniqueB.length
                : uniqueB.length - uniqueA.length;
        }
        if (sort.field === 'shortest_timeframe') {
            const diffA = getDates(a.network).endDate - getDates(a.network).startDate;
            const diffB = getDates(b.network).endDate - getDates(b.network).startDate;
            return diffA - diffB;
        }

        return 0;
    };

    const getWidgetName = (name: string) => {
        // Replace underscores with spaces
        const nameFormatted = name.replace(/_/g, ' ');
        // Capitalize words after spaces
        return nameFormatted.replace(/\s+(\w)/g, (_, p1) => p1.toUpperCase()) as keyof typeof WidgetName;
    };

    const updateActorID = () => {
        setActorId(getParam('actor_id'));
        setCoordinationId(getParam('coordination_id') || '');
    };

    const generateBreadcrumbs = () => {
        const breadcrumbs = [];
        if (!clusterId) {
            breadcrumbs.push({
                title: 'Coordination Info',
                onClick: () => {
                    setCoordinationId('');
                    unsetParam('subTab');
                    unsetParam('coordination_id');
                    unsetParam('actor_id');
                    unsetParam('proxyTab');
                    setIsSubTab(false);
                }
            });
        }
        if (header !== 'Coordination_actors') {
            breadcrumbs.push({
                title: header.replaceAll('_', ' '),
                ...(coordinationId && { onClick: () => {
                    setCoordinationId('');
                    setActorId('');
                    unsetParam('coordination_id');
                    unsetParam('actor_id');
                    unsetParam('proxyTab');
                } }
                )
            });
            if (coordinationId) {
                breadcrumbs.push({
                    title: singleHeader.replaceAll('_', ' ')
                });
            }
        }
        if (header === 'Coordination_actors') {
            breadcrumbs.push({
                title: header.replaceAll('_', ' '),
                ...(actorId && { onClick: () => {
                    setSort(getSortOptions('Coordination_actors')[0]);
                    setCoordinationId('');
                    setActorId('');
                    unsetParam('coordination_id');
                    unsetParam('actor_id');
                    unsetParam('proxyTab');
                } })
            });
            if (actorId) {
                breadcrumbs.push({
                    title: actorId?.replaceAll('_', ' ') || '',
                    ...(actorId && coordinationId && { onClick: () => {
                        setCoordinationId('');
                        unsetParam('coordination_id');
                        unsetParam('proxyTab');
                    } })
                });
            }
            if (actorId && coordinationId) {
                breadcrumbs.push({
                    title: (coordinationType || coordinationId)?.replaceAll('_', ' ') || ''
                });
            }
        }
        return breadcrumbs;
    };

    const handleActors = () => {
        unsetParam('actor_id');
        unsetParam('subTab');
        setParam('subTab', 'Coordination_actors');
        setActorId('');
        setCoordinationId('');
        setHeader('Coordination_actors');
        setSort(getSortOptions('Coordination_actors')[0]);
    };
    const bread = useMemo(
        () =>
            generateBreadcrumbs(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [actorId, coordinationId]
    );
    const handleActorRoute = (name: string) => {
        unsetParam('coordination_id');
        setParam('subTab', 'Coordination_actors');
        setParam('actor_id', name);
        setIsSubTab(true);
        setCoordinationId('');
        setActorId(name);
        setHeader('Coordination_actors');
        setSort(getSortOptions('Coordination_actors')[0]);
    };
    const getAllActors = () => {
        setHeader('Coordination_actors');
        setCoordinationId('');
        setActorId('');
        unsetParam('coordination_id');
        unsetParam('actor_id');
        unsetParam('proxyTab');
        setSort(getSortOptions('Coordination_actors')[0]);
    };
    return (
        <div className="bg-white">
            <div className="d-flex align-items-center justify-content-between">
                <BreadCrumbs items={bread} />
                {!(coordinationId || actorId) && <SortbyDropdown sortFor={header} sort={sort} setSort={(data) => setSort(data)} />}
            </div>
            <hr className="mt-1" />
            {header !== 'Coordination_actors' || (actorId && coordinationId)
                ? (
                    <div>
                        {!coordinationId
                            ? (
                                <div className="d-flex align-items-center">
                                    <h3 className="mr-1 my-0">
                                        <WidgetTextTooltip widgetName={WidgetName[getWidgetName(header)]} noMargin />
                                    </h3>
                                    <span className="tag" color="dark">{ttpData?.length || 1} networks </span>
                                </div>
                            ) : null}
                        {coordinationId
                            ? (
                                <CoordinatedDetail
                                    selectedCoordination={coordinationId}
                                    header={singleHeader}
                                    singleNetworkData={selectedData}
                                    goToActors={handleActorRoute}
                                />
                            )
                            : (
                                <>
                                    <hr className="mb-1" />
                                    <div className="d-flex justify-content-between mb-1">
                                        <h3><WidgetTextTooltip widgetName={WidgetName.Top3Actors} /></h3>
                                        <Button color="link" className="pr-0" onClick={handleActors}>View All</Button>
                                    </div>
                                    <div className="row gap-2 mx-0 mb-2">
                                        {sortedActors?.slice(0, 3).map((actor: {from: string, ctype: string, count: number}) => (
                                            <div key={actor.from}
                                                onClick={() => handleActorRoute(actor.from)}
                                                className="col cursor-pointer py-1
                                                 d-flex justify-content-between rounded hover-state"
                                            >
                                                <div className="d-flex align-items-center">
                                                    <SourceIconLabel data={{ contentType: actor.ctype }} />
                                                    <p className="mb-0">{actor?.from}</p>
                                                </div>
                                                <p className="tag mb-0" color="dark">{actor.count}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <hr />
                                    <div className="actors-modal scrollbar-small mt-3">
                                        <div className="mb-2">
                                            {ttpData?.sort(sortFn)
                                                .map((item: CoordinationDataType) => {
                                                    const summaryText = header === 'Coordinated_posts'
                                                        ? item.network.filter((ttp: any) => ttp.docId === item.coordinationSource)[0].text
                                                        : item.coordinationSource;
                                                    if (header === 'Similar_posting_patterns') {
                                                        return (
                                                            <SimilarPostingPattern data={item}
                                                                header={header}
                                                                startDate={getDates(item.network)?.startDate || new Date()}
                                                            />

                                                        );
                                                    }
                                                    return (
                                                        <CoordinatedSingle getCoordinatedDateType={getCoordinatedDateType(header)}
                                                            getWidgetName={getWidgetName(singleHeader)}
                                                            header={header}
                                                            item={item}
                                                            setCoordinationId={setCoordinationId}
                                                            setSelectedData={setSelectedData}
                                                            startDate={getDates(item.network).startDate}
                                                            endDate={getDates(item.network).endDate}
                                                            summaryText={summaryText}
                                                            getAllActors={getAllActors}
                                                        />
                                                    );
                                                })}
                                            <div />
                                        </div>
                                    </div>
                                </>
                            )}
                    </div>
                ) : (
                    <AllActors actors={actorsData.sort(sortFn)}
                        refreshBreadCrums={updateActorID}
                        setCoordinationId={setCoordinationId}
                        setCoordinationType={setCoordinationType}
                        setSelectedData={setSelectedData}
                        getAllActors={getAllActors}
                    />
                )}
        </div>
    );
};
