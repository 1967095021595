import React, { useState } from 'react';
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Input,
    Pagination
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { useDropDown } from '../../../../utils/useDropdown';
import { Loading } from '../../../Loading';
import { MultiCheckboxFilter } from './MultiCheckboxFilter';
import { color } from '../../../../utils/getColors';

export type ItemProps = {
    id: string,
    name: string,
    selected: boolean
    contentIds?: string[],
    count?: number
}

type DropdownWithMultiCheckboxProps = {
    value: string,
    name: string,
    filterOptions: ItemProps[],
    handleSelectItem: (e: string[]) => void,
    singleDropdown?: boolean,
    loading?: boolean,
    threat?: boolean,
    itemsPerPage?: number,
    cluster?: boolean
    dontTruncate?: boolean
    noSearch?: boolean
}
export const DropdownWithMultiCheckbox = ({
    value,
    name,
    filterOptions,
    handleSelectItem,
    singleDropdown = false,
    loading = false,
    threat = false,
    itemsPerPage = 30,
    cluster = false,
    dontTruncate = false,
    noSearch = false
}: DropdownWithMultiCheckboxProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const [searchValue, setSearchValue] = useState('');
    const [offset, setOffset] = useState(0);

    const filteredItems = searchValue ? filterOptions.filter(item =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())) : filterOptions;
    const selectedOptions = filteredItems?.length ? filteredItems.filter((option) => option.selected) : [];
    const handleSetValues = (filters: ItemProps[]) => {
        const filterIds = filters.map((a) => a.id);
        handleSelectItem(filterIds);
    };

    return (
        <Dropdown isOpen={dropdown} toggle={toggleDropdown}>
            <DropdownToggle tag="div"
                className={`dropdown-filter d-flex justify-content-between
                 align-items-center cursor-pointer px-2 py-1 ${dropdown && 'border border-primary'}`}
                data-testid="dropdown-toggle"
            >
                <span>{name}</span>
                <ChevronDown size={20}
                    className="ml-11"
                    color={color.blue[500]}
                    style={{ transition: 'all .25s ease-in-out', transform: dropdown ? 'rotate(-180deg)' : 'none' }}
                />
            </DropdownToggle>
            <DropdownMenu className={dropdown ? 'border border-primary' : ''}>
                {(filterOptions.length > 0 && !noSearch) && (
                    <div className="p-2 border-bottom">
                        <Input value={searchValue}
                            onChange={e => { setSearchValue(e.target.value); setOffset(0); }}
                            placeholder="Search"
                            data-testid="dropdown-search-input"
                        />
                    </div>
                )}
                <div className={`${(singleDropdown) && 'single-column-dropdown'} ${(!singleDropdown && !threat)
                && 'dropdown-filter-multi-checkbox'} py-2 pr-2`}
                >
                    {(filteredItems.length > 0 && !loading) && (
                        <MultiCheckboxFilter title="Sources"
                            filters={filteredItems}
                            values={filteredItems?.filter(src => !selectedOptions?.map(x => x.id).includes(src.id))?.map(x => x.id)}
                            setValues={(newItem: string[], selectAll?: boolean) => {
                                const newItems = filteredItems.filter(src => !newItem.includes(src.id) && src.count !== 0);
                                if (selectAll && newItems.length === selectedOptions.length) {
                                    handleSetValues([]);
                                } else {
                                    handleSetValues(newItems);
                                }
                            }}
                            value={value}
                            threat={threat}
                            cluster={cluster}
                            dontTruncate={dontTruncate}
                        />
                    )}
                    {loading && <Loading relative height={168} width={255} />}
                    {(filteredItems.length === 0 && !loading) && <p className="text-center no-white-space pl-2">No results found</p>}
                </div>
                {filteredItems.length > itemsPerPage && !loading && (
                    <Pagination total={filteredItems.length}
                        itemsPerPage={itemsPerPage}
                        offset={offset}
                        setOffset={setOffset}
                        className="p-2 mb-0"
                    />
                )}
            </DropdownMenu>
        </Dropdown>
    );
};
