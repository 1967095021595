import React from 'react';
import { Button } from 'reactstrap';
import { Cpu } from 'react-feather';
import { WidgetName, WidgetTextTooltip } from '../../../../pages/Room/AIClustering/SelectedClusterView/Summary/WidgetTextTooltip';
import { getCoordinatedDateType, getHeaderName, getWidgetName } from '../../../../utils/coordinatedTypes';
import { DateVisualizer } from '../../../DateVisualizer';
import { SummaryText } from './SummaryText';
import { IconsAndCount } from '../../../../pages/Room/AIClustering/CoordinatedModals/ActorsAndPosts';
import { getDates } from './CoordinatedActivityList';
import { useModal } from '../../../../context/modal/ModalComponent';
import { CoordinatedDetail } from '../../../../pages/Room/AIClustering/CoordinatedModals/CoordinatedDetail';
import { CoordinationDataType, SingleNetworkProps } from '../../../../pages/Room/AIClustering/CoordinatedModals/coordinationDataTypes';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { SimilarPostingPattern } from '../../../../pages/Room/AIClustering/CoordinatedModals/SimilarPostingPattern';
import { getParam } from '../../../../utils/urlParams';

type SingleCoordinationProps = {
    coordinationData: CoordinationDataType[];
    handleViewDetails?: (item: any) => void;
    isSummaryPage?: boolean
    contentUrl?: string
    getAllActors?: (name: string[]) => void
};

export const SingleCoordination = ({ coordinationData, handleViewDetails, contentUrl, isSummaryPage = false,
    getAllActors }: SingleCoordinationProps) => {
    const { setModal, closeModal } = useModal();
    const header = getParam('subTab');
    const openCoordinationModal = (item: CoordinationDataType) => {
        const networkData = [...(item.network || [])];
        setModal({
            header:
    <div className="d-flex align-items-center">
        <WidgetTextTooltip widgetName={WidgetName.CoordinationDetected} /><Cpu className="ml-1 mb-1" />
    </div>,
            component:
                item.coordinationType === 'similar_post_pattern' ? (
                    <SimilarPostingPattern data={item}
                        header={item.coordinationType}
                    />
                )
                    : (
                        <CoordinatedDetail
                            selectedCoordination={item.coordinationSource}
                            singleNetworkData={networkData.sort(
                                (a: SingleNetworkProps, b: SingleNetworkProps) => {
                                    const dateA = new Date(a.pubdatetime);
                                    const dateB = new Date(b.pubdatetime);
                                    return dateB.getTime() - dateA.getTime(); // Compare timestamps
                                }
                            )}
                            header={getHeaderName(item.coordinationType)}
                            originalPostedByName={contentUrl}
                        />
                    ),
            size: 'xl',
            onClose: closeModal
        });
    };
    const getSubWidgetName = (name: string) => {
        // Replace underscores with spaces
        const nameFormatted = name?.replace(/_/g, ' ');
        // Capitalize words after spaces
        return nameFormatted?.replace(/\s+(\w)/g, (_, p1) => p1?.toUpperCase()) as keyof typeof WidgetName;
    };

    return (
        <div className="threats-coordination-modal scrollbar-small">
            {coordinationData?.map((item: CoordinationDataType) => {
                const singleHeader = header ? capitalizeFirstLetter(`${(header.split('_')?.[1] || header.split('_')?.[0])?.replace(/s$/, '')}_network`) : item.coordinationType;
                const summaryText = item?.coordinationType === 'copost'
                    ? item.network.filter((ttp: SingleNetworkProps) => ttp.docId === item.coordinationSource)?.[0]?.text
                    : item.coordinationSource;
                const uniqueActors = Array.from(
                    new Map(item.network.map((item1: { from: string }) => [item1.from, item1])).values()
                );
                return (
                    <div key={item.id} className="bg-lightgray p-2 rounded mb-2">
                        <div className="d-flex justify-content-between align-items-center">

                            <p className="m-0 font-weight-bold widget-text-style">
                                <WidgetTextTooltip noMargin
                                    widgetName={WidgetName[header ? getSubWidgetName(singleHeader)
                                        : getWidgetName(singleHeader) as keyof typeof WidgetName]
                                        || WidgetName[getWidgetName(item.coordinationType) as keyof typeof WidgetName]}
                                />
                            </p>
                            <DateVisualizer dateType={(getCoordinatedDateType(item.coordinationType))}
                                startDate={getDates(item.network).startDate}
                                endDate={getDates(item.network).endDate}
                            />
                        </div>
                        <hr />
                        <SummaryText item={item} summaryText={summaryText} />
                        <div className="d-flex align-items-end justify-content-between">
                            <div className="d-flex gap-2">
                                <span onClick={() => getAllActors?.(uniqueActors.map((item1: any) => item1.from))}
                                    className="cursor-pointer"
                                >
                                    <IconsAndCount link label="Actors" count={uniqueActors.length} />
                                </span>
                                <IconsAndCount label="Posts" count={item.network.length} />
                            </div>
                            <Button size="sm"
                                onClick={() => {
                                    isSummaryPage ? openCoordinationModal(item)
                                        : handleViewDetails && handleViewDetails(item);
                                }}
                            >
                                View
                            </Button>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
