import { gql, useQuery } from '@apollo/client';
import { merge, omit } from 'lodash';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useThreatSignals = ({ dateFilter = undefined, useGlobalFilters = true, projectId = '', appliedFilters = undefined } = {}) => {
    const filters = useContentFilters({
        useGlobalFilters,
        defaultFilters: {
            date: dateFilter
        }
    });
    const sanitizedAppliedFilters = appliedFilters?.filters
        ? omit(appliedFilters.filters, ['threatResolvers', 'threatLabels', 'threatTypes'])
        : {};
    const ret = useQuery(TOP_THREAT_SIGNALS, {
        variables: {
            ...filters,
            filters: omit(merge(filters.filters, sanitizedAppliedFilters), ['labels']),
            projectId: filters.projectId || projectId,
        }
    });

    return {
        ...ret,
        data: ret.data ? ret.data?.topThreatSignals : [],
        total: ret.data?.topThreatSignals?.total || 0
    };
};

export const TOP_THREAT_SIGNALS = gql`
    query topThreatSignals(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $size: Int
    ) {
        topThreatSignals(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            size: $size
        ) {
                signal
                count
        }
    }
`;
