import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Alert, InfoBanner } from '../../../../components/Atoms';
import { BooleanInputValidated } from '../../../../components/Molecules/BooleanInputValidated';
import { useRoomEditor } from '../RoomEditorContext';
import { useSituationRoomDraftByID } from '../../../../services/SituationRooms/useSituationRoomDraftByID';
import { getToast } from '../../../../utils/getToast';

export const BooleanQuery = ({
    booleanQuery,
    setBooleanQuery,
    disabled = false
}) => {
    const { errors, setErrors, validationErrors, setValidationErrors, room, draft } = useRoomEditor();
    const [showBanner, setShowBanner] = useState();
    const toast = getToast();
    const { data: draftRoom } = useSituationRoomDraftByID({ id: room?.draftId });

    const handleCloseDraftBanner = () => {
        toast.info('Alert closed. You can still access the saved drafts from the home page.');
        setShowBanner(false);
    };

    const showBannerRef = useRef(false);

    useEffect(() => {
        if (!draft && draftRoom && !showBannerRef.current) {
            showBannerRef.current = true;
            requestAnimationFrame(() => setShowBanner(true));
        }
    }, [draftRoom, draft]);
    return (
        <>
            {!draft && draftRoom && showBanner && (
                <div className="mr-2 w-100 banner-width">
                    <InfoBanner closeBanner={() => handleCloseDraftBanner()}>
                        <div className="pr-2 mr-auto">
                            {draftRoom.createdBy?.displayName} saved a draft on {
                                moment(draftRoom.updatedAt || draftRoom.createdAt).format('DD/MM/YYYY | hh:mm A')
                            }, would you like to restore this draft?
                        </div>
                        <Link to={`/situation-room/edit-draft-room/${draftRoom.id}`}>
                            Restore
                        </Link>
                    </InfoBanner>
                </div>
            )}
            <div className="d-flex flex-column flex-1">
                {errors.query && (
                    <div>
                        <Alert closeAlert={() => setErrors({ ...errors, query: false })}
                            errorMessage={(
                                <>
                                    <span>
                                        We cannot submit your query, please check the Help tab and revise your query before resubmitting.
                                    </span>
                                    <div className="mb-n3">
                                        {errors.query.map((error, i) => {
                                            const key = error.errorCode + i;
                                            return <p key={key} className="mb-0 mt-1">{error.message}</p>;
                                        })}
                                    </div>
                                </>
                            )}
                        />
                    </div>
                )}
                {errors.emptyBoolean && <p className="text-danger">Your room must have a Boolean to operate.</p>}
                {errors.validationErrors && <p className="text-danger">Remove all errors by amending the Boolean.</p>}
                {(!errors.emptyBoolean && errors.noChanges) && <p className="text-danger">Make amendments to the Boolean before saving.</p>}
                <div className="border rounded d-flex flex-column flex-1">
                    <BooleanInputValidated booleanQuery={booleanQuery}
                        setBooleanQuery={setBooleanQuery}
                        validationErrors={validationErrors}
                        setValidationErrors={setValidationErrors}
                        disabled={disabled}
                        resetGlobalErrors={() => {
                            if (errors.query) {
                                setErrors({ ...errors, query: false });
                            }
                            if (errors.validationErrors) {
                                setErrors({ ...errors, validationErrors: false });
                            }
                        }}
                    />
                </div>
            </div>
        </>

    );
};
